import axios from 'axios';
import { BASEURL } from "../helper/constant.js";

export default () => {
    const instance = axios.create({
        baseURL: BASEURL
    })
    if (localStorage.getItem('token')) {
        instance.defaults.headers.common["authorization"] = `${localStorage.getItem('token')}`;
    }
    instance.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

    instance.interceptors.response.use(
        function (response) {
            return response;
        },
        function (error) {
            if (error.response.status == 401) {
                console.log("Your authorization token is invalid or expired");
                localStorage.clear();
                window.location.replace("/");
            }else if(error.response.status == 403){
                //Redirect to forbidden Page   
            }
            return Promise.reject(error.response);
        }
    );
    return instance;
};