import { combineReducers } from 'redux';
import authReducer from './auth/reducers';
import CountryReducer from './country/reducers';
import RoleReducer from './role/reducers';
import UserReducer from './user/reducers';
import LoginUserInfoReducer from './userInfo/reducers';


const rootReducers = combineReducers({
    auth: authReducer,
    role: RoleReducer,
    user: UserReducer,
    country: CountryReducer,
    loginUserInfo: LoginUserInfoReducer,
});

export default rootReducers;
