// Barcode.js
import React from 'react';
import Barcode from 'react-barcode';

const BarcodeComponent = ({ barcode_no }) => {
  return (
    <div>
      <Barcode
        value={barcode_no}
        format="CODE128" // You can choose other formats if needed
        displayValue={true}
        textAlign="center"
        fontSize={16}
        width={2}
        height={50}
      />
    </div>
  );
};

export default BarcodeComponent;
