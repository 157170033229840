import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Navigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { handleLogin } from "../../../redux/auth/actions";
import { toast, ToastContainer } from "react-toast";
import { BASEURL } from "../../../helper/constant";
import AuthServices from "../../../ApiServices/AuthServices";
import Api from "../../../ApiServices/MasterServices";
import { async } from "q";
import { FaSpinner } from "react-icons/fa";
function generateUniqueId() {
  return "_" + Math.random().toString(36).substr(2, 9);
}

function disableAutocomplete() {
  // Get all password input fields
  const passwordFields = document.querySelectorAll('input[type="password"]');
  // Disable autocomplete for each password field
  passwordFields.forEach((field) => {
    field.setAttribute("autocomplete", "new-password");
  });
}

const Login = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //const user = useSelector(state => state.auth.user)
  const user = localStorage.getItem("user");
  const [selectedValue, setSelectedValue] = useState("email");
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [disableSubmitButtonM, setDisableSubmitButtonM] = useState(false);
  const [uniqueId] = useState(generateUniqueId());

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    // Call the function to disable autocomplete when the component mounts
    disableAutocomplete();
  }, []);

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user]);

  const getPermissionsWithRole = async (role_id) => {
    try {
      const res = await Api.getPermissionsByRoleID(role_id);
      if (res.status) {
        let prevIds = [];
        res.data.data.permissions.map((p) => {
          prevIds.push(p.permission_id);
        });
        localStorage.setItem(
          "user_permissions",
          JSON.stringify(res.data.data.permissions)
        );
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log(e, "error in getPermissionsWithRole");
      return false;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const body = {
      m_user_email: e.target.email.value,
      m_user_password: e.target.password.value,
    };
    console.log(body);
    setTimeout(() => {
      setDisableSubmitButton(false);
    }, 1000);
    setDisableSubmitButton(true);
    //dispatch(handleLogin(body))
    AuthServices.Login(body)
      .then(async (response) => {
        const { data, status } = response;
        console.log("response", response.data);
        if (status == 200 && data.status) {
          toast.success(data.message);
          localStorage.setItem("user", JSON.stringify(data?.data?.user));
          localStorage.setItem(
            "favourites",
            JSON.stringify(data?.data?.favourites)
          );
          localStorage.setItem("module", JSON.stringify(data?.data?.module));
          localStorage.setItem("token", data?.data?.token);
          localStorage.setItem("role_id", data?.data?.user?.role_id);
          localStorage.setItem("user_id", data?.data?.user?.user_id);
          if (data?.data?.user?.role_id) {
            const getPermisson = await getPermissionsWithRole(
              data?.data?.user?.role_id
            );

            console.log("response", response.data);
            console.log("getPermisson", getPermisson);

            if (getPermisson) {
              console.log("response", response.data);
              setDisableSubmitButton(false);
              window.location.href = '/user-list'
              //history.push('/');
            }
          }
          setDisableSubmitButton(false);
        } else {
          toast.error(data.message);
          setDisableSubmitButton(false);
        }
      })
      .catch((error) => {
        const msg = "Username or Password does not match, please try again";
        toast.error(msg);
        setDisableSubmitButton(false);
      });
  };

  const handleMicrosoft = async () => {
    setTimeout(() => {
      setDisableSubmitButtonM(false);
    }, 1000);
    setDisableSubmitButtonM(true);
    const resp = await AuthServices.Microsoft("");
    console.log("resp", resp.data);
    if (resp.data.status) {
      window.location.href = resp.data.data.redirectUri;
      setDisableSubmitButtonM(false);
    }
  };

  return (
    <>
      {/* <ToastContainer delay={3000} position="top-right" /> */}
      {/* <div className="flex min-h-screen flex-col justify-center py-16 sm:px-6 lg:px-8 bg-slate-50 bg-[url('https://images.unsplash.com/photo-1570833181606-e694d0560b0c?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OTF8fG1hZ2F6aW5lfGVufDB8fDB8fHww')] "> */}
      <div className="flex min-h-screen flex-col justify-center py-16 sm:px-6 lg:px-8 bg-slate-50  ">
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 border border-gray-300">
            <div className="text-center flex justify-center">
              <div className="py-1 px-6 rounded">
                <img
                  className="mx-auto h-12 w-auto"
                  src="https://imgnew.outlookindia.com/images/home_new_v4/logo_outlook.svg"
                  alt="Your Company"
                />
              </div>
            </div>
            <div className="p-4">
              <h2 className="text-center text-2xl font-medium tracking-tight text-gray-800">
                {/* Sign in to your account */}
                {/* { location.pathname === "/signUp" ? "Sign-Up to your account" : "Sign-In to your account"} */}
                Welcome Back
              </h2>
              <p className="text-center text-sm text-gray-700">
                Please enter your details.
              </p>
            </div>

            <form className="space-y-6" onSubmit={(e) => handleSubmit(e)}>
              <div>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    // name={`email_${uniqueId}`}
                    type="text"
                    // autoComplete="text"
                    autoComplete="off"
                    placeholder="Enter Email"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    // name={`password_${uniqueId}`}
                    type="password"
                    // autoComplete="off"
                    autocomplete="new-password"
                    // autoComplete="current-password"
                    placeholder="Password"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                  />
                </div>
              </div>
              {/* <div className="relative flex items-center justify-between">
                <div className="flex">
                  <div className="flex h-5 items-center">
                    <input
                      id="remember-pass"
                      aria-describedby="remember-pass"
                      name="remember-pass"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-red-600 focus:ring-red-500"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      htmlFor="remember-pass"
                      className="font-medium text-gray-700"
                    >
                      Remember for 30 days
                    </label>
                  </div>
                </div>
                <div>
									<Link to="/forgot-password" className="text-red-600 text-sm">Forgot Password ?</Link>
								</div>
              </div> */}

              <div className="text-center">
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                  disabled={disableSubmitButton}
                >
                  {disableSubmitButton ? (
                    <FaSpinner color="gold" className="spinner" />
                  ) : location.pathname === "/signUp" ? (
                    "Sign-Up"
                  ) : (
                    "Sign-In"
                  )}
                  {/* {location.pathname === "/signUp" ? "Sign-Up" : "Sign-In"} */}
                </button>
                <span className="mt-2 mb-2">---OR---</span>
              </div>
            </form>
            <button
              onClick={handleMicrosoft}
              className="flex w-full justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              disabled={disableSubmitButtonM}
            >
              {disableSubmitButtonM ? (
                <FaSpinner color="gold" className="spinner" />
              ) : (
                "Login with Microsoft"
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;
