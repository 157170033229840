import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import MasterServices from "../../../ApiServices/MasterServices";
import ConfirmationModal from '../../../components/ConfirmationModal'
import Breadcrumb from '../../../components/Breadcrumb'
import { FallingLinesLoader } from '../../../components/spinners/Spinner';
import Table from '../../../components/tables/table';
import { subscription_columns } from '../../../components/tables/tableheader';
import { toast } from "react-toast";
import Select from 'react-select'
import makeAnimated from "react-select/animated";
import { getAllowedActions, checkPermissionsAndRedirect } from "../../../helper/commonHelper";
import { convertToAnotherTimezone, calculateAge } from '../../../helper/commonHelper';
import moment from 'moment';
import { utils, writeFile } from 'xlsx';

const Subscription = () => {
    const [loading, setLoading] = useState(false);
    const [subscriptionData, setSubscriptionData] = useState([])
    const navigate = useNavigate();
    const location = useLocation();
    const { customer_id: customer_id } = useParams();
    const pages = [{ title: "Subscription List", href: "/subscription/" + customer_id, module_id: 10 }];
    const [confirmationModal, setConfirmationModal] = useState({
        status: false,
        subs_id: null,
    });

    const [allowedActions, setAllowedActions] = useState([]);
    useEffect(() => {
        const permissions = JSON.parse(localStorage.getItem("user_permissions"));
        if (permissions && window.location.pathname) {
            // window.location.pathname
            const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect('/subscription', permissions, navigate);
            const actions = getAllowedActions(permissions, '/subscription');
            setAllowedActions(actions);
        }
    }, []);
    const handleExport = (excel_type) => {

        if (subscriptionData?.length > 0) {
            let newSheetdata = [];
            subscriptionData.map((obj, index) => {
                const newData = {
                    SN: parseInt(index) + 1,
                    subs_tot_issues_mm: obj?.subs_tot_issues_mm==0?1:obj?.subs_tot_issues_mm || '-',
                    subs_id: obj?.subs_id || '-',
                    sku_code: obj?.subs_skus_relation?.sku_code || '-',
                    mm_name: obj?.subs_master_magazines_relation?.mm_name || '-',
                    subs_start_date: moment(obj?.subs_start_date).format("DD-MM-YYYY") || '-',
                    subs_end_date: moment(obj?.subs_end_date).format("DD-MM-YYYY") || '-',
                    subs_fk_ord_id: obj?.subs_fk_ord_id || '-',
                    skumagm_subscription: obj?.subs_skus_relation?.skus_magazine_map.length > 0
                        ? obj?.subs_skus_relation?.skus_magazine_map[0]?.skumagm_subscription
                            ?.value
                        : "" || '-',
                    customer_id: obj?.subs_customer_relation?.customer_id || '-',
                    customer_username: (obj?.subs_m_order_relation?.ord_customer_name)?(obj?.subs_m_order_relation?.ord_customer_name):(obj?.subs_customer_relation?.customer_username) || '-',
                    customer_email: obj?.subs_customer_relation?.customer_email || '-',
                    customer_mobile: obj?.subs_customer_relation?.customer_mobile || '-',
                    customer_dob: moment(obj?.subs_customer_relation?.customer_dob).format("DD-MM-YYYY") || '-',
                    Age: calculateAge(moment(obj?.subs_customer_relation?.customer_dob).format("YYYY-MM-DD")) || '0',
                    Country: obj?.subs_m_order_relation?.ord_customer_state_name || '-',
                    State: obj?.subs_m_order_relation?.ord_customer_state_name || '-',
                    City: obj?.subs_m_order_relation?.ord_customer_city_name || '-',
                    Address: obj?.subs_m_order_relation?.ord_customer_customer_address || '-',
                    Pincode: obj?.subs_m_order_relation?.ord_customer_pincode || '-',
                    Gender: obj?.subs_customer_relation?.customer_gender || '-',
                    Occupation: obj?.subs_customer_relation?.customer_occupation || '-',
                    Designation: obj?.subs_customer_relation?.customer_designation || '-',
                    Status: obj?.subs_is_active == true ? 'Active' : 'In-Active' || '-',
                }
                newSheetdata.push(newData);
            });
            let currentDate = new Date();
            let fileName = 'Subscription_Excel_Report_' + moment(currentDate).format("DD-MM-YYYY") + '.' + excel_type;

            const headings = [['SN','Sub Total Issues', 'Sub ID', 'SKU`s Name', 'Magazine Name', 'Start Date', 'End Date', 'Order Id', 'Duration', 'Customer ID', 'Customer Name', 'Customer Email', 'Customer Phone', 'Customer DOB', 'Customer Age', 'Country', 'State', 'City', 'Address', 'Pincode', 'Gender', 'Occupation', 'Designation', 'Status']];
            const wb = utils.book_new();
            const ws = utils.json_to_sheet([]);
            utils.sheet_add_aoa(ws, headings);
            utils.sheet_add_json(ws, newSheetdata, { origin: 'A2', skipHeader: true });
            utils.book_append_sheet(wb, ws, 'Report');
            writeFile(wb, fileName);
        } else {
            toast.error("Data Not Found!");
        }
    }
    // Filter Values Start
    let filterInitialValues = {
        filterFrom: "",
        filterTo: "",
        filterCustomerName: "",
        filterCustomerEmail: "",
        filterCustomerMobile: "",
        filterSubscriptionId: "",
        filterOrderId: "",
        filterMagazineId: { label: "", value: "" },
        filterMagazineIssueId: { label: "", value: "" },
        filterStatus: "",
    };
    let [filterSubscription, setFilterSubscription] = useState(filterInitialValues);
    const [filterFlag, setFilterFlag] = useState(true);
    const [getAllMagazine, setGetAllMagazine] = useState([])
    const [getAllMagazinesIssue, setGetAllMagazinesIssue] = useState([]);
    const animatedComponents = makeAnimated();
    // Function to handle to CLear filter values
    const onFilterClear = () => {
        setIsSearched(false)
        setSubscriptionData([]);
        filterSubscription.filterFlag = false;
        setFilterSubscription(filterInitialValues);
        // getAllSubscriptionData(3);
    }
    // Function to handle changes in filter values
    const handleFilterChange = (event) => {
        const { name, value } = event.target;
        setFilterSubscription({ ...filterSubscription, [name]: value });
    };
    const handleSelectFilterChange = (selectedOption, name) => {
        console.log("selectedOption=>", selectedOption)
        const { label, value } = selectedOption || {};
        setFilterSubscription({ ...filterSubscription, [name]: selectedOption });
    };
    const onFilter = () => {
        if (checkObjectValues(filterSubscription)) {
            setIsSearched(true)
            // setFilterSubscription({ ...filterSubscription, filterFlag: true });
            filterSubscription.filterFlag = true;
            console.log("filterSubscription=>", filterSubscription)
            getAllSubscriptionData(3);
        }else{
            toast.error("Please Select Alteast One Filter!");
        }        
    }
    const getMagazines = () => {
        MasterServices.getAllMagazine(1).then((res) => {
            const { data, status } = res.data
            if (status) {
                const newArray = data?.data?.map((ele) => ({
                    label: ele.mm_name,
                    value: ele.mm_id,
                }))
                setGetAllMagazine(newArray);
            }
        }).catch((err) => {
            console.log(err)
        })
    }
    const getMagazineIssues = (e) => {
        MasterServices.getAllMagazineIssues(e.value).then((res) => {
            const { data, status } = res.data;
            if (status) {
                const newArray = data?.data?.map((ele) => ({
                    value: ele.mi_id,
                    label: ele.mi_name
                }))
                setGetAllMagazinesIssue(newArray)
            }
        }).catch((error) => {
            console.log(error)
        });
    }
    // Filter Values End

    useEffect(() => {
                getMagazines();
        if (customer_id) {
            getAllSubscriptionData(3);
            // setFilterFlag(false)
            setIsSearched(true)            
        }
    }, []);
    useEffect(()=>{
        if (!customer_id) {
            setFilterSubscription(filterInitialValues);
            setIsSearched(false)
            setSubscriptionData([])
        }
    },[location])
    const getAllSubscriptionData = async () => {
        setLoading(true)
        let payload = {
            status_code: 3,
            customer_id: customer_id,
            filterSubscription: filterSubscription,
        }
        const response = await MasterServices.getAllSubscriptionList(payload);
        console.log("response.data=>", response.data);
        if (response.data.status === true) {
            if (response?.data?.data?.length > 0) {
                setSubscriptionData(response?.data?.data);
                if (customer_id) {
                        setFilterSubscription((filterSubscription)=>({ ...filterSubscription, 'filterCustomerName': response.data.data[0].subs_customer_relation.customer_username  }));
                }
            } else {
                setSubscriptionData([]);
            }
            setLoading(false)
        }
    };
    const onDeleteOpen = (subs_id) => {
        setConfirmationModal({ subs_id, status: true });
    }

    const onDeleteItem = (subs_id) => {
        console.log("subs_id", subs_id)
        MasterServices.deleteSubscription({ subs_id })
            .then((response) => {
                const { data, status } = response;
                if (data.status) {
                    setLoading(true)
                    toast.success(data.message)
                    setConfirmationModal({ subs_id: null, status: false });
                    getAllSubscriptionData(3);
                    navigate("/subscription/" + customer_id);
                } else {
                    toast.error(data.message)
                }
                setLoading(false)
            })
            .catch((error) => {
                toast.error("Sorry Fatal Error!")
                setLoading(false)
            });
    }

    const onStatusUpdate = (subs_id, subs_is_deleted) => {
        let payload = {
            table_name: "subscription",
            table_field: "subs_id",
            table_field_val: subs_id,
            table_status_field: "subs_is_deleted",
            table_status_val: subs_is_deleted ? false : true,
            table_text: "Subscription",
            deleted_by_key: "subs_updated_by",
            delete_active_txt: subs_is_deleted ? " Deactivated" : " Activated"
        }
        MasterServices.removeMaster(payload).then((res) => {
            const { data, status, message } = res.data
            if (status) {
                getAllSubscriptionData(3);
                toast.success(message);
            } else {
                toast.error(message)
            }
        })
    }
    // You can define a function to increment the month value
    const incrementMonth = (monthValue) => {
        let nextMonth = parseInt(monthValue, 10) + 1;
        return nextMonth < 10 ? `0${nextMonth}` : `${nextMonth}`;
    }

    const getDateArr = (sortedPayload, selectedMonth, currentYear, DateArr) => {
        DateArr = [];
        sortedPayload?.map((item, ind) => {
            let currentMonthValue = selectedMonth?.value;
            if (ind !== 0) {
                // Increment logic based on your requirement
                currentMonthValue = incrementMonth(selectedMonth?.value);
            }
            let itemDate = currentYear + '-' + currentMonthValue + '-' + item.value;
            let myDate = new Date(itemDate);
            DateArr.push({
                date: myDate, subinm_fk_midm_id: item?.midm_id, subinm_type: item?.midm_type, subinm_label: item?.label
                , subinm_value: item?.value
                , subinm_label_value_json: item?.midm_label_value_json, selectedMonth: selectedMonth
            })
        })
        return DateArr;
    }

    // Function to generate DateArr based on duration and selectedMonth
    const generateMonthArr = (sortedPayload, duration, selectedMonth, currentYear) => {
        const DateArr = [];
        const startMonth = parseInt(selectedMonth.value, 10); // Assuming selectedMonth is an object like { label: "Feb", value: "02" }
        let subinm_fk_midm_id = sortedPayload.length > 0 ? sortedPayload[0]?.midm_id : 0;
        let subinm_type = sortedPayload.length > 0 ? sortedPayload[0]?.midm_type : 0;
        let subinm_label = sortedPayload.length > 0 ? sortedPayload[0]?.label : 0;
        let subinm_value = sortedPayload.length > 0 ? sortedPayload[0]?.value : 0;
        let subinm_label_value_json = sortedPayload.length > 0 ? sortedPayload[0]?.midm_label_value_json : 0;
        let myDate;
        for (let i = 0; i < duration; i++) {
            const monthValue = (startMonth + i) % 12 || 12; // Wrap around to January if needed
            const monthLabel = new Date(currentYear, monthValue - 1, 1).toLocaleString('en-US', { month: 'short' });

            DateArr.push({
                label: monthLabel, value: monthValue < 10 ? `0${monthValue}` : `${monthValue}`, date: myDate, subinm_fk_midm_id, subinm_type, subinm_label
                , subinm_value
                , subinm_label_value_json, selectedMonth: selectedMonth
            });
        }

        return DateArr;
    }

    const handleUpdateLSubs = async (payload, interval, selectedMonth) => {
        // console.log("payload=>",payload);
        // console.log("interval=>",interval);
        let intervalDate = JSON.parse(interval);
        // alert(intervalDate.label)
        let DateArr = [];

        const sortedPayload = [...payload?.DateArrDrop].sort((a, b) => {
            if (a.label === intervalDate.label) return -1; // Place 'Day 14' at the beginning
            if (b.label === intervalDate.label) return 1;
            return a.value - b.value; // Sort the rest based on the 'value'
        });
        const currentYear = new Date().getFullYear();
        let subinm_type = sortedPayload.length > 0 ? sortedPayload[0]?.midm_type : 0;
        console.log("sortedPayload=>", sortedPayload)
        if (subinm_type == "Day") {
            DateArr = getDateArr(sortedPayload, selectedMonth, currentYear, DateArr)
        } else {
            const duration = parseInt(sortedPayload[0].value, 10);
            DateArr = generateMonthArr(sortedPayload, duration, selectedMonth, currentYear);
        }

        let Payload = {
            // subinm_label:payload?.,
            // subinm_value:payload?.,
            // subinm_label_value_json:payload?.,
            // subinm_type:payload?.,
            // subinm_fk_midm_id:payload?.,



            subinm_type: sortedPayload.length > 0 ? sortedPayload[0]?.midm_type : 0,
            // subinm_fk_midm_id:sortedPayload.length>0?sortedPayload[0]?.midm_id:0,

            subinm_fk_subs_id: payload?.subs_id,
            subinm_fk_mm_id: payload?.subs_fk_mm_id,
            subinm_fk_sku_id: payload?.subs_fk_sku_id,
            subinm_fk_ord_id: payload?.subs_fk_ord_id,
            subinm_fk_ord_skum_id: payload?.subs_fk_ord_skum_id,
            subinm_fk_customer_id: payload?.subs_fk_customer_id,
            dateInterval: sortedPayload,
            DateArr: DateArr,
        }
        console.log("Payload=>", Payload);
        return false;

        setLoading(true)
        let res = await MasterServices.updateSubsInterval(Payload)
        if (res.status == 200) {
            toast.success(res.data.message)
            getAllSubscriptionData(3);
            setLoading(false)
        } else {
            toast.error(res.data.message)
            setLoading(false)
        }
    }

    const [isSearched, setIsSearched] = useState(false);
    function checkObjectValues(obj) {
        for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                if (typeof obj[key] === "object") {
                    if (checkObjectValues(obj[key])) {
                        return true;
                    }
                } else if (obj[key] !== "") {
                    return true;
                }
            }
        }
        return false;
    }

    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <ConfirmationModal
                open={confirmationModal.status}
                setOpen={setConfirmationModal}
                onDelete={() => onDeleteItem(confirmationModal.subs_id)}
            />
            <Breadcrumb pages={pages} />


            {filterFlag && (
                <div className="px-2 py-2 pb-5 sm:px-4 rounded-md bg-white shadow-lg ring-1 ring-white ring-opacity-5 focus:outline-none mb-2">
                    <button
                        type="button"
                        className="text-red-500 absolute right-20 hover:text-red-700 font-bold py-1 px-1 rounded-full border border-red-500 hidden"
                        onClick={() => { setFilterFlag(false); onFilterClear(); }}
                    >
                        X
                    </button>
                    <div className="space-y-6 sm:space-y-5">
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 mt-5">
                            <div className="sm:col-span-1 lg:col-span-1">
                                <label htmlFor="filterMagazineId" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Magazine
                                </label>
                                <Select
                                    name="filterMagazineId"
                                    id="filterMagazineId"
                                    placeholder="Select Magazine *"
                                    onChange={(e) => { getMagazineIssues(e); handleSelectFilterChange(e, 'filterMagazineId') }}
                                    value={filterSubscription.filterMagazineId}
                                    options={getAllMagazine}
                                    components={animatedComponents}
                                    className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                                />
                            </div>
                            <div className="sm:col-span-1 lg:col-span-1">
                                <label htmlFor="filterMagazineIssueId" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Magazine Issues
                                </label>
                                <Select
                                    name="filterMagazineIssueId"
                                    id="filterMagazineIssueId"
                                    placeholder="Select Magazine Issues *"
                                    onChange={(e) => { handleSelectFilterChange(e, 'filterMagazineIssueId') }}
                                    value={filterSubscription.filterMagazineIssueId}
                                    options={getAllMagazinesIssue}
                                    components={animatedComponents}
                                    className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                                />
                            </div>

                            <div className="sm:col-span-1 lg:col-span-1">
                                <label htmlFor="filterFrom" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Start Date
                                </label>
                                <input
                                    type="date"
                                    name="filterFrom"
                                    id="filterFrom"
                                    value={filterSubscription.filterFrom}
                                    onChange={handleFilterChange}
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                />
                            </div>
                            <div className="sm:col-span-1 lg:col-span-1">
                                <label htmlFor="filterTo" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    End Date
                                </label>
                                <input
                                    type="date"
                                    name="filterTo"
                                    id="filterTo"
                                    value={filterSubscription.filterTo}
                                    onChange={handleFilterChange}
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                />
                            </div>
                            <div className="sm:col-span-1 lg:col-span-1">
                                <label htmlFor="filterCustomerName" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Customer Name
                                </label>
                                <input
                                    type="text"
                                    name="filterCustomerName"
                                    id="filterCustomerName"
                                    value={filterSubscription.filterCustomerName}
                                    onChange={handleFilterChange}
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                />
                            </div>
                            <div className="sm:col-span-1 lg:col-span-1">
                                <label htmlFor="filterCustomerEmail" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Customer Email
                                </label>
                                <input
                                    type="email"
                                    name="filterCustomerEmail"
                                    id="filterCustomerEmail"
                                    value={filterSubscription.filterCustomerEmail}
                                    onChange={handleFilterChange}
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                />
                            </div>
                            <div className="sm:col-span-1 lg:col-span-1">
                                <label htmlFor="filterCustomerMobile" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Customer Mobile
                                </label>
                                <input
                                    type="number"
                                    name="filterCustomerMobile"
                                    id="filterCustomerMobile"
                                    value={filterSubscription.filterCustomerMobile}
                                    onChange={handleFilterChange}
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                />
                            </div>
                            <div className="sm:col-span-1 lg:col-span-1">
                                <label htmlFor="filterSubscriptionId" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Subscription ID
                                </label>
                                <input
                                    type="number"
                                    name="filterSubscriptionId"
                                    id="filterSubscriptionId"
                                    value={filterSubscription.filterSubscriptionId}
                                    onChange={handleFilterChange}
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                />
                            </div>
                            <div className="sm:col-span-1 lg:col-span-1">
                                <label htmlFor="filterOrderId" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Order ID
                                </label>
                                <input
                                    type="number"
                                    name="filterOrderId"
                                    id="filterOrderId"
                                    value={filterSubscription.filterOrderId}
                                    onChange={handleFilterChange}
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                />
                            </div>
                            <div className="sm:col-span-1 lg:col-span-1">
                                <label htmlFor="filterStatus" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Status
                                </label>
                                <select
                                    name="filterStatus"
                                    value={filterSubscription.filterStatus}
                                    onChange={handleFilterChange}
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                >
                                    <option value="">Select Status</option>
                                    <option value="1">Active</option>
                                    <option value="2">Inactive</option>
                                </select>
                            </div>

                            <div className="sm:col-span-1 md:col-span-2 lg:col-span-2 mt-6">
                                <button
                                    type="button"
                                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2"
                                    onClick={onFilter}
                                >
                                    Search
                                </button>
                                <button
                                    type="button"
                                    className="inline-flex items-center justify-center ml-2 rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2"
                                    onClick={onFilterClear}
                                >
                                    Clear
                                </button>
                            </div>
                        </div>
                        {/* <div className="mt-4 flex justify-end">
                            <button
                                type="button"
                                className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2"
                                onClick={onFilter}
                            >
                                Search
                            </button>
                            <button
                                type="button"
                                className="inline-flex items-center justify-center ml-2 rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2"
                                onClick={onFilterClear}
                            >
                                Clear
                            </button>
                        </div> */}
                    </div>
                </div>
            )}
            {/* <div className="">
                <h1 className="text-xl font-semibold text-gray-900">Subscription List</h1>
            </div> */}
            {/* allowedActions.filter((data) => data.permission_id == 76)?.length > 0 &&  */}
            <div className="flex justify-end mt-5">
                <div className="flex items-center space-x-2 hidden">
                    <div className="relative">
                        <button
                            onClick={() => {
                                setFilterFlag(filterFlag ? false : true);
                            }}
                            className="relative z-0 inline-flex text-sm rounded-md shadow-sm focus:ring-accent-500 focus:border-accent-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 mr-2">
                            <span className="relative inline-flex items-center px-3 py-3 space-x-2 text-sm font-medium text-gray-600 bg-white border border-gray-300 rounded-md sm:py-2">
                                <div>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="w-3 h-3"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
                                        />
                                    </svg>
                                </div>
                                <div className="hidden sm:block">Filters {filterFlag ? 'On' : 'Off'}</div>
                            </span>
                        </button>
                    </div>
                </div>
                {allowedActions.filter((data) => data.permission_id == 79)?.length > 0 &&subscriptionData?.length > 0 ? (
                    <Link
                        to={"#"}
                        onClick={() => handleExport('xlsx')}
                        type="button"
                        className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2"
                    >
                        Download Excel
                    </Link>) : null}
            </div>
            {!isSearched && (<p className='text-center text-xl font-semibold mt-5' style={{ color: "#872341" }}>Select Filter To Display Subscription List</p>)}
            {loading ? (<FallingLinesLoader />) : (
                isSearched &&
                <Table
                    columns={subscription_columns({ onDeleteOpen, onStatusUpdate, handleUpdateLSubs })}
                    data={subscriptionData}
                    is_toggle={false}
                />)}
        </div>
    )
}

export default Subscription