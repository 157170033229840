import React, { useEffect, useState, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { citySchema } from "../../../schemas";
import Table from "../../../components/tables/table";
import { city_columns } from "../../../components/tables/tableheader";
import { Dialog, Transition } from "@headlessui/react";
import Pagination from "../../../components/Pagination";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import Api from "../../../ApiServices/Api";
import SlideOverlay from "../../../components/SlideOverlay";
const API = process.env.REACT_APP_API_URL;

function CityList() {
  const pages = [{ title: "City List", href: "/city", module_id: 25 }];
  const [cityList, setCityList] = useState(false);
  const [PincodeList, setPincodeList] = useState([]);
  const [stateList, setStateList] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [modalOpenFlage, setmodalOpenFlage] = useState(false);
  const [city_id, setCityid] = useState("");
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    city_id: null,
  });
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Outlook | Citys";
    getAllStates();
    getAllCitys();
    getAllpincode();
  }, []);
  const getAllpincode = async () => {
    try {
      setIsLoading(true);
      const { data } = await Api().get(`/api/dashboard/masters/pincode`);
      console.log("All Citys Data", data);
      if (data.status) {
        setPincodeList(data.data);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const getAllStates = async () => {
    try {
      setIsLoading(true);
      const { data } = await Api().get(`/api/dashboard/masters/state`);
      console.log("All State Data", data);
      if (data.status) {
        setStateList(data.data);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const getAllCitys = async () => {
    try {
      setIsLoading(true);
      const { data } = await Api().get(`/api/dashboard/masters/city`);
      console.log("All Citys Data", data);
      if (data.status) {
        setCityList(data.data);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const addCity = async () => {
    try {
      setIsLoading(true);
      console.log("Add City");
      let body = {
        city_name: values.city_name,
        fk_state_id: +values.fk_state_id,
      };
      const res = await Api().post(
        `/api/dashboard/masters/city`,
        city_id !== undefined || city_id !== null || city_id !== ""
          ? { ...body, city_id }
          : body
      );
      if (res.status) {
        getAllCitys();
      }
      console.log("Add City Response", res);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const initialValues = {
    city_name: "",
    fk_state_id: "",
    print_run_time: false,
    fk_city_group_id: "",
    fk_city_type_id: "",
    fk_region_id: "",
    fk_pincode_id: "",
  };

  const [formCity, setFormCity] = useState(initialValues);
  const [cityTypeList, setCityTypeList] = useState([])
  const [cityGroupList, setCityGroupList] = useState([])
  const [regionList, setRegionList] = useState([])
  const getAllFKlists = async () => {
    try {
      setIsLoading(true);
      const cityType = await Api().get(`/api/dashboard/masters/city-type`);
      const cityGroup = await Api().get(`/api/dashboard/masters/city-group`);
      const region = await Api().get(`/api/dashboard/masters/region`);
      if (cityType.status) {
        setCityTypeList(cityType.data.data);
      }
      if (cityGroup.status) {
        setCityGroupList(cityGroup.data.data);
      }
      if (region.status) {
        setRegionList(region.data.data);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }
  useEffect(() => {
    getAllFKlists();
  }, []);
  const handleDrawer = (type, id, obj) => {
    console.log("Drawer", type, id, obj);
    if (type === "edit" && id) {
      setCityid(id);
      const initialValues = {
        city_name: obj.city_name,
        fk_state_id: obj.m_state_relation.state_id,
        print_run_time: obj.print_run_time,
        fk_city_group_id: obj.fk_city_group_id,
        fk_city_type_id: obj.fk_city_type_id,
        fk_region_id: obj.fk_region_id,
        fk_pincode_id: obj.fk_pincode_id,
      };
      setFormCity(initialValues);
    } else {
      setCityid("");
      setFormCity(initialValues);
    }
    if (modalOpenFlage === false) {
      setmodalOpenFlage(true);
    }
  };
  const onDeleteOpen = (city_id) => {
    setConfirmationModal({ city_id, status: true });
  };

  const onDeleteCity = async (city_id) => {
    try {
      setIsLoading(true);
      const res = await Api().delete(`/api/dashboard/masters/city/${city_id}`);
      if (res.status) {
        getAllCitys();
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit, setValues } =
    useFormik({
      enableReinitialize: true,
      initialValues: formCity,
      // validationSchema: citySchema,
      onSubmit: async (values, action) => {
        try {
          setIsLoading(true);
          let body = {
            city_name: values.city_name,
            fk_state_id: +values.fk_state_id,
            print_run_time: values.print_run_time,
            fk_city_group_id: +values.fk_city_group_id,
            fk_city_type_id: +values.fk_city_type_id,
            fk_region_id: +values.fk_region_id,
          };

          if (city_id) {
            const res = await Api().put(
              `/api/dashboard/masters/city/${city_id}`,
              body
            );
            if (res.status) {
              getAllCitys();
            }
          } else {
            const res = await Api().post(`/api/dashboard/masters/city`, body);
            if (res.status) {
              getAllCitys();
            }
          }

          setIsLoading(false);
        } catch (error) {
          console.log(error);
          setIsLoading(false);
        }
        action.resetForm();
        if (modalOpenFlage === true) {
          getAllCitys();
          setmodalOpenFlage(false);
        }
      },
    });

  function renderOverLay() {
    return (
      <div>
        <SlideOverlay open={modalOpenFlage} setOpen={setmodalOpenFlage}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(e);
            }}
            className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
          >
            <div className="h-0 flex-1 overflow-y-auto">
              <div className="bg-gradient-to-b from-[#872341] to-[#BE3144] py-6 px-4 sm:px-6">
                <div className="flex items-center justify-between">
                  <label className="text-lg font-medium text-white">
                    {city_id ? "Update" : "Add"} City
                  </label>
                  <div className="ml-3 flex h-7 items-center">
                    <button
                      type="button"
                      className="rounded-md bg-red-700 text-red-200 hover:text-white"
                      onClick={() => setmodalOpenFlage(false)}
                    >
                      <span className="sr-only">Close panel</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex flex-1 flex-col justify-between">
                <div className="p-4 sm:p-6">
                  <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                    <div>
                      <label
                        htmlFor="city_name"
                        className="block text-sm font-medium text-gray-900"
                      >
                        City Name
                      </label>
                    </div>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <input
                        value={values.city_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="text"
                        placeholder="Enter City Name"
                        name="city_name"
                        autoComplete="off"
                        className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                      />
                      {errors.city_name && touched.city_name ? (
                        <p className="text-red-600 text-sm">
                          {errors.city_name}
                        </p>
                      ) : null}
                    </div>
                  </div>

                  {stateList && stateList?.length > 0 ? (
                    <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                      <div>
                        <label
                          htmlFor="fk_state_id"
                          className="block text-sm font-medium text-gray-900"
                        >
                          State
                        </label>
                      </div>
                      <select
                        className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:max-w-xs sm:text-sm"
                        name="fk_state_id"
                        id="fk_state_id"
                        value={values.fk_state_id}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value={null}> Select State </option>
                        {stateList?.map((s, i) => (
                          <option
                            defaultValue={city_id ? values.fk_state_id : ""}
                            key={s.state_id}
                            value={s.state_id}
                          >
                            {s.state_name}
                          </option>
                        ))}
                      </select>

                      {errors.fk_state_id && touched.fk_state_id ? (
                        <div className="text-sm text-red-600">
                          {errors.fk_state_id}
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                  {/* <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                    <div>
                      <label
                        htmlFor="fk_state_id"
                        className="block text-sm font-medium text-gray-900"
                      >
                        Pincode
                      </label>
                    </div>
                    <select
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:max-w-xs sm:text-sm"
                      name="fk_pincode_id"
                      id="fk_pincode_id"
                      value={values.fk_pincode_id}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value={null}> Select pincode </option>
                      {PincodeList?.map((s, i) => (
                        <option
                          defaultValue={city_id ? values.fk_pincode_id : ""}
                          key={s.pincode}
                          value={s.pincode}
                        >
                          {s.pincode}
                        </option>
                      ))}
                    </select>


                  </div> */}
                  {cityGroupList && cityGroupList?.length > 0 ? (
                    <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                      <div>
                        <label
                          htmlFor="fk_state_id"
                          className="block text-sm font-medium text-gray-900"
                        >
                          Group City
                        </label>
                      </div>
                      <select
                        className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:max-w-xs sm:text-sm"
                        name="fk_city_group_id"
                        id="fk_state_id"
                        value={values.fk_city_group_id}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value={null}> Select City Group </option>
                        {cityGroupList?.map((s, i) => (
                          <option key={s.id} value={s.id}>
                            {s.city_group_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  ) : null}
                  {cityTypeList && cityTypeList?.length > 0 ? (
                    <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                      <div>
                        <label
                          htmlFor="fk_state_id"
                          className="block text-sm font-medium text-gray-900"
                        >
                          City Type
                        </label>
                      </div>
                      <select
                        className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:max-w-xs sm:text-sm"
                        name="fk_city_type_id"
                        id="fk_state_id"
                        value={values.fk_city_type_id}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value={null}> Select City Type </option>
                        {cityTypeList?.map((s, i) => (
                          <option key={s.id} value={s.id}>
                            {s.city_type_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  ) : null}
                  {regionList && regionList?.length > 0 ? (
                    <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                      <div>
                        <label
                          htmlFor="fk_state_id"
                          className="block text-sm font-medium text-gray-900"
                        >
                          Region
                        </label>
                      </div>
                      <select
                        className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:max-w-xs sm:text-sm"
                        name="fk_region_id"
                        id="fk_state_id"
                        value={values.fk_region_id}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value={null}> Select Region</option>
                        {regionList?.map((s, i) => (
                          <option key={s.id} value={s.id}>
                            {s.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  ) : null}
                  <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                    <label
                      htmlFor="print_run_time"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Print Run Time
                    </label>
                    <div className="mt-1 flex items-center flex-row gap-2 sm:mt-0">
                      <label htmlFor="print_run_time">
                        <input
                          type="radio"
                          name="print_run_time"
                          value="true"
                          checked={values.print_run_time === true}
                          onChange={(e) => {
                            setValues({
                              ...values,
                              print_run_time:
                                e.target.value === "true" ? true : false,
                            });
                          }}
                          onBlur={handleBlur}
                          className="h-4 w-4 text-red-600 focus:ring-red-500 border-gray-300"
                        />
                        <span className="ml-2 text-sm text-gray-900">Yes</span>
                      </label>
                      <label htmlFor="print_run_time">
                        <input
                          type="radio"
                          name="print_run_time"
                          value="false"
                          checked={values.print_run_time === false}
                          onChange={(e) => {
                            setValues({
                              ...values,
                              print_run_time:
                                e.target.value === "true" ? true : false,
                            });
                          }}
                          onBlur={handleBlur}
                          className="h-4 w-4 text-red-600 focus:ring-red-500 border-gray-300"
                        />
                        <span className="ml-2 text-sm text-gray-900">No</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-shrink-0 justify-end px-4 py-4">
              <button
                type="button"
                className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                onClick={() => {
                  setmodalOpenFlage(false);
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              >
                {city_id ? "Update" : "Add"}
              </button>
            </div>
          </form>
        </SlideOverlay>
      </div>
    );
  }
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      {renderOverLay()}
      <ConfirmationModal
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => onDeleteCity(confirmationModal.city_id)}
      />
      <Breadcrumb pages={pages} />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">City</h1>
        <p className="mt-2 text-sm text-gray-700">A list of all the Citys.</p>
      </div>
      <div className="mt-4 flex">
        <Link
          onClick={() => handleDrawer("add", "", {})}
          type="button"
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 ml-auto"
        >
          Add City
        </Link>
      </div>
      {isLoading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={city_columns({ onDeleteOpen, handleDrawer })}
          data={cityList?.length > 0 && !isLoading ? cityList : []}
          is_toggle={false}
        />
      )}

    </div>
  );
}
export default CityList;
