
import Api from './Api';
const AuthServices = {
    async Login(payload) {
        try {
            const res = await Api().post('api/auth/login', payload);
            // console.log("res Priyanshu=>", res.data.data)
            if (res && res.data.status) {
                return res;
            } else {
                return res;
            }
        } catch (error) {
            console.log('Error in Login Api ', error);
            return null;
        }
    },
    async Register(payload) {
        try {
            let {
                user_id,
                user_email: email,
                user_first_name: firstname,
                user_last_name: lastname,
                user_mobile: mobile,
                user_password: password,
                user_role: user_role_id, hos_id: fk_hos_id , loc_id: fk_hsp_loc_id } = payload;
            let body = {
                user_id,
                email, firstname, lastname, mobile: mobile + "", password, user_role_id: +user_role_id, fk_hos_id, fk_hsp_loc_id
            }
            const res = await Api().post('api/auth/register', body);
            // console.log("Register Response", res.data, body)
            if (res && res.data.status) {
                return res;
            } else {
                return res;
            }
        } catch (error) {
            console.log('Error in Register Api ', error);
            return null;
        }
    },
    async Microsoft() {
        try {
            const response = await Api().post('api/auth/microsoft');
            return response;
        } catch (error) {
            console.log('Error in Microsoft Api ', error);
            return null;
        }
    },

};
export default AuthServices;

