import React, { useEffect, useState } from 'react'
import { FallingLinesLoader } from '../../../components/spinners/Spinner'
import Table from '../../../components/tables/table'
import Breadcrumb from '../../../components/Breadcrumb'
import ConfirmationModal from '../../../components/ConfirmationModal'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { getAllowedActions, checkPermissionsAndRedirect } from "../../../helper/commonHelper";

import MasterServices from '../../../ApiServices/MasterServices'
import { toast } from 'react-toast'
import { customer_address_columns, customer_columns } from '../../../components/tables/tableheader'

const Customer = () => {
  const { customer_id: customer_id } = useParams();
  const pages = [{ title: "Customer Address List", href: `/customer-address/${customer_id}`, module_id: 26 }];
  const [isLoading, setIsLoading] = useState(false)
  const [customerAddress, setCustomerAddress] = useState([])
  const navigate = useNavigate();
  const [allowedActions, setAllowedActions] = useState([]);
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    ca_id: null,
  });
  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("user_permissions"));
    if (permissions && window.location.pathname) {
      const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect('/customer-address', permissions, navigate);
      const actions = getAllowedActions(permissions, '/customer-address');
      setAllowedActions(actions);
    }
  }, []);

  console.log("customerAddress", customerAddress)
  useEffect(() => {
    allCustomerAddress(customer_id)
  }, [])

  const allCustomerAddress = (customer_id) => {
    setIsLoading(true)
    let payload = {
      status_code: 3,
      customer_id: customer_id,
    }
    MasterServices.getAllCustomerAddress(payload).then((res) => {
      const { data, status } = res;
      console.log("getSingleCustomerAddress", data)
      if (status) {
        setCustomerAddress(data.data)
      }
      setIsLoading(false)
    }).catch((error) => {
      console.log(error)

    });
  }

  const onStatusUpdate = (ca_id, ca_is_active) => {
    let payload = {
      table_name: "customer_adress",
      table_field: "ca_id",
      table_field_val: ca_id,
      table_status_field: "ca_is_active",
      table_status_val: ca_is_active ? false : true,
      table_text: "customer Adress",
      deleted_by_key: "ca_updated_by",
      delete_active_txt: ca_is_active ? " Deactivated" : " Activated"
    }
    MasterServices.removeMaster(payload).then((res) => {
      const { data, status, message } = res.data
      if (status) {
        // getSingleCustomerAddress(customer_id)
        toast.success(message);
      } else {
        toast.error(message)
      }
    })
  }
  const onDeleteOpen = (ca_id,ca_is_active) => {
    console.log("ca_id", ca_id)
    console.log("ca_id", ca_id,ca_is_active)
    setConfirmationModal({ ca_id, status: true, ca_is_active });
  }
  const onDeleteItem = (ca_id) => {

    MasterServices.deleteCustomerAdd({ ca_id })
      .then((response) => {
        const { data, status } = response;
        console.log("response Prod=>", response)
        if (data.status) {
          setIsLoading(true)
          toast.success(data.message)
          setConfirmationModal({ ca_id: null, status: false });
          allCustomerAddress(customer_id)
          navigate("/customer-address/"+customer_id);
        } else {
          toast.error(data.message)
        }
        setIsLoading(false)
      })
      .catch((error) => {
        // const msg = error.response.data.message;
        // toast.error(msg)
        toast.error("Sorry Fatal Error!")
        setIsLoading(false)
      });
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
    <ConfirmationModal
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => onDeleteItem(confirmationModal.ca_id)}
      />
      <Breadcrumb pages={pages} />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">Customer Address</h1>
        <p className="mt-2 text-sm text-gray-700">A list of all the Customers Address.</p>
      </div>
      <div className="mt-4 flex">
        {allowedActions.filter((data) => data.permission_id == 59)?.length > 0 ? (<Link
          to={`/add-customer-address/${customer_id}/0`}
          type="button"
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2 ml-auto"
        >
          Add Customer Address
        </Link>) : null}
      </div>

      {isLoading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={customer_address_columns({ onDeleteOpen, onStatusUpdate,allowedActions })}
          data={customerAddress ? customerAddress : []}
          is_toggle={false}
        />
      )}
    </div>
  )
}

export default Customer