import momentTz from 'moment-timezone';
import { read, utils, writeFile } from 'xlsx';
import { timeZone } from './constant';
import moment from 'moment';
export const exportToExcel = (data, headings, fileName, sheetName) => {
  // Create a new workbook
  const workbook = utils.book_new();
  const worksheet = utils.json_to_sheet([]);
  utils.sheet_add_aoa(worksheet, headings);
  utils.sheet_add_json(worksheet, data, { origin: 'A2', skipHeader: true });
  utils.book_append_sheet(workbook, worksheet, 'Report');
  writeFile(workbook, fileName);
};

export const calculateAge = (dateOfBirth) => {
  // console.log("dateOfBirth=>", dateOfBirth)
  const dob = new Date(dateOfBirth);
  const now = new Date();

  const diff = now.getTime() - dob.getTime();
  // console.log("diff=>", diff)
  const age = Math.floor(diff / (1000 * 60 * 60 * 24 * 365));

  return age;
}

// Function to remove HTML tags using regex
export const removeTagsFromString = (htmlString) => {
  const regex = /(<([^>]+)>)/ig;
  return htmlString.replace(regex, '');
};

export const convertToAnotherTimezone = (date) => {
  var fmt = "MM/DD/YYYY h:mm:ss A";  // must match the input
  // console.log(momentTz(date).format(fmt), "India")
  var zone = timeZone;
  var m = momentTz.tz(date, zone).format(fmt);
  // console.log(m, zone)
  return m
}


export const convertUTStoIST = (date) => {
  var fmt = "MM/DD/YYYY h:mm:ss A";  // must match the input
  // console.log(momentTz(date).format(fmt), "India")
  const parsedDate = moment(date);
  // console.log(parsedDate)
  var local = date;
  if (parsedDate.isValid()) {
    const utcMoment = momentTz.utc(parsedDate);
    // Convert UTC to IST (Indian Standard Time)
    local = utcMoment.add(5, 'hours').add(30, 'minutes');
  }
  return local
}

// Helper function to get allowed actions based on pathname
export const getAllowedActions = (allModules, pathname) => {
  //const currentModule = allModules.find((module) => module.sys_module_relation.module_key === pathname) || {};
  let permissionArr = [];
  if (allModules?.length > 0) {
    allModules.map((module) => {
      if (module.sys_module_relation.module_key === pathname) {
        permissionArr.push(module);
      }
    })
  }
  // const permissions = currentModule.permissions || [];
  // return permissions.filter((permission) => permission.permission_is_active);
  console.log("permissionArr", permissionArr);
  return permissionArr;
};

export const checkPermissionsAndRedirect = (pathname, allModules, navigate) => {

  let moduleArr = [];
  if (allModules?.length > 0) {
    allModules.map((module) => {
      if (module.sys_module_relation.module_key === pathname) {
        moduleArr.push(module.sys_module_relation.module_key);
      }
    })
  }
  console.log("moduleArr", moduleArr);
  // If moduleArr is empty, redirect to '/'
  if (moduleArr.length == 0) {
    navigate('/');
    // window.location.redirect="/";
    return;
  }
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}


export const getWeekendDate = (subscriptionDetails, mag) => {
  console.log("PS subscriptionDetails=>,",subscriptionDetails)
  const currentMonth = parseInt(subscriptionDetails.subsMonth.value);
  const currentYear = parseInt(subscriptionDetails.subsYear.value);
  const currentDate = parseInt(subscriptionDetails.subsDay.value);

  // Calculate the next month and year
  const nextMonth = currentMonth === 12 ? 1 : currentMonth;
  const nextYear = currentMonth == nextMonth ? currentYear : nextMonth === 1 ? currentYear + 1 : currentYear;

  // First day of the next month
  const firstDayOfNextMonth = new Date(nextYear, nextMonth - 1, 1);

  // Get the desired weekday from the provided subscriptionDetails or fallback to 0 (Sunday)
  const weekDay = parseInt(mag?.mm_week_day_interval) || 0;
  const weekInterval = parseInt(mag?.mm_week_interval) || 0;

  
  // Find the first occurrence of the target weekday in the next month
  let firstTargetDayOfNextMonth = firstDayOfNextMonth.getDay();
  let daysToAdd = ((weekDay - firstTargetDayOfNextMonth + 7) % 7);

  // Calculate the target date based on week interval
  let targetDate = new Date(firstDayOfNextMonth);
  targetDate.setDate(targetDate.getDate() + daysToAdd + (weekInterval - 1) * 7);

  // Check if the target date is within the current month
  if (targetDate.getMonth() !== nextMonth - 1) {
      // Adjust to the previous week if it falls into the next month
      targetDate.setDate(targetDate.getDate() - 7);
  }

  // Check if the target date is less than or equal to the provided date
  if (targetDate <= new Date(currentYear, currentMonth - 1, currentDate)) {
      // Move to the next occurrence if it's before or on the provided date
      const daysDifference = currentDate - targetDate.getDate();
      const weeksToAdd = Math.ceil(daysDifference / 7);
      targetDate.setDate(targetDate.getDate() + weeksToAdd * 7);
  }

  // Format the date as yyyy-mm-dd
  const formattedDate = `${targetDate.getFullYear()}-${(targetDate.getMonth() + 1).toString().padStart(2, '0')}-${targetDate.getDate().toString().padStart(2, '0')}`;
  subscriptionDetails.subsDay = { label: targetDate.getDate().toString().padStart(2, '0'), value: targetDate.getDate().toString().padStart(2, '0') };
  return {
      ...subscriptionDetails,
      subsDate: formattedDate
  };
};