import React, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Table from "../../../components/tables/table";
import {refundDetails_colunms } from "../../../components/tables/tableheader";
const OrderRefundDetailsModal = (props) => {
  const { confirmationButtonColor = "grey", open, setOpen, data, loading } = props;
  console.log(data);
  const confirmationButtonTextref = useRef(null);

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={confirmationButtonTextref}
          onClose={() => {
            setOpen({
              status: false,
              ord_id: null,
              item: null,
            });
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                  <div className="sm:flex sm:items-center">
                    <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-yellow-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
                      <InformationCircleIcon
                        className={`w-6 h-6 text-${confirmationButtonColor}-600`}
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Refund Details
                      </Dialog.Title>
                    </div>
                  </div>
                  <div className="overflow-x-hidden">
                  {loading ? (
                    <FallingLinesLoader />
                  ) : (
                    <Table
                    columns={refundDetails_colunms({})}                    
                      data={[]}
                      is_toggle={false}
                    />
                  )}
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className={`inline-flex justify-center w-full px-4 py-2 text-base font-medium text-gray-500 bg-${confirmationButtonColor}-600 border border-transparent rounded-md shadow-sm hover:bg-${confirmationButtonColor}-700 focus:outline-none focus:ring-2 focus:ring-${confirmationButtonColor}-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm`}
                      onClick={() => {
                        setOpen({
                          status: false,
                          ord_id: null,
                          item: null,
                        });
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default OrderRefundDetailsModal;
