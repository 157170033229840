/* eslint-disable */
import axios from "axios";
import { getHeaders } from "../auth/actions";
import { toast } from "react-toast";
import {
	GET_ALL_USER_BEGIN,
	GET_ALL_USER_SUCCESS,
	GET_ALL_USER_ERROR,
	ADD_USER_BEGIN,
	ADD_USER_SUCCESS,
	ADD_USER_ERROR,
	GET_USER_BEGIN,
	GET_USER_SUCCESS,
	GET_USER_ERROR,
	UPDATE_USER_BEGIN,
	UPDATE_USER_SUCCESS,
	UPDATE_USER_ERROR,
	DELETE_USER_BEGIN,
	DELETE_USER_SUCCESS,
	DELETE_USER_ERROR,
} from "./constants";
import Api from "../../ApiServices/Api";

const API = process.env.REACT_APP_API_URL;

export const getAllUsers = (payload) => async (dispatch) => {
	try {
		dispatch({
			type: GET_ALL_USER_BEGIN,
		});
		const { data, status } = await Api().get(`/api/dashboard/user?search=${payload}`);
		console.log("getAllUsers", data);

		if (status == 200) {
			let updatedUsers = data?.data
			updatedUsers?.forEach((element) => {
				element.label = element.m_user_name
				element.value = element.m_user_id
			});

			dispatch({
				type: GET_ALL_USER_SUCCESS,
				data: data ? data.data : [],
			});
		} else {
			toast.error("Fatal Error Please Contact Admin")
		}
	} catch (error) {
		const msg = error.response.data.message;
		toast.error(msg);
		console.log(error);
		dispatch({
			type: GET_ALL_USER_ERROR,
			error: msg,
		});
	}
};

export const addUser = (body) => async (dispatch) => {
	try {
		dispatch({
			type: ADD_USER_BEGIN,
		});

		const user_status = true;
		console.log("Priyanshu SIngh=>", body)

		dispatch({
			type: ADD_USER_SUCCESS,
			data: data[0],
		});
	} catch (error) {
		console.log(error.response);
		const msg = error.response.data.message;
		toast.error(msg);
		dispatch({
			type: ADD_USER_ERROR,
			error: msg,
		});
	}
};

export const getUserWithId = (user_id) => async (dispatch) => {
	try {
		dispatch({
			type: GET_USER_BEGIN,
		});

		console.log("GET_USER_BEGIN : ", data);
		dispatch({
			type: GET_USER_SUCCESS,
			data: data,
		});
	} catch (error) {
		console.log(error.response);
		const msg = error.response.data.message;
		toast.error(msg);
		dispatch({
			type: GET_USER_ERROR,
			error: msg,
		});
	}
};

export const updateUser = (user_id, body) => async (dispatch) => {
	try {
		dispatch({
			type: UPDATE_USER_BEGIN,
		});

		console.log(data[0]);
		toast.success("User updated successfully!");
		dispatch({
			type: UPDATE_USER_SUCCESS,
			data: data[0],
		});
	} catch (error) {
		const msg = error.response.data.message;
		toast.error(msg);
		dispatch({
			type: UPDATE_USER_ERROR,
			error: msg,
		});
	}
};

export const deleteUser = (user_id) => async (dispatch) => {
	try {
		const role_status = 0;
		const body = {
			user_id: user_id,
		};
		console.log("body=>",body)
		const res = await Api().post(`/api/dashboard/user/remove`, body);
		dispatch({
			type: DELETE_USER_BEGIN,
		});
		const user_status = 0;
		if (res.data.status) {
			toast.success(res?.data?.message);
			dispatch({
				type: DELETE_USER_SUCCESS,
				data: user_id,
			});

		} else {
			toast.error(res?.data?.message);
			// dispatch({
			// 	type: DELETE_ROLE_ERROR,
			// 	data: role_id,
			// });
		}
		dispatch(getAllUsers());
	} catch (error) {
		console.log("error=>",error)
		const msg = error.response.data.message;
		toast.error(msg);
		dispatch({
			type: DELETE_USER_ERROR,
			error: msg,
		});
	}
};
