import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import Select from "react-select";
import { useFormik } from "formik";
import MasterServices from "../../../ApiServices/MasterServices";
import makeAnimated from "react-select/animated";
import { toast } from "react-toast";
import { voucherSchema } from "../../../schemas";
import { FaSpinner } from "react-icons/fa";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import { BsCurrencyRupee } from "react-icons/bs";
import moment from "moment";
import {
  CurrencyDollarIcon,
  CurrencyRupeeIcon,
  EnvelopeIcon,
} from "@heroicons/react/20/solid";
const AddVoucher = () => {
  const { voucher_id: voucher_id } = useParams();
  const pages = [
    {
      title: voucher_id ? "Update Voucher" : "Add Voucher",
      href: "/add-voucher",
      module_id: 12,
    },
  ];
  const [selectedVoucherType, setSelectedVoucherType] = useState([]);
  const [selectedVoucherCodeType, setSelectedVoucherCodeType] = useState([]);
  // const [skuData, setSkuData] = useState([
  //   {
  //     label: "ALL",
  //     value: "ALL",
  //   },
  //   {
  //     label: "SKU",
  //     value: "SPECIFIC_SKU",
  //   },
  //   {
  //     label: "Magazine",
  //     value: "SPECIFIEC_Magzine",
  //   },
  // ]);
  const voucherType = [
    {
      label: "PERCENTAGE",
      value: "PERCENTAGE",
    },
    {
      label: "FIXED",
      value: "FIXED",
    },
  ];
  const voucherCodeType = [
    {
      label: "STATIC",
      value: "STATIC",
    },
    {
      label: "CAMPAIGN",
      value: "CAMPAIGN",
    },
    {
      label: "REFERRAL",
      value: "REFERRAL",
    },
  ];
  const [loading, setLoading] = useState(false);
  const [selectedSku, setSelectedSku] = useState([]);
  const navigate = useNavigate();
  const animatedComponents = makeAnimated();
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  useEffect(() => {
    getAllSkuData(1);
    if (voucher_id) {
      getSingleVoucher(voucher_id);
    }
  }, []);

  const initialValues = {
    voucher_id: "",
    voucher_name: "",
    voucher_code: "",
    voucher_type: "",
    voucher_code_type: "",
    voucher_value: "",
    minimum_transaction: "",
    voucher_percent: "",
    max_discount: "",
    voucher_applicable_to: "",
    voucher_key: "",
    voucher_per_user_limit: "",
    voucher_max_redeems: "",
    voucher_redeems: "",
    // voucher_applicable_to: "",
    voucher_min_amount: "",
    voucher_max_amount: "",
    voucher_amount: "",
    voucher_start_date: "",
    voucher_end_date: "",
    voucher_description: "",
    voucher_is_unique: false,
    voucher_is_active: true,

    voucher_code_count: "",
    voucher_code_prefix: "",
    voucher_is_campaign: false,
    fk_voucher_id: null,
  };

  const [formVoucher, setFormVoucher] = useState(initialValues);

  const getSingleVoucher = async (voucher_id) => {
    setLoading(true);
    MasterServices.getSingleVoucher(voucher_id)
      .then(async (res) => {
        const { data, status } = res.data;
        if (status) {
          setSelectedSku({
            label: data?.voucher_applicable_to,
            value: data?.voucher_applicable_to,
          });
          setSelectedVoucherType({
            label: data?.voucher_type,
            value: data?.voucher_type,
          });
          setSelectedVoucherCodeType({
            label: data?.voucher_code_type,
            value: data?.voucher_code_type,
          });

          let editedValues = {
            voucher_id: voucher_id,
            voucher_name: data?.voucher_name,
            voucher_code: data?.voucher_code,
            voucher_type: data?.voucher_type,
            voucher_code_type: data?.voucher_code_type,
            voucher_value: data?.voucher_value,
            minimum_transaction: data?.minimum_transaction,
            voucher_percent: data?.voucher_percent,
            max_discount: data?.max_discount,
            voucher_applicable_to: data?.voucher_applicable_to,
            voucher_key: data?.voucher_key,
            voucher_per_user_limit: data?.voucher_per_user_limit,
            voucher_max_redeems: data?.voucher_max_redeems,
            voucher_redeems: data?.voucher_redeems,
            voucher_min_amount: data?.voucher_min_amount,
            voucher_max_amount: data?.voucher_max_amount,
            voucher_amount: data?.voucher_amount,
            voucher_start_date: moment(data?.voucher_start_date).format(
              "YYYY-MM-DD"
            ), //data?.voucher_start_date,
            voucher_end_date: moment(data?.voucher_end_date).format(
              "YYYY-MM-DD"
            ), //data?.voucher_end_date,
            voucher_description: data?.voucher_description,
            voucher_is_unique: data?.voucher_is_unique,
            voucher_is_active: data?.voucher_is_active,
            // voucher_is_campaign: data?.voucher_is_campaign,
            // fk_voucher_id: data?.fk_voucher_id,
            // voucher_code_count: "",
            // voucher_code_prefix: "",
          };

          if (data?.voucher_code_type === "CAMPAIGN" && !voucher_id) {
            editedValues.voucher_code_count = data?.voucher_code_count;
            editedValues.voucher_code_prefix = data?.voucher_code_prefix;
            editedValues.voucher_is_campaign = data?.voucher_is_campaign;
            editedValues.fk_voucher_id = data?.fk_voucher_id;
          }
          setFormVoucher(editedValues);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const getAllSkuData = async (payload) => {
    const response = await MasterServices.getSkuData(payload);
    if (response.data.status == true) {
      if (response.data.data.data.length > 0) {
        const newArray = response?.data?.data?.data?.map((ele) => ({
          ...ele,
          value: ele.sku_id,
          label: `${ele.sku_code}`,
        }));
        // setSkuData(newArray);
      }
    }
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formVoucher,
      validationSchema: voucherSchema,
      onSubmit: async (values, action) => {
        let body = {
          voucher_id: voucher_id ? voucher_id : "",
          voucher_name: values.voucher_name,
          voucher_code: values.voucher_code,
          voucher_type: selectedVoucherType?.value
            ? selectedVoucherType?.value
            : "",
          voucher_code_type: selectedVoucherCodeType?.value
            ? selectedVoucherCodeType?.value
            : "",
          voucher_value: values.voucher_value,
          minimum_transaction: values.minimum_transaction,
          max_discount: values.max_discount,
          // voucher_applicable_to: selectedSku?.value ? selectedSku?.value : "", //: selectedSku,
          voucher_applicable_to: "ALL", //: selectedSku,
          voucher_per_user_limit: values.voucher_per_user_limit,
          voucher_max_redeems: values.voucher_max_redeems,
          voucher_start_date: values.voucher_start_date,
          voucher_end_date: values.voucher_end_date,
          voucher_description: values.voucher_description,

          voucher_key: "",
          voucher_redeems: 0,
          voucher_min_amount: 0,
          voucher_max_amount: 0,
          voucher_amount: 0,
          voucher_is_unique:
            selectedVoucherCodeType?.value === "CAMPAIGN" ? true : false,
          voucher_is_active: true,
        };

        if (
          selectedVoucherCodeType?.value === "CAMPAIGN" &&
          (voucher_id <= 0 || !voucher_id)
        ) {
          body.voucher_code_count = values.voucher_code_count;
          body.voucher_code_prefix = values.voucher_code_prefix;
          body.voucher_is_campaign = true;
          body.fk_voucher_id = formVoucher?.fk_voucher_id;
        }
        if (
          selectedVoucherCodeType?.label !== "CAMPAIGN" &&
          !values.voucher_code &&
          (voucher_id <= 0 || !voucher_id)
        ) {
          toast.error("Please Enter Voucher Code ");
          return false;
        }

        if (
          selectedVoucherCodeType?.label === "CAMPAIGN" &&
          !values.voucher_code_count &&
          values.voucher_code_count <= 0 &&
          (voucher_id <= 0 || !voucher_id)
        ) {
          toast.error("Please Enter No Of Voucher");
          return false;
        }

        if (
          selectedVoucherCodeType?.label === "CAMPAIGN" &&
          !values.voucher_code_prefix &&
          (voucher_id <= 0 || !voucher_id)
        ) {
          toast.error("Please Enter Voucher Code Prefix");
          return false;
        }

        if (!selectedVoucherType?.label) {
          toast.error("Please Select Voucher Type");
          return false;
        }
        // if (!selectedSku?.label) {
        //   toast.error("Please Select Applicable On");
        //   return false;
        // }
        console.log("body", body);
        // return false;
        setTimeout(() => {
          setDisableSubmitButton(false);
        }, 1000);
        setDisableSubmitButton(true);
        const response = await MasterServices.saveVoucher(body);
        if (response.data.status) {
          action.resetForm();
          setFormVoucher(initialValues);
          toast.success(response.data.message);
          setDisableSubmitButton(false);
          navigate("/voucher");
        } else {
          setDisableSubmitButton(false);
          toast.error(response.data.message);
        }
      },
    });
  console.log("errors=>", errors);
  const handleApplicableOn = (e) => {
    setSelectedSku(e);
  };
  const handleVoucherType = (e) => {
    setSelectedVoucherType(e);
    console.log("setSelectedVoucherType", e);
  };
  const handleVoucherCodeType = (e) => {
    setSelectedVoucherCodeType(e);
  };
  return (
    <>
      {!loading ? (
        <div>
          <Breadcrumb pages={pages} />
          <div className="">
            <h1 className="text-xl font-semibold text-gray-900">
              {voucher_id ? "Update" : "Add"} Voucher
            </h1>
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(e);
            }}
          >
            <div className="mt-5">
              <label
                htmlFor="voucher_code_type"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Voucher Code Type
              </label>
              <div className="">
                <Select
                  className=" w-full max-w-lg rounded-md border-[1px] shadow-sm focus:border-[1px] sm:max-w-xs sm:text-sm "
                  placeholder="Select Voucher Type"
                  name="voucher_type"
                  onChange={(e) => handleVoucherCodeType(e)}
                  options={voucherCodeType}
                  value={selectedVoucherCodeType}
                  onBlur={handleBlur}
                />
                {errors.voucher_type && touched.voucher_type ? (
                  <div className="text-sm text-red-600">
                    {errors.voucher_type}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="grid grid-cols-4 lg:grid-cols-3 lx:grid-cols-3 gap-3 mt-3 lg:w-full w-full">
              {selectedVoucherCodeType?.label === "CAMPAIGN" && !voucher_id ? (
                <div className="col-span-4 sm:col-span-2 md:col-span-2 lg:col-span-1 xl:col-span-1">
                  <label
                    htmlFor="voucher_code_count"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    No Of Voucher
                  </label>
                  <div className="">
                    <input
                      type="number"
                      name="voucher_code_count"
                      id="voucher_code_count"
                      value={values.voucher_code_count}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ed1b24] sm:text-sm sm:leading-6"
                      placeholder="No Of Voucher"
                    />
                    {errors.voucher_code_count && touched.voucher_code_count ? (
                      <div className="text-sm text-red-600">
                        {errors.voucher_code_count}
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}
              {/* {voucher_id} */}
              {selectedVoucherCodeType?.label === "CAMPAIGN" && !voucher_id ? (
                <div className="col-span-4 sm:col-span-2 md:col-span-2 lg:col-span-1 xl:col-span-1">
                  <label
                    htmlFor="voucher_code_prefix"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Voucher Code Prefix
                  </label>
                  <div className="">
                    <input
                      type="text"
                      name="voucher_code_prefix"
                      id="voucher_code_prefix"
                      value={values.voucher_code_prefix}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ed1b24] sm:text-sm sm:leading-6"
                      placeholder="Voucher Code Prefix"
                    />
                    {errors.voucher_code_prefix &&
                    touched.voucher_code_prefix ? (
                      <div className="text-sm text-red-600">
                        {errors.voucher_code_prefix}
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}

              <div className="col-span-4 sm:col-span-2 md:col-span-2 lg:col-span-1 xl:col-span-1">
                <label
                  htmlFor="voucher_name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Voucher Name<span className="text-red-600">*</span>
                </label>
                <div className="">
                  <input
                    type="text"
                    name="voucher_name"
                    id="voucher_name"
                    value={values.voucher_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ed1b24] sm:text-sm sm:leading-6"
                    placeholder="Voucher Name"
                  />
                  {errors.voucher_name && touched.voucher_name ? (
                    <div className="text-sm text-red-600">
                      {errors.voucher_name}
                    </div>
                  ) : null}
                </div>
              </div>
              {selectedVoucherCodeType?.label !== "CAMPAIGN" || voucher_id ? (
                <div className="col-span-4 sm:col-span-2 md:col-span-2 lg:col-span-1 xl:col-span-1">
                  <label
                    htmlFor="voucher_code"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Voucher Code<span className="text-red-600">*</span>
                  </label>
                  <div className="">
                    <input
                      type="text"
                      name="voucher_code"
                      id="voucher_code"
                      value={values.voucher_code}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ed1b24] sm:text-sm sm:leading-6"
                      placeholder="Voucher Code"
                    />
                    {errors.voucher_code && touched.voucher_code ? (
                      <div className="text-sm text-red-600">
                        {errors.voucher_code}
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}
              <div className="col-span-4 sm:col-span-2 md:col-span-2 lg:col-span-1 xl:col-span-1">
                <label
                  htmlFor="voucher_type"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Voucher Type
                </label>
                <div className="">
                  <Select
                    className=" w-full max-w-lg rounded-md border-[1px] shadow-sm focus:border-[1px] sm:max-w-xs sm:text-sm "
                    placeholder="Select Voucher Type"
                    name="voucher_type"
                    onChange={(e) => handleVoucherType(e)}
                    // onChange={setSelectedVoucherType}
                    options={voucherType}
                    value={selectedVoucherType}
                    onBlur={handleBlur}
                  />
                  {errors.voucher_type && touched.voucher_type ? (
                    <div className="text-sm text-red-600">
                      {errors.voucher_type}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-span-4 sm:col-span-2 md:col-span-2 lg:col-span-1 xl:col-span-1">
                <label
                  htmlFor="voucher_value"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Voucher value<span className="text-red-600">*</span>
                </label>
                <div className="relative rounded-md shadow-sm">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    {selectedVoucherType &&
                    selectedVoucherType.value == "PERCENTAGE" ? (
                      "%"
                    ) : (
                      <BsCurrencyRupee
                        className="h-5 w-4 text-gray-400"
                        aria-hidden="true"
                      />
                    )}
                  </div>
                  <input
                    type="number"
                    name="voucher_value"
                    id="voucher_value"
                    value={values.voucher_value}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="Voucher value"
                  />
                </div>
                {errors.voucher_value && touched.voucher_value ? (
                  <div className="text-sm text-red-600">
                    {errors.voucher_value}
                  </div>
                ) : null}
                {/* <div className="">
                                    <input
                                        type="number"
                                        name="voucher_value"
                                        id="voucher_value"
                                        value={values.voucher_value}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ed1b24] sm:text-sm sm:leading-6"
                                        placeholder="Voucher value"
                                    />
                                    {errors.voucher_value && touched.voucher_value ? (
                                        <div className="text-sm text-red-600">
                                            {errors.voucher_value}
                                        </div>
                                    ) : null}
                                </div> */}
              </div>
              <div className="col-span-4 sm:col-span-2 md:col-span-2 lg:col-span-1 xl:col-span-1">
                <label
                  htmlFor="minimum_transaction"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Minimum Transaction<span className="text-red-600">*</span>
                </label>
                <div className="">
                  <input
                    type="number"
                    name="minimum_transaction"
                    id="minimum_transaction"
                    value={values.minimum_transaction}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ed1b24] sm:text-sm sm:leading-6"
                    placeholder="Minimum Transaction"
                  />
                  {errors.minimum_transaction && touched.minimum_transaction ? (
                    <div className="text-sm text-red-600">
                      {errors.minimum_transaction}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-span-4 sm:col-span-2 md:col-span-2 lg:col-span-1 xl:col-span-1">
                <label
                  htmlFor="max_discount"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Max Discount<span className="text-red-600">*</span>
                </label>
                <div className="">
                  <input
                    type="number"
                    name="max_discount"
                    id="max_discount"
                    value={values.max_discount}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ed1b24] sm:text-sm sm:leading-6"
                    placeholder="Max Discount"
                  />
                  {errors.max_discount && touched.max_discount ? (
                    <div className="text-sm text-red-600">
                      {errors.max_discount}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-span-4 sm:col-span-2 md:col-span-2 lg:col-span-1 xl:col-span-1">
                <label
                  htmlFor="voucher_per_user_limit"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Per User Limit<span className="text-red-600">*</span>
                </label>
                <div className="">
                  <input
                    type="number"
                    name="voucher_per_user_limit"
                    id="voucher_per_user_limit"
                    value={values.voucher_per_user_limit}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ed1b24] sm:text-sm sm:leading-6"
                    placeholder="Per User Limit"
                  />
                  {errors.voucher_per_user_limit &&
                  touched.voucher_per_user_limit ? (
                    <div className="text-sm text-red-600">
                      {errors.voucher_per_user_limit}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-span-4 sm:col-span-2 md:col-span-2 lg:col-span-1 xl:col-span-1">
                <label
                  htmlFor="voucher_max_redeems"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Max Redeems<span className="text-red-600">*</span>
                </label>
                <div className="">
                  <input
                    type="number"
                    name="voucher_max_redeems"
                    id="voucher_max_redeems"
                    value={values.voucher_max_redeems}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ed1b24] sm:text-sm sm:leading-6"
                    placeholder="Max Redeems"
                  />
                  {errors.voucher_max_redeems && touched.voucher_max_redeems ? (
                    <div className="text-sm text-red-600">
                      {errors.voucher_max_redeems}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="col-span-4 sm:col-span-2 md:col-span-2 lg:col-span-1 xl:col-span-1">
                <label
                  htmlFor="voucher_start_date"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Start Date<span className="text-red-600">*</span>
                </label>
                <div className="">
                  <input
                    type="date"
                    name="voucher_start_date"
                    id="voucher_start_date"
                    value={values.voucher_start_date}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ed1b24] sm:text-sm sm:leading-6"
                    min={new Date().toISOString().split("T")[0]}
                    placeholder="Start Date"
                  />
                  {errors.voucher_start_date && touched.voucher_start_date ? (
                    <div className="text-sm text-red-600">
                      {errors.voucher_start_date}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-span-4 sm:col-span-2 md:col-span-2 lg:col-span-1 xl:col-span-1">
                <label
                  htmlFor="voucher_end_date"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  End Date<span className="text-red-600">*</span>
                </label>
                <div className="">
                  <input
                    type="date"
                    name="voucher_end_date"
                    id="voucher_end_date"
                    value={values.voucher_end_date}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ed1b24] sm:text-sm sm:leading-6"
                    min={new Date().toISOString().split("T")[0]}
                    placeholder="End Date"
                  />
                  {errors.voucher_end_date && touched.voucher_end_date ? (
                    <div className="text-sm text-red-600">
                      {errors.voucher_end_date}
                    </div>
                  ) : null}
                </div>
              </div>
              {/* <div>
                <label
                  htmlFor="voucher_applicable_to"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Applicable On
                </label>
                <div className="">
                  <Select
                    name="Sku"
                    placeholder={
                      <span>
                        Select Applicable On
                        <span className="text-red-600">*</span>
                      </span>
                    }
                    onChange={(e) => handleApplicableOn(e)}
                    value={selectedSku}
                    // isMulti
                    components={animatedComponents}
                    options={skuData}
                    classNamePrefix="select"
                    className="basic-multi-select block w-full max-w-lg rounded-md border-[1px]  shadow-sm  focus:border-red-500 sm:max-w-xs sm:text-sm"
                  />
                  {errors.selectedSkus && touched.selectedSkus ? (
                    <div className="text-sm text-red-600">
                      {errors.selectedSkus}
                    </div>
                  ) : null}
                </div>
              </div> */}
              <div className="col-span-4 sm:col-span-2 md:col-span-2 lg:col-span-1 xl:col-span-1">
                <label
                  htmlFor="voucher_description"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Description
                </label>
                <div className="">
                  <textarea
                    rows={3}
                    name="voucher_description"
                    value={values.voucher_description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="voucher_description"
                    placeholder="Description"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ed1b24] sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
            <div className="mt-6 flex items-center justify-end gap-x-6">
              {/* <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
                        Cancel
                    </button> */}
              <button
                disabled={disableSubmitButton}
                type="submit"
                className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
              >
                {voucher_id ? (
                  disableSubmitButton ? (
                    <FaSpinner color="gold" />
                  ) : (
                    "Update"
                  )
                ) : disableSubmitButton ? (
                  <FaSpinner color="gold" />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </form>
        </div>
      ) : (
        <FallingLinesLoader />
      )}
    </>
  );
};

export default AddVoucher;
