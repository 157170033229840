import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import Table from "../../../components/tables/table";
import { Company_columns } from "../../../components/tables/tableheader";
import { Dialog, Transition } from "@headlessui/react";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import Api from "../../../ApiServices/Api";
import { companySchema } from "../../../schemas";
import MasterServices from "../../../ApiServices/MasterServices";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import moment from "moment";
import { toast, ToastContainer } from "react-toast";

function Company() {
  const pages = [{ title: "Company List", href: "/Company", module_id: 26 }];
  const [CompanyList, setCompanyList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [modalOpenFlage, setmodalOpenFlage] = useState(false);
  const [Company_id, setCompanyid] = useState("");
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    Company_id: null,
  });
  const [selectedCity, setSelectedCity] = useState([]);
  const [selectedState, setSelectedState] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const animatedComponents = makeAnimated();
  const [company_legal_entity_type, setCompany_legal_entity_type] = useState(
    []
  );

  console.log(selectedCity, selectedState, selectedCountry);

  const [company_legal_entity_typeList, setCompany_legal_entity_typeList] =
    useState([
      {
        label: "Private",
        value: "Private",
      },
      {
        label: "Public",
        value: "Public",
      },
    ]);

  useEffect(() => {
    document.title = "Outlook | Company Mater";
    getAllCompanies();
  }, []);

  useEffect(() => {
    getAllCountryList();
  }, []);

  const getAllStates = async (country_id) => {
    try {
      setIsLoading(true);
      MasterServices.getStateByCountry(country_id)
        .then((res) => {
          const { data, status } = res;
          if (status) {
            let newState = data.data?.map((v) => ({
              label: v.state_name,
              value: v.state_id,
            }));
            setStateList(newState);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  // getCityByState
  const getAllCitys = async (state_id) => {
    try {
      setIsLoading(true);
      MasterServices.getCityByState(state_id)
        .then((res) => {
          const { data, status } = res;
          if (status) {
            let newCity = data.data?.map((v) => ({
              label: v.city_name,
              value: v.city_id,
            }));
            setCityList(newCity);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const getAllCompanies = async () => {
    try {
      setIsLoading(true);
      const { data } = await Api().get(`/api/dashboard/company?search=1`);
      if (data.status) {
        setCompanyList(data.data);
        setImageBaseURL(data?.data[0]?.image_base_url);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const getAllCountryList = () => {
    MasterServices.getAllCountry(1)
      .then((res) => {
        const { data, status } = res;
        if (status) {
          setCountryList(data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCountry = (e) => {
    setSelectedCountry(e);
    getAllStates(e?.country_id);
  };

  const handleState = (e) => {
    setSelectedState(e);
    getAllCitys(e?.value);
  };

  const handleCity = (e) => {
    setSelectedCity(e);
  };

  let initialValues = {
    company_id: "",
    company_name: "",
    company_logo: "",
    company_banner: "",
    company_sign : "",
    company_cin: "",
    company_gstno: "",
    company_registered_office_add: "",
    company_corporate_office_add: "",
    company_panno: "",
    company_telephone_no: "",
    company_bank_account_no: "",
    company_bank_account_name: "",
    company_bank_name: "",
    company_branch_name: "",
    company_ifsc_code: "",
    company_swift_code: "",
    company_email: "",
    company_website: "",
    company_country: "",
    company_state: "",
    company_city: "",
    company_pincode: "",
    fk_state_id: "",
    fk_city_id: "",
    fk_country_id: "",
    company_legal_entity_type: "",
    company_registration_date: "",
    company_vat_no: "",
    company_tax_registration_no: "",
  };

  const [formZone, setFormZone] = useState(initialValues);

  const handleDrawer = (type, id, obj) => {
    if (type === "edit" && id) {
      setCompanyid(id);
      setCompany_logo(null);
      setBase64logo(null);
      setCompany_banner(null);
      setBase64banner(null);
      setCompany_sign(null);
      setBase64sign(null);
      const initialValues = {
        company_id: id,
        company_name: obj.company_name,
        company_logo: obj.company_logo,
        company_banner : obj.company_banner,
        company_sign : obj.company_sign,
        company_cin: obj.company_cin,
        company_gstno: obj.company_gstno,
        company_registered_office_add: obj.company_registered_office_add,
        company_corporate_office_add: obj.company_corporate_office_add,
        company_panno: obj.company_panno,
        company_telephone_no: obj.company_telephone_no,
        company_bank_account_no: obj.company_bank_account_no,
        company_bank_account_name: obj.company_bank_account_name,
        company_bank_name: obj.company_bank_name,
        company_branch_name: obj.company_branch_name,
        company_ifsc_code: obj.company_ifsc_code,
        company_swift_code: obj.company_swift_code,
        company_email: obj.company_email,
        company_website: obj.company_website,
        company_country: obj.company_country,
        company_state: obj.company_state,
        company_city: obj.company_city,
        company_pincode: obj.company_pincode,
        fk_state_id: obj.fk_state_id,
        fk_city_id: obj.fk_city_id,
        fk_country_id: obj.fk_country_id,
        company_legal_entity_type: obj.company_legal_entity_type,
        company_registration_date: moment(obj.company_registration_date).format(
          "YYYY-MM-DD"
        ),
        company_vat_no: obj.company_vat_no,
        company_tax_registration_no: obj.company_tax_registration_no,
      };
      setSelectedCountry({
        label: obj?.m_country_relation?.country_name,
        value: obj?.fk_country_id,
      });
      setSelectedState({
        label: obj?.m_state_relation?.state_name,
        value: obj?.fk_state_id,
      });
      setSelectedCity({
        label: obj?.m_city_relation?.city_name,
        value: obj?.fk_city_id,
      });
      setCompany_legal_entity_type({
        label: obj?.company_legal_entity_type,
        value: obj?.company_legal_entity_type,
      });
      setCompany_logo(imageBaseURL + obj?.company_logo);
      setCompany_banner(imageBaseURL + obj?.company_banner);
      setCompany_sign(imageBaseURL + obj?.company_sign);
      setFormZone(initialValues);
    } else {
      setBase64logo(null);
      setCompany_logo(null);
      setCompany_banner(null);
      setBase64banner(null);
      setCompany_sign(null);
      setBase64sign(null);
      setCompanyid(0);
      setFormZone(initialValues);
    }
    if (modalOpenFlage === false) {
      setmodalOpenFlage(true);
    }
  };
  const onDeleteOpen = (Company_id) => {
    setConfirmationModal({ Company_id, status: true });
  };

  const onDeleteCompany = async (Company_id) => {
    try {
      setIsLoading(true);
      let body = {
        company_id: Company_id,
      };
      
      const res = await Api().post(`/api/dashboard/company/remove`, body);
      if (res.status) {
        getAllCompanies();
        toast.success(res?.data?.message);
      }else{
        toast.error(res?.data?.message);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
    setConfirmationModal({ status: false, Company_id: null });
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formZone,
      validationSchema: companySchema,
      onSubmit: async (values, action) => {
        try {
          setIsLoading(true);
          let body = {
            company_id: Company_id ? Company_id : 0,
            company_name: values.company_name,
            company_logo: base64logo,
            company_banner : base64banner,
            company_sign : base64sign,
            company_cin: values.company_cin,
            company_gstno: values.company_gstno,
            company_registered_office_add: values.company_registered_office_add,
            company_corporate_office_add: values.company_corporate_office_add,
            company_panno: values.company_panno,
            company_telephone_no: values.company_telephone_no,
            company_bank_account_no: values.company_bank_account_no,
            company_bank_account_name: values.company_bank_account_name,
            company_bank_name: values.company_bank_name,
            company_branch_name: values.company_branch_name,
            company_ifsc_code: values.company_ifsc_code,
            company_swift_code: values.company_swift_code,
            company_email: values.company_email,
            company_website: values.company_website,
            company_country: selectedCountry?.label,
            company_state: selectedState?.label,
            company_city: selectedCity?.label,
            company_pincode: values.company_pincode,
            fk_state_id: selectedState?.value,
            fk_city_id: selectedCity?.value,
            fk_country_id: selectedCountry?.value,
            company_legal_entity_type: company_legal_entity_type.value,
            company_registration_date: values.company_registration_date,
            company_vat_no: values.company_vat_no,
            company_tax_registration_no: values.company_tax_registration_no,
          };

          console.log(body);

          if (Company_id) {
            const res = await Api().post(`/api/dashboard/company`, body);
            if (res.status) {
              toast.success(res?.data?.message);
              setBase64logo(null);
              setCompany_logo(null);
              setCompany_banner(null);
              setBase64banner(null);
              setCompany_sign(null);
              setBase64sign(null);
              getAllCompanies();
            }
          } else {
            const res = await Api().post(`/api/dashboard/company`, body);
            if (res.status) {
              toast.success(res?.data?.message);
              setBase64logo(null);
              setCompany_logo(null);
              setCompany_banner(null);
              setBase64banner(null);
              setCompany_sign(null);
              setBase64sign(null);
              getAllCompanies();
            }
          }

          setIsLoading(false);
        } catch (error) {
          console.log(error);
          toast.error("Something Went Wrong");
          setIsLoading(false);
        }
        action.resetForm();
        if (modalOpenFlage === true) {
          getAllCompanies();
          setmodalOpenFlage(false);
        }
      },
    });

  const [imageBaseURL, setImageBaseURL] = useState(
    "https://outlook-subscription-test.s3.ap-south-1.amazonaws.com/uploads/company/"
  );
  const [base64logo, setBase64logo] = useState(null);
  const [company_logo, setCompany_logo] = useState(null);  

  const onFileChangeLogo = (e) => {
    const file = e.target.files[0];
    const fileName = file.name;

    const reader = new FileReader();
    reader.onload = (e) => {
      setCompany_logo(e.target.result);
      setBase64logo(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  const [base64banner, setBase64banner] = useState(null);
  const [company_banner, setCompany_banner] = useState(null);

  const onFileChangeBanner = (e) => {
    const file = e.target.files[0];
    const fileName = file.name;

    const reader = new FileReader();
    reader.onload = (e) => {
      setCompany_banner(e.target.result);
      setBase64banner(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  const [base64sign, setBase64sign] = useState(null);
  const [company_sign, setCompany_sign] = useState(null);

  const onFileChangeSign = (e) => {
    const file = e.target.files[0];
    const fileName = file.name;

    const reader = new FileReader();
    reader.onload = (e) => {
      setCompany_sign(e.target.result);
      setBase64sign(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  const handleCompany_legal_entity_type = (e) => {
    setCompany_legal_entity_type(e);
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <ConfirmationModal
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => onDeleteCompany(confirmationModal.Company_id)}
      />
      <Breadcrumb pages={pages} />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">Company</h1>
        <p className="mt-2 text-sm text-gray-700">
          A list of all the Companies.
        </p>
      </div>
      <div className="mt-4 flex">
        <Link
          onClick={() => handleDrawer("add", "", {})}
          type="button"
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 ml-auto"
        >
          Add Company
        </Link>
      </div>

      {isLoading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={Company_columns({
            onDeleteOpen,
            handleDrawer,
            imageBaseURL,
          })}
          data={CompanyList?.length > 0 && !isLoading ? CompanyList : []}
          is_toggle={false}
        />
      )}

      {isLoading ? null : (
        <div>
          <Transition.Root show={modalOpenFlage} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-10"
              onClose={setmodalOpenFlage}
            >
              <div className="fixed inset-0" />
              <div className="fixed inset-0 overflow-hidden">
                <div className="absolute inset-0 overflow-hidden">
                  <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                    <Transition.Child
                      as={Fragment}
                      enter="transform transition ease-in-out duration-500 sm:duration-700"
                      enterFrom="translate-x-full"
                      enterTo="translate-x-0"
                      leave="transform transition ease-in-out duration-500 sm:duration-700"
                      leaveFrom="translate-x-0"
                      leaveTo="translate-x-full"
                    >
                      <Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
                        <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit(e);
                          }}
                          className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                        >
                          <div className="h-0 flex-1 overflow-y-auto">
                            <div className="bg-gradient-to-b from-[#872341] to-[#BE3144] py-6 px-4 sm:px-6">
                              <div className="flex items-center justify-between">
                                <Dialog.Title className="text-lg font-medium text-white">
                                  {Company_id ? "Update" : "Add"} Company
                                </Dialog.Title>
                                <div className="ml-3 flex h-7 items-center">
                                  <button
                                    type="button"
                                    className="rounded-md  text-red-200 hover:text-white"
                                    onClick={() => setmodalOpenFlage(false)}
                                  >
                                    <span className="sr-only">Close panel</span>
                                    <XMarkIcon
                                      className="h-6 w-6"
                                      aria-hidden="true"
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-1 flex-col justify-between">
                              <div className="p-4 pb-0">
                                <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                  <div>
                                    <label
                                      htmlFor="company_name"
                                      className="block text-sm font-medium text-gray-900"
                                    >
                                      Name
                                      <span className="text-red-600"> *</span>
                                    </label>
                                  </div>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <input
                                      value={values.company_name}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      type="text"
                                      placeholder="Enter Company Name"
                                      name="company_name"
                                      autoComplete="off"
                                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                                      //   required
                                    />
                                    {errors.company_name &&
                                    touched.company_name ? (
                                      <p className="text-red-600 text-sm mt-2">
                                        {errors.company_name.replace(
                                          "company_name",
                                          "Company Name"
                                        )}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-1 flex-col justify-between">
                              <div className="p-4 pb-0">
                                <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                  <div>
                                    <label
                                      htmlFor="company_logo"
                                      className="block text-sm font-medium text-gray-900"
                                    >
                                      Logo
                                      <span className="text-red-600"> *</span>
                                    </label>
                                  </div>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <input
                                      onChange={(e) => {
                                        handleChange(e);
                                        onFileChangeLogo(e);
                                      }}
                                      type="file"
                                      name="company_logo"
                                      autoComplete="off"
                                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 md:max-w-xs md:text-sm"
                                    />
                                  </div>
                                  <div className="col-span-full">
                                    {company_logo ? (
                                      <img
                                        className="shadow-sm mt-4 w-40"
                                        src={company_logo}
                                        alt="preview-image"
                                      />
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-1 flex-col justify-between">
                              <div className="p-4 pb-0">
                                <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                  <div>
                                    <label
                                      htmlFor="company_banner"
                                      className="block text-sm font-medium text-gray-900"
                                    >
                                      Banner
                                      <span className="text-red-600"> *</span>
                                    </label>
                                  </div>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <input
                                      onChange={(e) => {
                                        handleChange(e);
                                        onFileChangeBanner(e);
                                      }}
                                      type="file"
                                      name="company_banner"
                                      autoComplete="off"
                                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 md:max-w-xs md:text-sm"
                                    />
                                  </div>
                                  <div className="col-span-full">
                                    {company_banner ? (
                                      <img
                                        className="shadow-sm mt-4 w-40"
                                        src={company_banner}
                                        alt="preview-image"
                                      />
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-1 flex-col justify-between">
                              <div className="p-4 pb-0">
                                <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                  <div>
                                    <label
                                      htmlFor="company_sign"
                                      className="block text-sm font-medium text-gray-900"
                                    >
                                      Sign
                                      <span className="text-red-600"> *</span>
                                    </label>
                                  </div>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <input
                                      onChange={(e) => {
                                        handleChange(e);
                                        onFileChangeSign(e);
                                      }}
                                      type="file"
                                      name="company_sign"
                                      autoComplete="off"
                                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 md:max-w-xs md:text-sm"
                                    />
                                  </div>
                                  <div className="col-span-full">
                                    {company_sign ? (
                                      <img
                                        className="shadow-sm mt-4 w-40"
                                        src={company_sign}
                                        alt="preview-image"
                                      />
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-1 flex-col justify-between">
                              <div className="p-4 pb-0">
                                <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                  <div>
                                    <label
                                      htmlFor="company_website"
                                      className="block text-sm font-medium text-gray-900"
                                    >
                                      Company Website{" "}
                                      <span className="text-red-600"> *</span>
                                    </label>
                                  </div>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <input
                                      value={values.company_website}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      type="text"
                                      placeholder="Enter Company Website"
                                      name="company_website"
                                      autoComplete="off"
                                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                                    />
                                    {errors.company_website &&
                                    touched.company_website ? (
                                      <p className="text-red-600 text-sm mt-2">
                                        {errors.company_website.replace(
                                          "company_website",
                                          "Company Website"
                                        )}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-1 flex-col justify-between">
                              <div className="p-4 pb-0">
                                <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                  <div>
                                    <label
                                      htmlFor="company_cin"
                                      className="block text-sm font-medium text-gray-900"
                                    >
                                      CIN{" "}
                                      <span className="text-red-600"> *</span>
                                    </label>
                                  </div>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <input
                                      value={values.company_cin}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      type="text"
                                      placeholder="Enter Company CIN"
                                      name="company_cin"
                                      autoComplete="off"
                                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                                    />
                                    {errors.company_cin &&
                                    touched.company_cin ? (
                                      <p className="text-red-600 text-sm mt-2">
                                        {errors.company_cin.replace(
                                          "company_cin",
                                          "Company CIN"
                                        )}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-1 flex-col justify-between">
                              <div className="p-4 pb-0">
                                <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                  <div>
                                    <label
                                      htmlFor="company_swift_code"
                                      className="block text-sm font-medium text-gray-900"
                                    >
                                      Company Swift Code{" "}
                                      <span className="text-red-600"> *</span>
                                    </label>
                                  </div>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <input
                                      value={values.company_swift_code}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      type="text"
                                      placeholder="Enter Company Swift Code"
                                      name="company_swift_code"
                                      autoComplete="off"
                                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                                    />
                                    {errors.company_swift_code &&
                                    touched.company_swift_code ? (
                                      <p className="text-red-600 text-sm mt-2">
                                        {errors.company_swift_code.replace(
                                          "company_swift_code",
                                          "Company Swift Code"
                                        )}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-1 flex-col justify-between">
                              <div className="p-4 pb-0">
                                <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                  <div>
                                    <label
                                      htmlFor="company_email"
                                      className="block text-sm font-medium text-gray-900"
                                    >
                                      Company Email{" "}
                                      <span className="text-red-600"> *</span>
                                    </label>
                                  </div>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <input
                                      value={values.company_email}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      type="email"
                                      placeholder="Enter Company Email"
                                      name="company_email"
                                      autoComplete="off"
                                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                                    />
                                    {errors.company_email &&
                                    touched.company_email ? (
                                      <p className="text-red-600 text-sm mt-2">
                                        {errors.company_email.replace(
                                          "company_email",
                                          "Company Email"
                                        )}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-1 flex-col justify-between">
                              <div className="p-4 pb-0">
                                <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                  <div>
                                    <label
                                      htmlFor="company_gstno"
                                      className="block text-sm font-medium text-gray-900"
                                    >
                                      GSTIN number{" "}
                                      <span className="text-red-600"> *</span>
                                    </label>
                                  </div>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <input
                                      value={values.company_gstno}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      type="text"
                                      placeholder="Enter Company GST IN"
                                      name="company_gstno"
                                      autoComplete="off"
                                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                                    />
                                    {errors.company_gstno &&
                                    touched.company_gstno ? (
                                      <p className="text-red-600 text-sm mt-2">
                                        {errors.company_gstno.replace(
                                          "company_gstno",
                                          "Company GSTIN"
                                        )}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-1 flex-col justify-between">
                              <div className="p-4 pb-0">
                                <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                  <div>
                                    <label
                                      htmlFor="fk_country_id"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Country
                                    </label>
                                  </div>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <Select
                                      name="fk_country_id"
                                      placeholder={
                                        <span>
                                          Select Country
                                          <span className="text-red-600">
                                            *
                                          </span>
                                        </span>
                                      }
                                      onChange={(e) => handleCountry(e)}
                                      value={selectedCountry}
                                      components={animatedComponents}
                                      options={countryList}
                                      classNamePrefix="select"
                                      className="basic-multi-select block w-full max-w-lg rounded-md border-[1px]  shadow-sm  focus:border-red-500 sm:max-w-xs sm:text-sm"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-1 flex-col justify-between">
                              <div className="p-4 pb-0">
                                <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                  <div>
                                    <label
                                      htmlFor="fk_state_id"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      State
                                    </label>
                                  </div>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <Select
                                      name="fk_state_id"
                                      placeholder={
                                        <span>
                                          Select State
                                          <span className="text-red-600">
                                            *
                                          </span>
                                        </span>
                                      }
                                      onChange={(e) => handleState(e)}
                                      value={selectedState}
                                      components={animatedComponents}
                                      isDisabled={
                                        selectedCountry?.value ? false : true
                                      }
                                      options={stateList}
                                      classNamePrefix="select"
                                      className="basic-multi-select block w-full max-w-lg rounded-md border-[1px]  shadow-sm  focus:border-red-500 sm:max-w-xs sm:text-sm"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-1 flex-col justify-between">
                              <div className="p-4 pb-0">
                                <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                  <div>
                                    <label
                                      htmlFor="fk_city_id"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      City
                                    </label>
                                  </div>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <Select
                                      name="fk_city_id"
                                      placeholder={
                                        <span>
                                          Select City
                                          <span className="text-red-600">
                                            *
                                          </span>
                                        </span>
                                      }
                                      onChange={(e) => handleCity(e)}
                                      value={selectedCity}
                                      components={animatedComponents}
                                      isDisabled={
                                        selectedState?.value ? false : true
                                      }
                                      options={cityList}
                                      classNamePrefix="select"
                                      className="basic-multi-select block w-full max-w-lg rounded-md border-[1px]  shadow-sm  focus:border-red-500 sm:max-w-xs sm:text-sm"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-1 flex-col justify-between">
                              <div className="p-4 pb-0">
                                <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                  <div>
                                    <label
                                      htmlFor="company_pincode"
                                      className="block text-sm font-medium text-gray-900"
                                    >
                                      Company Pincode{" "}
                                      <span className="text-red-600"> *</span>
                                    </label>
                                  </div>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <input
                                      value={values.company_pincode}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      type="text"
                                      placeholder="Enter Company Pincode"
                                      name="company_pincode"
                                      autoComplete="off"
                                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                                    />
                                    {errors.company_pincode &&
                                    touched.company_pincode ? (
                                      <p className="text-red-600 text-sm mt-2">
                                        {errors.company_pincode.replace(
                                          "company_pincode",
                                          "Company Pincode"
                                        )}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-1 flex-col justify-between">
                              <div className="p-4 pb-0">
                                <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                  <div>
                                    <label
                                      htmlFor="company_registered_office_add"
                                      className="block text-sm font-medium text-gray-900"
                                    >
                                      Registered office address{" "}
                                      <span className="text-red-600"> *</span>
                                    </label>
                                  </div>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <textarea
                                      value={
                                        values.company_registered_office_add
                                      }
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      type="text"
                                      placeholder="Enter Registered office address"
                                      name="company_registered_office_add"
                                      autoComplete="off"
                                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                                    />
                                    {errors.company_registered_office_add &&
                                    touched.company_registered_office_add ? (
                                      <p className="text-red-600 text-sm mt-2">
                                        {errors.company_registered_office_add.replace(
                                          "company_registered_office_add",
                                          "Reg Office Address"
                                        )}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-1 flex-col justify-between">
                              <div className="p-4 pb-0">
                                <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                  <div>
                                    <label
                                      htmlFor="company_corporate_office_add"
                                      className="block text-sm font-medium text-gray-900"
                                    >
                                      Corporate office address{" "}
                                      <span className="text-red-600"> *</span>
                                    </label>
                                  </div>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <textarea
                                      value={
                                        values.company_corporate_office_add
                                      }
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      type="text"
                                      placeholder="Enter Corp office address"
                                      name="company_corporate_office_add"
                                      autoComplete="off"
                                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                                    />
                                    {errors.company_corporate_office_add &&
                                    touched.company_corporate_office_add ? (
                                      <p className="text-red-600 text-sm mt-2">
                                        {errors.company_corporate_office_add.replace(
                                          "company_corporate_office_add",
                                          "Corp Office Address"
                                        )}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-1 flex-col justify-between">
                              <div className="p-4 pb-0">
                                <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                  <div>
                                    <label
                                      htmlFor="company_panno"
                                      className="block text-sm font-medium text-gray-900"
                                    >
                                      Pan card number{" "}
                                      <span className="text-red-600"> *</span>
                                    </label>
                                  </div>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <input
                                      value={values.company_panno}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      type="text"
                                      placeholder="Enter Company Pancard No"
                                      name="company_panno"
                                      autoComplete="off"
                                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                                    />
                                    {errors.company_panno &&
                                    touched.company_panno ? (
                                      <p className="text-red-600 text-sm mt-2">
                                        {errors.company_panno.replace(
                                          "company_panno",
                                          "Company Pan Card Number"
                                        )}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-1 flex-col justify-between">
                              <div className="p-4 pb-0">
                                <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                  <div>
                                    <label
                                      htmlFor="company_telephone_no"
                                      className="block text-sm font-medium text-gray-900"
                                    >
                                      Telephone number{" "}
                                      <span className="text-red-600"> *</span>
                                    </label>
                                  </div>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <input
                                      value={values.company_telephone_no}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      type="text"
                                      placeholder="Enter Company Telephone No"
                                      name="company_telephone_no"
                                      autoComplete="off"
                                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                                    />
                                    {errors.company_telephone_no &&
                                    touched.company_telephone_no ? (
                                      <p className="text-red-600 text-sm mt-2">
                                        {errors.company_telephone_no.replace(
                                          "company_telephone_no",
                                          "Company Telephone Number"
                                        )}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-1 flex-col justify-between">
                              <div className="p-4 pb-0">
                                <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                  <div>
                                    <label
                                      htmlFor="company_legal_entity_type"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Company legal entity type
                                    </label>
                                  </div>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <Select
                                      name="company_legal_entity_type"
                                      placeholder={
                                        <span>
                                          Select Type
                                          <span className="text-red-600">
                                            *
                                          </span>
                                        </span>
                                      }
                                      onChange={(e) => {
                                        values.company_legal_entity_type =
                                          e.value;
                                        handleCompany_legal_entity_type(e);
                                      }}
                                      value={company_legal_entity_type}
                                      components={animatedComponents}
                                      options={company_legal_entity_typeList}
                                      classNamePrefix="select"
                                      className="basic-multi-select block w-full max-w-lg rounded-md border-[1px]  shadow-sm  focus:border-red-500 sm:max-w-xs sm:text-sm"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-1 flex-col justify-between">
                              <div className="p-4 pb-0">
                                <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                  <div>
                                    <label
                                      htmlFor="company_registration_date"
                                      className="block text-sm font-medium text-gray-900"
                                    >
                                      Company Registration Date{" "}
                                      <span className="text-red-600"> *</span>
                                    </label>
                                  </div>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <input
                                      value={values.company_registration_date}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      type="date"
                                      name="company_registration_date"
                                      autoComplete="off"
                                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                                    />
                                    {errors.company_registration_date &&
                                    touched.company_registration_date ? (
                                      <p className="text-red-600 text-sm mt-2">
                                        {errors.company_registration_date.replace(
                                          "company_registration_date",
                                          "Company Registration Date"
                                        )}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-1 flex-col justify-between">
                              <div className="p-4 pb-0">
                                <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                  <div>
                                    <label
                                      htmlFor="company_vat_no"
                                      className="block text-sm font-medium text-gray-900"
                                    >
                                      Company Vat No{" "}
                                      <span className="text-red-600"> *</span>
                                    </label>
                                  </div>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <input
                                      value={values.company_vat_no}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      type="text"
                                      placeholder="Enter Company Vat No"
                                      name="company_vat_no"
                                      autoComplete="off"
                                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                                    />
                                    {errors.company_vat_no &&
                                    touched.company_vat_no ? (
                                      <p className="text-red-600 text-sm mt-2">
                                        {errors.company_vat_no.replace(
                                          "company_vat_no",
                                          "Company vat Number"
                                        )}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-1 flex-col justify-between">
                              <div className="p-4 pb-0">
                                <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                  <div>
                                    <label
                                      htmlFor="company_tax_registration_no"
                                      className="block text-sm font-medium text-gray-900"
                                    >
                                      Company Tax Registration No{" "}
                                      <span className="text-red-600"> *</span>
                                    </label>
                                  </div>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <input
                                      value={values.company_tax_registration_no}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      type="text"
                                      placeholder="Enter Company Tax Registration No"
                                      name="company_tax_registration_no"
                                      autoComplete="off"
                                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                                    />
                                    {errors.company_tax_registration_no &&
                                    touched.company_tax_registration_no ? (
                                      <p className="text-red-600 text-sm mt-2">
                                        {errors.company_tax_registration_no.replace(
                                          "company_tax_registration_no",
                                          "Company Tax Registration Number"
                                        )}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-1 flex-col justify-between">
                              <div className="p-4 pb-0">
                                <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                  <div>
                                    <label
                                      htmlFor="company_bank_account_no"
                                      className="block text-sm font-medium text-gray-900"
                                    >
                                      Bank A/c No{" "}
                                      <span className="text-red-600"> *</span>
                                    </label>
                                  </div>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <input
                                      value={values.company_bank_account_no}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      type="text"
                                      placeholder="Enter Company Bank A/c No"
                                      name="company_bank_account_no"
                                      autoComplete="off"
                                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                                    />
                                    {errors.company_bank_account_no &&
                                    touched.company_bank_account_no ? (
                                      <p className="text-red-600 text-sm mt-2">
                                        {errors.company_bank_account_no.replace(
                                          "company_bank_account_no",
                                          "Company Bank A/c Number"
                                        )}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-1 flex-col justify-between">
                              <div className="p-4 pb-0">
                                <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                  <div>
                                    <label
                                      htmlFor="company_bank_account_name"
                                      className="block text-sm font-medium text-gray-900"
                                    >
                                      Bank A/c Name{" "}
                                      <span className="text-red-600"> *</span>
                                    </label>
                                  </div>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <input
                                      value={values.company_bank_account_name}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      type="text"
                                      placeholder="Enter Company Bank A/c Name"
                                      name="company_bank_account_name"
                                      autoComplete="off"
                                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                                    />
                                    {errors.company_bank_account_name &&
                                    touched.company_bank_account_name ? (
                                      <p className="text-red-600 text-sm mt-2">
                                        {errors.company_bank_account_name.replace(
                                          "company_bank_account_name",
                                          "Company Bank A/c Name"
                                        )}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-1 flex-col justify-between">
                              <div className="p-4 pb-0">
                                <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                  <div>
                                    <label
                                      htmlFor="company_bank_name"
                                      className="block text-sm font-medium text-gray-900"
                                    >
                                      Bank Name{" "}
                                      <span className="text-red-600"> *</span>
                                    </label>
                                  </div>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <input
                                      value={values.company_bank_name}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      type="text"
                                      placeholder="Enter Company Bank Name"
                                      name="company_bank_name"
                                      autoComplete="off"
                                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                                    />
                                    {errors.company_bank_name &&
                                    touched.company_bank_name ? (
                                      <p className="text-red-600 text-sm mt-2">
                                        {errors.company_bank_name.replace(
                                          "company_bank_name",
                                          "Company Bank Name"
                                        )}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-1 flex-col justify-between">
                              <div className="p-4 pb-0">
                                <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                  <div>
                                    <label
                                      htmlFor="company_ifsc_code"
                                      className="block text-sm font-medium text-gray-900"
                                    >
                                      Bank IFSC Code{" "}
                                      <span className="text-red-600"> *</span>
                                    </label>
                                  </div>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <input
                                      value={values.company_ifsc_code}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      type="text"
                                      placeholder="Enter Company Bank IFSC Code"
                                      name="company_ifsc_code"
                                      autoComplete="off"
                                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                                    />
                                    {errors.company_ifsc_code &&
                                    touched.company_ifsc_code ? (
                                      <p className="text-red-600 text-sm mt-2">
                                        {errors.company_ifsc_code.replace(
                                          "company_ifsc_code",
                                          "Company Bank IFSC Code"
                                        )}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-1 flex-col justify-between">
                              <div className="p-4 pb-0">
                                <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                  <div>
                                    <label
                                      htmlFor="company_branch_name"
                                      className="block text-sm font-medium text-gray-900"
                                    >
                                      Bank Branch Name{" "}
                                      <span className="text-red-600"> *</span>
                                    </label>
                                  </div>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <input
                                      value={values.company_branch_name}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      type="text"
                                      placeholder="Enter Company Bank Branch Name"
                                      name="company_branch_name"
                                      autoComplete="off"
                                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                                    />
                                    {errors.company_branch_name &&
                                    touched.company_branch_name ? (
                                      <p className="text-red-600 text-sm mt-2">
                                        {errors.company_branch_name.replace(
                                          "company_branch_name",
                                          "Company Bank Branch Name"
                                        )}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-shrink-0 justify-end px-4 py-4">
                            <button
                              type="button"
                              className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                              onClick={() => {
                                setmodalOpenFlage(false);
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                            >
                              {Company_id ? "Update" : "Add"}
                            </button>
                          </div>
                        </form>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        </div>
      )}
    </div>
  );
}
export default Company;
