import React, { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../../components/Breadcrumb";
import * as XLSX from "xlsx";
import { handleDynamicFileImport } from '../../../helper/dynamicImport';
import { toast, ToastContainer } from "react-toast";
import BarcodeServices from "../../../ApiServices/BarcodeServices";
import { FaSpinner } from "react-icons/fa";
import { barcode_columns } from "../../../components/tables/tableheader";
import Table from "../../../components/tables/table";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import ConfirmationModal from "../../../components/ConfirmationModal";
const BarcodeDispatch = () => {
  const pages = [
    { title: "Barcode Dispatch", href: `/barcode-dispatch`, module_id: 26 },
  ];
  const [importLoader, setImportLoader] = useState(false);
  const [barcodeList, setBracodeList] = useState([]);
  const [paginationData, setPagination] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [barcode_id, setBarcodeId] = useState("");
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    Company_id: null,
  });
  // Define your entity map for dynamic import (example)
  const entityMap = {
    barcode: 'barcode_no'
  };

  // Define the entity you are working with (in this case 'barcode')
  const entity = "barcode_no";

  const [error, setError] = useState(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    document.title = "Outlook | Barcode Dispatch";
    getAllBarcode()
  }, []);


  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleImport = (event) => {
    const file = event.target.files[0];

    // File type validation
    if (file && !file.name.match(/\.(xlsx|xls)$/)) {
      setError("Please upload a valid Excel file.");
      return;
    }
    if (file) {
      setImportLoader(true);

      // Call the dynamic import utility function
      handleDynamicFileImport(file, entityMap)
        .then((importData) => {
          if (importData.length > 0) {
            console.log('importData=>', importData)
            // Call API to save the imported data
            importEntities(importData);
          } else {
            setImportLoader(false);
            toast.warning('No valid data found in the file');
          }
        })
        .catch((error) => {
          setImportLoader(false);
          toast.error(`Error processing file: ${error.message}`);
        });
    } else {
      toast.error('No file selected');
    }
  };

  const importEntities = (data) => {
    const payload = { excel_data: data };

    BarcodeServices.barcodeExcelImport(payload, entity)
      .then((response) => {
        setImportLoader(false);
        if (response.status === 200) {
          toast.success(`${entity} imported successfully`);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        setImportLoader(false);
        toast.error(`Error while importing ${entity}`);
      });
  };

  const getAllBarcode = () => {
    setIsLoading(true)
    BarcodeServices.getAllBarcode(3).then((res) => {
      const { data, status } = res;
      console.log('data=>', data)
      if (status) {
        setBracodeList(data.data.barcodes)
        setPagination(data.data.pagination)
      }
      setIsLoading(false)
    }).catch((error) => {
      console.log(error)

    });
  }

  const onDeleteOpen = (barcode_id) => {
    setConfirmationModal({ barcode_id, status: true });
  };

  const onDeleteBarcode = async (barcode_id) => {

    setIsLoading(true);
    let body = {
      barcode_id: barcode_id,
    };

    BarcodeServices.RemoveBarcode(body).then((res) => {
      const { data, status } = res;
      console.log('data=>', data)
      if (status) {
        getAllBarcode();
      }
      setIsLoading(false)
    }).catch((error) => {
      console.log(error)

    });
    setConfirmationModal({ status: false, barcode_id: null });
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <ConfirmationModal
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => onDeleteBarcode(confirmationModal.barcode_id)}
      />
      <Breadcrumb pages={pages} />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">
          Barcode Dispatch
        </h1>
      </div>
      <div className="mt-4 flex">
        <button
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2"
          disabled={importLoader}
          onClick={handleButtonClick}
          aria-live="polite" // Optional for screen readers
        >
          {importLoader ? (
            <>
              <FaSpinner color="gold" className="spinner animate-spin" />
              <span className="ml-2">Uploading Please Wait ...</span>
            </>
          ) : (
            "Upload Barcode"
          )}
        </button>
        <input
          accept=".xlsx, .xls"
          onChange={handleImport}
          type="file"
          name="barcode_excel"
          autoComplete="off"
          ref={fileInputRef}
          className="block w-full bg-white max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
          style={{ display: 'none' }} // Hide the file input
        />
        {error && <p style={{ color: "red" }}>{error}</p>}
      </div>
      {isLoading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={barcode_columns({
            onDeleteOpen,
          })}
          data={barcodeList?.length > 0 && !isLoading ? barcodeList : []}
          is_toggle={false}
        />
      )}

    </div>
  );
};

export default BarcodeDispatch;
