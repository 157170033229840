import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import MasterServices from "../../../ApiServices/MasterServices";
import ConfirmationModal from '../../../components/ConfirmationModal'
import Breadcrumb from '../../../components/Breadcrumb'
import { FallingLinesLoader } from '../../../components/spinners/Spinner';
import Table from '../../../components/tables/table';
import { commission_columns } from '../../../components/tables/tableheader';
import { toast } from "react-toast";
import Select from 'react-select'
import makeAnimated from "react-select/animated";
import { getAllowedActions, checkPermissionsAndRedirect, capitalizeFirstLetter } from "../../../helper/commonHelper";
import { convertToAnotherTimezone, calculateAge } from '../../../helper/commonHelper';
import moment from 'moment';
import { utils, writeFile } from 'xlsx';

const Commission = () => {
    const [loading, setLoading] = useState(false);
    const [getCommission, setGetCommission] = useState([])
    const navigate = useNavigate();

    const pages = [{ title: "Salary List", href: "/commission", module_id: 10 }];
    const [confirmationModal, setConfirmationModal] = useState({
        status: false,
        comm_id: null,
    });

    const [allowedActions, setAllowedActions] = useState([]);
    useEffect(() => {
        const permissions = JSON.parse(localStorage.getItem("user_permissions"));

        if (permissions && window.location.pathname) {
            const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect(window.location.pathname, permissions, navigate);
            const actions = getAllowedActions(permissions, window.location.pathname);
            setAllowedActions(actions);
        }
    }, []);
    const handleExport = (excel_type) => {

        if (getCommission?.length > 0) {
            let newSheetdata = [];
            // getCommission.map((obj, index) => {
            //     const newData = {
            //         SN: parseInt(index) + 1,
            //         comm_id: obj?.comm_id || '-',
            //         fk_order_id: obj?.fk_order_id || '-',
            //         fk_user_id: obj?.fk_user_id || '-',
            //         userName: (obj?.m_user_commission_relation?.user_first_name ? capitalizeFirstLetter(obj?.m_user_commission_relation?.user_first_name) : "") + (obj?.m_user_commission_relation?.user_last_name ? " " + capitalizeFirstLetter(obj?.m_user_commission_relation?.user_last_name) : "") || '-',
            //         user_email: obj?.m_user_commission_relation?.user_email || '-',
            //         user_mobile: obj?.m_user_commission_relation?.user_mobile || '-',
            //         role_name: obj?.m_user_commission_relation?.m_role_relation?.role_name || '-',
            //         comm_ord_main_value: obj?.comm_ord_main_value || '-',
            //         comm_value: obj?.comm_value || '-',
            //         comm_percentage_value: obj?.comm_percentage_value || '-',
            //         comm_created_at: moment(obj?.comm_created_at).format("DD-MM-YYYY") || '-',
            //         Status: obj?.comm_is_active == true ? 'Active' : 'In-Active' || '-',
            //     }
            //     newSheetdata.push(newData);
            // });
            let currentDate = new Date();
            let fileName = 'Salary_Excel_Report_' + moment(currentDate).format("DD-MM-YYYY") + '.' + excel_type;

            getCommission.map((obj, index) => {
                const newData = {
                    SN: parseInt(index) + 1,
                    userName: (obj?.m_user_commission_relation?.user_first_name ? capitalizeFirstLetter(obj?.m_user_commission_relation?.user_first_name) : "") + (obj?.m_user_commission_relation?.user_last_name ? " " + capitalizeFirstLetter(obj?.m_user_commission_relation?.user_last_name) : "") || '-',
                    ord_user_referral_code: obj?.m_user_commission_relation?.user_referral_code || '-',
                    role_name: obj?.m_user_commission_relation?.m_role_relation?.role_name || '-',
                    parent_role_name: obj?.m_user_commission_relation?.user_assigned_relation?.m_role_relation?.role_name || '-',
                    parent_userName: (obj?.m_user_commission_relation?.user_assigned_relation?.user_first_name ? capitalizeFirstLetter(obj?.m_user_commission_relation?.user_assigned_relation?.user_first_name) : "") + (obj?.m_user_commission_relation?.user_assigned_relation?.user_last_name ? " " + capitalizeFirstLetter(obj?.m_user_commission_relation?.user_assigned_relation?.user_last_name) : "") || '-',
                    parentRefralCode: obj?.m_user_commission_relation?.user_assigned_relation?.user_referral_code || '-',
                    totalOrders: obj?.totalOrders || '-',
                    offlineSales: obj?.offlineSales || '-',
                    onlineSales: obj?.onlineSales || '-',
                    totalDirectSales: obj?.totalDirectSales || '-',
                    totalIndirectSales: obj?.totalIndirectSales || '-',
                    totalSale: obj?.totalSale || '-',
                    totalDirectCommission: obj?.totalDirectCommission || '-',
                }
                newSheetdata.push(newData);
            });
            const headings = [['SN', 'Name', 'Referal Code', 'Role', 'Parent role', 'Parent Name','Parent Referal Code', 'Total Orders','Offline sales', 'Online sales', 'Total Direct Sales', 'Total In-direct Sales', 'Total Sale', 'Total Direct Commission']];
            // const headings = [['SN', 'Commission ID', 'Order ID', 'User ID', 'User Name', 'User Email', 'User Mobie', 'Role', 'Order AMT', 'Commission AMT', 'Commission Percentage', 'Commission Date', 'Status']];
            const wb = utils.book_new();
            const ws = utils.json_to_sheet([]);
            utils.sheet_add_aoa(ws, headings);
            utils.sheet_add_json(ws, newSheetdata, { origin: 'A2', skipHeader: true });
            utils.book_append_sheet(wb, ws, 'Report');
            writeFile(wb, fileName);
        } else {
            toast.error("Data Not Found!");
        }
    }
    // Filter Values Start
    let filterInitialValues = {
        filterFrom: "",
        filterTo: "",
        filterRoleId: "",
        filterUserName: "",
        filterUserEmail: "",
        filterUserMobile: "",
        filterOrderId: "",
        filterCommissionStatus: "",
        fk_user_id: localStorage.getItem("commission_user_id") ? localStorage.getItem("commission_user_id") : "",
    };
    let [filterCommission, setFilterCommission] = useState(filterInitialValues);
    const [filterFlag, setFilterFlag] = useState(true);
    const [getAllRole, setGetAllRole] = useState([])
    const animatedComponents = makeAnimated();
    // Function to handle to CLear filter values
    const onFilterClear = () => {
        setIsSearched(false)
        setGetCommission([]);
        filterCommission.filterFlag = false;
        setFilterCommission(filterInitialValues);
        // getAllCommissionList(3);
    }
    // Function to handle changes in filter values
    const handleFilterChange = (event) => {
        const { name, value } = event.target;
        setFilterCommission({ ...filterCommission, [name]: value });
    };
    const handleSelectFilterChange = (selectedOption, name) => {
        const { label, value } = selectedOption || {};
        setFilterCommission({ ...filterCommission, [name]: selectedOption });
    };
    const onFilter = () => {
        if (checkObjectValues(filterCommission)) {
            setIsSearched(true)
            filterCommission.filterFlag = true;
            getAllCommissionList(3);
        }else{
            toast.error("Please Select Alteast One Filter!");
        }
    }
    const getRoles = (payload) => {
        MasterServices.getAllRole(payload).then((res) => {
            const { data, status } = res.data
            if (status) {
                const newArray = data?.map((ele) => ({
                    value: ele.role_id,
                    label: ele.role_name
                }))
                setGetAllRole(newArray);
            }
        }).catch((err) => {
            console.log(err)
        })
    }
    // Filter Values End

    useEffect(() => {
        getRoles(1);
        // getAllCommissionList(3);
    }, []);
    const getAllCommissionList = async () => {
        let fk_user_id = 0;
        if (localStorage.getItem("commission_user_id")) {
            fk_user_id = localStorage.getItem("commission_user_id");
            localStorage.setItem("commission_user_id", null);
        }
        setLoading(true)
        let payload = {
            status_code: 3,
            fk_user_id: fk_user_id,
            filterCommission: filterCommission,
        }
        const response = await MasterServices.getAllCommission(payload);
        if (response.data.status === true) {
            if (response?.data?.data?.length > 0) {
                setGetCommission(response?.data?.data);
            } else {
                setGetCommission([]);
            }
            setLoading(false)
        }
    };
    const onDeleteOpen = (comm_id) => {
        setConfirmationModal({ comm_id, status: true });
    }

    const onDeleteItem = (comm_id) => {
        MasterServices.deleteCommission({ comm_id })
            .then((response) => {
                const { data, status } = response;
                if (data.status) {
                    setLoading(true)
                    toast.success(data.message)
                    setConfirmationModal({ comm_id: null, status: false });
                    getAllCommissionList(3);
                    navigate("/subscription/");
                } else {
                    toast.error(data.message)
                }
                setLoading(false)
            })
            .catch((error) => {
                toast.error("Sorry Fatal Error!")
                setLoading(false)
            });
    }

    const onStatusUpdate = (comm_id, comm_is_active) => {
        let payload = {
            table_name: "m_commission",
            table_field: "comm_id",
            table_field_val: comm_id,
            table_status_field: "comm_is_active",
            table_status_val: comm_is_active ? false : true,
            table_text: "Commission",
            deleted_by_key: "comm_updated_by",
            delete_active_txt: comm_is_active ? " Deactivated" : " Activated"
        }
        MasterServices.removeMaster(payload).then((res) => {
            const { data, status, message } = res.data
            if (status) {
                getAllCommissionList(3);
                toast.success(message);
            } else {
                toast.error(message)
            }
        })
    }
    // You can define a function to increment the month value
    
    const [isSearched, setIsSearched] = useState(false);
    function checkObjectValues(obj) {
        for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                if (typeof obj[key] === "object") {
                    if (checkObjectValues(obj[key])) {
                        return true;
                    }
                } else if (obj[key] !== "") {
                    return true;
                }
            }
        }
        return false;
    }

    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <ConfirmationModal
                open={confirmationModal.status}
                setOpen={setConfirmationModal}
                onDelete={() => onDeleteItem(confirmationModal.comm_id)}
            />
            <Breadcrumb pages={pages} />


            {filterFlag && (
                <div className="px-2 py-2 pb-5 sm:px-4 rounded-md bg-white shadow-lg ring-1 ring-white ring-opacity-5 focus:outline-none mb-2">
                    <button
                        type="button"
                        className="text-red-500 absolute right-20 hover:text-red-700 font-bold py-1 px-1 rounded-full border border-red-500 hidden"
                        onClick={() => { setFilterFlag(false); onFilterClear(); }}
                    >
                        X
                    </button>
                    <div className="space-y-6 sm:space-y-5">
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 mt-5">
                            <div className="sm:col-span-1 lg:col-span-1">
                                <label htmlFor="filterRoleId" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Role
                                </label>
                                <Select
                                    name="filterRoleId"
                                    id="filterRoleId"
                                    placeholder="Select Role "
                                    onChange={(e) => { handleSelectFilterChange(e, 'filterRoleId') }}
                                    value={filterCommission.filterRoleId}
                                    options={getAllRole}
                                    components={animatedComponents}
                                    className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                                />
                            </div>
                            <div className="sm:col-span-1 lg:col-span-1">
                                <label htmlFor="filterUserName" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    User Name
                                </label>
                                <input
                                    type="text"
                                    name="filterUserName"
                                    id="filterUserName"
                                    value={filterCommission.filterUserName}
                                    onChange={handleFilterChange}
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                />
                            </div>
                            <div className="sm:col-span-1 lg:col-span-1">
                                <label htmlFor="filterUserEmail" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    User Email
                                </label>
                                <input
                                    type="email"
                                    name="filterUserEmail"
                                    id="filterUserEmail"
                                    value={filterCommission.filterUserEmail}
                                    onChange={handleFilterChange}
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                />
                            </div>
                            <div className="sm:col-span-1 lg:col-span-1">
                                <label htmlFor="filterUserMobile" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    User Mobile
                                </label>
                                <input
                                    type="number"
                                    name="filterUserMobile"
                                    id="filterUserMobile"
                                    value={filterCommission.filterUserMobile}
                                    onChange={handleFilterChange}
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                />
                            </div>
                            <div className="sm:col-span-1 lg:col-span-1">
                                <label htmlFor="filterFrom" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Start Date
                                </label>
                                <input
                                    type="date"
                                    name="filterFrom"
                                    id="filterFrom"
                                    value={filterCommission.filterFrom}
                                    onChange={handleFilterChange}
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                />
                            </div>
                            <div className="sm:col-span-1 lg:col-span-1">
                                <label htmlFor="filterTo" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    End Date
                                </label>
                                <input
                                    type="date"
                                    name="filterTo"
                                    id="filterTo"
                                    value={filterCommission.filterTo}
                                    onChange={handleFilterChange}
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                />
                            </div>
                            <div className="sm:col-span-1 lg:col-span-1">
                                <label htmlFor="filterOrderId" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Order ID
                                </label>
                                <input
                                    type="number"
                                    name="filterOrderId"
                                    id="filterOrderId"
                                    value={filterCommission.filterOrderId}
                                    onChange={handleFilterChange}
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                />
                            </div>
                            <div className="sm:col-span-1 lg:col-span-1">
                                <label htmlFor="filterCommissionStatus" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Status
                                </label>
                                <select
                                    name="filterCommissionStatus"
                                    value={filterCommission.filterCommissionStatus}
                                    onChange={handleFilterChange}
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                >
                                    <option value="">Select Commission Status</option>
                                    <option value="1">Active</option>
                                    <option value="2">In-Active</option>
                                </select>
                            </div>

                            <div className="sm:col-span-1 lg:col-span-1 mt-6">
                                <button
                                    type="button"
                                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2"
                                    onClick={onFilter}
                                >
                                    Search
                                </button>
                                <button
                                    type="button"
                                    className="inline-flex items-center justify-center ml-2 rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2"
                                    onClick={onFilterClear}
                                >
                                    Clear
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            )}

            <div className="flex justify-end mt-4">
                <div className="flex items-center space-x-2 hidden">
                    <div className="relative">
                        <button
                            onClick={() => {
                                setFilterFlag(filterFlag ? false : true);
                            }}
                            className="relative z-0 inline-flex text-sm rounded-md shadow-sm focus:ring-accent-500 focus:border-accent-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 mr-2">
                            <span className="relative inline-flex items-center px-3 py-3 space-x-2 text-sm font-medium text-gray-600 bg-white border border-gray-300 rounded-md sm:py-2">
                                <div>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="w-3 h-3"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
                                        />
                                    </svg>
                                </div>
                                <div className="hidden sm:block">Filters {filterFlag ? 'On' : 'Off'}</div>
                            </span>
                        </button>
                    </div>
                </div>
                {allowedActions.filter((data) => data.permission_id == 83)?.length > 0 && getCommission?.length > 0 ? (
                    <Link
                        to={"#"}
                        onClick={() => handleExport('xlsx')}
                        type="button"
                        className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2"
                    >
                        Download Excel
                    </Link>) : null}
            </div>
            {!isSearched && (<p className='text-center text-xl font-semibold mt-5' style={{ color: "#872341" }}>Select Filter To Display Salary List</p>)}
            {loading ? (<FallingLinesLoader />) : (
                isSearched &&
                <Table
                    columns={commission_columns({ onDeleteOpen, onStatusUpdate })}
                    data={getCommission}
                    is_toggle={false}
                />)}
        </div>
    )
}

export default Commission