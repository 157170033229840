import { React, useState, useRef, useEffect } from 'react'
import { Switch } from '@headlessui/react'
import Breadcrumb from "../../../components/Breadcrumb";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { ArrowUturnLeftIcon } from '@heroicons/react/20/solid';
import Api from '../../../ApiServices/MasterServices';
import PermissoinServices from '../../../ApiServices/PermissionServices';
import { toast } from 'react-toast';
import { event } from 'jquery';
// import { Dashboard } from '@mui/icons-material';

function locationSpacificeRole(...location) {
    return location.filter(Boolean).join(' ')
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const initialCategories = {
    Dashboard : [],
    Home: [],
    User: [],
    Role: []
}

const EditPermissions = ({ roleList, setRolesList }) => {
    const pages = [{ title: "Add Role", href: "/add-role" }];
    const roleRef = useRef();
    const prevLocation = useLocation();
    const [location, setlocation] = useState(false)
    // const [roleName, setroleName] = useState(null)
    const [rolePermissionRes, setRolePermissionRes] = useState(false)
    const [prevPermissions, setPrevPermissions] = useState([])
    const [allPermissions, setAllPermissions] = useState(false)
    const [allModule, setAllModule] = useState([])
    const [allModuleNew, setAllModuleNew] = useState([])
    const [enabled, setEnabled] = useState(true)

    const [permissionsByCategory, setPermissionsByCategory] = useState(initialCategories)

    const navigate = useNavigate();
    const { role_id: role_id } = useParams();

    // console.log("newArrList", newArrList);
    const handleSubmit = async (event) => {
        event.preventDefault();

        let payload = {
            role_id: role_id,
            role_name: rolePermissionRes.role_name,
            fk_permission_ids: []
        }
        payload.fk_permission_ids = prevPermissions.map((e) => {
            return e = { permission_id: e }
        })

        // console.log({ payload }, "payload")
        let res = await Api.saveRole(payload)

        if(res.status){
            // console.log("Success", res.message)
            toast.success("Role and Permissions saved successfully")
        }
    };

    useEffect(() => {
        getPermissionsWithRole(role_id)
        getAllPermissions()
        getAllModules()
        allModulesWithPermissions(role_id)
    }, [])

    const allModulesWithPermissions = async (role_id) => {
        const resp = await PermissoinServices.getAllModulesWithPermissions(role_id);
        setAllModuleNew(resp?.data?.data?.allModules)
        console.log("allModulesWithPermissions", resp);
    }

    useEffect(() => {
        if (allPermissions) {
            sortPermissionsByCategory()
        }
    }, [allPermissions])

    const getPermissionsWithRole = async (role_id) => {
        try {
            const res = await Api.getPermissionsByRoleID(role_id)
            if (res.status) {
                setRolePermissionRes(res.data.data)

                let prevIds = []
                res.data.data.permissions.map((p) => {
                    prevIds.push(p.permission_id)
                })
                setPrevPermissions(prevIds)
            }
        } catch (e) {
            console.log(e, "error in getPermissionsWithRole")
        }
    }

    const getAllPermissions = async () => {
        try {
            const res = await Api.getAllPermissions()
            if (res.status) {
                setAllPermissions(res.data.data)
            }
        } catch (e) {
            console.log(e, "error in getAllPermissions")
        }
    }

    const getAllModules = async () => {
        try {
            const res = await PermissoinServices.getAllModules(3)
            if (res.status) {
                setAllModule(res.data.data);
            }
        } catch (e) {
            console.log(e, "error in getAllModules")
        }
    }

    const sortPermissionsByCategory = () => {

        allPermissions.forEach((el)=>{
            if(initialCategories[el.permission_category] == undefined) {
                initialCategories[el.permission_category] = []
            }
        })

        let sortObj = {
            ...initialCategories
        }

        allPermissions.map((p) => {
            for(let key in sortObj){
                if(key === p.permission_category){
                    if(p.permission_name.split(" ").includes("Admin")){
                        sortObj[key] =[p, ...sortObj[key]]
                    }else{
                        sortObj[key] =[...sortObj[key], p]
                    }
                }
            }
        })

        // console.log(sortObj, "sortObj")

        setPermissionsByCategory({
           ...sortObj
        })
    }

    const updateMainModuleMenu = (id, name, data) => {
        console.log({id, data});
        const getAllMOdule = allModule;
        let newModule = [];
        allModule.map((data, index) => {
            if(data.module_id == id){
                data.module_is_active = data;
            }
            newModule.push(data);
        })
        setAllModule(newModule);
    }

    const getStatusByMID = (fk_mid) => {
        let status = false;
        allModule.map((module, index) => {
            if(module.module_id == fk_mid){
                status= module.module_is_active;
            }
        })
        return status
    }

    const handlePermissionToggle = async (parent, child, event, pid) => {
        console.log({parent, child, event, pid});
        let newData = { ...allModuleNew };
        newData[parent].m_permission[child].rpm_is_active = event;

        console.log({parent, child, event, pid, gg: newData[parent].m_permission[child].rpm_is_active});
        setAllModuleNew(newData);

        let payload = {
            role_id: role_id,
            permission_id: pid,
            status: event
        }
        let res = await Api.saveRole(payload);
        console.log("handlePermissionToggle", res);
    }

    return (
        <div>
            <Breadcrumb pages={pages} />
            <button
                type="button"
                onClick={() => navigate(-1)}
                className="inline-flex mb-3 justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-[#BE3144] focus:outline-none focus:ring-2 focus:ring-[#BE3144] focus:ring-offset-2"
            >
                <span className="w-5 mr-2"><ArrowUturnLeftIcon size={18} /></span> Back
            </button>
            <div className="sm:flex-auto mb-5">
                <h1 className="text-xl font-semibold text-gray-900">Edit Permission</h1>
                <h4 className='mt-4'>Role : {rolePermissionRes ? rolePermissionRes.role_name : ""}</h4>
                
            </div>
            <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-4">
                {allModuleNew ?
                    Object.values(allModuleNew).map((module, parrentPos) => {
                        return (
                            
                            <div className='border border-gray-300 rounded-md px-4 py-4 mb-5 mr-4 roar'>
                                <div className='items-center gap-5' >
                                    <div className='items-center border-b border-gray-300'>
                                        <div className='flex justify-between my-2'>
                                            <label htmlFor="role" className="block text-lg font-medium text-gray-700 mr-5">
                                                {module?.module_name}
                                            </label>

                                            {/* <Switch
                                                //checked={permissionsByCategory[cat][0]?.sys_module_relation?.module_is_active}
                                                checked={enabled}
                                                onChange={(event) => {
                                                    updateMainModuleMenu(permissionsByCategory[cat][0]?.fk_module_id, cat,  event)
                                                }}
                                                className={classNames(
                                                    enabled ? 'bg-green-600' : 'bg-gray-200',
                                                    'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2'
                                                )}
                                            >
                                                <span className="sr-only">Use setting</span>
                                                <span
                                                aria-hidden="true"
                                                className={classNames(
                                                    enabled ? 'translate-x-5' : 'translate-x-0',
                                                    'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                                )}
                                                />
                                            </Switch> */}
                                        </div>
                                    </div>
                                    <div className='w-full items-center'>
                                        {/* Toggles Switches */}
                                        {
                                        module?.m_permission.length > 0 ?

                                        <div>
                                        {module?.m_permission.map((per, childPos) => {
                                            return (
                                                
                                                <div className='flex justify-between my-4'>
                                                    <label htmlFor="role" className={`${per.permission_name.split(" ").includes("Admin") ? "text-cyan-500" : "text-gray-700"} block text-sm font-medium  mr-5`}>
                                                        {per.permission_name}
                                                    </label>
                                                    <Switch
                                                        checked={per.rpm_is_active}
                                                        onChange={(e) => {
                                                            // console.log(e);
                                                            /* setPrevPermissions((p) => {
                                                                if (e) {
                                                                    return [...p, per.permission_id]
                                                                } else {
                                                                    let newp = [...p];
                                                                    newp.splice(newp.indexOf(per.permission_id), 1);
                                                                    return [...newp]
                                                                }
                                                            }) */
                                                            handlePermissionToggle(parrentPos, childPos, e, per.permission_id)
                                                        }}
                                                        className={locationSpacificeRole(
                                                            per.rpm_is_active ? 'bg-gradient-to-b from-[#872341] to-[#BE3144]' : 'bg-gray-200',
                                                            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-[#BE3144] focus:ring-offset-2'
                                                        )}
                                                    >
                                                        <span className="sr-only">Use setting</span>
                                                        <span
                                                            aria-hidden="true"
                                                            className={locationSpacificeRole(
                                                                per.rpm_is_active ? 'translate-x-5' : 'translate-x-0',
                                                                'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                                            )}
                                                        />
                                                    </Switch>
                                                </div>
                                            )
                                        })}
                                        </div> 
                                        : <div className='mt-2'>No Permissoins Available</div>
                                        
                                        }
                                    </div>


                                </div>
                            </div>
                            
                        )
                    })
                    : null}
            </div>

            <div className='block w-full'>
                {/* <Link
                    onClick={handleSubmit}
                    type="button"
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-[#557C55] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 sm:w-auto"
                >
                    Save Role and Permissions
                </Link> */}
            </div>


        </div>
    )
}

export default EditPermissions