import React, { Fragment, useEffect, useState, useRef } from "react";
import Breadcrumb from "../../../components/Breadcrumb";
import { PlusCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import MasterServices from "../../../ApiServices/MasterServices";
import { useFormik } from "formik";
import { orderSchema } from "../../../schemas";
import { toast, ToastContainer } from "react-toast";
import { Await, Link, useNavigate, useParams } from "react-router-dom";
import { FaSpinner } from "react-icons/fa";
import { Dialog, Switch, Transition } from "@headlessui/react";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import moment from 'moment';

const AddOrder = () => {
  const { ord_id: ord_id } = useParams();
  const pages = [
    {
      title: ord_id ? "Update Order" : "Add Order",
      href: "/add-order",
      module_id: 8,
    },
  ];
  let valueFlagChanged = 0;
  const navigate = useNavigate();
  const animatedComponents = makeAnimated();
  const [skuData, setSkuData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [allCountry, setAllCountry] = useState([]);
  const [payMethod, setPayMethod] = useState([]);
  const [selectedSku, setSelectedSku] = useState([]);
  const [selectCountry, setSelectCountry] = useState([]);
  const [onlineStatus, setOnlineStatus] = useState(false);

  const [selectedOption, setSelectedOption] = useState([]);
  const [orderDetails, setOrderDetails] = useState([]);

  const [viewSubscription, setViewSubscription] = useState(false);
  const cancelButtonRef = useRef(null);
  const [subscriptionList, setSubscriptionList] = useState(false);
  const [loadingSubs, setLoadingSubs] = useState(false);
  const months = [];
  const currentYear = new Date().getFullYear();
  const [submitButtonLoader, setSubmitButtonLoader] = useState(false);
  for (let i = 1; i <= 12; i++) {
    const monthValue = i < 10 ? `0${i}` : `${i}`;
    const monthLabel = new Date(currentYear, i - 1, 1).toLocaleString('en-US', { month: 'long' });
    months.push({ label: monthLabel, value: monthValue });
  }
  const generateYears = () => {
    const years = [];
    for (let year = 2024; year <= 3000; year++) {
      years.push({ label: year.toString(), value: year });
    }
    return years;
  };

  const yearsList = generateYears();
  // let subsInitialValues = {

  //   subsDay: { label: "", value: "" },
  //   subsMonth: { label: "", value: "" },
  //   subsYear: { label: "", value: "" },
  //   subsDate: "",
  // };
  // const [subsDetails, setSubscriptionDetails] = useState(subsInitialValues);

  // Initialize state for subscription details array
  const [subsDetails, setSubscriptionDetails] = useState([]);
  // Function to initialize subscription details for each row
  const initializeSubscriptionDetails = (magazineDetails) => {
    const initialDetails = magazineDetails.map(() => ({
      subsDay: { label: "", value: "" },
      subsMonth: { label: "", value: "" },
      subsYear: { label: "", value: "" },
      subsDate: "",
    }));
    setSubscriptionDetails(initialDetails);
  };
  // Call initializeSubscriptionDetails when magazineDetails changes
  useEffect(() => {
    if (orderDetails && orderDetails.magazineDetails) {
      initializeSubscriptionDetails(orderDetails.magazineDetails);
    }
  }, [orderDetails]);
  // Update subscription details for a specific row
  const handleInputChange = (event, index, mm_interval_type) => {
    const { name, value } = event.target;
    setSubscriptionDetails(prevState => {
      const newState = [...prevState];
      let formattedDate = "";
      if (mm_interval_type == 'Date') {
        let parts = value.split('-');
        formattedDate = subsDetails[index]?.subsYear?.value + '-' + subsDetails[index]?.subsMonth?.value + '-' + parts[2];
      }
      newState[index] = { ...newState[index], [name]: formattedDate };
      return newState;
    });
  };

  const handleSelectChange = (selectedOption, name, index, mag) => {

    setSubscriptionDetails(prevState => {
      const newState = [...prevState];
      newState[index] = { ...newState[index], [name]: selectedOption };
      if (name === 'subsMonth' || name === 'subsDay') {
        newState[index].subsDate = "";
        newState[index].subsYear = { label: "", value: "" };
      }
      if (name === 'subsYear') {
        newState[index] = getDateofSubsStart(newState[index], name);
      }
      if (mag?.mm_interval_type == 'Weekend') {
        // console.log("mag=>", mag)
        newState[index] = generateWeekendDate(newState[index], mag);
      }
      return newState;
    });
  };
  const generateWeekendDateNew = (subscriptionDetails, mag) => {
    const currentMonth = parseInt(subscriptionDetails.subsMonth.value);
    const currentYear = parseInt(subscriptionDetails.subsYear.value);
    const currentDate = parseInt(subscriptionDetails.subsDay.value);

    // Calculate the next month and year
    const nextMonth = currentMonth === 12 ? 1 : currentMonth;
    const nextYear = currentMonth == nextMonth ? currentYear : nextMonth === 1 ? currentYear + 1 : currentYear;

    // First day of the next month
    const firstDayOfNextMonth = new Date(nextYear, nextMonth - 1, 1);

    // Get the desired weekday from the provided subscriptionDetails or fallback to 0 (Sunday)
    const weekDay = parseInt(mag?.mm_week_day_interval) || 0;
    const weekInterval = parseInt(mag?.mm_week_interval) || 0;

    // Find the first occurrence of the target weekday in the next month
    // let firstTargetDayOfNextMonth = firstDayOfNextMonth.getDay();
    // let daysToAdd = ((weekDay - firstTargetDayOfNextMonth + 7) % 7);
    // console.log("weekDay=>",weekDay,firstTargetDayOfNextMonth,daysToAdd,weekInterval)
    // // Calculate the target date based on week interval
    // let targetDate = new Date(firstDayOfNextMonth);

    // targetDate.setDate(targetDate.getDate() + daysToAdd + (parseInt(mag?.mm_week_interval)) * 7);
    // // Check if the target date is within the current month
    // if (targetDate.getMonth() !== currentMonth - 1) {
    //   // Adjust to the previous week if it falls into the next month
    //   targetDate.setDate(targetDate.getDate() - 7);
    // }

    // // Check if the target date is greater than or equal to the provided date
    // if (targetDate < new Date(currentYear, currentMonth - 1, currentDate)) {
    //   // Move to the next occurrence if it's before the provided date
    //   targetDate.setDate(targetDate.getDate() + 7);
    // }

    // // Format the date as yyyy-mm-dd
    // const formattedDate = `${targetDate.getFullYear()}-${(targetDate.getMonth() + 1).toString().padStart(2, '0')}-${targetDate.getDate().toString().padStart(2, '0')}`;
    // subscriptionDetails.subsDay = { label: targetDate.getDate().toString().padStart(2, '0'), value: targetDate.getDate().toString().padStart(2, '0') };
    // return {
    //   ...subscriptionDetails,
    //   subsDate: formattedDate
    // };

    
    // Find the first occurrence of the target weekday in the next month
    let firstTargetDayOfNextMonth = firstDayOfNextMonth.getDay();
    let daysToAdd = ((weekDay - firstTargetDayOfNextMonth + 7) % 7);

    // Calculate the target date based on week interval
    let targetDate = new Date(firstDayOfNextMonth);
    targetDate.setDate(targetDate.getDate() + daysToAdd + (weekInterval - 1) * 7);

    // Check if the target date is within the current month
    if (targetDate.getMonth() !== nextMonth - 1) {
        // Adjust to the previous week if it falls into the next month
        targetDate.setDate(targetDate.getDate() - 7);
    }

    // Check if the target date is less than or equal to the provided date
    if (targetDate <= new Date(currentYear, currentMonth - 1, currentDate)) {
        // Move to the next occurrence if it's before or on the provided date
        const daysDifference = currentDate - targetDate.getDate();
        const weeksToAdd = Math.ceil(daysDifference / 7);
        targetDate.setDate(targetDate.getDate() + weeksToAdd * 7);
    }

    // Format the date as yyyy-mm-dd
    const formattedDate = `${targetDate.getFullYear()}-${(targetDate.getMonth() + 1).toString().padStart(2, '0')}-${targetDate.getDate().toString().padStart(2, '0')}`;
    subscriptionDetails.subsDay = { label: targetDate.getDate().toString().padStart(2, '0'), value: targetDate.getDate().toString().padStart(2, '0') };
    return {
        ...subscriptionDetails,
        subsDate: formattedDate
    };
  };


  const generateWeekendDate = (subscriptionDetails, mag) => {
    const currentMonth = parseInt(subscriptionDetails.subsMonth.value);
    const currentYear = parseInt(subscriptionDetails.subsYear.value);

    // Calculate the first day of the month
    const firstDayOfMonth = new Date(currentYear, currentMonth, 1);

    // Calculate the next month and year
    const nextMonth = currentMonth === 12 ? 1 : currentMonth;
    const nextYear = currentMonth == nextMonth ? currentYear : nextMonth === 1 ? currentYear + 1 : currentYear;

    // First day of the next month
    const firstDayOfNextMonth = new Date(nextYear, nextMonth - 1, 1);

    const weekInterval = parseInt(mag?.mm_week_interval);
    const weekDay = parseInt(mag?.mm_week_day_interval);

    // Find the first occurrence of the target weekday in the next month
    let firstTargetDayOfNextMonth = firstDayOfNextMonth.getDay();
    while (firstTargetDayOfNextMonth !== weekDay) {
      firstDayOfNextMonth.setDate(firstDayOfNextMonth.getDate() + 1);
      firstTargetDayOfNextMonth = firstDayOfNextMonth.getDay();
    }

    // Calculate the target date based on week interval
    const targetDate = new Date(
      firstDayOfNextMonth.setDate(firstDayOfNextMonth.getDate() + (weekInterval - 1) * 7)
    );

    // Check if the target date is within the current month
    if (targetDate.getMonth() !== currentMonth - 1) {
      // Adjust to the previous week if it falls into the next month
      targetDate.setDate(targetDate.getDate() - 7);
    }

    // Format the date as yyyy-mm-dd
    const formattedDate = `${targetDate.getFullYear()}-${(targetDate.getMonth() + 1).toString().padStart(2, '0')}-${targetDate.getDate().toString().padStart(2, '0')}`;
    subscriptionDetails.subsDay = { label: targetDate.getDate().toString().padStart(2, '0'), value: targetDate.getDate().toString().padStart(2, '0') }
    return {
      ...subscriptionDetails,
      subsDate: formattedDate
    };
  };


  // Function to calculate subscription start date
  const getDateofSubsStart = (subscriptionDetails) => {

    let subs_start_date = '';

    if (subscriptionDetails.subsDay?.value) {
      subs_start_date = subscriptionDetails.subsDay.value;
    }
    if (subscriptionDetails.subsMonth?.value) {
      subs_start_date += `-${subscriptionDetails.subsMonth.value}`;
    }
    if (subscriptionDetails.subsYear?.value) {
      subs_start_date += `-${subscriptionDetails.subsYear.value}`;
    }

    const parts = subs_start_date.split('-');

    let Day = "";
    if (parts[0] <= 9) {
      Day = parts[0] < 10 ? `0${parts[0]}` : `${parts[0]}`;
    }else{
      Day = parts[0];
    }
    const formattedDate = `${parts[2]}-${parts[1]}-${Day}`;
    return {
      ...subscriptionDetails,
      subsDate: formattedDate
    };
  };

  // Function to Save subscription 
  const reorderArrayBasedOnValue = (arr, selectedValue) => {
    const selectedIndex = arr.findIndex(item => item.value === selectedValue);
    if (selectedIndex === -1) {
      return arr; // Selected value not found, return the original array
    }

    const firstPart = arr.slice(selectedIndex);
    const secondPart = arr.slice(0, selectedIndex);

    return firstPart.concat(secondPart);
  }
  const saveSubscription = async (orderDetails, subsDetails, mag) => {
    let reorderedArray = mag?.mm_date_interval;
    if (mag?.mm_interval_type == "Day") {
      reorderedArray = reorderArrayBasedOnValue(mag?.mm_date_interval, subsDetails?.subsDay?.value);
    }


    // console.log(reorderedArray, mag?.mm_interval_type, subsDetails?.subsDay?.value);
    let payload = {
      subs_id: mag?.subs_id,
      subs_fk_mm_id: mag?.mm_id,
      mm_name: mag?.mm_name,
      mm_interval_type: mag?.mm_interval_type,
      mm_month_interval: mag?.mm_month_interval,
      mm_interval_date: mag?.mm_interval_date,
      mm_interval_month: mag?.mm_interval_month,
      mm_week_day_interval_json: mag?.mm_week_day_interval_json,
      mm_week_interval_json: mag?.mm_week_interval_json,
      mm_date_interval: reorderedArray,
      // mm_date_interval: mag?.mm_date_interval,
      subscription_duration: mag?.skumagm_subscription,
      subs_fk_sku_id: mag?.sku_id,
      subs_fk_ord_skum_id: mag?.subs_fk_ord_skum_id,
      subs_fk_ord_id: orderDetails?.ord_id,
      subs_fk_customer_id: orderDetails?.fk_customer_id,
      fk_cust_add_id: orderDetails?.fk_cust_add_id,
      ord_quantity: orderDetails?.ord_quantity,
      subs_start_date: mag?.mm_interval_type == "Weekend" ? mag?.subsDateStartDate : subsDetails?.subsDate,
      subsDate: mag?.mm_interval_type == "Weekend" ? mag?.subsDateStartDate : subsDetails?.subsDate,
      subsMonth: mag?.mm_interval_type == "Weekend" ? mag?.subsMonth : subsDetails?.subsMonth,
      subsYear: mag?.mm_interval_type == "Weekend" ? mag?.subsYear : subsDetails?.subsYear,
      subsDay: mag?.mm_interval_type == "Weekend" ? { label: mag?.subsDateStartDate?.split('-')[2], value: mag?.subsDateStartDate?.split('-')[2] } : subsDetails?.subsDay,
    }
    // console.log("payload=>", payload); return false;
    // console.log("mag=>", mag); return false;
    const response = await MasterServices.saveSubscription(payload);

    if (response.data.status) {
      toast.success(response.data.message);
      valueFlagChanged = 1;

      getSingleOrder(ord_id);
      // navigate("/add-order/" + ord_id);
    } else {
      toast.error(response.data.message);
    }
  }
  const getSubscription = async (orderDetails, subsDetails, mag) => {
    setLoadingSubs(true);
    setSubscriptionList([]);
    let payload = {
      subinm_fk_subs_id: mag?.subs_id,
    }
    const response = await MasterServices.getSubscriptionBasedDispatch(payload);

    if (response.data.status) {
      // console.log("ViewSubscription=>", response?.data?.data)
      setSubscriptionList(response?.data?.data);
      setLoadingSubs(false);
      toast.success(response.data.message);
    } else {
      toast.error(response.data.message);
    }
  }

  // const handleInputChange = (event) => {
  //   const { name, value } = event.target;
  //   setSubscriptionDetails({ ...subsDetails, [name]: value });
  // };
  // const handleSelectChange = (selectedOption, name) => {
  //   console.log("selectedOption=>", selectedOption)
  //   const { label, value } = selectedOption || {};
  //   setSubscriptionDetails({ ...subsDetails, [name]: selectedOption });
  //   if (name == 'subsYear') {
  //     getDateofSubsStart(selectedOption);
  //   }
  // };

  // const getDateofSubsStart = (Year) => {
  //   let subs_start_date = '';
  //   if (subsDetails?.subsDay?.value) {
  //     subs_start_date = subsDetails?.subsDay?.value;
  //   }
  //   if (subsDetails?.subsMonth?.value) {
  //     subs_start_date = subs_start_date + '-' + subsDetails?.subsMonth?.value;
  //   }

  //   // alert(Year?.value)
  //   if (Year?.value) {
  //     subs_start_date = subs_start_date + '-' + Year?.value;
  //     const subsDetailsCopy = { ...subsDetails }; // Creating a shallow copy of subsDetails

  //     // Update subsDate in the copied object
  //     // subsDetailsCopy.subsDate = subs_start_date;
  //     // setSubscriptionDetails(subsDetailsCopy);
  //     // console.log("subsDetailsCopy=>", subsDetailsCopy)
  //     const parts = subs_start_date.split('-');
  //     const formattedDate = parts[2] + '-' + parts[1] + '-' + parts[0];
  //     subsDetailsCopy.subsDate = formattedDate;
  //     subsDetailsCopy.subsYear = Year;
  //     setSubscriptionDetails(subsDetailsCopy);
  //     console.log("subsDetailsCopy=>", subsDetailsCopy)
  //   }
  //   // alert(subs_start_date)

  // }
  // console.log("subsDetails=>", subsDetails);
  const gender = [
    {
      value: "Male",
      label: "Male",
    },
    {
      value: "Female",
      label: "Female",
    },
  ];
  const paymentMethod = [
    {
      value: "DD",
      label: "Demand Draft (DD)",
    },
    {
      value: "CHEQUE",
      label: "CHEQUE",
    },
  ];

  useEffect(() => {
    getAllSkuData();
    getAllCountryList();
    getAllCustomers()
    if (ord_id) {
      getSingleOrder(ord_id);
    }
  }, []);
  useEffect(() => {
    if (selectedSku) {
      calculateTotals();
    }
  }, [selectedSku]);
  // Priyanshu COntengt
  const initialValues = {
    ord_id: null,
    selectedSkus: [],
    ord_quantity: null,
    customer_id: null,
    customer_first_name: null,
    customer_last_name: null,
    customer_username: "",
    customer_gender: { label: "", value: "" },
    customer_dob: "",
    customer_password: null,
    customer_is_outlook_user: false,
    customer_email: "",
    customer_mobile: "",
    customer_occupation: "",
    customer_designation: "",
    customer_organisation: "",
    fk_state_id: null,
    fk_city_id: null,
    fk_country_id: { label: "", value: "" },
    customer_address: "",
    customer_legal_proof: null,
    ca_landmark: null,
    state_name: "",
    city_name: "",
    country_name: "",
    pincode: "",
    ord_user_referral_code: null,
    price: 0,
    currency: "",
    razorpay_payment_id: "",
    discounted_from: "",
    ord_is_active: true,
    payment_type: { label: "", value: "" },
    trans_card_id: "",
    trans_amount: "",
    trans_bank_date: "",
    trans_bank: "",
    trans_branch_name: "",
  };

  const [formOrder, setFormOrder] = useState(initialValues);


  const calculateVoucherValue = (orderDetails, price, voucher_value) => {
    // console.log("orderDetails=>", orderDetails)
    // console.log("price=>", price)
    // console.log("voucher_value=>", orderDetails?.vouchers_relation?.voucher_value)
    voucher_value = orderDetails?.vouchers_relation?.voucher_value ? orderDetails?.vouchers_relation?.voucher_value : null;
    price = price && !isNaN(price) ? parseInt(price) : 0;
    voucher_value = voucher_value && !isNaN(voucher_value) ? parseInt(voucher_value) : 0;
    if (orderDetails && price && voucher_value) {
      if (orderDetails?.vouchers_relation && orderDetails?.vouchers_relation?.voucher_type === 'FIXED') {
        return parseInt(voucher_value);
      } else {
        return (parseInt(price) * parseInt(voucher_value)) / 100;
      }
    }
    return 0;
  }

  const calculateSKUVoucherValue = (orderDetails, price, voucher_value) => {
    voucher_value = orderDetails?.vouchers_relation?.voucher_value ? orderDetails?.vouchers_relation?.voucher_value : null;
    price = price && !isNaN(price) ? parseInt(price) : 0;
    voucher_value = voucher_value && !isNaN(voucher_value) ? parseInt(voucher_value) : 0;
    // console.log("orderDetails TOT=>", orderDetails)
    // console.log("price TOT=>", price)
    // console.log("voucher_value TOT=>", voucher_value)
    if (orderDetails && price && voucher_value) {
      if (orderDetails?.vouchers_relation && orderDetails?.vouchers_relation?.voucher_type === 'FIXED') {
        return parseInt(price) - parseInt(voucher_value);
      } else if (orderDetails?.vouchers_relation && orderDetails?.vouchers_relation?.voucher_type === 'PERCENTAGE') {
        return (parseInt(price) - ((parseInt(price) * parseInt(voucher_value)) / 100));
      } else {
        return (parseInt(price));
      }
    } else {
      return (parseInt(price));
    }
    return 0;
  }

  const getSingleOrder = async (ord_id) => {
    if (valueFlagChanged == 0) {
      setLoading(true)
    }
    MasterServices.getSingleOrder(ord_id)
      .then(async (res) => {
        const { data, status } = res.data;
        if (status) {
          // console.log(data);
          if (data?.ord_quantity) {            
            for (let i = 0; i < data?.ord_quantity; i++) {
              addCustObject();
            }
          }            
          data?.magazineDetails?.map((item) => {
            if (item?.mm_interval_type == "Weekend") {
              // console.log("Order item=>", item);
              if (item?.mm_iss_start) {
                let value = item?.mm_iss_start.split("-");
                // console.log("value=>", value);

                let subscriptionDetails = {
                  subsMonth: {
                    label: value[1],
                    value: value[1]
                  },
                  subsYear: {
                    label: value[0],
                    value: value[0]
                  },
                  subsDay: {
                    label: value[2],
                    value: value[2]
                  }

                }
                let subsDateStartDate = generateWeekendDateNew(subscriptionDetails, item);
                // console.log("subsDateStartDate=>", subsDateStartDate)
                item.subsDateStartDate = item?.mm_iss_start ? moment(subsDateStartDate?.subsDate).format("YYYY-MM-DD") : '';
                item.subsMonth = subscriptionDetails?.subsMonth;
                item.subsYear = subscriptionDetails?.subsYear;
              }
            }
            // mag?.subsDateStartDate = item?.ma
          })
          setOrderDetails(data);
          setOnlineStatus(data.online_status)
          setSelectedSku(data.selectedSku);
          setSelectCountry({
            label: data?.ord_customer_adress_relation?.country_name,
            value: data?.ord_customer_adress_relation?.fk_country_id,
          });
          setSelectedGender([{
            label: data?.ord_customer_relation?.customer_gender,
            value: data?.ord_customer_relation?.customer_gender,
          }]);
          setPayMethod([{
            label:
              data?.m_transaction?.length > 0
                ? data?.m_transaction[0]?.payment_type == 'DD' ? 'Demand Draft (DD)'
                  : data?.m_transaction[0]?.payment_type : "",
            value:
              data?.m_transaction?.length > 0
                ? data?.m_transaction[0]?.payment_type
                : "",
          }]);
          setOrdQty(data.ord_quantity)
          const editedValues = {
            ord_id: ord_id,
            selectedSkus: data.selectedSku,
            ord_quantity: data.ord_quantity ? data.ord_quantity : ordQty,
            customer_id: data?.ord_customer_relation?.customer_id,
            customer_first_name:
              data?.ord_customer_relation?.customer_first_name,
            customer_last_name: data?.ord_customer_relation?.customer_last_name,
            customer_username: data?.ord_customer_name,
            customer_gender: {
              label: data?.ord_customer_relation?.customer_gender,
              value: data?.ord_customer_relation?.customer_gender,
            },
            customer_dob: moment(data?.ord_customer_relation?.customer_dob).format("YYYY-MM-DD"),
            customer_password: data?.ord_customer_relation?.customer_password,
            customer_is_outlook_user:
              data?.ord_customer_relation?.customer_is_outlook_user,
            customer_email: data?.ord_customer_relation?.customer_email,
            customer_mobile: data?.ord_customer_relation?.customer_mobile,
            customer_occupation:
              data?.ord_customer_relation?.customer_occupation,
            customer_designation:
              data?.ord_customer_relation?.customer_designation,
            customer_organisation:
              data?.ord_customer_relation?.customer_organisation,
            fk_state_id: data?.ord_customer_adress_relation?.fk_state_id,
            fk_city_id: data?.ord_customer_adress_relation?.fk_city_id,
            fk_country_id: {
              label: data?.ord_customer_adress_relation?.country_name,
              value: data?.ord_customer_adress_relation?.fk_country_id,
            },
            customer_address: data?.ord_customer_relation?.customer_address,
            customer_legal_proof:
              data?.ord_customer_relation?.customer_legal_proof,
            ca_landmark: data?.ord_customer_adress_relation?.ca_landmark,
            state_name: data?.ord_customer_adress_relation?.state_name,
            city_name: data?.ord_customer_adress_relation?.city_name,
            country_name: data?.ord_customer_adress_relation?.country_name,
            pincode: data?.ord_customer_adress_relation?.pincode,
            ord_user_referral_code: data?.ord_user_referral_code,
            price: totals.price ? totals.price : 0,
            discounted_from: totals?.totalCpDisplayPrice
              ? data?.totals?.totalCpDisplayPrice
              : 0,
            ord_total_diff_amt: data?.ord_total_diff_amt
              ? data?.ord_total_diff_amt
              : 0,
            ord_total_amount: data?.ord_total_amount
              ? data?.ord_total_amount
              : 0,
            ord_total_price_amt: data?.ord_total_price_amt
              ? data?.ord_total_price_amt
              : 0,
            ord_total_discount_amt: data?.ord_total_discount_amt
              ? data?.ord_total_discount_amt
              : 0,

            currency:
              data?.m_transaction?.length > 0
                ? data?.m_transaction[0]?.trans_currency
                : "",
            razorpay_payment_id: data?.razorpay_payment_id,
            ord_is_active: data?.ord_customer_relation?.ord_is_active,
            payment_type: {
              label:
                data?.m_transaction?.length > 0
                  ? data?.m_transaction[0]?.payment_type
                  : "",
              value:
                data?.m_transaction?.length > 0
                  ? data?.m_transaction[0]?.payment_type
                  : "",
            },
            trans_card_id:
              data?.m_transaction?.length > 0
                ? data?.m_transaction[0]?.trans_card_id
                : "",
            trans_amount:
              data?.m_transaction?.length > 0
                ? data?.m_transaction[0]?.trans_amount
                : "",
            trans_bank_date:
              data?.m_transaction?.length > 0
                ? data?.m_transaction[0]?.trans_bank_date
                : "",
            trans_bank:
              data?.m_transaction?.length > 0
                ? data?.m_transaction[0]?.trans_bank
                : "",
            trans_branch_name:
              data?.m_transaction?.length > 0
                ? data?.m_transaction[0]?.trans_branch_name
                : "",
          };
          setFormOrder(editedValues);
          setLoading(false)
          valueFlagChanged = 0;
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false)
      });
  };
  const checkPaymentMethod = () => {
    if (selectedGender.length <= 0) {
      toast.error("Please Select Gender");
      return false;
    }
    if (selectedSku && selectedSku.length <= 0) {
      toast.error("Please Select SKUS");
      return false;
    }

    if (selectCountry.length <= 0) {
      toast.error("Please Select Country");
      return false;
    }
    if (payMethod.length <= 0) {
      toast.error("Please Select Payment Method");
      return false;
    }
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formOrder,
      validationSchema: orderSchema,
      onSubmit: async (values, action) => {
        setSubmitButtonLoader(true)
        let body = {
          ord_id: ord_id ? ord_id : null,
          selectedSkus: selectedSku,
          ord_quantity: ordQty !== undefined && ordQty !== null && ordQty !== "" ? ordQty : values.ord_quantity,
          customer_id: values.customer_id,
          customer_first_name: values.customer_first_name,
          customer_last_name: values.customer_last_name,
          customer_username: values.customer_username,
          customer_gender: selectedGender && selectedGender.value ? selectedGender.value : values.customer_gender.value,
          customer_dob: new Date(values.customer_dob),
          customer_password: values.customer_password,
          customer_is_outlook_user: false,
          customer_email: values.customer_email,
          customer_mobile: values.customer_mobile,
          customer_occupation: values.customer_occupation,
          customer_designation: values.customer_designation,
          customer_organisation: values.customer_organisation,
          fk_state_id: values.fk_state_id,
          fk_city_id: values.fk_city_id,
          fk_country_id: selectCountry?.value ? selectCountry?.value : null,
          customer_address: values.customer_address,
          customer_legal_proof: values.customer_legal_proof,
          ca_landmark: values.ca_landmark,
          state_name: values.state_name,
          city_name: values.city_name,
          country_name: selectCountry?.label ? selectCountry?.label : null,
          pincode: values.pincode,
          ord_user_referral_code: values.ord_user_referral_code,
          price: totals.price ? totals.price : 0,
          discounted_from: totals?.totalCpDisplayPrice
            ? totals?.totalCpDisplayPrice
            : 0,
          ord_total_diff_amt: totals.totalDifference
            ? totals.totalDifference
            : 0,
          ord_total_amount: totals.ord_total_amount
            ? totals.ord_total_amount
            : 0,
          currency: "INR",
          razorpay_payment_id: values.razorpay_payment_id,
          ord_is_active: true,
          payment_type:
            payMethod.length > 0
              ? payMethod[0]?.value
                ? payMethod[0]?.value
                : null
              : "",
          trans_card_id: values.trans_card_id,
          trans_amount: values.trans_amount,
          trans_bank_date: values.trans_bank_date,
          trans_bank: values.trans_bank,
          trans_branch_name: values.trans_branch_name,
          additional_customer_data : custObjectsArray
        };

        if (selectedGender && selectedGender.length <= 0) {
          toast.error("Please Select Gender");
          return false;
        }
        if (selectedSku && selectedSku.length <= 0) {
          toast.error("Please Select SKUS");
          return false;
        }

        if (selectCountry && selectCountry.length <= 0) {
          toast.error("Please Select Country");
          return false;
        }
        if (payMethod.length <= 0) {
          toast.error("Please Select Payment Method");
          return false;
        }
        // if (selectedSku.length <= 0) {
        //   toast.error("Please Select SKUS");
        //   return false;
        // }

        // if (selectCountry.length <= 0) {
        //   toast.error("Please Select Country");
        //   return false;
        // }
        // if (selectedOption.length <= 0) {
        //   toast.error("Please Select Gender");
        //   return false;
        // }
        // if (payMethod.length <= 0) {
        //   toast.error("Please Select Payment Method");
        //   return false;
        // }

        // if (totals.price != values.trans_amount) {
        //   toast.error("Please Enter Valid Amount!");
        //   return false;
        // }

        // console.log(body);

        const response = await MasterServices.saveOrder(body);

        if (response.data.status) {
        setSubmitButtonLoader(false)
          action.resetForm();
          setFormOrder(initialValues);
          toast.success(response.data.message);
          navigate("/order");
        } else {
        setSubmitButtonLoader(false)
          toast.error(response.data.message);
        }
      },
    });
  const [totals, setTotals] = useState({
    totalCpPrice: 0,
    totalCpDisplayPrice: 0,
    totalDifference: 0,
    ord_total_amount: 0,
  });
  // Function to calculate totals
  const calculateTotals = () => {
    let totalCpPrice = 0;
    let totalCpDisplayPrice = 0;
    let totalDifference = 0;
    let ord_total_amount = 0;

    selectedSku && selectedSku.forEach((item) => {
      if (ord_id > 0) {

        const price = parseFloat(item?.ord_skum_cp_price ? item?.ord_skum_cp_price : 0);
        const displayPrice = parseFloat(item?.ord_skum_cp_display_price ? item?.ord_skum_cp_display_price : 0);
        totalCpPrice += price;
        totalCpDisplayPrice += displayPrice;
        totalDifference += price - displayPrice;
      } else {
        if (item.country_pricing && item.country_pricing.length > 0) {
          item.country_pricing.forEach((cp) => {
            const price = parseFloat(cp.cp_price ? cp.cp_price : 0);
            const displayPrice = parseFloat(
              cp?.cp_display_price ? cp?.cp_display_price : 0
            );

            if (cp?.cp_display_price > 0) {
              ord_total_amount += displayPrice;
            } else {
              ord_total_amount += price;
            }

            totalCpPrice += price;
            totalCpDisplayPrice += displayPrice;
            totalDifference += price - displayPrice;
          });
        }
      }
    });

    setTotals({
      totalCpPrice,
      totalCpDisplayPrice,
      totalDifference,
      ord_total_amount,
    });
  };
  // Output the totals
  // Priyanshu COntengt



  const getAllSkuData = async () => {
    setLoading(true);
    const response = await MasterServices.getSkuData(1);
    if (response.data.status == true) {
      if (response.data.data.data.length > 0) {
        const newArray = response?.data?.data?.data?.map((ele) => ({
          ...ele,
          value: ele.sku_id,
          label: `${ele.sku_code}`,
        }));
        setSkuData(newArray);
      }
      setLoading(false);
    }
  };

  const getAllCountryList = () => {
    MasterServices.getAllCountry(1)
      .then((res) => {
        const { data, status } = res;
        if (status) {
          setAllCountry(data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSku = (e) => {
    setSelectedSku(e);
    calculateTotals();
  };


  const handleCountry = (e) => {
    getCountryBasedSkuData(e);
    setSelectCountry(e);
  };
  // const handleGender = (e) => {
  //   setSelectedOption(e);
  // };

  const handlePayMethod = (e) => {
    setPayMethod([e]);
  };

  const getCountryBasedSkuData = async (e) => {
    if (e.value > 0) {
      let payload = {
        country_id: e.value,
        country_name: e.label,
      }
      setSkuData([]);
      const response = await MasterServices.getCountryBasedSku(payload);
      // console.log("getCountryBasedSkuData response=>", response)
      if (response.data.status == true) {
        // console.log("response.data.data.rows=>", response.data.data.rows)
        if (response.data.data.rows.length > 0) {
          const newArray = response.data.data.rows?.map((ele) => ({
            ...ele?.price,
            value: ele?.price?.sku_id,
            label: `${ele?.price?.sku_code}`,
          }));
          // console.log("newArray=>", newArray)
          setSkuData(newArray);
        }
      }
    }
  };

  const [selectedGender, setSelectedGender] = useState([])
  const [customerList, setCustomerList] = useState([])
  const getAllCustomers = () => {
    MasterServices.getAllCustomer(3).then((response) => {
      // console.log(response);
      if (response.data.data.length > 0) {
        const newArray = response?.data?.data?.map((ele) => ({
          value: ele,
          label: `${ele.customer_first_name ? `${ele.customer_first_name ? ele.customer_first_name : ""} ${ele.customer_last_name ? ele.customer_last_name : ""}` : `${ele.customer_username}`}`,
        }));
        // console.log(newArray);
        setCustomerList(newArray);
      }
    }).catch((error) => {
      console.log(error)

    });
  }

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  const [custToggle, setCustToggle] = useState(false)
  const setEnabled = () => {
    setCustToggle(!custToggle)
  }
  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const handleCustSelect = async (val) => {
    // console.log(val);   
    setSelectedCustomer(val)
    let payload = {
      status_code: 3,
      customer_id: val.value.customer_id,
    }
    // console.log(payload);
    await MasterServices.getAllCustomerAddress(payload).then((res) => {
      const resData = res.data;
      const status = res.status;
      // console.log("getSingleCustomerAddress", resData)
      if (status) {
        const data = resData.data[0];
        // console.log(data.customer_relation.customer_gender);
        setSelectedGender({
          label: data.customer_relation.customer_gender && capitalizeFirstLetter(data.customer_relation.customer_gender.trim()),
          value: data.customer_relation.customer_gender && capitalizeFirstLetter(data.customer_relation.customer_gender.trim()),
        });
        setSelectCountry({
          label: data.country_name,
          value: data.fk_country_id,
        });

        const formValues = {
          ord_id: null,
          selectedSkus: [],
          ord_quantity: ordQty !== undefined && ordQty !== null && ordQty !== "" ? ordQty : null,
          customer_id: data.customer_relation.customer_id,
          customer_first_name: data.customer_relation.customer_first_name,
          customer_last_name: data.customer_relation.customer_last_name,
          customer_username: data.customer_relation.customer_username,
          customer_gender: {
            label: data.customer_relation.customer_gender && capitalizeFirstLetter(data.customer_relation.customer_gender.trim()),
            value: data.customer_relation.customer_gender && capitalizeFirstLetter(data.customer_relation.customer_gender.trim()),
          },
          customer_dob: moment(data.customer_relation.customer_dob).format("YYYY-MM-DD"),
          customer_password: data.customer_relation.customer_password,
          customer_is_outlook_user: data.customer_relation.customer_is_outlook_user,
          customer_email: data.customer_relation.customer_email,
          customer_mobile: data.customer_relation.customer_mobile,
          customer_occupation: data.customer_relation.customer_occupation,
          customer_designation: data.customer_relation.customer_designation,
          customer_organisation: data.customer_relation.customer_organisation,
          fk_state_id: data.fk_state_id,
          fk_city_id: data.fk_city_id,
          fk_country_id: { label: data.country_name, value: data.fk_country_id },
          customer_address: data.ca_address,
          customer_legal_proof: data.customer_relation.customer_legal_proof,
          ca_landmark: null,
          state_name: data.state_name,
          city_name: data.city_name,
          country_name: data.country_name,
          pincode: data.pincode,
          ord_user_referral_code: null,
          price: 0,
          currency: "",
          razorpay_payment_id: "",
          discounted_from: "",
          ord_is_active: true,
          payment_type: { label: "", value: "" },
          trans_card_id: "",
          trans_amount: "",
          trans_bank_date: "",
          trans_bank: "",
          trans_branch_name: "",
        };
        // console.log(formValues);
        setFormOrder(formValues)
      }
    }).catch((error) => {
      console.log(error)
    });
  }

  useEffect(() => {
    if (custToggle) {
      getAllCustomers()
    } else {
      setSelectedCustomer(null)
      setSelectedGender(null)
      setSelectCountry(null)
      setSelectedSku(null)
      setFormOrder(initialValues)
    }
  }, [custToggle])
  useEffect(() => {
    if (selectedGender && (!selectedGender.value || !selectedGender.label)) {
      setSelectedGender(null)
    }
    if (selectCountry && (!selectCountry.value || !selectCountry.label)) {
      setSelectCountry(null)
    }
  }, [selectedGender, selectCountry])
  
  // new changes
  const [ordQty, setOrdQty] = useState(0)
  const [custObjectsArray, setCustObjectsArray] = useState([]);

  const handleQuantityChange = (event) => {
    let value = event.target.value;
    if (value === '') {
      setOrdQty('');
      setCustObjectsArray([]);
      return;
    }

    let newQuantity = parseInt(value);
    if (isNaN(newQuantity) || newQuantity < 0) {
      newQuantity = 0;
    }

    const diff = newQuantity - (ordQty || 0);

    if (diff > 0) {
      for (let i = 0; i < diff; i++) {
        addCustObject();
      }
    } else if (diff < 0) {
      for (let i = 0; i < -diff; i++) {
        removeCustObject();
      }
    }

    setOrdQty(newQuantity);
  };

  const addCustObject = () => {
    const newCustObject = {
      customer_id: null,
      name: null,
      email: null,
      city: null,
      address: null,
      state: null,
      pincode: null,
      quantity: 1
    };
    setCustObjectsArray(prevArray => [...prevArray, newCustObject]);
  };

  const removeCustObject = () => {
    setCustObjectsArray(prevArray => prevArray.slice(0, -1));
  };

  const handleRemoveObject = (index) => {
    const updatedArray = [...custObjectsArray];
    updatedArray.splice(index, 1);
    setCustObjectsArray(updatedArray);
    setOrdQty(ordQty - 1); 
  };

  const custRowHandleChange = (value, index, key) => {
    const updatedArray = [...custObjectsArray];
    updatedArray[index][key] = value;
    setCustObjectsArray(updatedArray);
  };  

  const [toggleStates, setToggleStates] = useState(Array.from({ length: custObjectsArray }, () => false));
  const toggleSwitch = (index) => {
    const newToggleStates = [...toggleStates];
    newToggleStates[index] = !newToggleStates[index];
    setToggleStates(newToggleStates);
  };

  const filterChooseData = () => {
    try {  
      const filteredRowData = customerList.filter(item1 => !custObjectsArray.some(item2 => item2.customer_id === item1.value.customer_id));    
      return Array.isArray(filteredRowData) ? filteredRowData : []; 
    } catch (error) {
      
      return customerList
    }
  };

  const handleCustSelectRow = async (val, index) => {
    let payload = {
      status_code: 3,
      customer_id: val.value.customer_id,
    }
    // console.log(payload);
    await MasterServices.getAllCustomerAddress(payload).then((res) => {
      const resData = res.data;
      const status = res.status;
      if (status) {
        const data = resData.data[0];
        console.log(data);
        custRowHandleChange(data.customer_relation.customer_first_name ? `${data.customer_relation.customer_first_name ? data.customer_relation.customer_first_name : ""} ${data.customer_relation.customer_last_name ? data.customer_relation.customer_last_name : ""}` : data.customer_relation.customer_username ? data.customer_relation.customer_username : null, index, 'name')
        custRowHandleChange(data.ca_email ? data.ca_email : data.customer_relation.customer_email, index, 'email')
        custRowHandleChange(data.city_name, index, 'city')
        custRowHandleChange(data.ca_address ? data.ca_address : data.customer_relation.customer_address, index, 'address')
        custRowHandleChange(data.state_name, index, 'state')
        custRowHandleChange(data.pincode, index, 'pincode')
        custRowHandleChange(data.customer_relation.customer_id, index, 'customer_id')
      }
    }).catch((error) => {
      console.log(error)
    });
  }

  const clearRowValue = (index)=>{
    custRowHandleChange(null, index, 'name')
    custRowHandleChange(null, index, 'email')
    custRowHandleChange(null, index, 'city')
    custRowHandleChange(null, index, 'address')
    custRowHandleChange(null, index, 'state')
    custRowHandleChange(null, index, 'pincode')
    custRowHandleChange(null, index, 'customer_id')
  }

  useEffect(()=>{
    if (!custToggle) {
      setOrdQty(0)
      setCustObjectsArray([])
    }
  },[custToggle])

  useEffect(()=>{
     if (custObjectsArray && custObjectsArray.length !== 0 && values) {
      const updatedArray = [...custObjectsArray];
      updatedArray[0].customer_id = selectedCustomer?.value?.customer_id ? selectedCustomer.value.customer_id : null;
      updatedArray[0].name = values.customer_first_name ? `${values.customer_first_name ? values.customer_first_name : ""} ${values.customer_last_name ? values.customer_last_name : ""}` : values.customer_username ? values.customer_username : null;
      updatedArray[0].email = values.customer_email ? values.customer_email : null;
      updatedArray[0].city = values.city_name ? values.city_name : null;
      updatedArray[0].address = values.customer_address ? values.customer_address : null;
      updatedArray[0].state = values.state_name ? values.state_name : null;
      updatedArray[0].pincode = values.pincode ? values.pincode : null;
      setCustObjectsArray(updatedArray);
     }
  },[selectedCustomer, values, ordQty])

  console.log(custObjectsArray);

  return (
    <>
      {!loading ? (
        <div>
          <Breadcrumb pages={pages} />
          <div className="flex flex-col sm:flex-row md:flex-row lg:flex-row xl:flex-row gap-2 sm:gap-10 xl:gap-10 md:gap-10 lg:gap-10 sm:items-center md:items-center lg:items-center xl:items-center  content-center">
            <h1 className="text-xl font-semibold text-gray-900">
              {ord_id ? "View" : "Add"} Order {ord_id ? onlineStatus : ''}
            </h1>
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              checkPaymentMethod(e);
              handleSubmit(e);
            }}
          >
            <div className="flex flex-col">
              <div>
                {!ord_id && <div className='flex my-4'>
                  <label htmlFor="role" className={`text-gray-700 block text-sm font-medium  mr-5`}>
                    Existing Customer
                  </label>
                  <Switch
                    checked={custToggle}
                    onChange={setEnabled}
                    className={`${custToggle ? 'bg-blue-600' : 'bg-gray-200'} relative inline-flex h-6 w-11 items-center rounded-full`}
                  >
                    <span className="sr-only">Existing Customer</span>
                    <span
                      className={`${custToggle ? 'translate-x-6' : 'translate-x-1'
                        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                    />
                  </Switch>
                </div>}
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 mt-5  gap-5 lg:w-full w-full">
                  <div>
                    {!custToggle && <>
                      <label className="sm:text-sm text-black whitespace-nowrap">
                        Enter Name
                      </label>
                      <div>
                        <input
                          disabled={ord_id ? true : false}
                          value={values.customer_username}
                          onChange={(e)=>{handleChange(e)}}
                          onBlur={handleBlur}
                          type="text"
                          placeholder="Enter Name"
                          name="customer_username"
                          autoComplete="off"
                          className={`w-full rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] sm:max-w-xs sm:text-sm ${ord_id ? 'bg-gray-200' : ''}`}
                        />
                      </div>
                      {errors.customer_username && touched.customer_username ? (
                        <p className="text-red-600 text-sm">
                          {errors.customer_username}
                        </p>
                      ) : null}
                    </>}
                    {custToggle && <>
                      <label className="sm:text-sm text-black whitespace-nowrap">
                        Select Customer
                      </label>
                      <Select
                        isDisabled={ord_id ? true : false}
                        className=" w-full max-w-xl rounded-md border-[1px] shadow-sm focus:border-[1px] sm:max-w-xs  sm:text-sm "
                        classNamePrefix="select "
                        placeholder="Select Customer"
                        name="customer"
                        defaultValue={selectedCustomer}
                        onChange={(e) => {handleCustSelect(e);}}
                        options={customerList}
                        value={selectedCustomer}
                        onBlur={handleBlur}
                      // isClearable={true}
                      />
                      {errors.customer_gender && touched.customer_gender ? (
                        <div className="text-sm text-red-600">
                          {errors.customer_gender}
                        </div>
                      ) : null}
                    </>}
                  </div>
                  <div>
                    <label className="sm:text-sm text-black whitespace-nowrap">
                      Select Gender
                    </label>
                    <div>
                      <Select
                        isDisabled={ord_id ? true : false}
                        className=" w-full max-w-lg rounded-md border-[1px]    shadow-sm focus:border-[1px] sm:max-w-xs  sm:text-sm "
                        classNamePrefix="select "
                        placeholder="Select Gender"
                        name="customer_gender"
                        value={selectedGender && selectedGender.value ? selectedGender : values.customer_gender}
                        onChange={(e)=>{setSelectedGender(e)}}
                        options={gender}
                        // value={selectedOption}
                        onBlur={handleBlur}
                        isClearable={true}
                      />
                      {errors.customer_gender && touched.customer_gender ? (
                        <div className="text-sm text-red-600">
                          {errors.customer_gender}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div>
                    <label className="sm:text-sm text-black whitespace-nowrap">
                      Date of birth
                    </label>
                    <div>

                      <input
                        disabled={ord_id ? true : false}
                        value={values.customer_dob}
                        onChange={(e)=>{handleChange(e)}}
                        onBlur={handleBlur}
                        name="customer_dob"
                        type="date"
                        placeholder="Date of birth"
                        className={`w-full rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] sm:max-w-xs sm:text-sm ${ord_id ? 'bg-gray-200' : ''}`}
                      />
                      {errors.customer_dob && touched.customer_dob ? (
                        <div className="text-sm text-red-600">
                          {errors.customer_dob}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div>
                    <label className="sm:text-sm text-black whitespace-nowrap">
                      Enter City
                    </label>
                    <div>
                      <input
                        disabled={ord_id ? true : false}
                        value={values.city_name}
                        onChange={(e)=>{handleChange(e);}}
                        onBlur={handleBlur}
                        name="city_name"
                        type="text"
                        placeholder="Enter City"
                        className={`w-full rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] sm:max-w-xs sm:text-sm ${ord_id ? 'bg-gray-200' : ''}`}
                      />
                      {errors.city_name && touched.city_name ? (
                        <div className="text-sm text-red-600">
                          {errors.city_name}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div>
                    <label className="sm:text-sm text-black whitespace-nowrap">
                      Enter Mailing Address
                    </label>
                    <div>
                      <input
                        disabled={ord_id ? true : false}
                        value={values.customer_address}
                        onChange={(e)=>{handleChange(e)}}
                        onBlur={handleBlur}
                        name="customer_address"
                        type="text"
                        placeholder="Enter Mailing Address"
                        className={`w-full rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] sm:max-w-xs sm:text-sm ${ord_id ? 'bg-gray-200' : ''}`}
                      />
                      {errors.customer_address && touched.customer_address ? (
                        <div className="text-sm text-red-600">
                          {errors.customer_address}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div>
                    <label className="sm:text-sm text-black whitespace-nowrap">
                      Enter State
                    </label>
                    <div>
                      <input
                        disabled={ord_id ? true : false}
                        value={values.state_name}
                        onChange={(e)=>{handleChange(e)}}
                        onBlur={handleBlur}
                        name="state_name"
                        type="text"
                        placeholder="Enter State"
                        className={`w-full rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] sm:max-w-xs sm:text-sm ${ord_id ? 'bg-gray-200' : ''}`}
                      />
                      {errors.state_name && touched.state_name ? (
                        <div className="text-sm text-red-600">
                          {errors.state_name}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div>
                    <label className="sm:text-sm text-black whitespace-nowrap">
                      Enter Email
                    </label>
                    <div>
                      <input
                        disabled={ord_id ? true : false}
                        value={values.customer_email}
                        onChange={(e)=>{handleChange(e)}}
                        onBlur={handleBlur}
                        name="customer_email"
                        type="email"
                        placeholder="Enter Email"
                        className={`w-full rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] sm:max-w-xs sm:text-sm ${ord_id ? 'bg-gray-200' : ''}`}
                      />
                      {errors.customer_email && touched.customer_email ? (
                        <div className="text-sm text-red-600">
                          {errors.customer_email}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div>
                    <label className="sm:text-sm text-black whitespace-nowrap">
                      Enter Pincode
                    </label>
                    <div>
                      <input
                        disabled={ord_id ? true : false}
                        value={values.pincode}
                        onChange={(e)=>{handleChange(e)}}
                        onBlur={handleBlur}
                        name="pincode"
                        type="number"
                        placeholder="Enter Pincode"
                        className={`w-full rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] sm:max-w-xs sm:text-sm ${ord_id ? 'bg-gray-200' : ''}`}
                      />
                      {errors.pincode && touched.pincode ? (
                        <div className="text-sm text-red-600">{errors.pincode}</div>
                      ) : null}
                    </div>
                  </div>
                  <div>
                    <label className="sm:text-sm text-black whitespace-nowrap">
                      Enter Phone Number
                    </label>
                    <div>
                      <input
                        disabled={ord_id ? true : false}
                        value={values.customer_mobile}
                        onChange={(e)=>{handleChange(e)}}
                        onBlur={handleBlur}
                        name="customer_mobile"
                        type="number"
                        placeholder="Enter Phone Number"
                        className={`w-full rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] sm:max-w-xs sm:text-sm ${ord_id ? 'bg-gray-200' : ''}`}
                      />
                      {errors.customer_mobile && touched.customer_mobile ? (
                        <div className="text-sm text-red-600">
                          {errors.customer_mobile}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div>
                    <label className="sm:text-sm text-black whitespace-nowrap">
                      Enter Occupation
                    </label>
                    <div>
                      <input
                        disabled={ord_id ? true : false}
                        value={values.customer_occupation}
                        onChange={(e)=>{handleChange(e)}}
                        onBlur={handleBlur}
                        name="customer_occupation"
                        type="text"
                        placeholder="Enter Occupation"
                        className={`w-full rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] sm:max-w-xs sm:text-sm ${ord_id ? 'bg-gray-200' : ''}`}
                      />
                      {errors.customer_occupation && touched.customer_occupation ? (
                        <div className="text-sm text-red-600">
                          {errors.customer_occupation}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div>
                    <label className="sm:text-sm text-black whitespace-nowrap">
                      Enter Designation
                    </label>
                    <div>
                      <input
                        disabled={ord_id ? true : false}
                        value={values.customer_designation}
                        onChange={(e)=>{handleChange(e)}}
                        onBlur={handleBlur}
                        name="customer_designation"
                        type="text"
                        placeholder="Enter Designation"
                        className={`w-full rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] sm:max-w-xs sm:text-sm ${ord_id ? 'bg-gray-200' : ''}`}
                      />
                      {errors.customer_designation &&
                        touched.customer_designation ? (
                        <div className="text-sm text-red-600">
                          {errors.customer_designation}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div>
                    <label className="sm:text-sm text-black whitespace-nowrap">
                      Enter Organisation
                    </label>
                    <div>
                      <input
                        disabled={ord_id ? true : false}
                        value={values.customer_organisation}
                        onChange={(e)=>{handleChange(e)}}
                        onBlur={handleBlur}
                        name="customer_organisation"
                        type="text"
                        placeholder="Enter Organisation"
                        className={`w-full rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] sm:max-w-xs sm:text-sm ${ord_id ? 'bg-gray-200' : ''}`}
                      />
                      {errors.customer_organisation &&
                        touched.customer_organisation ? (
                        <div className="text-sm text-red-600">
                          {errors.customer_organisation}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div>
                    <label className="sm:text-sm text-black whitespace-nowrap">
                      Select Country
                    </label>
                    <div>
                      <Select
                        isDisabled={ord_id ? true : false}
                        className=" w-full max-w-lg  shadow-sm rounded-md border-[1px]   focus:border-[1px] sm:max-w-xs  sm:text-sm "
                        classNamePrefix="select "
                        placeholder="Select Country"
                        name="country"
                        options={allCountry}
                        onChange={(e) => {handleCountry(e)}}
                        value={selectCountry}
                        components={animatedComponents}
                      />
                      {errors.fk_country_id && touched.fk_country_id ? (
                        <div className="text-sm text-red-600">
                          {errors.fk_country_id}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div>
                    <label className="sm:text-sm text-black whitespace-nowrap">
                      Select Sku
                    </label>
                    <div>
                      <Select
                        isDisabled={(ord_id || !selectCountry || !selectCountry.value) ? true : false}
                        name="Sku"
                        placeholder={
                          <span>
                            Select Sku
                            <span className="text-red-600">*</span>
                          </span>
                        }
                        onChange={(e) => {handleSku(e);}}
                        value={selectedSku}
                        isMulti
                        components={animatedComponents}
                        options={skuData}
                        classNamePrefix="select"
                        className="basic-multi-select block w-full max-w-lg rounded-md border-[1px]  shadow-sm  focus:border-red-500 sm:max-w-xs sm:text-sm"
                      />
                      {errors.selectedSkus && touched.selectedSkus ? (
                        <div className="text-sm text-red-600">
                          {errors.selectedSkus}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="">
                    <label className="sm:text-sm text-black whitespace-nowrap">
                      Enter Quantity
                    </label>
                    <div>
                    <input
                      disabled={ ord_id ? true : (selectedCustomer?.values?.customer_id ? false : (values?.customer_username ? false : true))}
                      // disabled={!ord_id && !selectedCustomer ? true : false}
                      // value={ordQty !== undefined && ordQty !== null && ordQty !== "" ? ordQty : values.ord_quantity}
                      value={values?.ord_quantity ? values.ord_quantity : ordQty }
                      onChange={(e) => { handleQuantityChange(e) }}
                      onBlur={handleBlur}
                      name="ord_quantity"
                      type="number"
                      min={0}
                      placeholder="Enter Quantity"
                      className={`w-full rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] sm:max-w-xs sm:text-sm ${ord_id ? ' bg-gray-200' : (selectedCustomer?.values?.customer_id ? '' : (values?.customer_username ? '' : ' bg-gray-200'))}`}
                    />
                      {errors.ord_quantity && touched.ord_quantity ? (
                        <div className="text-sm text-red-600">{errors.ord_quantity}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="md:col-span-2 lg:col-span-1 xl:col-span-1 col-span-1">
                    <label className="sm:text-sm text-black whitespace-nowrap">
                      Select Payment Method
                    </label>
                    <div className="w-full">
                      <Select
                        isDisabled={ord_id ? true : false}
                        className="w-full shadow-sm rounded-md border-[1px] focus:border-[1px] sm:max-w-xs  sm:text-sm "
                        classNamePrefix="select "
                        placeholder="Select Payment Method"
                        name="paymethod"
                        options={paymentMethod}
                        onChange={(e) => handlePayMethod(e)}
                        value={payMethod}
                        components={animatedComponents}
                      />

                      {errors.payment_type && touched.payment_type ? (
                        <div className="text-sm text-red-600">
                          {errors.payment_type}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  {/* {renderRows()} */}
                  {custObjectsArray.map((object, index) => {
                    return (
                      <div key={index} className="sm:col-span-3 md:col-span-3 lg:col-span-4 xl:col-span-4 grid grid-cols-5 grid-rows-8 gap-3 sm:grid-cols-5 sm:grid-rows-5 md:grid-cols-7 md:grid-rows-4">
                        {ord_id ? <></> : index !== 0 && <div className='col-span-5 sm:col-span-5 md:col-span-7 flex items-center'>
                        <label htmlFor="role" className={`text-gray-700 block text-sm font-medium  mr-5`}>
                           Existing Customer
                        </label>
                        <Switch
                          checked={toggleStates[index]}
                          onChange={() => {toggleSwitch(index); clearRowValue(index)}}
                          className={`${toggleStates[index] ? "bg-blue-600" : "bg-gray-200"} relative inline-flex h-6 w-11 items-center rounded-full`}                    
                         >
                            <span className="sr-only">Existing Customer</span>
                            <span className={`${toggleStates[index] ? "translate-x-6" : "translate-x-1"} inline-block h-4 w-4 transform rounded-full bg-white transition`} />
                         </Switch>
                      </div>}                      
                      <div className=" col-span-4 row-start-2 sm:col-span-2 sm:row-start-2 md:col-start-1 md:col-span-2 md:row-start-2">
                        { index == 0 ? !custToggle && <>
                        <label className="sm:text-sm text-black whitespace-nowrap">
                          Enter Name
                        </label>
                        <div>
                          <input
                            disabled={true}
                            onChange={(e) => custRowHandleChange(e.target.value, index, 'name')}
                            value={object.name || ''}
                            type="text"
                            placeholder="Enter Name"
                            name="customer_username"
                            autoComplete="off"
                            className={`w-full rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] sm:max-w-xs sm:text-smbg-gray-200 ${index == 0 ? 'bg-gray-200' : ''}`}
                          />
                        </div>
                        {errors.customer_username && touched.customer_username ? (
                          <p className="text-red-600 text-sm">{errors.customer_username}</p>
                        ) : null}
                      </> : !toggleStates[index] && <>
                        <label className="sm:text-sm text-black whitespace-nowrap">
                          Enter Name
                        </label>
                        <div>
                          <input
                            disabled={ord_id ? true : false}
                            onChange={(e) => custRowHandleChange(e.target.value, index, 'name')}
                            value={object.name || ''}
                            type="text"
                            placeholder="Enter Name"
                            name="customer_username"
                            autoComplete="off"
                            className={`w-full rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] sm:max-w-xs sm:text-sm ${ord_id ? 'bg-gray-200' : ""}`}
                          />
                        </div>
                        {errors.customer_username && touched.customer_username ? (
                          <p className="text-red-600 text-sm">{errors.customer_username}</p>
                        ) : null}
                      </> }
                      {index == 0 ? custToggle && <>
                        <label className="sm:text-sm text-black whitespace-nowrap">
                           Select Customer
                       </label>
                      <Select
                        isDisabled={index == 0 ? true : false}
                        className=" w-full max-w-xl rounded-md border-[1px]    shadow-sm focus:border-[1px] sm:max-w-xs  sm:text-sm "
                        classNamePrefix="select "
                        placeholder="Select Customer"
                        name={"row_customer_" + index}
                        defaultValue={selectedCustomer}                      
                      options={customerList}
                      value={selectedCustomer}
                      onBlur={handleBlur}                      
                    />
                    {errors.customer_username_ + index && touched.customer_username_ + index ? (
                    <div className="text-sm text-red-600">
                      {errors.customer_username_ + index}
                    </div>
                    ) : null}
                      </> : toggleStates[index] && <>
                       <label className="sm:text-sm text-black whitespace-nowrap">
                           Select Customer
                       </label>
                      <Select
                        isDisabled={index == 0 ? true : false}
                        className=" w-full max-w-xl rounded-md border-[1px]    shadow-sm focus:border-[1px] sm:max-w-xs  sm:text-sm "
                        classNamePrefix="select "
                        placeholder="Select Customer"
                        name={"row_customer_" + index}
                        defaultValue={null}
                       onChange={(e) => {                       
                        handleCustSelectRow(e, index);
                       }}
                      options={filterChooseData()}
                      value={customerList.find(item1 => item1.value.customer_id  === custObjectsArray[index].customer_id)}
                      onBlur={handleBlur}
                    />
                    {errors.customer_username_ + index && touched.customer_username_ + index ? (
                    <div className="text-sm text-red-600">
                      {errors.customer_username_ + index}
                    </div>
                    ) : null}
                    </> }                                           
                      </div>
                      <div className="col-span-4 col-start-1 row-start-3 sm:col-span-2 sm:col-start-3 sm:row-start-2 md:col-span-2 md:col-start-3 md:row-start-2">
                        <label className="sm:text-sm text-black whitespace-nowrap">
                          Customer Email
                        </label>
                        <div>
                          <input
                            disabled={ord_id ? true : index == 0 ? true : false}
                            onChange={(e) => custRowHandleChange(e.target.value, index, 'email')}
                            value={object.email || ''}
                            name={"customer_email"}
                            type="text"
                            placeholder="Enter Customer email"
                            className={`w-full rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] sm:max-w-xs sm:text-sm ${ord_id ? 'bg-gray-200' : index == 0 ? 'bg-gray-200' : ''}`}
                          />
                          {errors.customer_email && touched.customer_email ? (
                            <div className="text-sm text-red-600">{errors.customer_email}</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-span-4 col-start-1 row-start-4 sm:col-span-2 sm:col-start-1 sm:row-start-3 md:col-span-2 md:col-start-5 md:row-start-2">
                        <label className="sm:text-sm text-black whitespace-nowrap">
                          Enter City
                        </label>
                        <div>
                          <input
                            disabled={ord_id ? true : index == 0 ? true : false}
                            onChange={(e) => custRowHandleChange(e.target.value, index, 'city')}
                            value={object.city || ''}
                            type="text"
                            placeholder="Enter City"
                            name="city_name"
                            autoComplete="off"
                            className={`w-full rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] sm:max-w-xs sm:text-sm ${ord_id ? 'bg-gray-200' : index == 0 ? 'bg-gray-200' : ''}`}
                          />
                        </div>
                        {errors.city_name && touched.city_name ? (
                          <p className="text-red-600 text-sm">{errors.city_name}</p>
                        ) : null}
                      </div>
                      <div className="col-span-4 col-start-1 row-start-5 sm:col-span-2 sm:col-start-3 sm:row-start-3 md:col-span-2 md:col-start-1 md:row-start-3">
                        <label className="sm:text-sm text-black whitespace-nowrap">
                          Enter Mailing Address
                        </label>
                        <div>
                          <input
                            disabled={ord_id ? true : index == 0 ? true : false}
                            onChange={(e) => custRowHandleChange(e.target.value, index, 'address')}
                            value={object.address || ''}
                            type="text"
                            placeholder="Enter Address"
                            name="customer_address"
                            autoComplete="off"
                            className={`w-full rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] sm:max-w-xs sm:text-sm ${ord_id ? 'bg-gray-200' : index == 0 ? 'bg-gray-200' : ''}`}
                          />
                        </div>
                        {errors.customer_address && touched.customer_address ? (
                          <p className="text-red-600 text-sm">{errors.customer_address}</p>
                        ) : null}
                      </div>
                      <div className="col-span-4 col-start-1 row-start-6 sm:col-span-2 sm:col-start-1 sm:row-start-4 md:col-span-2 md:col-start-3 md:row-start-3">
                        <label className="sm:text-sm text-black whitespace-nowrap">
                          Enter State
                        </label>
                        <div>
                          <input
                            disabled={ord_id ? true : index == 0 ? true : false}
                            onChange={(e) => custRowHandleChange(e.target.value, index, 'state')}
                            value={object.state || ''}
                            type="text"
                            placeholder="Enter State"
                            name="state_name"
                            autoComplete="off"
                            className={`w-full rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] sm:max-w-xs sm:text-sm ${ord_id ? 'bg-gray-200' : index == 0 ? 'bg-gray-200' : ''}`}
                          />
                        </div>
                        {errors.state_name && touched.state_name ? (
                          <p className="text-red-600 text-sm">{errors.state_name}</p>
                        ) : null}
                      </div>
                      <div className="col-span-4 col-start-1 row-start-7 sm:col-span-2 sm:col-start-3 sm:row-start-4 md:col-span-2 md:col-start-5 md:row-start-3">
                        <label className="sm:text-sm text-black whitespace-nowrap">
                          Enter Pincode
                        </label>
                        <div>
                          <input
                            disabled={ord_id ? true : index == 0 ? true : false}
                            onChange={(e) => custRowHandleChange(e.target.value, index, 'pincode')}
                            value={object.pincode || ''}
                            type="text"
                            placeholder="Enter Pincode"
                            name="pincode"
                            autoComplete="off"
                            className={`w-full rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] sm:max-w-xs sm:text-sm ${ord_id ? 'bg-gray-200' : index == 0 ? 'bg-gray-200' : ''}`}
                          />
                        </div>
                        {errors.pincode && touched.pincode ? (
                          <p className="text-red-600 text-sm">{errors.pincode}</p>
                        ) : null}
                      </div>
                      <div className="col-span-4 col-start-1 row-start-8 sm:col-span-2 sm:col-start-1 sm:row-start-5 md:col-span-2 md:col-start-1 md:row-start-4">
                        <label className="sm:text-sm text-black whitespace-nowrap">Quantity</label>
                        <div>
                          <input
                            disabled={true}
                            onChange={(e) => custRowHandleChange(e.target.value, index, 'quantity')}
                            value={object.quantity || ''}
                            type="number"
                            placeholder="Enter Quantity"
                            className={`w-full rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] sm:max-w-xs sm:text-sm bg-gray-200`}
                          />
                          {errors.customer_quantity && touched.customer_quantity ? (
                            <div className="text-sm text-red-600">
                              {errors.customer_quantity}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {
                        ord_id ? <></> : (index + 1) == custObjectsArray.length && <div className="flex items-center row-span-7 col-start-5 row-start-8 sm:row-span-4 sm:col-start-5 sm:row-start-3 md:row-span-4 md:col-start-7 md:row-start-2">
                        <button
                          type="button"
                          onClick={() => handleRemoveObject(index)}
                          className=" rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2 ml-auto"
                        >
                          <XMarkIcon className="w-5 h-5" />
                        </button>
                      </div>
                      }                      
                    </div>                    
                  )})}
                </div>
                {payMethod.length > 0 ? (
                  <div className="grid grid-cols-3 mt-5 gap-5 lg:w-full w-full">
                    <div>
                      <label className="sm:text-sm text-black whitespace-nowrap">
                        Enter DD Number
                      </label>
                      <div>
                        <input
                          disabled={ord_id ? true : false}
                          value={values.trans_card_id}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="trans_card_id"
                          type="text"
                          placeholder={`${payMethod[0].label} Number`}
                          className={`w-full rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] sm:max-w-xs sm:text-sm ${ord_id ? 'bg-gray-200' : ''}`}
                        />
                        {errors.trans_card_id && touched.trans_card_id ? (
                          <div className="text-sm text-red-600">
                            {errors.trans_card_id}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div>
                      <label className="sm:text-sm text-black whitespace-nowrap">
                        Enter Amount
                      </label>
                      <div>
                        <input
                          disabled={ord_id ? true : false}
                          type="number"
                          value={values.trans_amount}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="trans_amount"
                          placeholder="Amount"
                          className={`w-full rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] sm:max-w-xs sm:text-sm ${ord_id ? 'bg-gray-200' : ''}`}
                        />
                        {errors.trans_amount && touched.trans_amount ? (
                          <div className="text-sm text-red-600">
                            {errors.trans_amount}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div>
                      <label className="sm:text-sm text-black whitespace-nowrap">
                        Select Date
                      </label>
                      <div>
                        <input
                          disabled={ord_id ? true : false}
                          type="date"
                          value={values.trans_bank_date}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="trans_bank_date"
                          placeholder="Date"
                          className={`w-full rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] sm:max-w-xs sm:text-sm ${ord_id ? 'bg-gray-200' : ''}`}
                        />
                        {errors.trans_bank_date && touched.trans_bank_date ? (
                          <div className="text-sm text-red-600">
                            {errors.trans_bank_date}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div>
                      <label className="sm:text-sm text-black whitespace-nowrap">
                        Enter Bank
                      </label>
                      <div>
                        <input
                          disabled={ord_id ? true : false}
                          type="text"
                          value={values.trans_bank}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="trans_bank"
                          placeholder="Bank"
                          className={`w-full rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] sm:max-w-xs sm:text-sm ${ord_id ? 'bg-gray-200' : ''}`}
                        />
                        {errors.trans_bank && touched.trans_bank ? (
                          <div className="text-sm text-red-600">
                            {errors.trans_bank}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div>
                      <label className="sm:text-sm text-black whitespace-nowrap">
                        Enter Branch Name
                      </label>
                      <div>
                        <input
                          disabled={ord_id ? true : false}
                          type="text"
                          value={values.trans_branch_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="trans_branch_name"
                          placeholder="Branch"
                          className={`w-full rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] sm:max-w-xs sm:text-sm ${ord_id ? 'bg-gray-200' : ''}`}
                        />
                        {errors.trans_branch_name && touched.trans_branch_name ? (
                          <div className="text-sm text-red-600">
                            {errors.trans_branch_name}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                ) : null}                
              </div>
              <div>

                <div className="mt-8 flow-root">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <h2 className="text-base font-semibold leading-7 text-gray-900 mb-2">
                        <u>Payment Details</u>
                      </h2>
                      <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-300">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                SN.
                              </th>
                              {ord_id ? (<th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Order ID
                              </th>) : (<></>)}
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                              >
                                Sku Name
                              </th>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                              >
                                Sku Type
                              </th>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                              >
                                Sub Tot.
                              </th>
                              {ord_id ? (<th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                              >
                                Discount Type
                              </th>) : (<></>)}
                              {ord_id ? (<th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                              >
                                Discount
                              </th>) : (<></>)}
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                              >
                                Total
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            {selectedSku && selectedSku.length > 0 ? (
                              selectedSku.map((sku, index) => (
                                <tr key={index}>
                                  <td className=" py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                    {index + 1}
                                  </td>
                                  {ord_id ? (<td className=" py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                    {orderDetails?.ord_id}
                                  </td>) : (<></>)}
                                  <td className=" px-3 py-4 text-sm text-gray-500">
                                    {sku.sku_code}
                                  </td>
                                  <td className=" px-3 py-4 text-sm text-gray-500">
                                    {sku.sku_type}
                                  </td>
                                  <td className=" px-3 py-4 text-sm text-gray-500">
                                    {sku?.ord_skum_cp_display_price ? sku?.ord_skum_cp_display_price : sku.country_pricing[0]?.cp_display_price}
                                  </td>
                                  {ord_id ? (<td className=" px-3 py-4 text-sm text-gray-500">
                                    {orderDetails?.vouchers_relation?.voucher_type ? orderDetails?.vouchers_relation?.voucher_type : ''}
                                  </td>) : (<></>)}
                                  {ord_id ? (<td className=" px-3 py-4 text-sm text-gray-500">
                                    {calculateVoucherValue(orderDetails, sku?.ord_skum_cp_display_price, sku?.vouchers_relation?.voucher_value)}
                                  </td>) : (<></>)}

                                  <td className=" px-3 py-4 text-sm text-gray-500">
                                    {calculateSKUVoucherValue((orderDetails?.ord_id) ? orderDetails : sku, sku?.ord_skum_cp_display_price ? sku?.ord_skum_cp_display_price : sku.country_pricing[0]?.cp_display_price, sku?.vouchers_relation?.voucher_value)}
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td className="text-center p-5" colSpan={!ord_id ? 5 : 8}>
                                  No Data Found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        {/* {onlineStatus != 'Online' ? (<table className="min-w-full divide-y divide-gray-300">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                SN.
                              </th>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                              >
                                Sku Name
                              </th>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                              >
                                Sku Type
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Discounted Price(Without Voucher)
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Original AMT
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            {selectedSku.length > 0 ? (
                              selectedSku.map((sku, index) => (
                                <tr key={index}>
                                  <td className=" py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                    {index + 1}
                                  </td>
                                  <td className=" px-3 py-4 text-sm text-gray-500">
                                    {sku.sku_code}
                                  </td>
                                  <td className=" px-3 py-4 text-sm text-gray-500">
                                    {sku.sku_type}
                                  </td>
                                  <td className=" px-3 py-4 text-sm text-gray-500">
                                    {sku?.ord_skum_cp_display_price ? sku?.ord_skum_cp_display_price : sku.country_pricing[0]?.cp_display_price}
                                  </td>
                                  <td className=" px-3 py-4 text-sm text-gray-500">
                                    {sku?.ord_skum_cp_price ? sku?.ord_skum_cp_price : sku.country_pricing[0]?.cp_price}
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td className="text-center p-5" colSpan={5}>
                                  No Data Found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>) : (<table className="min-w-full divide-y divide-gray-300">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                SN.
                              </th>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                              >
                                Sku Name
                              </th>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                              >
                                Sku Type
                              </th>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                              >
                                Voucher No
                              </th>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                              >
                                Voucher Discount
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Discounted Price(Without Voucher)
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Original AMT
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            {selectedSku.length > 0 ? (
                              selectedSku.map((sku, index) => (
                                <tr key={index}>
                                  <td className=" py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                    {index + 1}
                                  </td>
                                  <td className=" px-3 py-4 text-sm text-gray-500">
                                    {sku.sku_code}
                                  </td>
                                  <td className=" px-3 py-4 text-sm text-gray-500">
                                    {sku.sku_type}
                                  </td>
                                  <td className=" px-3 py-4 text-sm text-gray-500">
                                    {sku.ord_total_voucher_discount}
                                  </td>
                                  <td className=" px-3 py-4 text-sm text-gray-500">
                                    {sku?.vouchers_relation?.voucher_code}
                                  </td>
                                  <td className=" px-3 py-4 text-sm text-gray-500">
                                    {sku?.ord_skum_cp_display_price ? sku?.ord_skum_cp_display_price : sku.country_pricing[0]?.cp_display_price}
                                  </td>
                                  <td className=" px-3 py-4 text-sm text-gray-500">
                                    {sku?.ord_skum_cp_price ? sku?.ord_skum_cp_price : sku.country_pricing[0]?.cp_price}
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td className="text-center p-5" colSpan={7}>
                                  No Data Found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>)} */}
                      </div>
                    </div>
                  </div>
                </div>

                {ord_id ? (
                  <div className="mt-8 flow-root ">
                    <div className="-mx-4 -my-2  sm:-mx-6 lg:-mx-8">
                      <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <h2 className="text-base font-semibold leading-7 text-gray-900 mb-2">
                          <u>Magazine Details</u>
                        </h2>
                        <div className=" shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                          <table className="min-w-full divide-y divide-gray-300">
                            <thead className="bg-gray-50">
                              <tr>
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                >
                                  SN.
                                </th>
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                >
                                  Magazine Name
                                </th>
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                >
                                  Day
                                </th>
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                >
                                  Month
                                </th>
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                >
                                  Year
                                </th>
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                >
                                  Date
                                </th>
                                {/* <th
                                  scope="col"
                                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                >
                                  Duration
                                </th>
                                <th
                                  scope="col"
                                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                >
                                  Month
                                </th> */}
                                <th
                                  scope="col"
                                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                >
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                              {orderDetails?.magazineDetails?.length > 0 ? (
                                orderDetails?.magazineDetails?.map((mag, index) => (
                                  <tr key={index}>
                                    <td className=" py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                      {index + 1}
                                    </td>
                                    <td className=" py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                      {mag?.mm_name}
                                      {/* {setSubscriptionDetails(prevState => {
                                        const newState = [...prevState];
                                        newState[index].subsDate =  mag?.subsDate;
                                        newState[index].subsYear = mag?.subsYear_json;
                                        newState[index].subsDay = mag?.subsDay_json;
                                        newState[index].subsMonth = mag?.subsMonth_json;

                                        return newState;
                                      })} */}
                                    </td>
                                    <td className=" px-3 py-4 text-sm text-gray-500">
                                      {mag?.singleSKUMagazineIssues?.mi_name && !mag?.skumagm_subscription?.value ? (<>{mag?.singleSKUMagazineIssues?.mi_name}</>) : (
                                        <>
                                          {
                                            mag?.subsDay_json?.label ? (
                                              <>{mag?.subsDay_json?.label}</>
                                            ) : (
                                              mag?.mm_interval_type === 'Day' ? (
                                                
                                                <Select
                                                  name="subsDay"
                                                  id="subsDay"
                                                  placeholder="Select Day"
                                                  menuPlacement="top"
                                                  onChange={(e) => { handleSelectChange(e, 'subsDay', index) }}
                                                  value={subsDetails[index]?.subsDay}
                                                  options={mag?.mm_date_interval}
                                                  components={animatedComponents}
                                                  className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm hidden"
                                                />
                                              ) : (
                                                <>-</>
                                              )
                                            )
                                          }

                                        </>)}

                                      {/* {mag?.mm_interval_type == 'Day' ? (<Select
                                        name="subsDay"
                                        id="subsDay"
                                        placeholder="Select Day"
                                        menuPlacement="top"
                                        onChange={(e) => { handleSelectChange(e, 'subsDay', index) }}
                                        value={subsDetails[index]?.subsDay}
                                        options={mag?.mm_date_interval}
                                        components={animatedComponents}
                                        className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                                      />) : (<></>)} */}
                                      <br />
                                      {/* {mag?.mm_interval_type == 'Day' ? mag?.mm_date_interval.map(item => item.label).join(', ') : mag?.mm_month_interval + ' Month'} */}
                                    </td>
                                    <td className=" px-3 py-4 text-sm text-gray-500">
                                      {/* {mag?.subs_duration} */}
                                      {mag?.singleSKUMagazineIssues?.mi_name && !mag?.skumagm_subscription?.value ? (<>{moment(mag?.singleSKUMagazineIssues?.mi_issue_month).format("MM")}</>) : (
                                        <>{mag?.subsMonth_json?.label ? (<>{mag?.subsMonth_json?.label}</>) : (<Select
                                          isDisabled={mag?.mm_interval_type == 'Date' ? false : !subsDetails[index]?.subsDay?.value}
                                          name="subsMonth"
                                          id="subsMonth"
                                          placeholder="Select Month "
                                          menuPlacement="top"
                                          onChange={(e) => { handleSelectChange(e, 'subsMonth', index) }}
                                          value={subsDetails[index]?.subsMonth?.value ? subsDetails[index]?.subsMonth : mag?.subsMonth}
                                          options={months}
                                          components={animatedComponents}
                                          className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm hidden"
                                        />)}</>)}
                                    </td>
                                    <td className=" px-3 py-4 text-sm text-gray-500">
                                      {mag?.singleSKUMagazineIssues?.mi_name && !mag?.skumagm_subscription?.value ? (<>{moment(mag?.singleSKUMagazineIssues?.mi_issue_month).format("YYYY")}</>) : (
                                        <>{mag?.subsYear_json?.value ? (<>{mag?.subsYear_json?.value}</>) : (<Select
                                          isDisabled={!subsDetails[index]?.subsMonth?.value}
                                          name="subsYear"
                                          id="subsYear"
                                          placeholder="Select Year"
                                          menuPlacement="top"
                                          onChange={(e) => { handleSelectChange(e, 'subsYear', index, mag) }}
                                          value={subsDetails[index]?.subsYear?.value ? subsDetails[index]?.subsYear : mag.subsYear}
                                          options={yearsList}
                                          components={animatedComponents}
                                          className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm hidden"
                                        />)}</>)}
                                    </td>
                                    <td className=" px-3 py-4 text-sm text-gray-500">
                                      {mag?.singleSKUMagazineIssues?.mi_name && !mag?.skumagm_subscription?.value ? (<>{moment(mag?.singleSKUMagazineIssues?.mi_issue_month).format("DD-MM-YYYY")}</>) : (
                                        <>{mag?.subsDate ? (<>{moment(mag?.subsDate).format("DD-MM-YYYY")}</>) : (<input
                                          disabled={mag?.mm_interval_type == 'Day' || mag?.mm_interval_type == 'Day' || mag?.mm_interval_type == 'Weekend' ? true : false}
                                          type="date"
                                          name="subsDate"
                                          id="subsDate"
                                          value={mag?.subsDateStartDate ? mag?.subsDateStartDate : subsDetails[index]?.subsDate}
                                          onChange={(e) => { handleInputChange(e, index, mag?.mm_interval_type) }}
                                          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm hidden"
                                        />)}</>)}
                                    </td>

                                    <td className=" px-3 py-4 text-sm text-gray-500">

                                      {/* || !parseInt(subsDetails[index]?.subsDate.split('-')[2]) > 0 */}
                                      {/* { && parseInt(subsDetails[index]?.subsDate.split('-')[2])>0} */}
                                      {ord_id && (orderDetails?.ord_payment_status == 'Paid' ||orderDetails?.ord_payment_status == 'Partly Paid' || orderDetails?.ord_payment_status == 'Extra Paid') && ((subsDetails[index]?.subsMonth?.value && subsDetails[index]?.subsYear?.value) || mag?.mm_interval_type == 'Weekend') && (mag?.subscription_interval_map?.length <= 0 || mag?.subscription_interval_map?.length > 0)
                                        ? (<button
                                          disabled={(!subsDetails[index]?.subsMonth?.value || !subsDetails[index]?.subsYear?.value || !parseInt(subsDetails[index]?.subsDate.split('-')[2]) > 0) ? mag?.mm_interval_type == 'Weekend' ? false : true : !ord_id ? true : false}
                                          type="button"
                                          onClick={() => { saveSubscription(orderDetails, subsDetails[index], mag); }}
                                          className={
                                            !ord_id
                                              ? "inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#867a7c] to-[#867a7c] px-2 py-1 text-xs font-medium text-white shadow-sm hover:bg-[#867a7c] focus:outline-none focus:ring-2 focus:ring-[#867a7c] focus:ring-offset-2 ml-1 mr-1 hidden"
                                              : "inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-2 py-1 text-xs font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2 ml-1 mr-1 hidden"
                                          }
                                        >
                                          Save
                                        </button>
                                        ) : null}
                                      {ord_id && orderDetails?.ord_payment_status == 'Paid' && mag?.subscription_interval_map?.length > 0
                                        ? (<button
                                          disabled={!ord_id ? true : false}
                                          onClick={() => { getSubscription(orderDetails, subsDetails[index], mag); setViewSubscription(true); }}
                                          type="button"
                                          className={
                                            !ord_id
                                              ? "inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#867a7c] to-[#867a7c] px-2 py-1 text-xs font-medium text-white shadow-sm hover:bg-[#867a7c] focus:outline-none focus:ring-2 focus:ring-[#867a7c] focus:ring-offset-2"
                                              : "inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-2 py-1 text-xs font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2"
                                          }
                                        >
                                          View Subs
                                        </button>) : null}
                                    </td>

                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td className="text-center p-5" colSpan={7}>
                                    No Data Found
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>) : (<></>)}

              </div>
            </div>

            <div className="mt-10 flex items-center justify-end gap-x-6">
              {!ord_id ? (<table>
                <tfoot>
                  <tr>
                    <th
                      scope="row"
                      colSpan={3}
                      className="hidden pt-4 text-right font-semibold text-gray-900 sm:table-cell"
                    >
                      Total Subscription Cost
                    </th>
                    <td className="pb-0 pl-8 pr-0 pt-4 text-right font-semibold tabular-nums text-gray-900">
                      Rs {totals.totalCpPrice}
                    </td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      colSpan={3}
                      className="hidden pt-4 text-right font-semibold text-gray-900 sm:table-cell"
                    >
                      Total I Pay
                    </th>
                    <td className="pb-0 pl-8 pr-0 pt-4 text-right font-semibold tabular-nums text-gray-900">
                      Rs {totals?.totalCpDisplayPrice}
                    </td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      colSpan={3}
                      className="hidden pt-4 text-right font-semibold text-gray-900 sm:table-cell"
                    >
                      Total I Save
                    </th>
                    <td className="pb-0 pl-8 pr-0 pt-4 text-right font-semibold tabular-nums text-gray-900">
                      Rs {totals.totalDifference}
                    </td>
                  </tr>
                </tfoot>
              </table>) : (<></>)}
            </div>
            <div className="mt-10 flex items-center justify-end gap-x-6">
              {/* <button
            type="button"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Cancel
          </button> */}
              {!ord_id
                ? (<button
                  disabled={submitButtonLoader || ord_id ? true : false}
                  type="submit"
                  className={
                    ord_id
                      ? "inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#867a7c] to-[#867a7c] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#867a7c] focus:outline-none focus:ring-2 focus:ring-[#867a7c] focus:ring-offset-2"
                      : "inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2"
                  }
                >
                  {ord_id ? (submitButtonLoader || disableSubmitButton ? <FaSpinner color="gold" /> : "Update") : (submitButtonLoader || disableSubmitButton ? <FaSpinner color="gold" /> : "Add")}
                  {/* Save Order */}
                </button>) : null}
            </div>
          </form>

          <Transition.Root show={viewSubscription} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-10"
              initialFocus={cancelButtonRef}
              onClose={() => { }}
              static
            >

              <Transition.Child
                afterLeave={() => {

                }}
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative p-8 bg-white w-full max-w-6xl mx-auto my-6 rounded-lg shadow-lg ">
                      {/* <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 booking-quotation-table-w sm:p-6"> */}
                      <div>
                        <form className="space-y-8 divide-y divide-gray-200">
                          <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                            <div className="space-y-6">
                              <div className="flex justify-between items-center">
                                {/* <div> */}
                                <h3 className="text-2xl font-bold">
                                  Subscription List
                                </h3>

                                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">


                                  <button
                                    type="button"
                                    className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                                    onClick={() => setViewSubscription(false)}
                                  >
                                    <span className="sr-only">Close</span>
                                    <XMarkIcon
                                      className="h-6 w-6"
                                      aria-hidden="true"
                                    />
                                  </button>
                                </div>
                              </div>
                              <div className="px-5">
                                <div className="flow-root">
                                  <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                      <div
                                        className="overflow-hidden border rounded-lg"
                                      >
                                        {!loadingSubs ? (
                                          <table className="min-w-full divide-y divide-gray-200">
                                            <thead className="bg-gray-50">
                                              <tr>
                                                <th className="px-4 py-2 text-grey-600 text-xs">
                                                  SN
                                                </th>
                                                <th className="px-4 py-2 text-grey-600 text-xs">
                                                  Subs ID
                                                </th>
                                                <th className="px-4 py-2 text-grey-600 text-xs">
                                                  Day
                                                </th>
                                                <th className="px-4 py-2 text-grey-600 text-xs">
                                                  Month
                                                </th>
                                                <th className="px-4 py-2 text-grey-600 text-xs">
                                                  Year
                                                </th>
                                                <th className="px-4 py-2 text-grey-600 text-xs">
                                                  Date
                                                </th>
                                                {/* <th className="px-4 py-2 text-grey-600 text-xs">
                                                Action
                                              </th> */}
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {subscriptionList?.length > 0 ? (
                                                subscriptionList?.map(
                                                  (subs_item, subs_key) => {

                                                    return (
                                                      <tr>
                                                        <td className="border border-grey-500 px-4 py-2 text-grey-600 font-medium text-xs">

                                                          {parseInt(subs_key + 1)}.
                                                        </td>
                                                        <td className="border border-grey-500 px-4 py-2 text-grey-600 font-medium  text-xs">

                                                          {subs_item.subinm_fk_subs_id}
                                                        </td>
                                                        <td className="border border-grey-500 px-4 py-2 text-grey-600 font-medium text-xs">

                                                          {subs_item.subsDay_json?.label}
                                                        </td>
                                                        <td className="border border-grey-500 px-4 py-2 text-grey-600 font-medium text-xs">
                                                          <span>
                                                            {subs_item.subsMonth_json?.label}
                                                          </span>
                                                        </td>
                                                        <td className="border border-grey-500 px-4 py-2 text-grey-600 font-medium text-xs">
                                                          {subs_item.subsYear_json?.value}
                                                        </td>
                                                        <td className="border border-grey-500 px-4 py-2 text-grey-600 font-medium text-xs">
                                                          {moment(subs_item?.selectedDate).format("DD-MM-YYYY")}
                                                        </td>
                                                        {/* <td className="border border-grey-500 px-4 py-2 text-grey-600 font-medium text-xs">
                                                        </td> */}

                                                      </tr>
                                                    );
                                                  }
                                                )
                                              ) : (
                                                <tr>
                                                  <td
                                                    colSpan={7}
                                                    className="border border-grey-500 px-4 py-2 text-grey-600 font-medium"
                                                  >
                                                    <center>Data Not Found</center>
                                                  </td>
                                                </tr>
                                              )}
                                            </tbody>
                                          </table>) : (

                                          <FallingLinesLoader />
                                        )}
                                      </div>
                                    </div>


                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="pt-5">
                            <div className="flex justify-end">
                              <button
                                onClick={() => setViewSubscription(false)}
                                type="button"
                                className="rounded-md border border-gray-300 bg-white py-1 px-2 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        </div>) : (

        <FallingLinesLoader />
      )}</>
  );
};

export default AddOrder;
