import React, { useEffect, useState } from "react";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Table from "../../../components/tables/table";
import Breadcrumb from "../../../components/Breadcrumb";
import { Link, useNavigate } from "react-router-dom";
import {
  getAllowedActions,
  checkPermissionsAndRedirect,
} from "../../../helper/commonHelper";
import ReportServices from "../../../ApiServices/ReportServices";
import { IssuesReport_columns } from "../../../components/tables/tableheader";
import makeAnimated from "react-select/animated";
import MasterServices from "../../../ApiServices/MasterServices";
import Select from "react-select";

const PrintOrderReport = () => {
  const pages = [
    { title: "Print Order Report", href: `/print-order-report`, module_id: 26 },
  ];
  const [isLoading, setIsLoading] = useState(false);
  const [printOrderReportList, setPrintOrderReportList] = useState([]);
  const navigate = useNavigate();
  const [allowedActions, setAllowedActions] = useState([]);
  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("user_permissions"));
    if (permissions && window.location.pathname) {
      const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect(
        window.location.pathname,
        permissions,
        navigate
      );
      const actions = getAllowedActions(permissions, window.location.pathname);
      setAllowedActions(actions);
    }
  }, []);

  useEffect(() => {
    getPrintOrderReport();
  }, []);

  const getPrintOrderReport = () => {
    setIsLoading(true);
    let payload = {
      status_code: 3,
    };
    ReportServices.getAllIssuesReport(payload)
      .then((res) => {
        const { data, status } = res;
        if (status) {
          setPrintOrderReportList(data.data);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const animatedComponents = makeAnimated();
  const [getAllMagazine, setGetAllMagazine] = useState([]);
  const [Magazine, setMagazine] = useState({ label: "", value: "" });

  const getMagazines = () => {
    MasterServices.getAllMagazine(1)
      .then((res) => {
        const { data, status } = res.data;
        if (status) {
          const newArray = data?.data?.map((ele) => ({
            ...ele,
            label: ele.mm_name,
            value: ele.mm_id,
          }));
          setGetAllMagazine(newArray);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getMagazines();
  }, []);

  const dummyData = {
    categoryWise: {
      title: "Category Wise",
      headers: ["Category", "Previous Issue", "Current Issue"],
      data: [
        { Category: "Free Issue", "Previous Issue": 0, "Current Issue": 0 },
        { Category: "Regular", "Previous Issue": 2092, "Current Issue": 2113 },
        { Category: "Complimentary", "Previous Issue": 6, "Current Issue": 4 },
        { Category: "Total", "Previous Issue": 2098, "Current Issue": 2117 },
      ],
    },
    termWise: {
      title: "Term Wise",
      headers: ["Term", "Previous Issue", "Current Issue"],
      data: [
        { Term: "12 Issues", "Previous Issue": -1, "Current Issue": -1 },
        { Term: "13 Issues", "Previous Issue": 2, "Current Issue": 5 },
        { Term: "18 Issues", "Previous Issue": -1, "Current Issue": -1 },
        { Term: "26 Issues", "Previous Issue": 1037, "Current Issue": 1071 },
        { Term: "30 Issues", "Previous Issue": 0, "Current Issue": 0 },
        { Term: "32 Issues", "Previous Issue": 1, "Current Issue": 0 },
        { Term: "39 Issues", "Previous Issue": 0, "Current Issue": 0 },
        { Term: "52 Issues", "Previous Issue": 126, "Current Issue": 110 },
        { Term: "54 Issues", "Previous Issue": 1, "Current Issue": 1 },
        { Term: "56 Issues", "Previous Issue": 1, "Current Issue": 1 },
        { Term: "58 Issues", "Previous Issue": 0, "Current Issue": 0 },
        { Term: "61 Issues", "Previous Issue": 0, "Current Issue": 0 },
        { Term: "65 Issues", "Previous Issue": 1, "Current Issue": 1 },
        { Term: "78 Issues", "Previous Issue": 182, "Current Issue": 182 },
        { Term: "82 Issues", "Previous Issue": 0, "Current Issue": 0 },
        { Term: "84 Issues", "Previous Issue": 3, "Current Issue": 3 },
        { Term: "91 Issues", "Previous Issue": 0, "Current Issue": 0 },
        { Term: "97 Issues", "Previous Issue": 0, "Current Issue": 0 },
        { Term: "104 Issues", "Previous Issue": 0, "Current Issue": 0 },
        { Term: "120 Issues", "Previous Issue": 2, "Current Issue": 2 },
        { Term: "130 Issues", "Previous Issue": 274, "Current Issue": 270 },
        { Term: "134 Issues", "Previous Issue": 36, "Current Issue": 36 },
        { Term: "136 Issues", "Previous Issue": 14, "Current Issue": 14 },
        { Term: "138 Issues", "Previous Issue": 0, "Current Issue": 0 },
        { Term: "143 Issues", "Previous Issue": 1, "Current Issue": 1 },
        { Term: "156 Issues", "Previous Issue": 0, "Current Issue": 0 },
        { Term: "182 Issues", "Previous Issue": 1, "Current Issue": 1 },
        { Term: "Total", "Previous Issue": 1681, "Current Issue": 1697 },
      ],
    },
    paymentWise: {
      title: "Payment Wise",
      headers: ["Payment Mode", "Previous Issue", "Current Issue"],
      data: [
        {
          "Payment Mode": "CASH",
          "Previous Issue": 10,
          "Current Issue": 10,
        },
        {
          "Payment Mode": "CHEQUE",
          "Previous Issue": 89,
          "Current Issue": 94,
        },
        {
          "Payment Mode": "CREDIT CARD",
          "Previous Issue": 3,
          "Current Issue": 3,
        },
        {
          "Payment Mode": "DEMAND DRAFT",
          "Previous Issue": 6,
          "Current Issue": 6,
        },
        {
          "Payment Mode": "MONEY ORDER",
          "Previous Issue": 1,
          "Current Issue": 1,
        },
        {
          "Payment Mode": "ONLINE PAYMENT",
          "Previous Issue": 1983,
          "Current Issue": 1999,
        },
        { "Payment Mode": "Total", "Previous Issue": 2092, "Current Issue": 2113 },
      ],
    },
    regionWise: {
      title: "Region Wise",
      headers: ["Region", "Previous Issue", "Current Issue"],
      data: [
        {
          "Region": "NEW DELHI/DELHI",
          "Previous Issue": 159,
          "Current Issue": 161,
        },
        {
          "Region": "NORTH/EAST UPCOUNTRY",
          "Previous Issue": 1388,
          "Current Issue": 1386,
        },
        { "Region": "MUMBAI", "Previous Issue": 32, "Current Issue": 37 },
        {
          "Region": "WEST UPCOUNTRY",
          "Previous Issue": 228,
          "Current Issue": 245,
        },
        { "Region": "CHENNAI", "Previous Issue": 7, "Current Issue": 6 },
        {
          "Region": "SOUTH UPCOUNTRY",
          "Previous Issue": 66,
          "Current Issue": 67,
        },
        {
          "Region": "CALCUTTA",
          "Previous Issue": 27,
          "Current Issue": 27,
        },
        {
          "Region": "BANGALORE/KARNATAKA",
          "Previous Issue": 64,
          "Current Issue": 62,
        },
        {
          "Region": "AHMEDABAD",
          "Previous Issue": 18,
          "Current Issue": 18,
        },
        { "Region": "PUNE", "Previous Issue": 32, "Current Issue": 33 },
        { "Region": "OTHERS", "Previous Issue": 77, "Current Issue": 75 },
        {
          "Region": "Total",
          "Previous Issue": 2098,
          "Current Issue": 2117,
        },
      ],
    },
    cityWise: {
      title: "City Wise",
      headers: ["City", "Previous Issue", "Current Issue"],
      data: [
        { "City": "AHMEDABAD", "Previous Issue": 18, "Current Issue": 18 },
        { "City": "BANGALORE", "Previous Issue": 45, "Current Issue": 44 },
        { "City": "CALCUTTA", "Previous Issue": 27, "Current Issue": 27 },
        { "City": "CHANDIGARH", "Previous Issue": 7, "Current Issue": 7 },
        { "City": "CHENNAI", "Previous Issue": 7, "Current Issue": 6 },
        { "City": "HYDERABAD", "Previous Issue": 12, "Current Issue": 11 },
        { "City": "MUMBAI", "Previous Issue": 49, "Current Issue": 54 },
        {
          "City": "NEW DELHI",
          "Previous Issue": 162,
          "Current Issue": 164,
        },
        { "City": "PUNE", "Previous Issue": 32, "Current Issue": 33 },
        { "City": "THANE", "Previous Issue": 6, "Current Issue": 6 },
        { "City": "THANE/VASHI", "Previous Issue": 5, "Current Issue": 6 },
        {
          "City": "OTHERS",
          "Previous Issue": 1728,
          "Current Issue": 1741,
        },
        { "City": "Total", "Previous Issue": 2098, "Current Issue": 2117 },
      ],
    },
  };

  const Table = ({ data, headers, title }) => (
    <div className="py-2 align-middle inline-block min-w-full px-0">
      <div className="shadow overflow-hidden border border-gray-200 sm:rounded-lg">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              {headers.map((header, index) => (
                <th
                  key={index}
                  scope="col"
                  className="group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {data.map((item, rowIndex) => {
              console.log(item);
              return (
                <tr key={rowIndex}>
                  {headers.map((header, colIndex) => (
                    <td
                      key={colIndex}
                      role="cell"
                      className="px-6 py-4 whitespace-nowrap text-left"
                    >
                      <div className="text-sm text-gray-500">
                        {item[header]}
                      </div>
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <Breadcrumb pages={pages} />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">
          Print Order Report
        </h1>
      </div>

      <div className="flex justify-end">
        <div className="mt-4 flex">
          {allowedActions.filter((data) => data.permission_id == 78)?.length >
          0 ? (
            <Link
              to={`/add-customer-address/`}
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2"
            >
              Download Excel
            </Link>
          ) : null}
        </div>
      </div>

      <div className="space-y-6 sm:space-y-5">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 gap-3 mt-5">
          <div className="sm:col-span-1 col-span-1 md:col-span-3 xl:col-span-1 lg:col-span-2">
            <label
              htmlFor="filterMagazineId"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Select Magazine
            </label>
            <Select
              name="filterMagazineId"
              id="filterMagazineId"
              placeholder="Select Magazine *"
              onChange={(e) => {
                console.log("PS=>", e);
                setMagazine(e);
              }}
              value={Magazine}
              options={getAllMagazine}
              components={animatedComponents}
              className="basic-multi-select block w-full shadow-sm focus:border-[1px] focus:border-red-500 sm:text-sm"
              classNamePrefix="select"
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 9999,
                }),
              }}
            />
          </div>
        </div>
      </div>

      <div className="space-y-6 mt-4 sm:space-y-5">
        <h5>Issue Dated 10-June-24</h5>
        <div className="grid grid-cols-1 xl:grid-rows-6 sm:grid-cols-1 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 gap-3 mt-5">
          <div className="col-span-1 sm:col-span-1 md:col-span-4 lg:col-span-4 xl:col-span-2 xl:row-span-1 overflow-x-auto">
            <Table
              data={dummyData.categoryWise.data}
              headers={dummyData.categoryWise.headers}
              title={dummyData.categoryWise.title}
            />
          </div>
          <div className="col-span-1 sm:col-span-1 md:col-span-4 lg:col-span-4 xl:col-span-2 xl:row-span-6 overflow-x-auto">
            <Table
              data={dummyData.termWise.data}
              headers={dummyData.termWise.headers}
              title={dummyData.termWise.title}
            />
          </div>
          <div className="col-span-1 sm:col-span-1 md:col-span-4 lg:col-span-4 xl:col-span-2 xl:row-span-2 xl:mt-[-1.3rem] overflow-x-auto">
            <Table
              data={dummyData.paymentWise.data}
              headers={dummyData.paymentWise.headers}
              title={dummyData.paymentWise.title}
            />
          </div>
          <div className="col-span-1 sm:col-span-1 md:col-span-4 lg:col-span-4 xl:col-span-2 xl:row-span-3 xl:mt-[-10.2rem] overflow-x-auto">
            <Table
              data={dummyData.regionWise.data}
              headers={dummyData.regionWise.headers}
              title={dummyData.regionWise.title}
            />
          </div>
          <div className="col-span-1 sm:col-span-1 md:col-span-4 lg:col-span-4 xl:col-span-2 xl:row-span-4 xl:mt-[-20.1rem] overflow-x-auto">
            <Table
              data={dummyData.cityWise.data}
              headers={dummyData.cityWise.headers}
              title={dummyData.cityWise.title}
            />
          </div>
        </div>
      </div>

      {/* {isLoading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={IssuesReport_columns({ allowedActions })}
          data={printOrderReportList ? printOrderReportList : []}
          is_toggle={false}
        />
      )} */}
    </div>
  );
};

export default PrintOrderReport;