import { Fragment, useState, useEffect } from "react";
import { Link, NavLink, Navigate, useNavigate } from "react-router-dom";
import { Dialog, Menu, Transition, Combobox } from "@headlessui/react";
import { ChevronDownIcon, PaintBrushIcon } from "@heroicons/react/20/solid";
import { ToastContainer, toast } from "react-toast";
import { BASEURL } from "../helper/constant";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { decode as base64_decode, encode as base64_encode } from "base-64";
/* 
let encoded = base64_encode('YOUR_DECODED_STRING');
let decoded = base64_decode('YOUR_ENCODED_STRING');
 */

import {
  Bars3BottomLeftIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
  UserIcon,
  MagnifyingGlassIcon,
  NewspaperIcon,
  CubeIcon,
  DocumentDuplicateIcon,
  ShieldCheckIcon,
  ShareIcon,
} from "@heroicons/react/24/outline";
import { useDispatch } from "react-redux";
import { handleLogout } from "../redux/auth/actions";
import { useSelector } from "react-redux";
import { getLoginUserInfo } from "../redux/userInfo/actions";
import MasterServices from "../ApiServices/MasterServices";
import { useLocation } from 'react-router-dom';
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const DashboardLayout = ({ children }) => {
  const location = useLocation();
  const animatedComponents = makeAnimated();
  const permissions = JSON.parse(localStorage.getItem("user_permissions"));
  const allModules = JSON.parse(localStorage.getItem("module"));

  // console.log(allModules);

  let FilterMenus = allModules?.map((module) => {
    // console.log("module?.sys_module_ref=>", module?.module_name, module?.sys_module_ref)
    // const submenuItems = createSubmenuItems(module?.m_permission);
    const submenuItems = createSubmenuItems(module?.sys_module_ref);
    const menu = {
      module_id: module?.module_id,
      name: module?.module_name,
      href: module?.module_key,
      icon: getIconForModule(module?.module_name), // Replace with a function that maps module_name to an icon
      current: true, // You can set this based on your logic
      is_permitted: module?.module_is_active,
      // submenuItems: createSubmenuItems(module.m_permission),
      submenuItems: createSubmenuItems(module?.sys_module_ref),
    };
    // alert(submenuItems.length)
    menu.submenu = submenuItems.length > 0 ? true : false;
    menu.current = true;
    return menu;
  });
  // console.log("permissions=>", permissions);
  const uniqueArray = [];
  const uniqueModuleIds = new Set();
  // if (FilterMenus?.length > 0) {
  //   FilterMenus?.forEach((module) => {
  //     if (!uniqueModuleIds.has(module?.module_id)) {
  //       // const matchingPermissions = permissions.filter(permission => permission.sys_module_relation.module_id === module.module_id);
  //       const matchingPermissions = permissions?.filter(
  //         (permission) => permission.fk_module_id === module.module_id
  //       );

  //       if (matchingPermissions?.length > 0) {
  //         uniqueModuleIds.add(module.module_id);

  //         if (module.href != "/magazine-issue") {
  //           const combinedObject = {
  //             name: module.name,
  //             href: module.href,
  //             icon: module.icon,
  //             current: true, // You can customize this based on your logic
  //             is_permitted: matchingPermissions[0].permission_is_active, // Assuming taking the first permission
  //             // Add other properties as needed
  //           };

  //           uniqueArray.push(combinedObject);
  //         }
  //       }
  //     }
  //   });
  // }

  if (FilterMenus?.length > 0) {
    FilterMenus?.forEach(module => {
      if (!uniqueModuleIds.has(module?.module_id)) {
        // const matchingPermissions = permissions.filter(permission => permission.sys_module_relation.module_id === module.module_id);
        const matchingPermissions = permissions?.filter(permission => permission.fk_module_id === module.module_id);
        // console.log("matchingPermissions=>", matchingPermissions)
        if (matchingPermissions?.length > 0) {
          uniqueModuleIds.add(module.module_id);

          if (module.href != "/magazine-issue") {
            const combinedObject = {
              name: module.name,
              href: module.href,
              icon: module.icon,
              current: true, // You can customize this based on your logic
              is_permitted: matchingPermissions[0].permission_is_active, // Assuming taking the first permission
              // Add other properties as needed
              submenuItems: module?.submenuItems,
              submenu: module?.submenu,
            };

            uniqueArray.push(combinedObject);
          }
        }
      }
    });
  }

  function getIconForModule(moduleName) {
    switch (moduleName) {
      case "Dashboard":
        return HomeIcon;
      case "Users":
        return UsersIcon;
      case "Roles":
        return ShieldCheckIcon;
      // Add more cases as needed for other modules
      default:
        return CubeIcon; // Replace with a default icon
    }
  }

  const Menus = uniqueArray;
  // console.log("Menus=>", Menus)
  // console.log("FilterMenus=>", FilterMenus)
  // Function to create submenu items based on permissions
  function createSubmenuItemsBak(permissions) {
    const submenuItems = [];

    if (permissions && permissions.length > 0) {
      permissions.forEach((permission) => {
        // Define submenu items based on permission name
        const permissionName = permission.permission_name;
        if (permission.permission_is_active) {
          const submenuItem = {
            name: permissionName,
            href: `/permissions/${permissionName}`,
            icon: "",
            current: false, // Set this based on your logic
            is_permitted: permission.permission_is_active,
          };

          submenuItems.push(submenuItem);
        }
      });
    }

    return submenuItems;
  }
  function createSubmenuItems(permissions) {
    const submenuItems = [];

    if (permissions && permissions.length > 0) {
      permissions.forEach((permission) => {
        // Define submenu items based on permission name
        const permissionName = permission?.module_name;
        const permissionhref = permission?.module_key;
        if (permission.module_is_active) {
          const submenuItem = {
            name: permissionName,
            href: permissionhref,
            icon: ShieldCheckIcon,
            current: false, // Set this based on your logic
            is_permitted: permission.module_is_active,
          };

          submenuItems.push(submenuItem);
        }
      });
    }

    return submenuItems;
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [sysModule, setSysModule] = useState([]);
  const [userLocDrop, setUserLocDrop] = useState([]);
  const [userLocData, setUserLocData] = useState([]);
  const [userFilterLocationId, setUserFilterLocationId] = useState([]);
  const {
    loginUserInfo: { userData },
  } = useSelector((state) => state);
  const [submenuOpen, setsubmenuOpen] = useState({
    appointment: false,
    forms: false,
    master: false,
    settings: false,
  });

  useEffect(() => {
    (async function () {
      const getSysModule = JSON.parse(localStorage.getItem("module"));
      const user_loc_menus = JSON.parse(localStorage.getItem("user_loc_menus"));
      const userLocData = JSON.parse(localStorage.getItem("userLocData"));
      setUserLocDrop(user_loc_menus);
      setUserLocData(userLocData);
      getSysModule ? setSysModule(getSysModule) : setSysModule([]);
      dispatch(getLoginUserInfo("All"));
    })();
  }, []);

  const onLogout = () => {
    dispatch(handleLogout());
    Navigate("/login");
  };

  const updateUserLoc = (payload) => {
    if (payload.label && payload.value) {
      let body = {
        label: payload.label,
        value: payload.value,
      };
      MasterServices.updateUserLoc(body)
        .then((response) => {
          const { data, status } = response;
          if (status == 200 && data?.status) {
            localStorage.setItem("user", JSON.stringify(data.data.user));
            localStorage.setItem(
              "favourites",
              JSON.stringify(data.data.favourites)
            );
            localStorage.setItem("module", JSON.stringify(data.data.module));
            localStorage.setItem(
              "user_loc_menus",
              JSON.stringify(data.data.user_loc_menus)
            );
            localStorage.setItem(
              "userLocData",
              JSON.stringify(data.data.userLocData)
            );
            localStorage.setItem("token", data.data.token);
            localStorage.setItem("TBB_role_id", data.data.user.role_id);
            window.location.reload();
          }
        })
        .catch((error) => {
          const msg = error;
        });
    }
  };

  const handleProfile = () => {
    //console.log('nitesh');
  };

  // const CustomMenu = [
  //   {
  //     name: "Vendor Masters",
  //     href: "/vendor-masters",
  //   },
  //   {
  //     name: "vendor Transaction",
  //     href: "/vendor-transaction",
  //   },
  // ];

  const userNavigation = [
    { name: "Profile", href: "/profile", onclick: handleProfile, click: false },
    { name: "Logout", href: "#", onclick: onLogout, click: true },
  ];

  const [query, setQuery] = useState("");

  const [searchModal, setSearchModal] = useState(false);

  const filteredMenus =
    query === ""
      ? []
      : sysModule.filter((data) => {
        return data.module_name.toLowerCase().includes(query.toLowerCase());
      });

  const navigatePage = (routeData) => {
    setSearchModal(false);
    navigate(routeData.module_key);
  };

  const handleCopyReferral = () => {
    const user_referral_code = JSON.parse(
      localStorage.getItem("user")
    )?.user_referral_code;
    let encoded = base64_encode("ABCDESG");
    // let user_referral_code_encoded = base64_encode(user_referral_code);

    const textToCopy = `${window.location.hostname.includes("localhost")
    ? process.env.REACT_APP_LOCAL_BACKEND_URL
    : process.env.REACT_APP_LIVE_BACKEND_URL
    }newdesign?userId=656ec1d8fcb1a42aa9185597&referrarData=eyJ1c2VyU291cmNlRGF0YSI6IkRJUkVDVCIsInJlZmVycmVyIjoiIiwicmVmZXJyZXJVcmwiOiIifQ==&user_referral_code=${user_referral_code}&token_code=${localStorage.getItem('token')}`;
    // const textToCopy = `${window.location.hostname.includes("localhost")
    //   ? process.env.REACT_APP_LOCAL_BACKEND_URL
    //   : process.env.REACT_APP_LIVE_BACKEND_URL
    //   }default?userId=656ec1d8fcb1a42aa9185597&referrarData=eyJ1c2VyU291cmNlRGF0YSI6IkRJUkVDVCIsInJlZmVycmVyIjoiIiwicmVmZXJyZXJVcmwiOiIifQ==&user_referral_code=${user_referral_code}`; // `https://subscriptions.outlookindia.com/default?userId=${encoded}&referrarData=testDate`;
    // const textToCopy = `${window.location.hostname.includes("localhost") ? process.env.REACT_APP_LOCAL_BACKEND_URL : process.env.REACT_APP_LIVE_BACKEND_URL}default?userId=656ec1d8fcb1a42aa9185597&referrarData=eyJ1c2VyU291cmNlRGF0YSI6IkRJUkVDVCIsInJlZmVycmVyIjoiIiwicmVmZXJyZXJVcmwiOiIifQ==&user_referral_code=${user_referral_code}&&token_code=${localStorage.getItem('token')}`; // `https://subscriptions.outlookindia.com/default?userId=${encoded}&referrarData=testDate`;
    // const textToCopy = `https://api-outlook-subscription.enpointe.io/default?userId=656ec1d8fcb1a42aa9185597&referrarData=eyJ1c2VyU291cmNlRGF0YSI6IkRJUkVDVCIsInJlZmVycmVyIjoiIiwicmVmZXJyZXJVcmwiOiIifQ==&user_referral_code=${user_referral_code}`; // `https://subscriptions.outlookindia.com/default?userId=${encoded}&referrarData=testDate`;
    // const textToCopy = `https://subscriptions.outlookindia.com/default?userId=656ec1d8fcb1a42aa9185597&referrarData=eyJ1c2VyU291cmNlRGF0YSI6IkRJUkVDVCIsInJlZmVycmVyIjoiIiwicmVmZXJyZXJVcmwiOiIifQ==&user_referral_code=${user_referral_code}`; // `https://subscriptions.outlookindia.com/default?userId=${encoded}&referrarData=testDate`;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        console.log("Text copied to clipboard");
        toast.success("Referral Link Coppied, Ready to share");
      })
      .catch((error) => {
        console.error("Error copying text:", error);
      });
  };

  const [submenuDivOpen, setSubmenuDivOpen] = useState(false);
  const [activeParent, setActiveParent] = useState(null);

  const handleToggle = (uniqueId) => {
    const childDiv = document.getElementById(`child${uniqueId}`);
    const parentDiv = document.getElementById(`parent${uniqueId}`);
    if (childDiv) { 
      if (childDiv.style.display === 'block') {
        childDiv.style.display = 'none';
        parentDiv.classList.remove('active');
        setSubmenuDivOpen(false);
        setActiveParent(null);
      } else {
        childDiv.style.display = 'block';
        parentDiv.classList.add('active');
        setSubmenuDivOpen(true);
        setActiveParent(uniqueId);
      }
    }
  };

  return (
    <>
      <ToastContainer delay={3000} position="top-right" />

      <div className="bg-gray-100">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 md:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-gradient-to-b from-[#2e2e34] from-10%   via-gray-900 via-9%    to-gray-900 to-80% pt-5 pb-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-shrink-0 items-center px-4">
                    <img
                      className="h-8 w-auto rounded-md"
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT2Mv7QAYQ7SU7I95lE0tl6cDEgcex2L1KgqYxWfOTp9wlSgJAFsOCi&usqp=CAE&s"
                      alt="MaQueenSoft"
                    />
                    <h1 className="ml-2 items-center text-center text-white text-xl whitespace-nowrap">
                      OI Subscription
                    </h1>
                  </div>
                  <div className="mt-5 h-0 flex-1 overflow-y-auto">
                    <nav className="space-y-1 px-2">
                      {Menus.map((menu, index) =>
                      {
                        // console.log(menu)
                        const uniqueId = Math.floor(Math.random() * 1000000000000)
                       return menu.is_permitted ? (
                          <div key={menu.name} id={`parent${uniqueId}`} onClick={() => handleToggle(uniqueId)}>
                            <NavLink
                              to={menu.href}
                              // onClick={() => {
                              //   menu.submenu && setsubmenuOpen(!submenuOpen);
                              // }}                              
                              onClick={()=> !menu.submenu && setSidebarOpen(false)}
                              /* className={classNames(
                                menu.current
                                  ? "bg-cyan-800 text-white"
                                  : "text-cyan-100 hover:bg-cyan-600",
                                "group flex items-center px-2 py-2  font-medium rounded-md"
                              )} */
                              className={classNames(
                                window.location.pathname === menu.href
                                  ? "bg-gradient-to-b from-[#872341] to-[#BE3144] text-white shadow-md"
                                  : "text-gray-100 hover:bg-gray-600",
                                "group flex items-center px-2 py-3 my-2 mx-1 font-medium rounded-md"
                              )}
                            >
                              <menu.icon
                                className="mr-3 h-6 w-6 flex-shrink-0 text-white"
                                aria-hidden="true"
                              />
                              {menu.name}
                              {menu.submenu && (
                                <ChevronDownIcon
                                  className={` ${submenuOpen & submenuDivOpen && activeParent === `parent${uniqueId}` && "-rotate-0"
                                    } mr-3 h-6 w-6 flex-shrink-0 -rotate-90 text-white duration-150`}
                                  aria-hidden="true"
                                />
                              )}
                            </NavLink>
                            {menu.submenu && (
                              <div id={`child${uniqueId}`} style={{ display: "none" }}>
                                {menu.submenuItems.map((submenuItem, index) =>
                                  submenuItem.is_permitted ? (
                                    <Link                                    
                                      key={submenuItem.name}
                                      to={submenuItem.href}
                                      className={classNames(
                                        menu.current
                                          ? "bg-gradient-to-b from-[#872341] to-[#BE3144] text-white"
                                          : "text-white hover:bg-cyan-600",
                                        "group flex items-center  px-2 py-2 text-xs font-normal rounded-md my-2"
                                      )}
                                      onClick={()=>setSidebarOpen(false)}
                                    >
                                      <submenuItem.icon
                                        className="ml-5 mr-3 h-5 w-6 flex-shrink-0 text-white"
                                        aria-hidden="true"
                                      />
                                      {submenuItem.name + " " + index}
                                    </Link>
                                  ) : null
                                )}
                              </div>
                            )}
                          </div>
                        ) : null
                      }
                      )}
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-grow flex-col overflow-y-auto m-2 rounded-lg bg-gradient-to-b from-[#2e2e34] from-10%   via-gray-900 via-9%    to-gray-900 to-80% pt-5">
            <div className="flex flex-shrink-0 items-center px-4">
              <img
                className="h-8 w-auto rounded-md"
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT2Mv7QAYQ7SU7I95lE0tl6cDEgcex2L1KgqYxWfOTp9wlSgJAFsOCi&usqp=CAE&s"
                alt="MaQueenSoft"
              />
              <h1 className="ml-2 items-center text-center text-white text-xl whitespace-nowrap">
                OI Subscription
              </h1>
            </div>
            <div className="mt-5 flex flex-1 flex-col">
              <nav className="flex flex-1 flex-col p-4">
                <ul role="list" className="flex flex-1 flex-col gap-y-1">
                  <li>
                    <ul role="list" className="-mx-2 space-y-1">
                      {Menus.map((menu, index) =>
                        menu.is_permitted ? (
                          <li key={menu.name}>
                            <Link
                              to={menu.href}
                              onClick={() => {
                                setsubmenuOpen({
                                  ...submenuOpen,
                                  [menu.name.toLowerCase()]:
                                    !submenuOpen[menu.name.toLowerCase()],
                                });
                              }}
                              className={classNames(
                                window.location.pathname === menu.href
                                  ? "bg-gradient-to-b from-[#872341] to-[#BE3144] text-white shadow-md"
                                  : "text-gray-100 hover:bg-gray-600",
                                "group flex items-center px-1 py-1 my-1 mx-1 font-medium rounded-md"
                              )}
                            >
                              <menu.icon
                                className="mr-3 h-6 w-6 flex-shrink-0 text-white"
                                aria-hidden="true"
                              />
                              {menu.name}
                              {menu.submenu && (
                                <ChevronDownIcon
                                  className={` ${submenuOpen[menu.name.toLowerCase()]
                                    ? "-rotate-0"
                                    : "-rotate-90"
                                    } mr-3 h-6 w-6 flex-shrink-0 -rotate-90 text-gray-300 duration-150`}
                                  aria-hidden="true"
                                />
                              )}
                            </Link>
                            {menu.submenu &&
                              submenuOpen[menu.name.toLowerCase()] && (
                                <div>
                                  {submenuOpen &&
                                    menu.submenuItems.map(
                                      (submenuItem, index) =>
                                        submenuItem.is_permitted ? (
                                          <Link
                                            to={submenuItem.href}
                                            className={classNames(
                                              window.location.pathname ===
                                                submenuItem.href
                                                ? "bg-indigo-800 text-white"
                                                : "text-indigo-100 hover:bg-indigo-600",
                                              "group flex items-center px-2 py-2 font-medium rounded-md"
                                            )}
                                            key={submenuItem.name}
                                          >
                                            <submenuItem.icon
                                              className="ml-5 mr-3 h-5 w-6 flex-shrink-0 text-indigo-300"
                                              aria-hidden="true"
                                            />
                                            {submenuItem.name}
                                          </Link>
                                        ) : null
                                    )}
                                </div>
                              )}
                          </li>
                        ) : null
                      )}
                    </ul>
                  </li>
                  {/* {CustomMenu.map((item, index) => {
                    return (
                      <>
                        <li key={index} className="-mx-2">

                          <Link
                            to={item.href}
                            className={classNames(
                              window.location.pathname === item.href
                                ? "bg-gradient-to-b from-[#872341] to-[#BE3144] text-white shadow-md"
                                : "text-gray-100 hover:bg-gray-600",
                              "group flex items-center px-1 py-1 my-1 mx-1 font-medium rounded-md"
                            )}
                          >
                            <CubeIcon
                              className="h-6 w-6 shrink-0 mr-3"
                              aria-hidden="true"
                            />
                            {item.name}
                          </Link>
                        </li>
                      </>
                    );
                  })} */}
                  <li className="mt-auto">
                    <span
                      onClick={handleCopyReferral}
                      className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 bg-gray-800 text-white cursor-pointer"
                    >
                      <ShareIcon
                        className="h-6 w-6 shrink-0"
                        aria-hidden="true"
                      />
                      My Referral Link
                    </span>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>

        {/* Main Section */}
        <div className="flex flex-1 flex-col md:pl-64 min-h-screen">
          {/* Header */}
          <div className="sticky top-0 z-10 flex h-16 flex-shrink-0 shadow rounded-lg m-3 backdrop-blur-md bg-white">
            <button
              type="button"
              className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500 md:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="flex flex-1 justify-between px-4">
              <div className="flex flex-1 items-center">
                <div className="w-8">
                  <MagnifyingGlassIcon
                    size={18}
                    className="cursor-pointer"
                    onClick={() => setSearchModal(true)}
                  />
                </div>
                {/* CAN PUT CONTENT HERE INSTEAD OF SEARCH BAR */}
              </div>
              {/* <div className="flex flex-1"> */}
              {/* CAN PUT CONTENT HERE INSTEAD OF SEARCH BAR */}
              {/* </div> */}
              <div className="ml-4 flex items-center md:ml-6">
                <div className="text-end leading-none">
                  <p className="font-black">{userData?.user_name || ""} </p>
                  <i className="text-xs font-black">
                    {(userData?.user_first_name || "") +
                      " (" +
                      (userData?.m_role_relation?.role_name || "") +
                      ")"}
                  </i>                 
                </div>

                {/* Profile dropdown */}
                <Menu as="div" className="relative ml-3">
                  <div>
                    <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2">
                      <span className="sr-only">Open user menu</span>
                      <span className="h-8 w-8 overflow-hidden rounded-full bg-gray-100">
                        {userData && userData.user_profile_image ? (
                          <img
                            src={userData.user_profile_image}
                            alt="profile_icon"
                          />
                        ) : (
                          <svg
                            className="h-full w-full text-gray-300"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                          </svg>
                        )}
                      </span>
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {userNavigation.map((item, key) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <Link
                              onClick={item.click ? item.onclick : null}
                              to={item.click ? null : item.href}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              {item.name}
                            </Link>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <main>
            <div className="py-6">
              <div className={"mx-auto max-w-[90rem] px-4 sm:px-6 md:px-8" +`${location.pathname !== "/print-issue" ? " overflow-x-hidden" : ""}`} >
                {children}
              </div>
            </div>
          </main>

          {/* Search Modal */}
          <Transition.Root
            show={searchModal}
            as={Fragment}
            afterLeave={() => setQuery("")}
            appear
          >
            <Dialog as="div" className="relative z-10" onClose={setSearchModal}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                    <Combobox
                      //onChange={(person) => (window.location = person.url)}
                      onChange={(person) => navigatePage(person)}
                    >
                      <div className="relative">
                        <MagnifyingGlassIcon
                          className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        <Combobox.Input
                          className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm"
                          placeholder="Search..."
                          onChange={(event) => setQuery(event.target.value)}
                        />
                      </div>

                      {filteredMenus.length > 0 && (
                        <Combobox.Options
                          static
                          className="max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800"
                        >
                          {filteredMenus.map((person, key) => (
                            <Combobox.Option
                              key={person.module_name}
                              value={person}
                              className={({ active }) =>
                                classNames(
                                  "cursor-pointer select-none px-4 py-2",
                                  active && "bg-cyan-600 text-white"
                                )
                              }
                            >
                              {person.module_name}
                            </Combobox.Option>
                          ))}
                        </Combobox.Options>
                      )}

                      {query !== "" && filteredMenus.length === 0 && (
                        <p className="p-4 text-sm text-gray-500">
                          No search found.
                        </p>
                      )}
                    </Combobox>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>
        </div>
      </div>
    </>
  );
};

export default DashboardLayout;
