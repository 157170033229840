import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../components/Breadcrumb";
import Table from "../../../components/tables/table";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import { Link, useNavigate } from "react-router-dom";
import { vendor_tran_columns } from "../../../components/tables/tableheader";
import MasterServices from "../../../ApiServices/MasterServices";
import { getAllowedActions, checkPermissionsAndRedirect, calculateAge, convertToAnotherTimezone, capitalizeFirstLetter } from "../../../helper/commonHelper";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { toast, ToastContainer } from "react-toast";
import makeAnimated from "react-select/animated";
import Select from 'react-select'
import moment from 'moment';
import { utils, writeFile } from 'xlsx';
const Masters = () => {

  const navigate = useNavigate();


  const pages = [
    {
      title: "Vendor Transaction List",
      href: "/vendor-transaction",
      module_id: 51,
    },
  ];
  // Filter Values Start
  let filterInitialValues = {
    filterFrom: "",
    filterTo: "",
    filterVendorName: "",
    filterVendorEmail: "",
    filterVendorMobile: "",
    filterStatus: "",
    filterMagazineId: { label: "", value: "" },
    filterMagazineIssueId: { label: "", value: "" },
  };
  let [filterVendorTransaction, setFilterVendorTransaction] = useState(filterInitialValues);
  const [filterFlag, setFilterFlag] = useState(true);
  const [loading, setLoading] = useState(false);
  const [vednorTransaction, setVednorTransaction] = useState([]);
  const [allowedActions, setAllowedActions] = useState([]);
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    vendor_id: null,
  });
  console.log(allowedActions, "ALLLLLLLLLLLOUDACTION");
  const [getAllMagazine, setGetAllMagazine] = useState([])
  const [getAllMagazinesIssue, setGetAllMagazinesIssue] = useState([]);
  const animatedComponents = makeAnimated();

  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("user_permissions"));
    if (permissions && window.location.pathname) {
      const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect(window.location.pathname, permissions, navigate);
      const actions = getAllowedActions(permissions, window.location.pathname);
      setAllowedActions(actions);
    }
  }, []);

  console.log(vednorTransaction);

  const getMagazines = () => {
    MasterServices.getAllMagazine(1).then((res) => {
      const { data, status } = res.data
      if (status) {
        const newArray = data?.data?.map((ele) => ({
          label: ele.mm_name,
          value: ele.mm_id,
        }))
        setGetAllMagazine(newArray);
      }
    }).catch((err) => {
      console.log(err)
    })
  }
  const getMagazineIssues = (e) => {
    MasterServices.getAllMagazineIssues(e.value).then((res) => {
      const { data, status } = res.data;
      if (status) {
        const newArray = data?.data?.map((ele) => ({
          value: ele.mi_id,
          label: ele.mi_name
        }))
        setGetAllMagazinesIssue(newArray)
      }
    }).catch((error) => {
      console.log(error)
    });
  }

  const getAllVendorTransaction = () => {
    setLoading(true)
    let payload = {
      status_code: 1,
      filterVendorTransaction: filterVendorTransaction,
    }
    MasterServices.getAllVendorTransaction(payload).then((res) => {
      const { data, status } = res;
      if (status) {
        console.log(data.data);
        const transformedData = data.data.map((item) => {
          // Transform each item to match the table's column accessors
          return {

            vendor_id: item.m_vendor_relation.vendor_id,
            vendor_name: item.m_vendor_relation.vendor_name || [item.m_vendor_relation.vendor_first_name, item.m_vendor_relation.vendor_last_name].filter(Boolean).join(" "),
            vendor_email: item.m_vendor_relation.vendor_email,
            vendor_mobile: item.m_vendor_relation.vendor_mobile, // Assuming `vendor_mobile` is directly available or you need to adjust based on your data structure
            // vendor_gender: item.vendor_gender, // Commented out as it's not present in your given columns
            // Assuming you have relations for country, state, city, address, and pincode in your data or adjust accordingly
            m_country_relation: item.m_country_relation, // Mock example, replace with your actual data path
            m_state_relation: item.m_state_relation, // Mock example
            m_city_relation: item.m_city_relation, // Mock example
            // vendor_address: item, // Mock example
            // vendor_pincode: item, // Mock example
            // ven_iss_trax_is_active: item.ven_iss_trax_is_active, // Assuming this directly maps to your status
            ven_iss_trax_id: item.ven_iss_trax_id,
            ven_iss_trax_grand_tot: item.ven_iss_trax_grand_tot,
            ven_iss_trax_tot_disc: item.ven_iss_trax_tot_disc,
            ven_iss_trax_sub_tot: item.ven_iss_trax_sub_tot,
            discount_type: item.discount_type,
            magazineIssues: item.magazineIssues,
            magazine: item.magazine,
            ven_iss_trax_is_active: item?.ven_iss_trax_is_active,
            ven_iss_trax_id: item?.ven_iss_trax_id,
            ven_iss_trax_created_at: item?.ven_iss_trax_created_at,
          };
        });

        setVednorTransaction(transformedData);
      }
      setLoading(false)
    });
  };

  const onFilterClear = () => {
    setIsSearched(false)
    setVednorTransaction([]);
    filterVendorTransaction.filterFlag = false;
    setFilterVendorTransaction(filterInitialValues);
    // getAllVendorTransaction();
  }
  // Function to handle changes in filter values
  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilterVendorTransaction({ ...filterVendorTransaction, [name]: value });
  };
  const handleSelectFilterChange = (selectedOption, name) => {
    const { label, value } = selectedOption || {};
    setFilterVendorTransaction({ ...filterVendorTransaction, [name]: selectedOption });
  };
  const onFilter = () => {
    if (checkObjectValues(filterVendorTransaction)) {
      setIsSearched(true)
    filterVendorTransaction.filterFlag = true;
    getAllVendorTransaction();
  }else{
    toast.error("Please Select Alteast One Filter!");
  }
  }
  useEffect(() => {
    // getAllVendorTransaction();
    getMagazines();
  }, []);

  const onStatusUpdate = (ven_iss_trax_id, ven_iss_trax_is_active) => {
    setLoading(true)
    let payload = {
      table_name: "m_vendor_issues_trans",
      table_field: "ven_iss_trax_id",
      table_field_val: ven_iss_trax_id,
      table_status_field: "ven_iss_trax_is_active",
      table_status_val: ven_iss_trax_is_active ? false : true,
      table_text: "Vendor Transaction",
      deleted_by_key: "ven_iss_trax_updated_by",
      delete_active_txt: ven_iss_trax_is_active ? " Deactivated" : " Activated"
    }
    MasterServices.removeMaster(payload).then((res) => {
      const { data, status, message } = res.data
      if (status) {
        getAllVendorTransaction()
        toast.success(message);
      } else {
        toast.error(message)
      }
      setLoading(false)
    })
  }
  const onDeleteOpen = (ven_iss_trax_id) => {
    console.log("ven_iss_trax_id", ven_iss_trax_id)
    setConfirmationModal({ ven_iss_trax_id, status: true });
  }

  const onDeleteItem = (ven_iss_trax_id) => {
    setLoading(true)
    MasterServices.deleteVendorTransaction({ ven_iss_trax_id })
      // console.log( ven_iss_trax_id,"DELETELOOZZZZZZZZZZZZZZZZZZZZZZp");
      .then((response) => {
        // Your existing code
        getAllVendorTransaction(); // Refresh the list after deletion
        setLoading(false)
      })
      .catch((error) => {
        // Your existing error handling
      });
  }
  // const onDeleteItem = (ven_iss_trax_id) => {


  // MasterServices.deleteVendorTransaction({ ven_iss_trax_id })
  //   .then((response) => {
  //     const { data, status } = response;
  //     console.log("response Prod=>", response)
  //     if (data.status) {
  //       setIsLoading(true)
  //       toast.success(data.message)
  //       setConfirmationModal({ customer_id: null, status: false });
  //       getAllCustomers()
  //       navigate("/customer");
  //     } else {
  //       toast.error(data.message)
  //     }
  //     setIsLoading(false)
  //   })
  //   .catch((error) => {
  //     const msg = error.response.data.message;
  //     toast.error(msg)
  //     toast.error("Sorry Fatal Error!")
  //     setIsLoading(false)
  //   });
  // }

  const handleExport = (excel_type) => {

    if (vednorTransaction?.length > 0) {
      let newSheetdata = [];
      vednorTransaction.map((obj, index) => {
        const newData = {
          SN: parseInt(index) + 1,
          ven_iss_trax_id: obj?.ven_iss_trax_id || '-',
          vendor_id: obj?.vendor_id || '-',
          vendor_name: capitalizeFirstLetter(obj?.vendor_name) || '-',
          vendor_email: obj?.vendor_email || '-',
          vendor_mobile: obj?.vendor_mobile || '-',
          magazine: obj?.magazine || '-',
          magazineIssues: obj?.magazineIssues || '-',
          discount_type: obj?.discount_type || '-',
          ven_iss_trax_sub_tot: obj?.ven_iss_trax_sub_tot || '-',
          ven_iss_trax_tot_disc: obj?.ven_iss_trax_tot_disc || '-',
          ven_iss_trax_grand_tot: obj?.ven_iss_trax_grand_tot || '-',
          ven_iss_trax_created_at: moment(obj?.ven_iss_trax_created_at).format("DD-MM-YYYY") || '-',
          ven_iss_trax_is_active: obj?.ven_iss_trax_is_active == true ? 'Active' : 'In-Active' || '-',
        }
        newSheetdata.push(newData);
      });
      let currentDate = new Date();
      let fileName = 'Vendor_Transaction_Excel_Report_' + moment(currentDate).format("DD-MM-YYYY") + '.' + excel_type;

      const headings = [['SN', 'Transaction ID', 'Vendor ID', 'Vendor Name', 'Vendor Email', 'Vendor Mobie', 'Magazine', 'Magazine Issues', 'Discount Type', 'Sub. Total', 'Discount', 'Grand Total', 'Created Date', 'Status']];
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);
      utils.sheet_add_aoa(ws, headings);
      utils.sheet_add_json(ws, newSheetdata, { origin: 'A2', skipHeader: true });
      utils.book_append_sheet(wb, ws, 'Report');
      writeFile(wb, fileName);
    } else {
      toast.error("Data Not Found!");
    }
  }

  const [isSearched, setIsSearched] = useState(false);
    function checkObjectValues(obj) {
        for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                if (typeof obj[key] === "object") {
                    if (checkObjectValues(obj[key])) {
                        return true;
                    }
                } else if (obj[key] !== "") {
                    return true;
                }
            }
        }
        return false;
    }

  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8">
        <ConfirmationModal
          open={confirmationModal.status}
          setOpen={setConfirmationModal}
          onDelete={() => onDeleteItem(confirmationModal.ven_iss_trax_id)}
        />
        <Breadcrumb pages={pages} />
        {filterFlag && (
          <div className="px-2 py-2 pb-5 sm:px-4 rounded-md bg-white shadow-lg ring-1 ring-white ring-opacity-5 focus:outline-none mb-2">
            <button
              type="button"
              className="text-red-500 absolute right-20 hover:text-red-700 font-bold py-1 px-1 rounded-full border border-red-500 hidden"
              onClick={() => { setFilterFlag(false); onFilterClear(); }}
            >
              X
            </button>
            <div className="space-y-6 sm:space-y-5">
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 mt-5">
                <div className="sm:col-span-1 lg:col-span-1">
                  <label htmlFor="filterMagazineId" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Magazine
                  </label>
                  <Select
                    name="filterMagazineId"
                    id="filterMagazineId"
                    placeholder="Select Magazine *"
                    onChange={(e) => { getMagazineIssues(e); handleSelectFilterChange(e, 'filterMagazineId') }}
                    value={filterVendorTransaction.filterMagazineId}
                    options={getAllMagazine}
                    components={animatedComponents}
                    className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                  />
                </div>
                <div className="sm:col-span-1 lg:col-span-1">
                  <label htmlFor="filterMagazineIssueId" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Magazine Issues
                  </label>
                  <Select
                    name="filterMagazineIssueId"
                    id="filterMagazineIssueId"
                    placeholder="Select Magazine Issues *"
                    onChange={(e) => { handleSelectFilterChange(e, 'filterMagazineIssueId') }}
                    value={filterVendorTransaction.filterMagazineIssueId}
                    options={getAllMagazinesIssue}
                    components={animatedComponents}
                    className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                  />
                </div>
                <div className="sm:col-span-1 lg:col-span-1">
                  <label htmlFor="filterVendorName" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Vendor Name
                  </label>
                  <input
                    type="text"
                    name="filterVendorName"
                    id="filterVendorName"
                    value={filterVendorTransaction.filterVendorName}
                    onChange={handleFilterChange}
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                  />
                </div>
                <div className="sm:col-span-1 lg:col-span-1">
                  <label htmlFor="filterVendorEmail" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Vendor Email
                  </label>
                  <input
                    type="email"
                    name="filterVendorEmail"
                    id="filterVendorEmail"
                    value={filterVendorTransaction.filterVendorEmail}
                    onChange={handleFilterChange}
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                  />
                </div>
                <div className="sm:col-span-1 lg:col-span-1">
                  <label htmlFor="filterVendorMobile" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Vendor Mobile
                  </label>
                  <input
                    type="number"
                    name="filterVendorMobile"
                    id="filterVendorMobile"
                    value={filterVendorTransaction.filterVendorMobile}
                    onChange={handleFilterChange}
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                  />
                </div>
                <div className="sm:col-span-1 lg:col-span-1">
                  <label htmlFor="filterFrom" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Start Date
                  </label>
                  <input
                    type="date"
                    name="filterFrom"
                    id="filterFrom"
                    value={filterVendorTransaction.filterFrom}
                    onChange={handleFilterChange}
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                  />
                </div>
                <div className="sm:col-span-1 lg:col-span-1">
                  <label htmlFor="filterTo" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    End Date
                  </label>
                  <input
                    type="date"
                    name="filterTo"
                    id="filterTo"
                    value={filterVendorTransaction.filterTo}
                    onChange={handleFilterChange}
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                  />
                </div>

                <div className="sm:col-span-1 lg:col-span-1">
                  <label htmlFor="filterStatus" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Status
                  </label>
                  <select
                    name="filterStatus"
                    value={filterVendorTransaction.filterStatus}
                    onChange={handleFilterChange}
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                  >
                    <option value="">Select Status</option>
                    <option value="1">Active</option>
                    <option value="2">In-Active</option>
                  </select>
                </div>

                <div className="sm:col-span-1 md:col-span-2 lg:col-span-2 mt-6">
                  <button
                    type="button"
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2"
                    onClick={onFilter}
                  >
                    Search
                  </button>
                  <button
                    type="button"
                    className="inline-flex items-center justify-center ml-2 rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2"
                    onClick={onFilterClear}
                  >
                    Clear
                  </button>
                </div>
              </div>

            </div>
          </div>
        )}

        <div className="">
          <h1 className="text-xl font-semibold text-gray-900">
            Vendor Transaction
          </h1>
        </div>
        <div className="mt-4 flex">
          {allowedActions.filter((data) => data.permission_id == 70)?.length > 0 ? (<Link
            to={"/add-vendor-transaction"}
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144]  px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#BE3144] focus:ring-offset-2 ml-auto"
          >
            Add Transaction
          </Link>) : null}

          <div className="inline-flex  items-center space-x-2 hidden">
            <div className="relative">
              <button
                onClick={() => {
                  setFilterFlag(filterFlag ? false : true);
                }}
                className="relative z-0 inline-flex text-sm rounded-md shadow-sm focus:ring-accent-500 focus:border-accent-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 mr-2 ml-2">
                <span className="relative inline-flex items-center px-3 py-3 space-x-2 text-sm font-medium text-gray-600 bg-white border border-gray-300 rounded-md sm:py-2">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-3 h-3"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
                      />
                    </svg>
                  </div>
                  <div className="hidden sm:block">Filters {filterFlag ? 'On' : 'Off'}</div>
                </span>
              </button>
            </div>
          </div>
          {
            vednorTransaction && vednorTransaction.length !== 0 && isSearched && <Link
            to={"#"}
            onClick={() => handleExport('xlsx')}
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2 ml-2"
          >
            Download Excel
          </Link>
          }          
        </div>
        {!isSearched && (<p className='text-center text-xl font-semibold mt-5' style={{ color: "#872341" }}>Select Filter To Display Vendor Transaction List</p>)}
        {loading ? (
          <FallingLinesLoader />
        ) : ( isSearched &&
          <Table
            columns={vendor_tran_columns({ onStatusUpdate, onDeleteOpen, onDeleteItem, allowedActions })}
            data={vednorTransaction ? vednorTransaction : []}
          />
        )}
      </div>
    </>
  );
};

export default Masters;
