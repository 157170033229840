import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import MasterServices from "../../../ApiServices/MasterServices";
import ConfirmationModal from '../../../components/ConfirmationModal'
import Breadcrumb from '../../../components/Breadcrumb'
import { FallingLinesLoader } from '../../../components/spinners/Spinner';
import Table from '../../../components/tables/table';
import { printSubsIssues_columns } from '../../../components/tables/tableheader';
import { toast } from "react-toast";
import Select from 'react-select'
import makeAnimated from "react-select/animated";
import { getAllowedActions, checkPermissionsAndRedirect, getWeekendDate } from "../../../helper/commonHelper";
import { convertToAnotherTimezone, calculateAge, capitalizeFirstLetter } from '../../../helper/commonHelper';
import moment from 'moment';
import { utils, writeFile } from 'xlsx';
import './SubscriptionIssuse.css'
import { Dialog, Switch, Transition } from "@headlessui/react";

const PrintSubsIssues = () => {
    const [loading, setLoading] = useState(false);
    const [printSubsIssuesData, setPrintSubsIssuesData] = useState([])
    const navigate = useNavigate();
    const location = useLocation();
    const { customer_id: customer_id } = useParams();
    const pages = [{ title: "Print Subscription Issues List", href: "/print-issue", module_id: 10 }];
    const [confirmationModal, setConfirmationModal] = useState({
        status: false,
        subs_id: null,
    });
    const [custToggle, setCustToggle] = useState(false)
    const [magazineId, setMagazineId] = useState(false);


    const [allowedActions, setAllowedActions] = useState([]);
    useEffect(() => {
        const permissions = JSON.parse(localStorage.getItem("user_permissions"));
        if (permissions && window.location.pathname) {
            // window.location.pathname
            const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect(window.location.pathname, permissions, navigate);
            const actions = getAllowedActions(permissions, window.location.pathname);
            setAllowedActions(actions);
        }
    }, []);
    const handleExport = (excel_type) => {

        if (printSubsIssuesData?.length > 0) {
            let newSheetdata = [];
            printSubsIssuesData.map((obj, index) => {
                const newData = {
                    SN: parseInt(index) + 1,
                    subinm_fk_ord_id: obj?.subinm_fk_ord_id || '-',
                    subinm_fk_subs_id: obj?.subinm_fk_subs_id || '-',
                    sku_code: capitalizeFirstLetter(obj?.subinm_skus_relation?.sku_code) || '-',
                    mm_name: capitalizeFirstLetter(obj?.subinm_master_magazines_relation?.mm_name) || '-',
                    mi_name: obj?.subinm_master_magazines_relation?.magazine_issues?.length > 0 ? capitalizeFirstLetter(obj?.subinm_master_magazines_relation?.magazine_issues[0]?.mi_name) : '' || '-',
                    mm_iss_start: obj?.subinm_master_magazines_relation?.mm_interval_type == 'Weekend' ? obj?.subinm_master_magazines_relation?.magazine_issues?.length > 0 ? moment(obj?.subinm_master_magazines_relation?.magazine_issues[0]?.mi_release_date).format("DD-MM-YYYY") : moment(obj?.subinm_master_magazines_relation?.mm_iss_start).format("DD-MM-YYYY") : obj?.subinm_master_magazines_relation?.magazine_issues?.length > 0 ? moment(obj?.subinm_master_magazines_relation?.magazine_issues[0]?.mi_release_date).format("DD-MM-YYYY") : '' || '-',

                    customer_username: obj?.subinm_m_order_relation?.ord_customer_name ? capitalizeFirstLetter(obj?.subinm_m_order_relation?.ord_customer_name) : capitalizeFirstLetter(obj?.subinm_customer_relation?.customer_username) || '-',
                    customer_email: obj?.subinm_customer_relation?.customer_email || '-',
                    customer_mobile: obj?.subinm_customer_relation?.customer_mobile || '-',
                    Status: obj?.subinm_is_active == true ? 'Active' : 'In-Active' || '-',
                }
                newSheetdata.push(newData);
            });
            let currentDate = new Date();
            let fileName = 'PrintSubsIssues_Excel_Report_' + moment(currentDate).format("DD-MM-YYYY") + '.' + excel_type;

            const headings = [['SN', 'Sub ID', 'SKU`s Name', 'Magazine Name', 'Start Date', 'End Date', 'Order Id', 'Duration', 'Customer ID', 'Customer Name', 'Customer Email', 'Customer Phone', 'Customer DOB', 'Customer Age', 'Country', 'State', 'City', 'Address', 'Pincode', 'Gender', 'Occupation', 'Designation', 'Status']];
            const wb = utils.book_new();
            const ws = utils.json_to_sheet([]);
            utils.sheet_add_aoa(ws, headings);
            utils.sheet_add_json(ws, newSheetdata, { origin: 'A2', skipHeader: true });
            utils.book_append_sheet(wb, ws, 'Report');
            writeFile(wb, fileName);
        } else {
            toast.error("Data Not Found!");
        }
    }
    // Filter Values Start
    let filterInitialValues = {
        filterFrom: "",
        filterTo: "",
        filterMagazineId: { label: "", value: "" },
        filterMagazineIssueId: { label: "", value: "" },
        filterFinalize: custToggle,
    };
    let [filterPrintSubsIssues, setFilterPrintSubsIssues] = useState(filterInitialValues);
   
    let mi_finalize = false;
    const setEnabled = () => {
        filterPrintSubsIssues.filterMagazineIssueId = { label: "", value: "" };
        mi_finalize = !custToggle;
        setCustToggle(!custToggle)
        getMagazineIssues(magazineId);
    }
    const [filterFlag, setFilterFlag] = useState(true);
    const [getAllMagazine, setGetAllMagazine] = useState([])
    const [getAllMagazinesIssue, setGetAllMagazinesIssue] = useState([]);
    const animatedComponents = makeAnimated();
    // Function to handle to CLear filter values
    const onFilterClear = () => {
        setIsSearched(false)
        setPrintSubsIssuesData([]);
        filterPrintSubsIssues.filterFlag = false;
        setFilterPrintSubsIssues(filterInitialValues);
    }
    // Function to handle changes in filter values
    const handleSelectFilterChange = (selectedOption, name) => {
        const { label, value } = selectedOption || {};
        setFilterPrintSubsIssues({ ...filterPrintSubsIssues, [name]: selectedOption });
    };
    const onFilter = () => {
        if (checkObjectValues(filterPrintSubsIssues)) {
            setIsSearched(true)
            filterPrintSubsIssues.filterFlag = true;
            console.log("filterPrintSubsIssues=>", filterPrintSubsIssues)
            getAllPrintSubsIssuesData(3);
        } else {
            toast.error("Please Select Alteast One Filter!");
        }
    }
    const getMagazines = () => {
        MasterServices.getAllMagazine(1).then((res) => {
            const { data, status } = res.data
            if (status) {
                const newArray = data?.data?.map((ele) => ({
                    label: ele.mm_name,
                    value: ele.mm_id,
                }))
                setGetAllMagazine(newArray);
            }
        }).catch((err) => {
            console.log(err)
        })
    }
    const getMagazineIssues = (e) => {
        let payload = `${e}?filterFinalize=${mi_finalize}`;
        MasterServices.getAllMagazineIssues(payload).then((res) => {
            // MasterServices.getAllMagazineIssues(e.value).then((res) => {
            const { data, status } = res.data;
            if (status) {
                const newArray = data?.data?.map((ele) => ({
                    value: ele.mi_id,
                    label: ele.mi_name
                }))
                setGetAllMagazinesIssue(newArray)
            }
        }).catch((error) => {
            console.log(error)
        });
    }
    // Filter Values End

    useEffect(() => {
        getMagazines();
        if (customer_id) {
            getAllPrintSubsIssuesData(3);
            // setFilterFlag(false)
            setIsSearched(true)
        }
    }, []);
    useEffect(() => {
        if (!customer_id) {
            setFilterPrintSubsIssues(filterInitialValues);
            setIsSearched(false)
            setPrintSubsIssuesData([])
        }
    }, [location])
    const getAllPrintSubsIssuesData = async () => {
        setLoading(true)
        filterPrintSubsIssues.filterFinalize = custToggle;
        let payload = {
            status_code: 3,
            customer_id: customer_id,
            filterPrintSubsIssues: filterPrintSubsIssues,
        }
        const response = await MasterServices.getAllPrintSubsIssuesList(payload);
        console.log("response.data=>", response.data);
        if (response.data.status === true) {
            if (response?.data?.data?.length > 0) {
                let filterData = response?.data?.data;
                filterData.map((item) => {
                    if (item?.subinm_master_magazines_relation?.mm_interval_type == 'Weekend') {
                        if (item?.subinm_master_magazines_relation?.mm_iss_start) {
                            // item?.firstIssueDate ? item?.firstIssueDate : 
                            let value = item?.subinm_master_magazines_relation?.magazine_issues.length > 0 ? item?.subinm_master_magazines_relation?.magazine_issues[0]?.mi_release_date : item?.subinm_master_magazines_relation?.mm_iss_start;
                            if (value) {
                                value = moment(value).format("YYYY-MM-DD").split("-");
                            }
                            console.log("getSubsDate value=>", value)
                            let subscriptionDetails = {
                                subsMonth: {
                                    label: value[1],
                                    value: value[1]
                                },
                                subsYear: {
                                    label: value[0],
                                    value: value[0]
                                },
                                subsDay: {
                                    label: value[2],
                                    value: value[2]
                                }
                            }
                            let getSubsDate = getWeekendDate(subscriptionDetails, item?.subinm_master_magazines_relation);
                            item.subs_date = getSubsDate;
                            console.log("getSubsDate=>", getSubsDate)
                        } else {
                            let value = item?.subinm_master_magazines_relation?.magazine_issues.length > 0 ? item?.subinm_master_magazines_relation?.magazine_issues[0]?.mi_release_date : '';
                            if (value) {
                                value = moment(value).format("YYYY-MM-DD").split("-");
                            }
                            let subsDate = item?.subinm_master_magazines_relation?.magazine_issues.length > 0 ? item?.subinm_master_magazines_relation?.magazine_issues[0]?.mi_release_date : "";
                            let subscriptionDetails = {
                                subsDate: subsDate,
                                subsMonth: {
                                    label: value[1],
                                    value: value[1]
                                },
                                subsYear: {
                                    label: value[0],
                                    value: value[0]
                                },
                                subsDay: {
                                    label: value[2],
                                    value: value[2]
                                }
                            };
                            item.subs_date = subscriptionDetails;
                        }

                    } else {
                        let value = item?.subinm_master_magazines_relation?.magazine_issues.length > 0 ? item?.subinm_master_magazines_relation?.magazine_issues[0]?.mi_release_date.split("-") : '';
                        if (value) {
                            value = moment(value).format("YYYY-MM-DD").split("-");
                        }
                        let subsDate = item?.subinm_master_magazines_relation?.magazine_issues.length > 0 ? item?.subinm_master_magazines_relation?.magazine_issues[0]?.mi_release_date : "";
                        let subscriptionDetails = {
                            subsDate: subsDate,
                            subsMonth: {
                                label: value[1],
                                value: value[1]
                            },
                            subsYear: {
                                label: value[0],
                                value: value[0]
                            },
                            subsDay: {
                                label: value[2],
                                value: value[2]
                            }
                        };
                        item.subs_date = subscriptionDetails;
                    }
                });
                console.log("filterData=>", filterData)
                setPrintSubsIssuesData(filterData);
                // setPrintSubsIssuesData(response?.data?.data);
                if (customer_id) {
                    setFilterPrintSubsIssues((filterPrintSubsIssues) => ({ ...filterPrintSubsIssues, 'filterCustomerName': response.data.data[0].subs_customer_relation.customer_username }));
                }
            } else {
                setPrintSubsIssuesData([]);
            }
            setLoading(false)
        }
    };


    const [isSearched, setIsSearched] = useState(false);
    function checkObjectValues(obj) {
        for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                if (typeof obj[key] === "object") {
                    if (checkObjectValues(obj[key])) {
                        return true;
                    }
                } else if (obj[key] !== "") {
                    return true;
                }
            }
        }
        return false;
    }
    const handleUpdatePrintSubsIssues = async () => {
        let Payload = {
            printSubsIssuesData: printSubsIssuesData,
        }
        console.log("Payload=>", Payload);
        // return false;

        setLoading(true)
        let res = await MasterServices.savePrintSubsIssues(Payload)
        if (res.status == 200) {
            toast.success(res.data.message)
            setLoading(false);
            setFilterPrintSubsIssues({
                filterFrom: "",
                filterTo: "",
                filterMagazineId: { label: "", value: "" },
                filterMagazineIssueId: { label: "", value: "" },
                filterFinalize: custToggle,
            })
            // setGetAllMagazine([]);
            setGetAllMagazinesIssue([]);
            setPrintSubsIssuesData([]);
            
            
            // getAllPrintSubsIssuesData(3);
        } else {
            toast.error(res.data.message)
            setLoading(false)
        }
    }
    return (
        <div className="px-4 sm:px-6 lg:px-8">

            <Breadcrumb pages={pages} />


            {filterFlag && (
                <div className="px-2 py-2 pb-5 sm:px-4 rounded-md bg-white shadow-lg ring-1 ring-white ring-opacity-5 focus:outline-none mb-2">
                    <button
                        type="button"
                        className="text-red-500 absolute right-20 hover:text-red-700 font-bold py-1 px-1 rounded-full border border-red-500 hidden"
                        onClick={() => { setFilterFlag(false); onFilterClear(); }}
                    >
                        X
                    </button>
                    <div className="space-y-6 sm:space-y-5">
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-3 mt-5">
                            <div className="sm:col-span-1 lg:col-span-1">
                                <label htmlFor="filterMagazineId" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Magazine
                                </label>
                                <Select
                                    name="filterMagazineId"
                                    id="filterMagazineId"
                                    placeholder="Select Magazine *"
                                    onChange={(e) => {
                                        getMagazineIssues(e.value); handleSelectFilterChange(e, 'filterMagazineId')
                                        setMagazineId(e.value)
                                    }}
                                    value={filterPrintSubsIssues.filterMagazineId}
                                    options={getAllMagazine}
                                    components={animatedComponents}
                                    menuPlacement="top"
                                    classNamePrefix="select"
                                    className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                                />
                            </div>
                            <div className='sm:col-span-1 sm:col-span-1'>
                                <label htmlFor="role" className={`text-gray-700 block text-sm font-medium  mr-5`}>
                                    Finalize
                                </label>
                                <Switch
                                    disabled={!magazineId ? true : false}
                                    checked={custToggle}
                                    onChange={setEnabled}
                                    className={`${custToggle ? 'bg-blue-600' : 'bg-gray-200'} relative inline-flex h-6 w-11 items-center rounded-full`}
                                >
                                    <span className="sr-only">Existing Customer</span>
                                    <span
                                        className={`${custToggle ? 'translate-x-6' : 'translate-x-1'
                                            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                    />
                                </Switch>
                            </div>
                            <div className="sm:col-span-1 lg:col-span-1">
                                <label htmlFor="filterMagazineIssueId" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Magazine Issues
                                </label>
                                <Select
                                    name="filterMagazineIssueId"
                                    id="filterMagazineIssueId"
                                    menuPlacement="top"
                                    placeholder="Select Magazine Issues *"
                                    onChange={(e) => { handleSelectFilterChange(e, 'filterMagazineIssueId') }}
                                    value={filterPrintSubsIssues.filterMagazineIssueId}
                                    options={getAllMagazinesIssue}
                                    components={animatedComponents}
                                    classNamePrefix="select"
                                    className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                                />
                            </div>

                            <div className="sm:col-span-3 lg:col-span-2 mt-6 ml-auto">
                               {
                               (filterPrintSubsIssues?.filterMagazineId?.value && 
                               filterPrintSubsIssues?.filterMagazineIssueId?.value) ? (<><button
                                    type="button"
                                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2"
                                    onClick={onFilter}
                                >
                                    Search
                                </button></>):(<></>)}
                                <button
                                    type="button"
                                    className="inline-flex items-center justify-center ml-2 rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2"
                                    onClick={onFilterClear}
                                >
                                    Clear
                                </button>
                                {allowedActions.filter((data) => data.permission_id == 90)?.length > 0 && printSubsIssuesData?.length > 0 ? (
                                    <button
                                        type="button"
                                        className="inline-flex items-center justify-center ml-2 rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2"
                                        onClick={() => handleExport('xlsx')}
                                    >
                                        Download Excel
                                    </button>
                                ) : null}
                                {allowedActions.filter((data) => data.permission_id == 89)?.length > 0 && printSubsIssuesData?.length > 0 && !custToggle ? (
                                    <button
                                        type="button"
                                        className="inline-flex items-center justify-center ml-2 rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2"
                                        onClick={handleUpdatePrintSubsIssues}
                                    >
                                        Finalize
                                    </button>) : null}
                            </div>
                        </div>

                    </div>
                </div>
            )}

            <div className="flex justify-end mt-5">
                <div className="flex items-center space-x-2 hidden">
                    <div className="relative">
                        <button
                            onClick={() => {
                                setFilterFlag(filterFlag ? false : true);
                            }}
                            className="relative z-0 inline-flex text-sm rounded-md shadow-sm focus:ring-accent-500 focus:border-accent-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 mr-2">
                            <span className="relative inline-flex items-center px-3 py-3 space-x-2 text-sm font-medium text-gray-600 bg-white border border-gray-300 rounded-md sm:py-2">
                                <div>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="w-3 h-3"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
                                        />
                                    </svg>
                                </div>
                                <div className="hidden sm:block">Filters {filterFlag ? 'On' : 'Off'}</div>
                            </span>
                        </button>

                    </div>
                </div>

            </div>
            {!isSearched && (<p className='text-center text-xl font-semibold mt-5' style={{ color: "#872341" }}>Select Filter To Display Print Subscription Issues List</p>)}
            {loading ? (<FallingLinesLoader />) : (
                isSearched &&
                <Table
                    columns={printSubsIssues_columns({ handleUpdatePrintSubsIssues })}
                    data={printSubsIssuesData}
                    is_toggle={false}
                />)}
        </div>
    )
}

export default PrintSubsIssues