import React, { useEffect, useState, Fragment } from "react";
import Breadcrumb from "../../../components/Breadcrumb";
import { Formik } from 'formik';
import { XMarkIcon } from "@heroicons/react/24/outline";
// import ConfirmationModal from "../../../components/ConfirmationModal";
import { Link, useNavigate } from 'react-router-dom';
import { Dialog, Transition } from "@headlessui/react";
import { useFormik } from "formik";
import { magazineIssueSchema, magazineSchema } from "../../../schemas";
import { magazine_columns } from "../../../components/tables/tableheader";
import Table from "../../../components/tables/table";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import { Switch } from '@headlessui/react'
import MasterServices from "../../../ApiServices/MasterServices";
import { toast, ToastContainer } from "react-toast";
import ConfirmationModal from "../../../components/ConfirmationModal";
import Select from 'react-select'
import makeAnimated from "react-select/animated";
import { getAllowedActions, checkPermissionsAndRedirect } from "../../../helper/commonHelper";
import { FaSpinner } from "react-icons/fa";
import moment from "moment";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

// const dayInterval = []

const Magazine = () => {
  const pages = [{ title: "Magazine", href: "/magazine" }]
  const [modalOpenFlage, setmodalOpenFlage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [banner, setBanner] = useState(null);
  const [tempBanner, setTempBanner] = useState(null);
  const [base64banner, setBase64banner] = useState(null);
  const [enabled, setEnabled] = useState(true)
  const [maganizeId, setMagazineId] = useState("")

  const [allMagazines, setAllMagazines] = useState([])
  const [issueModal, setIssueModal] = useState(false)
  const [imageBaseURL, setImageBaseURL] = useState(null)
  const [singleMagazine, setSingleMagazine] = useState({})
  const [currentMagazineID, setCurrentMagazineID] = useState(0)
  const [poster, setPoster] = useState(null);
  const [selectDateInterval, setSelectDateInterval] = useState([]);
  const [selectMonthInterval, setSelectMonthInterval] = useState(null);
  const [selectWeekInterval, setSelectWeekInterval] = useState(null);
  const [selectDayInterval, setSelectDayInterval] = useState(null);
  const [tempPoster, setTempPoster] = useState(null);
  const [base64poster, setBase64poster] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    mm_id: null,
  });
  const animatedComponents = makeAnimated();
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const [allowedActions, setAllowedActions] = useState([]);
  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("user_permissions"));
    if (permissions && window.location.pathname) {
      const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect(window.location.pathname, permissions, navigate);
      const actions = getAllowedActions(permissions, window.location.pathname);
      setAllowedActions(actions);
    }
  }, []);

  const dateInterval = Array.from({ length: 31 }, (_, index) => ({
    label: `Day ${index + 1}`,
    value: index + 1,
    mm_id: 0,
    midm_id: 0
  }));

  const monthInterval = [
    {
      label: "1 Month",
      value: "1",
      mm_id: 0,
      midm_id: 0
    },
    {
      label: "2 Months",
      value: "2",
      mm_id: 0,
      midm_id: 0
    },
    {
      label: "3 Months",
      value: "3",
      mm_id: 0,
      midm_id: 0
    },
    {
      label: "6 Months",
      value: "6",
      mm_id: 0,
      midm_id: 0
    }
  ]

  const weekInterval = [
    {
      label: "Once a Week",
      value: "1",
      mm_id: 0,
      midm_id: 0
    },
    {
      label: "2 times a Week",
      value: "2",
      mm_id: 0,
      midm_id: 0
    },
    {
      label: "3 times a Week",
      value: "3",
      mm_id: 0,
      midm_id: 0
    },
    {
      label: "4 times a Week",
      value: "4",
      mm_id: 0,
      midm_id: 0
    }
  ]

  const dayInterval = [
    {
      label: "Monday",
      value: "1",
      mm_id: 0,
      midm_id: 0
    },
    {
      label: "Tuesday",
      value: "2",
      mm_id: 0,
      midm_id: 0
    },
    {
      label: "Wednesday",
      value: "3",
      mm_id: 0,
      midm_id: 0
    },
    {
      label: "Thursday",
      value: "4",
      mm_id: 0,
      midm_id: 0
    },
    {
      label: "Friday",
      value: "5",
      mm_id: 0,
      midm_id: 0
    },
    {
      label: "Saturday",
      value: "6",
      mm_id: 0,
      midm_id: 0
    },
    {
      label: "Sunday",
      value: "7",
      mm_id: 0,
      midm_id: 0
    }
  ];

  const navigate = useNavigate()
  const fileTypes = ["JPEG", "PNG", "GIF"];

  useEffect(() => {
    document.title = "Outlook | Magazine";
    getAllMagazines()
  }, []);

  const initialValues = {
    magazine_id: "",
    // magazine_gap: "",
    magazine_name: "",
    mm_lead_time: "",
    magazine_banner: "",
    magazine_desc: "",
    magazine_is_active: "",
    mm_iss_start: "",
  }

  const issueInitialValuse = {
    issue_name: "",
    issue_month: "",
    issue_date: "",
    issue_price: "",
    issue_magazine: 0,
    issue_cover: ""
  }

  const [formMagazine, setformMagazine] = useState(initialValues);
  const [formIssueMagazine, setFormIssueMagazine] = useState(issueInitialValuse);

  const handleDrawer = (type, id, obj) => {

    if ((type === "edit", id)) {
      setMagazineId(id)
      setEnabled(obj?.mm_status)
      setBase64banner(null)
      // setBanner(!obj?.mm_poster ? null : obj?.mm_poster)
      const initialValues = {
        magazine_id: id,
        // magazine_gap: obj?.mm_gap,
        magazine_name: obj?.mm_name,
        mm_code: obj?.mm_code,
        mm_lead_time: obj?.mm_lead_time,
        magazine_banner: obj?.mm_poster,
        magazine_desc: obj?.mm_description,
        // date_interval: obj?.mm_date_interval?obj?.mm_date_interval:[],
        // month_interval: obj?.mm_month_interval?obj?.mm_month_interval:{label:'',value:''},
        date_interval: obj?.intervalDay ? obj?.intervalDay : [],
        month_interval: obj?.intervalMonth ? obj?.intervalMonth : { label: '', value: '' },
        mm_week_interval: obj?.intervalWeek?.value ? obj?.intervalWeek : { label: '', value: '' },
        mm_week_day_interval: obj?.intervalWeekDay.value ? obj?.intervalWeekDay : { label: '', value: '' },
        mm_status: obj?.mm_status,
        mm_iss_start: moment(obj?.mm_iss_start).format("YYYY-MM-DD"),
      };
      // alert(JSON.stringify(obj?.intervalWeek?.value))
      // setSelectDateInterval(obj?.mm_date_interval ? obj?.mm_date_interval : [])
      // setSelectMonthInterval(obj?.mm_month_interval ? obj?.mm_month_interval : { label: '', value: '' })
      setSelectDateInterval(obj?.intervalDay ? obj?.intervalDay : [])
      setSelectMonthInterval(obj?.intervalMonth ? obj?.intervalMonth : { label: '', value: '' })
      setSelectWeekInterval(obj?.intervalWeek?.value ? obj?.intervalWeek : { label: '', value: '' })
      setSelectDayInterval(obj?.intervalWeekDay?.value ? obj?.intervalWeekDay : { label: '', value: '' })

      setformMagazine(initialValues)
      setTempBanner(imageBaseURL + obj?.mm_poster)
    } else {
      setMagazineId('')
      setBanner(null)
      setSelectDateInterval([])
      setSelectMonthInterval([])
      setSelectWeekInterval([])
      setSelectDayInterval([])
      setformMagazine(initialValues)
    }
    if (modalOpenFlage === false) {
      setmodalOpenFlage(true);
    }
  };

  const onFileChange = (e) => {
    const file = e.target.files[0];
    const fileName = file.name;

    const reader = new FileReader();
    reader.onload = (e) => {
      setBanner(file)
      setTempBanner(e.target.result)
      setBase64banner(e.target.result)
    };
    reader.readAsDataURL(file);
  };
  const getAllMagazines = () => {
    setLoading(true)
    MasterServices.getAllMagazine(3).then((res) => {
      const { data, status } = res;
      if (status) {
        let MagazineArr = []
        data?.data?.data.map((item) => {
          const matchingInterval = monthInterval.find(interval => interval.value === item.mm_month_interval);
          item.mm_month_interval = matchingInterval;

          MagazineArr.push({ ...item })
        })
        setAllMagazines(MagazineArr)
        // setAllMagazines(data?.data?.data)
        setImageBaseURL(data?.data?.image_base_url);
        console.log("magazine", data?.data?.image_base_url)
      }
      setLoading(false)
    }).catch((error) => {
      console.log(error)

    });
  }

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formMagazine,
      validationSchema: magazineSchema,
      onSubmit: async (values, action) => {
        // if (selectWeekInterval?.value && !selectDayInterval?.value) {
        //   toast.error("Weekday Interval Fields is Required!");
        //   return false;
        // }
        if (selectDayInterval?.value && !values?.mm_iss_start) {
          toast.error("Start Date Fields is Required!");
          return false;
        }
        let body = {
          mm_id: maganizeId ? maganizeId : 0,
          mm_name: values.magazine_name,
          mm_code: values.mm_code,
          mm_lead_time: values.mm_lead_time,
          // mm_gap: values.magazine_gap,
          date_interval: selectDateInterval,
          month_interval: selectMonthInterval,
          mm_week_interval: selectWeekInterval,
          mm_week_day_interval: selectDayInterval,
          mm_poster: base64banner,
          mm_description: values.magazine_desc,
          mm_status: enabled,
          mm_iss_start: values.mm_iss_start,
        };
        console.log(body);
        if (body.mm_name != undefined || body.mm_name != null || body.mm_name != "") {
          setTimeout(() => {
            setDisableSubmitButton(false)
          }, 1000)
          setDisableSubmitButton(true)
          console.log("body", body);
          MasterServices.saveMagazine(body).then((res) => {
            const { data, status, message } = res.data;
            if (status) {
              action.resetForm();
              if (modalOpenFlage === true) {
                setTempBanner(null)
                setSelectDateInterval([])
                setSelectMonthInterval([])
                setSelectWeekInterval([])
                setSelectDayInterval([])
                toast.success(message)
                getAllMagazines()
                setMagazineId('')
                setmodalOpenFlage(false);
                setDisableSubmitButton(false)
              }
            } else {
              toast.error(message)
              setDisableSubmitButton(false)
            }
          }).catch((error) => {
            console.log(error)
          });
        }
      },
    });

  const onDeleteOpen = (mm_id, mm_status) => {
    setConfirmationModal({ mm_id, status: true, mm_status });
  }

  const onDeleteItem = (mm_id) => {

    /* let payload = {
      table_name: "master_magazines",
      table_field: "mm_id",
      table_field_val: mm_id,
      table_status_field: "mm_deleted",
      table_status_val: true,
      table_text: "Magazine",
      deleted_by_key: "mm_updated_by"
    }
    MasterServices.removeMaster(payload).then((res) => {
      const {data, status, message} = res.data
      if (status) {
        getAllMagazines()
        toast.success(message);
      } else {
        toast.error(message)
      }
    })
    setConfirmationModal({ mm_id: null, status: false }); */

    MasterServices.deleteMagazine({ mm_id })
      .then((response) => {
        const { data, status } = response;
        console.log("response Prod=>", response)
        if (data.status) {
          setLoading(true)
          toast.success(data.message)
          setConfirmationModal({ mm_id: null, status: false });
          getAllMagazines()
          navigate("/magazine");
        } else {
          toast.error(data.message)
        }
        setLoading(false)
      })
      .catch((error) => {
        // const msg = error.response.data.message;
        // toast.error(msg)
        toast.error("Sorry Fatal Error!")
        setLoading(false)
      });
  }

  const onStatusUpdate = (mm_id, mm_status) => {
    let payload = {
      table_name: "master_magazines",
      table_field: "mm_id",
      table_field_val: mm_id,
      table_status_field: "mm_status",
      table_status_val: mm_status ? false : true,
      table_text: "Magazine",
      deleted_by_key: "mm_updated_by",
      delete_active_txt: mm_status ? " Deactivated" : " Activated"
    }
    MasterServices.removeMaster(payload).then((res) => {
      const { data, status, message } = res.data
      if (status) {
        getAllMagazines()
        toast.success(message);
      } else {
        toast.error(message)
      }
    })
  }

  const issueModalOpen = (id, obj) => {
    setCurrentMagazineID(id);
    setIssueModal(true)
    setSingleMagazine(obj)
  }

  const onFileChangeMagazineIssue = (e) => {
    const file = e.target.files[0];
    const fileName = file.name;

    const reader = new FileReader();
    reader.onload = (e) => {
      setPoster(file);
      setTempPoster(e.target.result);
      setBase64poster(e.target.result);
    };
    reader.readAsDataURL(file);
  };


  const handleMonthInterval = (e) => {
    setSelectDateInterval([])
    setSelectMonthInterval(e)
    setSelectWeekInterval([])
    setSelectDayInterval([])
  }
  const handleDateInterval = (e) => {
    setSelectDateInterval(e)
    setSelectMonthInterval([])
    setSelectWeekInterval([])
    setSelectDayInterval([])
  }

  const handleWeekInterval = (e) => {
    setSelectDateInterval([])
    setSelectMonthInterval([])
    setSelectWeekInterval(e)
    setSelectDayInterval([])
  }

  const handleDayInterval = (e) => {
    setSelectDateInterval([])
    setSelectMonthInterval([])
    // setSelectWeekInterval([])
    setSelectDayInterval(e)
  }


  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <ConfirmationModal
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => onDeleteItem(confirmationModal.mm_id)}
      />
      <Breadcrumb pages={pages} />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">Magazine List</h1>
        <p className="mt-2 text-sm text-gray-700">A list of all the Magazine.</p>
      </div>
      <div className="mt-4 flex">
        {allowedActions.filter((data) => data.permission_id == 9)?.length > 0 ? (<Link
          onClick={() => handleDrawer("add", "", {})}
          type="button"
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2 ml-auto"
        >
          Add Magazine
        </Link>) : null}
      </div>

      {!loading ? (
        <Table
          columns={magazine_columns({ issueModalOpen, onDeleteOpen, handleDrawer, imageBaseURL, onStatusUpdate, allowedActions })}
          data={allMagazines}
          is_toggle={false}
        />
      ) : (

        <FallingLinesLoader />
      )}

      <div>
        <Transition.Root show={modalOpenFlage} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={setmodalOpenFlage}
          >
            <div className="fixed inset-0" />
            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleSubmit(e);
                        }}
                        className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                      >
                        <div className="h-0 flex-1 overflow-y-auto">
                          <div className="bg-gradient-to-b from-[#872341] to-[#BE3144] py-6 px-4 sm:px-6">
                            <div className="flex items-center justify-between">
                              <Dialog.Title className="text-lg font-medium text-white">
                                {/* {role_id ? "Update" : "Add"} */} Magazine
                              </Dialog.Title>
                              <div className="ml-3 flex h-7 items-center">
                                <button
                                  type="button"
                                  className="rounded-md bg-gradient-to-b from-[#872341] to-[#BE3144] text-white hover:text-white"
                                  onClick={() => setmodalOpenFlage(false)}
                                >
                                  <span className="sr-only">Close panel</span>
                                  <XMarkIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-1 flex-col justify-between">
                            <div className="p-4 sm:p-6">
                              <div className="my-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="magazine_name"
                                    className="block text-sm font-medium text-gray-900 mb-1"
                                  >
                                    Name<span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.magazine_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Name"
                                    name="magazine_name"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.magazine_name &&
                                    touched.magazine_name ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.magazine_name}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                              <div className="my-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="mm_code"
                                    className="block text-sm font-medium text-gray-900 mb-1"
                                  >
                                    Code<span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.mm_code}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Code"
                                    name="mm_code"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.mm_code &&
                                    touched.mm_code ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.mm_code}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="my-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="mm_lead_time"
                                    className="block text-sm font-medium text-gray-900 mb-1"
                                  >
                                    Lead Time<span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.mm_lead_time}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="number"
                                    placeholder="Enter Lead Time"
                                    name="mm_lead_time"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.mm_lead_time &&
                                    touched.mm_lead_time ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.mm_lead_time}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              {/* Gap */}
                              <div className="my-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <div>
                                    <label className="block text-sm font-medium text-gray-900 mt-2 mb-1">
                                      Day Interval
                                    </label>
                                  </div>
                                  <Select
                                    name="mm_week_day_interval"
                                    placeholder={
                                      <span>
                                        Select Days
                                      </span>
                                    }
                                    onChange={(e) => handleDateInterval(e)}
                                    isMulti
                                    value={selectDateInterval}
                                    components={animatedComponents}
                                    options={dateInterval}
                                    classNamePrefix="select"
                                    className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                                  />
                                </div>
                              </div>
                              <div className="my-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <div>
                                    <label className="block text-sm font-medium text-gray-900 mt-2 mb-1">
                                      Month Interval
                                    </label>
                                  </div>
                                  <Select
                                    name="month_interval"
                                    placeholder={
                                      <span>
                                        Select Month
                                      </span>
                                    }
                                    // defaultValue={selectedMagazineIssue}
                                    onChange={(e) => handleMonthInterval(e)}
                                    value={selectMonthInterval}
                                    components={animatedComponents}
                                    // isDisabled={selectedMagazine ? false : true}
                                    options={monthInterval}
                                    classNamePrefix="select"
                                    className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                                  />
                                </div>
                              </div>

                              <div className="my-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <div>
                                    <label className="block text-sm font-medium text-gray-900 mt-2 mb-1">
                                      Week Interval
                                    </label>
                                  </div>
                                  <Select
                                    name="mm_week_interval"
                                    placeholder={
                                      <span>
                                        Select Week
                                      </span>
                                    }
                                    onChange={(e) => handleWeekInterval(e)}
                                    value={selectWeekInterval}
                                    components={animatedComponents}
                                    options={weekInterval}
                                    classNamePrefix="select"
                                    className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                                  />
                                </div>
                              </div>

                              {/* {selectWeekInterval?.value ? (<div className="my-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <div>
                                    <label className="block text-sm font-medium text-gray-900 mt-2 mb-1">
                                      Weekday Interval
                                    </label>
                                  </div>
                                  <Select
                                    name="weekday_interval"
                                    placeholder={
                                      <span>
                                        Select Weekday
                                      </span>
                                    }
                                    // defaultValue={selectedMagazineIssue}
                                    onChange={(e) => handleDayInterval(e)}
                                    value={selectDayInterval}
                                    components={animatedComponents}
                                    // isDisabled={selectedMagazine ? false : true}
                                    options={dayInterval}
                                    classNamePrefix="select"
                                    className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                                  />
                                </div>
                              </div>) : (<></>)} */}


                              {/* {selectWeekInterval?.value && selectDayInterval?.value ? ( */}
                              {(selectMonthInterval?.value && selectMonthInterval?.value || selectWeekInterval?.value) ? (
                                // {(selectMonthInterval?.value && selectMonthInterval?.value || selectWeekInterval?.value && selectDayInterval?.value) ? (
                                <div className="my-2 sm:col-span-2 sm:mt-0">
                                  <div>
                                    <label
                                      htmlFor="mm_iss_start"
                                      className="block text-sm font-medium text-gray-900 mb-1"
                                    >
                                      Start Date
                                    </label>
                                  </div>
                                  <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                    <input
                                      value={values.mm_iss_start}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      type="date"
                                      min={new Date().toISOString().split('T')[0]}
                                      placeholder="Date"
                                      name="mm_iss_start"
                                      autoComplete="off"
                                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-[#BE3144] sm:text-sm"
                                    />
                                    {errors.mm_iss_start &&
                                      touched.mm_iss_start ? (
                                      <p className="text-red-600 text-sm">
                                        {errors.mm_iss_start}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>) : (<></>)}
                              {/* ) : (<></>)} */}

                              <div className="my-2 sm:col-span-2 sm:mt-0">
                                <div className="basis-1/2 overflow-auto">
                                  <label htmlFor="magazine_banner" className="block text-sm font-medium text-gray-700">
                                    Banner
                                  </label>
                                  <input
                                    onChange={(e) => {
                                      handleChange(e);
                                      onFileChange(e);
                                    }}
                                    onBlur={handleBlur}
                                    type="file"
                                    name="magazine_banner"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.magazine_banner &&
                                    touched.magazine_banner ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.magazine_banner}
                                    </p>
                                  ) : null}
                                </div>
                                <div className="col-span-full">
                                  {tempBanner ? (
                                    <img
                                      className="shadow-sm mt-4 w-40"
                                      src={tempBanner}
                                      alt="preview-banner"
                                    />
                                  ) : null}
                                </div>
                              </div>
                              <div className="my-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="magazine_desc"
                                    className="block text-sm font-medium text-gray-900 mb-1"
                                  >
                                    Description
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <textarea
                                    rows={4}
                                    value={values.magazine_desc}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Description"
                                    name="magazine_desc"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {/* {errors.name &&
                                    touched.name ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.name}
                                    </p>
                                  ) : null} */}
                                </div>
                              </div>

                              <div className="my-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="magazine_is_active"
                                    className="block text-sm font-medium text-gray-900 mb-1"
                                  >
                                    Status
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <Switch
                                    checked={enabled}
                                    onChange={setEnabled}
                                    className={classNames(
                                      enabled ? 'bg-[#872341]' : 'bg-gray-200',
                                      'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2'
                                    )}
                                  >
                                    <span className="sr-only">Use setting</span>
                                    <span
                                      aria-hidden="true"
                                      className={classNames(
                                        enabled ? 'translate-x-5' : 'translate-x-0',
                                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                      )}
                                    />
                                  </Switch>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-shrink-0 justify-end px-4 py-4">
                          <button
                            type="button"
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                            onClick={() => {
                              setmodalOpenFlage(false);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gradient-to-b focus:outline-none focus:ring-2 focus:ring-[#BE3144] focus:ring-offset-2"
                            disabled={disableSubmitButton}
                          >
                            {maganizeId ? (disableSubmitButton ? <FaSpinner color="gold" /> : "Update") : (disableSubmitButton ? <FaSpinner color="gold" /> : "Add")}
                            {/* {role_id ? "Update" : "Add"} Add */}
                          </button>
                        </div>
                      </form>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <Transition.Root show={issueModal} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={setIssueModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:w-full sm:max-w-md">
                    <div className="flex items-center text-lg font-extrabold px-4 text-white py-2 bg-gradient-to-b from-[#872341] to-[#BE3144] italic justify-between">
                      <h1 className="text-lg font-medium text-white">
                        Add Issue for {singleMagazine ? singleMagazine.mm_name : null}
                      </h1>
                      <XMarkIcon
                        className="text-white h-8 cursor-pointer w-8 ml-auto"
                        aria-hidden="true"
                        onClick={() => {
                          setIssueModal(false);
                        }}
                      />
                    </div>
                    <div className="p-5">
                      <Formik
                        initialValues={formIssueMagazine}
                        validationSchema={magazineIssueSchema}
                        onSubmit={(values, { setSubmitting }) => {
                          const payload = {
                            mi_id: 0,
                            mi_status: enabled,
                            mi_fk_master_magazine_id: currentMagazineID,
                            mi_default_price: values.issue_price,
                            mi_release_date: values.issue_date,
                            mi_issue_month: values.issue_month,
                            mi_name: values.issue_name,
                            mi_cover: base64poster,
                          }
                          if (payload.mi_name != undefined || payload.mi_name != null || payload.mi_name != "") {
                            setTimeout(() => {
                              setDisableSubmitButton(false)
                            }, 1000)
                            setDisableSubmitButton(true)
                            MasterServices.saveMagazineIssue(payload).then((res) => {
                              const { data, status, message } = res.data;
                              if (status) {

                                setCurrentMagazineID(0);
                                setIssueModal(false)
                                setTempPoster(null)
                                const issueInitialValuse = {
                                  issue_name: "",
                                  issue_month: "",
                                  issue_date: "",
                                  issue_price: "",
                                  issue_magazine: 0
                                }
                                setFormIssueMagazine(issueInitialValuse);
                                toast.success(message);
                                setDisableSubmitButton(false)
                                //action.resetForm();
                              } else {
                                toast.error(message);
                                setDisableSubmitButton(false)
                              }
                            }).catch((error) => {
                              console.log(error)
                              setDisableSubmitButton(false)
                            });
                          }

                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          /* and other goodies */
                        }) => (
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();
                              handleSubmit(e);
                            }}
                          // className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                          >
                            <div className="my-2 sm:col-span-2 sm:mt-0">
                              <div>
                                <label
                                  htmlFor="issue_name"
                                  className="block text-sm font-medium text-gray-900 mb-1"
                                >
                                  Name<span className="text-red-600">*</span>
                                </label>
                              </div>
                              <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                <input
                                  value={values.issue_name}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  type="text"
                                  placeholder="Name"
                                  name="issue_name"
                                  autoComplete="off"
                                  className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-[#BE3144] sm:text-sm"
                                />
                                {errors.issue_name &&
                                  touched.issue_name ? (
                                  <p className="text-red-600 text-sm">
                                    {errors.issue_name}
                                  </p>
                                ) : null}
                              </div>
                            </div>

                            <div className="my-2 sm:col-span-2 sm:mt-0">
                              <div>
                                <label
                                  htmlFor="issue_month"
                                  className="block text-sm font-medium text-gray-900 mb-1"
                                >
                                  Issue Month<span className="text-red-600">*</span>
                                </label>
                              </div>
                              <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                <input
                                  value={values.issue_month}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  type="month"
                                  placeholder="Month"
                                  name="issue_month"
                                  autoComplete="off"
                                  className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-[#BE3144] sm:text-sm"
                                />
                                {errors.issue_month &&
                                  touched.issue_month ? (
                                  <p className="text-red-600 text-sm">
                                    {errors.issue_month}
                                  </p>
                                ) : null}
                              </div>
                            </div>

                            <div className="my-2 sm:col-span-2 sm:mt-0">
                              <div>
                                <label
                                  htmlFor="issue_date"
                                  className="block text-sm font-medium text-gray-900 mb-1"
                                >
                                  Issue Date<span className="text-red-600">*</span>
                                </label>
                              </div>
                              <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                <input
                                  value={values.issue_date}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  type="date"
                                  min={new Date().toISOString().split('T')[0]}
                                  placeholder="Date"
                                  name="issue_date"
                                  autoComplete="off"
                                  className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-[#BE3144] sm:text-sm"
                                />
                                {errors.issue_date &&
                                  touched.issue_date ? (
                                  <p className="text-red-600 text-sm">
                                    {errors.issue_date}
                                  </p>
                                ) : null}
                              </div>
                            </div>

                            <div className="my-2 sm:col-span-2 sm:mt-0">
                              <div>
                                <label
                                  htmlFor="issue_price"
                                  className="block text-sm font-medium text-gray-900 mb-1"
                                >
                                  Price<span className="text-red-600">*</span>
                                </label>
                              </div>
                              <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                <input
                                  value={values.issue_price}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  type="number"
                                  placeholder="Price"
                                  name="issue_price"
                                  autoComplete="off"
                                  className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-[#BE3144] sm:text-sm"
                                />
                                {errors.issue_price &&
                                  touched.issue_price ? (
                                  <p className="text-red-600 text-sm">
                                    {errors.issue_price}
                                  </p>
                                ) : null}
                              </div>
                            </div>
                            <div className="my-2 sm:col-span-2 sm:mt-0">
                              <div>
                                <label
                                  htmlFor="issue_cover"
                                  className="block text-sm font-medium text-gray-900 mb-1"
                                >
                                  Issue Cover<span className="text-red-600">*</span>
                                </label>
                              </div>
                              <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                {/* <label
                                  htmlFor="issue_cover"
                                  className="block text-sm font-medium text-gray-900 mb-1"
                                >Banner<span className="text-red-600">*</span></label> */}
                                <input
                                  onChange={(e) => {
                                    handleChange(e);
                                    onFileChangeMagazineIssue(e);
                                  }}
                                  onBlur={handleBlur}
                                  type="file"
                                  placeholder="Issue Cover"
                                  name="issue_cover"
                                  autoComplete="off"
                                  className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-[#BE3144] sm:text-sm"
                                />
                                {errors.issue_cover &&
                                  touched.issue_cover ? (
                                  <p className="text-red-600 text-sm">
                                    {errors.issue_cover}
                                  </p>
                                ) : null}
                              </div>
                              <div className="col-span-full">
                                {tempPoster ? (
                                  <img
                                    className="shadow-sm mt-4 w-40"
                                    src={tempPoster}
                                    alt="preview-banner"
                                  />
                                ) : null}
                              </div>
                            </div>

                            <div className="my-2 sm:col-span-2 sm:mt-0">
                              <div>
                                <label
                                  htmlFor="magazine_is_active"
                                  className="block text-sm font-medium text-gray-900 mb-1"
                                >
                                  Status
                                </label>
                              </div>
                              <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                <Switch
                                  checked={enabled}
                                  onChange={setEnabled}
                                  className={classNames(
                                    enabled ? 'bg-[#872341]' : 'bg-gray-200',
                                    'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2'
                                  )}
                                >
                                  <span className="sr-only">Use setting</span>
                                  <span
                                    aria-hidden="true"
                                    className={classNames(
                                      enabled ? 'translate-x-5' : 'translate-x-0',
                                      'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                    )}
                                  />
                                </Switch>
                              </div>
                            </div>
                            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                              <button
                                type="submit"
                                className="inline-flex w-full justify-center rounded-md bg-gradient-to-b from-[#872341] to-[#BE3144] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gradient-to-b focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#BE3144] sm:col-start-2"
                                // onClick={() => issueModalOpen(false)}
                                disabled={disableSubmitButton}
                              >

                                {(disableSubmitButton ? <FaSpinner color="gold" /> : "Add")}

                              </button>
                              <button
                                type="button"
                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                                onClick={() => setIssueModal(false)}
                              >
                                Cancel
                              </button>
                            </div>
                          </form>
                        )}
                      </Formik>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

      </div>
    </div>
  )
}

export default Magazine