import React, { useEffect, useState, Fragment } from "react";
import Breadcrumb from "../../../components/Breadcrumb";
import { Formik } from "formik";
import { XMarkIcon } from "@heroicons/react/24/outline";
// import ConfirmationModal from "../../../components/ConfirmationModal";
import { Link, useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { useFormik } from "formik";
import { vendorSchema } from "../../../schemas";
import { vendor_columns } from "../../../components/tables/tableheader";
import Table from "../../../components/tables/table";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import { Switch } from "@headlessui/react";
import makeAnimated from "react-select/animated";
import MasterServices from "../../../ApiServices/MasterServices";
import { toast, ToastContainer } from "react-toast";
import Select from "react-select";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { getAllowedActions, checkPermissionsAndRedirect } from "../../../helper/commonHelper";
import { FaSpinner } from "react-icons/fa";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Vendor = () => {
  const pages = [{ title: "Vendor", href: "/vendor-masters" }];
  const [modalOpenFlage, setmodalOpenFlage] = useState(false);
  const [loading, setLoading] = useState(false);

  const [enabled, setEnabled] = useState(true);
  const [vendorId, setvendorId] = useState("");
  const [allvendor, setAllVendor] = useState([])
  const [selectedcountry, setSelectedcountry] = useState({ value: '', label: '' });
  const [selectedState, setSelectedState] = useState({ value: '', label: '' });
  const [selectedCity, setSelectedCity] = useState({ value: '', label: '' });
  const [countryAll, setCountryAll] = useState([])
  const [stateAll, setStateAll] = useState([])
  const [cityAll, setCityAll] = useState([])

  const animatedComponents = makeAnimated();
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    vendor_id: null,
  });
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const navigate = useNavigate();
  const [allowedActions, setAllowedActions] = useState([]);
  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("user_permissions"));
    if (permissions && window.location.pathname) {
      const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect(window.location.pathname, permissions, navigate);
      const actions = getAllowedActions(permissions, window.location.pathname);
      setAllowedActions(actions);
    }
  }, []);

  useEffect(() => {
    document.title = "Outlook | Vendor";

    getAllVendors(3);
    getAllCountryList(1);
  }, []);

  const getAllCountryList = (payload) => {
    MasterServices.getAllCountry(payload).then((res) => {
      const { data, status } = res;
      if (status) {
        setCountryAll(data.data);
      }
    }).catch((error) => {
      console.log(error)
    });
  }

  const getAllStates = async (country_id) => {
    console.log("selectedCountry", selectedcountry, country_id)
    try {
      MasterServices.getStateByCountry(country_id)
        .then((res) => {
          const { data, status } = res;
          if (status) {
            let newState = data.data?.map((v) => ({
              label: v.state_name,
              value: v.state_id
            }))
            setStateAll(newState);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  // getCityByState
  const getAllCitys = async (state_id) => {
    try {
      MasterServices.getCityByState(state_id)
        .then((res) => {
          const { data, status } = res;
          if (status) {
            let newCity = data.data?.map((v) => ({
              label: v.city_name,
              value: v.city_id
            }))
            setCityAll(newCity);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const getAllVendors = async (payload) => {
    setLoading(true)
    const response = await MasterServices.getAllVendor(payload);
    console.log("response PS=>", response)
    if (response.data.status == true) {
      if (response.data.data.length > 0) {
        setAllVendor(response.data.data);
      } else {
        setAllVendor([]);
      }
      setLoading(false)
    }
  }

  const handleCountry = (e) => {
    console.log("Country", e)
    getAllStates(e?.value)
  }

  const handleState = (e) => {
    getAllCitys(e?.value)
  }

  const handleCity = (e) => {
    console.log("handleState", e)
  }

  const onStatusUpdate = (vendor_id, vendor_is_active) => {
    let payload = {
      table_name: "m_vendor",
      table_field: "vendor_id",
      table_field_val: vendor_id,
      table_status_field: "vendor_is_active",
      table_status_val: vendor_is_active ? false : true,
      table_text: "Vendor",
      deleted_by_key: "vendor_updated_by",
      delete_active_txt: vendor_is_active ? " Deactivated" : " Activated"
    }
    MasterServices.removeMaster(payload).then((res) => {
      const { data, status, message } = res.data
      if (status) {
        getAllVendors(3)
        toast.success(message);
      } else {
        toast.error(message)
      }
    })
  }

  const initialValues = {
    vendor_id: "",
    vendor_first_name: "",
    vendor_last_name: "",
    vendor_name: "",
    vendor_email: "",
    vendor_mobile: "",
    vendor_gender: "",
    vendor_dob: "",
    vendor_dob: "",
    fk_state_id: "",
    fk_city_id: "",
    fk_country_id: "",
    vendor_address: "",
    vendor_pincode: "",
    vendor_is_active: "",
  };

  const [formVendor, setformVendor] = useState(initialValues);

  const handleDrawer = (type, id, obj) => {
    console.log("obj", obj)
    if ((type === "edit", id)) {
      handleCountry({ value: obj?.m_country_relation.country_id, label: obj?.m_country_relation.country_name });
      handleState({ value: obj?.m_state_relation.state_id, label: obj?.m_state_relation.state_name });
      setvendorId(id);
      const initialValues = {
        vendor_id: obj.vendor_id,
        vendor_first_name: obj.vendor_first_name,
        vendor_last_name: obj.vendor_last_name,
        vendor_name: obj.vendor_name,
        vendor_email: obj.vendor_email,
        vendor_mobile: obj.vendor_mobile,
        vendor_gender: obj.vendor_gender,
        vendor_dob: obj.vendor_dob,
        fk_state_id: obj.fk_state_id,
        fk_city_id: obj.fk_city_id,
        fk_country_id: obj.fk_country_id,
        vendor_address: obj.vendor_address,
        vendor_pincode: obj.vendor_pincode,
        vendor_is_active: obj.vendor_is_active,
      };
      setSelectedcountry({ value: obj?.m_country_relation.country_id, label: obj?.m_country_relation.country_name })
      setSelectedState({ value: obj?.m_state_relation.state_id, label: obj?.m_state_relation.state_name })
      setSelectedCity({ value: obj?.m_city_relation.city_id, label: obj?.m_city_relation.city_name })
      // if (Array.isArray(countryAll) && countryAll.length > 0) {
      //   let country = countryAll.filter(v => v.country_id == obj.fk_country_id)
      //   handleCountry({ value: country[0].country_id, label: country[0].country_name })

      //   if (country.length > 0) {
      //     setSelectedcountry({ value: country[0].country_id, label: country[0].country_name })
      //   }
      // }
      // if (Array.isArray(stateAll) && stateAll.length > 0) {
      //   let state = stateAll.filter(v => v.value == obj.fk_state_id)
      //   handleState(state)
      //   if (state.length > 0) {
      //     setSelectedState({ value: state[0].value, label: state[0].label })
      //   }
      // }
      // if (Array.isArray(cityAll) && cityAll.length > 0) {
      //   let city = cityAll.filter(v => v.value == obj.fk_city_id)
      //   if (city.length > 0) {
      //     setSelectedCity({ value: city[0].role_id, label: city[0].label })
      //   }
      // }
      setformVendor(initialValues);
    } else {
      setvendorId("");
      setformVendor(initialValues);
    }
    if (modalOpenFlage === false) {
      setmodalOpenFlage(true);
    }
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formVendor,
      validationSchema: vendorSchema,
      onSubmit: async (values, action) => {
        let body = {
          vendor_id: vendorId ? vendorId : 0,
          vendor_first_name: values.vendor_first_name,
          vendor_last_name: values.vendor_last_name,
          vendor_name: values.vendor_name,
          vendor_email: values.vendor_email,
          vendor_mobile: values.vendor_mobile,
          vendor_gender: values.vendor_gender,
          vendor_dob: values.vendor_dob,
          fk_state_id: selectedState.value ? selectedState.value : values.fk_state_id,
          fk_city_id: selectedCity.value ? selectedCity.value : values.fk_city_id,
          fk_country_id: selectedcountry.value ? selectedcountry.value : values.fk_country_id,
          vendor_address: values.vendor_address,
          vendor_pincode: values.vendor_pincode,
          vendor_is_active: values.vendor_is_active,
        };

        if (body.vendor_name != undefined || body.vendor_name != null || body.vendor_name != "") {
          setTimeout(() => {
            setDisableSubmitButton(false)
          }, 1000)
          setDisableSubmitButton(true)
          const response = await MasterServices.saveVendor(body);

          if (response.data.status) {
            action.resetForm();
            if (modalOpenFlage === true) {
              getAllVendors(3)

              toast.success(response.data.message);
              setvendorId("");
              setDisableSubmitButton(false)
              setmodalOpenFlage(false);
            }
          } else {
            toast.error(response.data.message);
            setDisableSubmitButton(false)
          }
        }
      },
    });

  const onDeleteOpen = (vendor_id) => {
    setConfirmationModal({ vendor_id, status: true });
  };

  const onDeleteVendor = (vendor_id) => {
    MasterServices.deleteVendor({ vendor_id })
      .then((response) => {
        const { data, status } = response;
        console.log("response Prod=>", response)
        if (data.status) {
          setLoading(true)
          toast.success(data.message)
          setConfirmationModal({ vendor_id: null, status: false });
          getAllVendors()
          navigate("/vendor-masters");
        } else {
          toast.error(data.message)
        }
        setLoading(false)
      })
      .catch((error) => {
        toast.error("Sorry Fatal Error!")
        setLoading(false)
      });

  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <ConfirmationModal
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => onDeleteVendor(confirmationModal.vendor_id)}
      />
      <Breadcrumb pages={pages} />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">Vendor List</h1>
      </div>
      <div className="mt-4 mb-4 flex">
        {allowedActions.filter((data) => data.permission_id == 65)?.length > 0 ? (<Link
          onClick={() => handleDrawer("add", "", {})}
          type="button"
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2 ml-auto"
        >
          Add Vendor
        </Link>) : null}
      </div>

      {!loading ? (
        <Table
          columns={vendor_columns({ onDeleteOpen, handleDrawer, onStatusUpdate, allowedActions })}
          data={allvendor}
          is_toggle={false}
        />

      ) : (
        <FallingLinesLoader />
      )}

      <div>
        <Transition.Root show={modalOpenFlage} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={setmodalOpenFlage}
          >
            <div className="fixed inset-0" />
            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleSubmit(e);
                        }}
                        className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                      >
                        <div className="h-0 flex-1 overflow-y-auto">
                          <div className="bg-gradient-to-b from-[#872341] to-[#BE3144] py-6 px-4 sm:px-6">
                            <div className="flex items-center justify-between">
                              <Dialog.Title className="text-lg font-medium text-white">
                                {/* {role_id ? "Update" : "Add"} */} Vendor
                              </Dialog.Title>
                              <div className="ml-3 flex h-7 items-center">
                                <button
                                  type="button"
                                  className="rounded-md bg-gradient-to-b from-[#872341] to-[#BE3144] text-white hover:text-white"
                                  onClick={() => setmodalOpenFlage(false)}
                                >
                                  <span className="sr-only">Close panel</span>
                                  <XMarkIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-1 flex-col justify-between ">
                            <div className="p-4 sm:p-6 space-y-4">
                              <div className="my-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="vendor_name"
                                    className="block text-sm font-medium text-gray-900 mb-1"
                                  >
                                    Name<span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.vendor_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Name"
                                    name="vendor_name"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.vendor_name &&
                                    touched.vendor_name ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.vendor_name}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900 mt-2 mb-1"
                                  >
                                    Vendor Email
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.vendor_email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="email"
                                    placeholder="User Email"
                                    name="vendor_email"
                                    // name={`vendor_email_${uniqueId}`}
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                    disabled={vendorId ? true : false}
                                  />
                                  {errors.vendor_email && touched.vendor_email ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.vendor_email}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900 mt-2 mb-1"
                                  >
                                    Vendor Mobile
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.vendor_mobile}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="number"
                                    placeholder="Mobile"
                                    name="vendor_mobile"
                                    // name={`vendor_mobile_${uniqueId}`}
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                    disabled={vendorId ? true : false}
                                  />
                                  {errors.vendor_mobile && touched.vendor_mobile ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.vendor_mobile}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="my-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="vendor_address"
                                    className="block text-sm font-medium text-gray-900 mb-1"
                                  >
                                    Address
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <textarea
                                    rows={2}
                                    value={values.vendor_address}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Address"
                                    name="vendor_address"
                                    id="vendor_address"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.vendor_address &&
                                    touched.vendor_address ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.vendor_address}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900 mt-2 mb-1"
                                  >
                                    Pincode
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.vendor_pincode}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="number"
                                    placeholder="Pincode"
                                    name="vendor_pincode"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                    disabled={vendorId ? true : false}
                                  />
                                  {errors.vendor_pincode && touched.vendor_pincode ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.vendor_pincode}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              {countryAll && countryAll.length > 0 ? (
                                <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                  <div>
                                    <label
                                      htmlFor="project-name"
                                      className="block text-sm font-medium text-gray-900 mt-2 mb-1"
                                    >
                                      Country
                                      {/* <span className="text-red-600">*</span> */}
                                    </label>
                                  </div>
                                  <div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
                                    <Select
                                      name="fk_country_id"
                                      id="fk_country_id"
                                      menuPlacement="top"
                                      placeholder={
                                        <span>
                                          Select Country
                                          {/* <span className="text-red-600">*</span> */}
                                        </span>
                                      }
                                      value={selectedcountry}
                                      onChange={(e) => {
                                        console.log(e);
                                        values.fk_country_id = e.value;
                                        setSelectedcountry(e);
                                        handleCountry(e);
                                      }}
                                      // onBlur={handleBlur}
                                      components={animatedComponents}
                                      options={
                                        countryAll
                                          ? countryAll.map((v) => ({
                                            value: v.country_id,
                                            label: v.country_name,
                                          }))
                                          : []
                                      }
                                      classNamePrefix="select"
                                      className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                    />

                                    {errors.fk_country_id && touched.fk_country_id ? (
                                      <div className="text-sm text-red-600">
                                        {errors.fk_country_id}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              ) : null}

                              {stateAll && stateAll.length > 0 ? (
                                <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                  <div>
                                    <label
                                      htmlFor="project-name"
                                      className="block text-sm font-medium text-gray-900 mt-2 mb-1"
                                    >
                                      State
                                      {/* <span className="text-red-600">*</span> */}
                                    </label>
                                  </div>
                                  <div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
                                    <Select
                                      name="fk_state_id"
                                      id="fk_state_id"
                                      menuPlacement="top"
                                      placeholder={
                                        <span>
                                          Select State
                                          {/* <span className="text-red-600">*</span> */}
                                        </span>
                                      }
                                      value={selectedState}
                                      onChange={(e) => {
                                        console.log(e);
                                        values.fk_state_id = e.value;
                                        setSelectedState(e);
                                        handleState(e);
                                      }}
                                      // onBlur={handleBlur}
                                      components={animatedComponents}
                                      options={
                                        stateAll
                                          ? stateAll
                                          : []
                                      }
                                      classNamePrefix="select"
                                      className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                    />

                                    {errors.fk_state_id && touched.fk_state_id ? (
                                      <div className="text-sm text-red-600">
                                        {errors.fk_state_id}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              ) : null}
                              {cityAll && cityAll.length > 0 ? (
                                <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                  <div>
                                    <label
                                      htmlFor="project-name"
                                      className="block text-sm font-medium text-gray-900 mt-2 mb-1"
                                    >
                                      City
                                      {/* <span className="text-red-600">*</span> */}
                                    </label>
                                  </div>
                                  <div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
                                    <Select
                                      name="fk_city_id"
                                      id="fk_city_id"
                                      menuPlacement="top"
                                      placeholder={
                                        <span>
                                          Select City
                                          {/* <span className="text-red-600">*</span> */}
                                        </span>
                                      }
                                      value={selectedCity}
                                      onChange={(e) => {
                                        console.log(e);
                                        values.fk_city_id = e.value;
                                        setSelectedCity(e);
                                        handleCity(e);
                                      }}
                                      // onBlur={handleBlur}
                                      components={animatedComponents}
                                      options={
                                        cityAll
                                          ? cityAll
                                          : []
                                      }
                                      classNamePrefix="select"
                                      className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                    />

                                    {errors.fk_city_id && touched.fk_city_id ? (
                                      <div className="text-sm text-red-600">
                                        {errors.fk_city_id}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              ) : null}
                              <div className="my-2 sm:col-span-2 sm:mt-0 ">
                                <div>
                                  <label
                                    htmlFor="vendor_is_active"
                                    className="block text-sm font-medium text-gray-900 mb-1"
                                  >
                                    Status
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <Switch
                                    checked={enabled}
                                    onChange={setEnabled}
                                    className={classNames(
                                      enabled ? "bg-[#872341]" : "bg-gray-200",
                                      "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2"
                                    )}
                                  >
                                    <span className="sr-only">Use setting</span>
                                    <span
                                      aria-hidden="true"
                                      className={classNames(
                                        enabled
                                          ? "translate-x-5"
                                          : "translate-x-0",
                                        "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                      )}
                                    />
                                  </Switch>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-shrink-0 justify-end px-4 py-4">
                          <button
                            type="button"
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                            onClick={() => {
                              setmodalOpenFlage(false);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            disabled={disableSubmitButton}
                            type="submit"
                            className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gradient-to-b focus:outline-none focus:ring-2 focus:ring-[#BE3144] focus:ring-offset-2"
                          >
                            {vendorId ? (disableSubmitButton ? <FaSpinner color="gold" /> : "Update") : (disableSubmitButton ? <FaSpinner color="gold" /> : "Add")}

                          </button>
                        </div>
                      </form>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </div>
  );
};

export default Vendor;
