import React, { useEffect, useState } from 'react'
import Breadcrumb from '../../../components/Breadcrumb';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import Api from '../../../ApiServices/Api';
import MasterServices from '../../../ApiServices/MasterServices';
import makeAnimated from "react-select/animated";
import { useFormik } from 'formik';
import { toast } from 'react-toast';
import { customerAddressSchema } from '../../../schemas';
import { FaSpinner } from "react-icons/fa";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
const AddCustomerAddress = () => {
    const { ca_id: ca_id, customer_id: customer_id } = useParams();
    const pages = [{ title: "Add Customer Address", href: `/add-customer-address/${customer_id}/${ca_id}`, module_id: 40 }];
    const [selectedCity, setSelectedCity] = useState([])
    const [selectedState, setSelectedState] = useState([])
    const [selectedCountry, setSelectedCountry] = useState([])
    const [countryList, setCountryList] = useState([])
    const [cityList, setCityList] = useState([])
    const [stateList, setStateList] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [customer, setCustomer] = useState([])
    const [customerAddress, setCustomerAddress] = useState([])
    const [selectedCustomer, setSelectedCustomer] = useState([])
    const animatedComponents = makeAnimated();
    const navigate = useNavigate()
    const [disableSubmitButton, setDisableSubmitButton] = useState(false);

    useEffect(() => {
        getAllCountryList()
        singleCustomerAddress(ca_id)
        getSingleCustomer(customer_id)
    }, [])

    const getAllStates = async (country_id) => {
        console.log("selectedCountry", selectedCountry)
        try {
            setIsLoading(true)
            MasterServices.getStateByCountry(country_id)
                .then((res) => {
                    const { data, status } = res;
                    if (status) {
                        let newState = data.data?.map((v) => ({
                            label: v.state_name,
                            value: v.state_id
                        }))
                        setStateList(newState);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
            setIsLoading(false)
        } catch (error) {
            console.log(error);
            setIsLoading(false)
        }
    };
    // getCityByState
    const getAllCitys = async (state_id) => {
        try {
            setIsLoading(true)
            MasterServices.getCityByState(state_id)
                .then((res) => {
                    const { data, status } = res;
                    if (status) {
                        let newCity = data.data?.map((v) => ({
                            label: v.city_name,
                            value: v.city_id
                        }))
                        setCityList(newCity);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
            setIsLoading(false)
        } catch (error) {
            console.log(error);
            setIsLoading(false)
        }
    };

    const getAllCountryList = () => {
        MasterServices.getAllCountry(1)
            .then((res) => {
                const { data, status } = res;
                if (status) {
                    setCountryList(data.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };



    const getSingleCustomer = (customer_id) => {
        setIsLoading(true)
        MasterServices.getSingleCustomer(customer_id).then((res) => {
            const { data, status } = res;
            if (status) {
                let newCustomer = {
                    label: data?.data?.customer_username,
                    value: data?.data?.customer_id
                }
                setCustomer([newCustomer])
            }
            setIsLoading(false)
        }).catch((error) => {
            console.log(error)
            setIsLoading(false)

        });
    }

    const handleCountry = (e) => {
        setSelectedCountry(e)
        console.log("handleCountry", e)
        getAllStates(e?.country_id)
    }

    const handleState = (e) => {
        setSelectedState(e)
        console.log("handleState", e)
        getAllCitys(e?.value)
    }

    const handleCity = (e) => {
        setSelectedCity(e)
        console.log("handleState", e)
    }

    const initialValues = {
        fk_customer_id: customer_id.toString(),
        ca_id: null,
        customer_name: customer,
        fk_state_id: "",
        fk_city_id: "",
        ca_address: "",
        fk_country_id: "",
        ca_landmark: "",
        pincode: "",
        city_name: "",
        state_name: "",
        country_name: "",
    }
    const [customerAddressForm, setCustomerAddressForm] = useState(initialValues)

    const singleCustomerAddress = (ca_id) => {
        setIsLoading(true)
        MasterServices.getSingleCustomerAddress(ca_id).then((res) => {
            const { data, status } = res;
            if (status) {
                setSelectedCountry({
                    label: data?.data?.country_name,
                    value: data?.data?.fk_country_id,
                });
                setSelectedCity({
                    label: data?.data?.city_name,
                    value: data?.data?.fk_city_id,
                })
                setSelectedState({
                    label: data?.data?.state_name,
                    value: data?.data?.fk_state_id,
                })
                let editValues = {
                    fk_customer_id: data?.data?.fk_customer_id,
                    ca_id: data?.data?.ca_id,
                    customer_name: customer,
                    fk_state_id: {
                        label: data?.data?.state_name,
                        value: data?.data?.fk_state_id,
                    },
                    fk_city_id: {
                        label: data?.data?.city_name,
                        value: data?.data?.fk_city_id,
                    },
                    fk_country_id: {
                        label: data?.data?.country_name,
                        value: data?.data?.fk_country_id,
                    },
                    ca_address: data?.data?.ca_address,
                    ca_landmark: data?.data?.ca_landmark,
                    pincode: data?.data?.pincode,
                    city_name: data?.data?.city_name,
                    state_name: data?.data?.state_name,
                    country_name: data?.data?.country_name,
                }
                setCustomerAddressForm(editValues)

            }
            setIsLoading(false)
        }).catch((error) => {
            console.log(error)

        });
    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
            enableReinitialize: true,
            initialValues: customerAddressForm,
            validationSchema: customerAddressSchema,
            onSubmit: async (values, action) => {
                let body = {
                    ca_id: ca_id ? ca_id : null,
                    fk_customer_id: customer_id ? parseInt(customer_id) : null,
                    ca_address: values.ca_address,
                    fk_state_id: selectedState?.value,
                    fk_city_id: selectedCity?.value,
                    fk_country_id: selectedCountry?.value,
                    ca_landmark: values.ca_landmark,
                    pincode: values.pincode.toString(),
                    city_name: selectedCity?.label,
                    state_name: selectedState?.label,
                    country_name: selectedCountry?.label,
                };

                if (selectedCountry.length <= 0) {
                    toast.error("Please Select Country");
                    return false;
                }
                if (selectedState.length <= 0) {
                    toast.error("Please Select State");
                    return false;
                }
                if (selectedCity.length <= 0) {
                    toast.error("Please Select City");
                    return false;
                }
                setTimeout(() => {
                    setDisableSubmitButton(false)
                }, 1000)
                setDisableSubmitButton(true)
                const response = await MasterServices.saveCustomerAddress(body);
                if (response.data.status) {
                    action.resetForm();
                    setCustomerAddressForm(initialValues);
                    toast.success(response.data.message);
                    setDisableSubmitButton(false)
                    navigate(`/customer-address/${customer_id}`);
                } else {
                    toast.error(response.data.message);
                    setDisableSubmitButton(false)
                }
            },
        });

    return (
        <>
            {!isLoading ? (
                <div>
                    <Breadcrumb pages={pages} />
                    <div className="">
                        <h1 className="text-xl font-semibold text-gray-900">Add Customer Address</h1>
                    </div>
                    <div>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleSubmit(e);
                            }}
                        >
                            <div className="grid grid-cols-3 gap-3 mt-3 lg:w-full w-full">
                                <div>
                                    <label htmlFor="customer_name" className="block text-sm font-medium leading-6 text-gray-900">
                                        Customer Name<span className="text-red-600">*</span>
                                    </label>
                                    <div className="">
                                        <Select
                                            name="fk_country_id"
                                            placeholder={
                                                <span>
                                                    Select Customer
                                                    <span className="text-red-600">*</span>
                                                </span>
                                            }
                                            onChange={setSelectedCustomer}
                                            value={customer}
                                            isDisabled
                                            components={animatedComponents}
                                            options={customer}
                                            classNamePrefix="select"
                                            className="basic-multi-select block w-full max-w-lg rounded-md border-[1px]  shadow-sm  focus:border-red-500 sm:max-w-xs sm:text-sm"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="fk_country_id" className="block text-sm font-medium leading-6 text-gray-900">
                                        Country
                                    </label>
                                    <div className="">
                                        <Select
                                            name="fk_country_id"
                                            placeholder={
                                                <span>
                                                    Select Country
                                                    <span className="text-red-600">*</span>
                                                </span>
                                            }
                                            onChange={(e) => handleCountry(e)}
                                            value={selectedCountry}
                                            components={animatedComponents}
                                            options={countryList}
                                            classNamePrefix="select"
                                            className="basic-multi-select block w-full max-w-lg rounded-md border-[1px]  shadow-sm  focus:border-red-500 sm:max-w-xs sm:text-sm"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="fk_state_id" className="block text-sm font-medium leading-6 text-gray-900">
                                        State
                                    </label>
                                    <div className="">
                                        <Select
                                            name="fk_state_id"
                                            placeholder={
                                                <span>
                                                    Select State
                                                    <span className="text-red-600">*</span>
                                                </span>
                                            }
                                            onChange={(e) => handleState(e)}
                                            value={selectedState}
                                            components={animatedComponents}
                                            isDisabled={selectedCountry?.value ? false : true}
                                            options={stateList}
                                            classNamePrefix="select"
                                            className="basic-multi-select block w-full max-w-lg rounded-md border-[1px]  shadow-sm  focus:border-red-500 sm:max-w-xs sm:text-sm"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="fk_city_id" className="block text-sm font-medium leading-6 text-gray-900">
                                        City
                                    </label>
                                    <div className="">
                                        <Select
                                            name="fk_city_id"
                                            placeholder={
                                                <span>
                                                    Select City
                                                    <span className="text-red-600">*</span>
                                                </span>
                                            }
                                            onChange={(e) => handleCity(e)}
                                            value={selectedCity}
                                            components={animatedComponents}
                                            isDisabled={selectedState?.value ? false : true}
                                            options={cityList}
                                            classNamePrefix="select"
                                            className="basic-multi-select block w-full max-w-lg rounded-md border-[1px]  shadow-sm  focus:border-red-500 sm:max-w-xs sm:text-sm"
                                        />
                                    </div>
                                </div>

                                {/* <div>
                            <label htmlFor="country_name" className="block text-sm font-medium leading-6 text-gray-900">
                                Country name
                            </label>
                            <div className="">
                                <input
                                    type="text"
                                    name="country_name"
                                    id="country_name"
                                    value={values.country_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ed1b24] sm:text-sm sm:leading-6"
                                    placeholder="Customer Address"
                                />
                                {errors.country_name && touched.country_name ? (
                                    <div className="text-sm text-red-600">
                                        {errors.country_name}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <div>
                            <label htmlFor="state_name" className="block text-sm font-medium leading-6 text-gray-900">
                                State Name
                            </label>
                            <div className="">
                                <input
                                    type="text"
                                    name="state_name"
                                    id="state_name"
                                    value={values.state_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ed1b24] sm:text-sm sm:leading-6"
                                    placeholder="Customer Address"
                                />
                                {errors.state_name && touched.state_name ? (
                                    <div className="text-sm text-red-600">
                                        {errors.state_name}
                                    </div>
                                ) : null}
                            </div>
                        </div>

                        <div>
                            <label htmlFor="city_name" className="block text-sm font-medium leading-6 text-gray-900">
                                City Name
                            </label>
                            <div className="">
                                <input
                                    type="text"
                                    name="city_name"
                                    id="city_name"
                                    value={values.city_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ed1b24] sm:text-sm sm:leading-6"
                                    placeholder="Customer Address"
                                />
                                {errors.city_name && touched.city_name ? (
                                    <div className="text-sm text-red-600">
                                        {errors.city_name}
                                    </div>
                                ) : null}
                            </div>
                        </div> */}

                                <div>
                                    <label htmlFor="ca_address" className="block text-sm font-medium leading-6 text-gray-900">
                                        Customer Address<span className="text-red-600">*</span>
                                    </label>
                                    <div className="">
                                        <input
                                            type="text"
                                            name="ca_address"
                                            id="ca_address"
                                            value={values.ca_address}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ed1b24] sm:text-sm sm:leading-6"
                                            placeholder="Customer Address"
                                        />
                                        {errors.ca_address && touched.ca_address ? (
                                            <div className="text-sm text-red-600">
                                                {errors.ca_address}
                                            </div>
                                        ) : null}
                                    </div>

                                </div>
                                <div>
                                    <label htmlFor="ca_landmark" className="block text-sm font-medium leading-6 text-gray-900">
                                        Customer Landmark<span className="text-red-600">*</span>
                                    </label>
                                    <div className="">
                                        <input
                                            type="text"
                                            name="ca_landmark"
                                            id="ca_landmark"
                                            value={values.ca_landmark}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ed1b24] sm:text-sm sm:leading-6"
                                            placeholder="Customer Landmark"
                                        />
                                        {errors.ca_landmark && touched.ca_landmark ? (
                                            <div className="text-sm text-red-600">
                                                {errors.ca_landmark}
                                            </div>
                                        ) : null}
                                    </div>

                                </div>
                                <div>
                                    <label htmlFor="pincode" className="block text-sm font-medium leading-6 text-gray-900">
                                        Pincode<span className="text-red-600">*</span>
                                    </label>
                                    <div className="">
                                        <input
                                            type="number"
                                            name="pincode"
                                            id="pincode"
                                            value={values.pincode}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ed1b24] sm:text-sm sm:leading-6"
                                            placeholder="Customer Pincode"
                                        />
                                        {errors.pincode && touched.pincode ? (
                                            <div className="text-sm text-red-600">
                                                {errors.pincode}
                                            </div>
                                        ) : null}
                                    </div>

                                </div>
                            </div>
                            <div className="mt-6 flex items-center justify-end gap-x-6">
                                {/* <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
                            Cancel
                        </button> */}
                                <button
                                    disabled={disableSubmitButton}
                                    type="submit"
                                    className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                >
                                    {ca_id ? (disableSubmitButton ? <FaSpinner color="gold" /> : "Update") : (disableSubmitButton ? <FaSpinner color="gold" /> : "Save")}
                                    {/* Save */}
                                </button>
                            </div>
                        </form>
                    </div>

                </div>) : (

                <FallingLinesLoader />
            )}</>
    )
}

export default AddCustomerAddress