import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../components/Breadcrumb";
import Table from "../../../components/tables/table";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Select from "react-select";
import MasterServices from "../../../ApiServices/MasterServices";
import { useNavigate, useParams } from "react-router-dom";
import { FaSpinner } from "react-icons/fa";

const AddVendorTransaction = () => {

  const { vendor_transaction_id: transaction_id } = useParams();
  const pages = [
    { title: transaction_id > 0 ? "Update Transaction List" : "Add Transaction List", href: "/add-vendor-transaction" },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const navigate = useNavigate();

  const [transactions, setTransactions] = useState([]);
  console.log(transactions);

  const [selectedDiscountType, setSelectedDiscountType] = useState(null);

  const [discountValue, setDiscountValue] = useState("");

  const [allMagazines, setallMagazines] = useState([]);

  const [allMagazinesIssue, setAllMagazinesIssue] = useState([]);
  // console.log(allMagazinesIssue);
  console.log(allMagazines);

  const [loading, setLoading] = useState(false);

  const [allvendor, setAllVendor] = useState([]);

  const [selectedVendor, setSelectedVendor] = useState(null);

  const [discountedAmount, setdiscountedAmount] = useState(null);

  const [formErrors, setFormErrors] = useState({});
  console.log(formErrors);

  const [isSubmitAttempted, setIsSubmitAttempted] = useState(false);

  console.log(discountedAmount);

  // console.log(transaction_id);

  const [ven_iss_trax_id, setven_iss_trax_id] = useState(null);

  useEffect(() => {
    getAllMagazine();
    getAllMagazinesIssue();
    getAllVendors(1);
    if (transaction_id) {
      getSingleTransaction(transaction_id);
    } else {
      setTransactions([
        {
          id: 1,
          ven_iss_id: 0,
          magazine: "",
          magazineIssue: "",
          quantity: 1,
          defaultPrice: "",
          price: 0,
        },
      ]);
    }
  }, [transaction_id]);

  const addTransaction = () => {
    const newTransaction = {
      id: transactions.length + 1,
      magazine: "",
      magazineIssue: "",
      quantity: 1,
      defaultPrice: "",
      price: 0,
      ven_iss_id: 0,
    };

    setTransactions([...transactions, newTransaction]);

    getAllMagazinesIssue();
    // if (allMagazinesIssue.length === 0) {
    //   getAllVendors();
    // }
  };

  const quantityOptions = Array.from({ length: 100 }, (_, i) => ({
    value: i + 1,
    label: `${i + 1}`,
  }));

  const handleQuantityChange = (id, option) => {
    const updatedTransactions = transactions.map((transaction) => {
      if (transaction.id === id) {
        return {
          ...transaction,
          quantity: option.value,
          price: option.value * transaction.defaultPrice,
        };
      }
      return transaction;
    });

    setTransactions(updatedTransactions);
  };

  const discountDataType = [
    { value: "FIXED", label: "Fixed Discount" },
    { value: "PERCENTAGE", label: "Percentage Discount" },
  ];

  const totalPrice = transactions.reduce(
    (acc, current) => acc + Number(current.price),
    0
  );

  const calculatedDiscountAmount = selectedDiscountType
    ? selectedDiscountType.value === "FIXED"
      ? parseFloat(discountValue)
      : (parseFloat(discountValue) / 100) * totalPrice
    : 0;

  const grandTotal = totalPrice - calculatedDiscountAmount;

  const getAllMagazine = () => {
    setLoading(true);
    MasterServices.getAllMagazine(3)
      .then((res) => {
        const { data, status } = res;
        if (status) {
          console.log(data.data.data);
          setallMagazines(data.data.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  const getAllMagazinesIssue = () => {
    setLoading(true);
    MasterServices.getAllMagazineIssuesData(3)
      .then((res) => {
        const { data, status } = res;
        if (status) {
          console.log(data.data);
          setAllMagazinesIssue(data.data.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const magazineOptions = allMagazines.map((magazine) => ({
    label: magazine.mm_name,
    value: magazine.mm_id,
  }));

  const magazineIssueOptions = allMagazinesIssue.map((issue) => ({
    label: issue.mi_name,
    value: issue.mi_id,
    defaultPrice: parseFloat(issue.mi_default_price),
    mi_fk_master_magazine_id: issue.mi_fk_master_magazine_id,
  }));

  console.log(magazineIssueOptions);


  const handlemagazineChange = (id, selectedOption) => {
    const updatedTransactions = transactions.map((transaction) => {
      if (transaction.id === id) {
        return {
          ...transaction,
          magazine: selectedOption.value,
        };
      }
      return transaction;
    });
    setTransactions(updatedTransactions);
    validateField("magazine", selectedOption ? selectedOption.value : null, id);
    // console.log(updatedTransactions);
  };

  const handlemagazineIssueChange = (id, selectedOption) => {
    const updatedTransactions = transactions.map((transaction) => {
      if (transaction.id === id) {
        return {
          ...transaction,
          magazineIssue: selectedOption.value,
          defaultPrice: selectedOption.defaultPrice,
          price: selectedOption.defaultPrice * transaction.quantity,
        };
      }
      return transaction;
    });

    setTransactions(updatedTransactions);
    validateField("magazineIssue", selectedOption ? selectedOption.value : null, id);
  };

  const getFilteredMagazineIssueOptions = (currentTransactionId) => {
    const currentTransaction = transactions.find(
      (t) => t.id === currentTransactionId
    );

    // if (!currentTransaction) return [];
    console.log(transactions);
    const selectedIssueIds = transactions
      .filter((transaction) => transaction.id !== currentTransactionId)
      .map((transaction) => transaction.magazineIssue);

    console.log(selectedIssueIds);

    return magazineIssueOptions.filter(
      (issue) =>
        issue.mi_fk_master_magazine_id === currentTransaction.magazine &&
        !selectedIssueIds.includes(issue.value)
    );
  };

  const getAllVendors = async (payload) => {
    setLoading(true);
    const response = await MasterServices.getAllVendor(payload);
    // console.log(response);
    if (response.data.status == true) {
      if (response.data.data.length > 0) {
        // console.log(response.data.data);
        setAllVendor(response.data.data);
      } else {
        setAllVendor([]);
      }
      setLoading(false);
    }
  };

  const vendorOptions = allvendor.map((vendor) => ({
    label: vendor.vendor_name,
    value: vendor.vendor_id,
  }));

  const handleVendorChange = (selectedOption) => {
    setSelectedVendor(selectedOption);
    validateField("selectedVendor", selectedOption ? selectedOption.value : null);
    // console.log(selectedOption);
  };
  
  const handleDiscountTypeChange = (selectedOption)=>{
    setSelectedDiscountType(selectedOption);
    validateField("discount", selectedOption ? selectedOption.value : null);
    console.log(selectedDiscountType);
  }

  const removeTransaction = (transactionId) => {
    setTransactions((currentTransactions) =>
      currentTransactions.filter(
        (transaction) => transaction.id !== transactionId
      )
    );
  };

  const validateField = (fieldName, value, transactionId = null) => {
    let errors = { ...formErrors };

    switch (fieldName) {
      case "magazine":
        if (value) {
          delete errors[`transaction_${transactionId}_magazine`];
        } else {
          errors[`transaction_${transactionId}_magazine`] = "Magazine must be selected.";
        }
        break;

      case "magazineIssue":
        if (value) {
          delete errors[`transaction_${transactionId}_magazineIssue`];
        } else {
          errors[`transaction_${transactionId}_magazineIssue`] = "Magazine issue must be selected.";
        }
        break;

      case "selectedVendor":
        if (value) {
          delete errors["vendor"];
        } else {
          errors["vendor"] = "Vendor must be selected.";
        }
        break;

      case "discount":
        if (value) {
          delete errors["discount"];
        } else {
          errors["discount"] = "Discount type must be selected.";
        }
        break;


    }

    setFormErrors(errors);
  };

  const validateForm = () => {
    let validationErrors = {};

    if (!selectedVendor) {
      validationErrors["vendor"] = "Vendor must be selected.";
    }

    if (transactions.length === 0) {
      validationErrors["transactions"] = "At least one transaction must be added.";
    }

    transactions.forEach((transaction) => {
      if (!transaction.magazine) {
        validationErrors[`transaction_${transaction.id}_magazine`] = "Magazine must be selected.";
      }

      if (!transaction.magazineIssue) {
        validationErrors[`transaction_${transaction.id}_magazineIssue`] = "Magazine issue must be selected.";
      }

      if (transaction.quantity <= 0) {
        validationErrors[`transaction_${transaction.id}_quantity`] = "Quantity must be greater than 0.";
      }
    });

    if (!selectedDiscountType || !discountValue) {
      validationErrors["discount"] = "Discount type must be selected.";
    }

    return validationErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setFormErrors(validationErrors);
      setIsSubmitAttempted(true);
      console.error("Validation errors:", validationErrors);
      return;
    }

    setFormErrors({});
    setIsSubmitAttempted(false);

    createPayload();
  };

  const createPayload = () => {
    const transactionsPayload = transactions.map((transaction) => ({
      ven_iss_price: transaction.defaultPrice,
      ven_iss_qty: transaction.quantity,
      ven_iss_qty_price: transaction.price,
      ven_iss_fk_mi_id: transaction.magazineIssue,
      ven_iss_fk_mm_id: transaction.magazine,
    }));

    const payload = {
      ven_iss_trax_id: ven_iss_trax_id,
      fk_vendor_id: selectedVendor.value,
      ven_iss_trax_det: transactionsPayload,
      discount_type: selectedDiscountType ? selectedDiscountType.value : null,
      ven_iss_trax_tot_disc: calculatedDiscountAmount,
      ven_iss_trax_disc: parseFloat(discountValue),
      ven_iss_trax_sub_tot: parseFloat(totalPrice),
      ven_iss_trax_grand_tot: parseFloat(grandTotal.toFixed(2)),
      ven_iss_fk_mi_id: transactions.map((t) => t.magazineIssue),
      // These seem to be placeholders, consider what values they should have
      // ven_iss_price: ,
      // ven_iss_qty: ,
      // ven_iss_qty_price: ,
    };

    // console.log(payload);
    setTimeout(() => {
      setDisableSubmitButton(false)
    }, 1000)
    setDisableSubmitButton(true)
    MasterServices.saveVendorTransaction(payload).then((res) => {
      const { data, status, message } = res.data;
      if (status) {
        // console.log(data);
        setDisableSubmitButton(false)
        navigate("/vendor-transaction");
      }
      setDisableSubmitButton(false)
    });
  };

  const getSingleTransaction = async (transaction_id) => {
    setIsLoading(true);
    setTransactions([]);
    // setven_iss_trax_id(transaction_id);
    // console.log(parseInt(transaction_id, 10));

    MasterServices.getAllVendorIssuesTransaction({
      fk_ven_iss_trax_id: transaction_id,
      search: 1,
    })
      .then(async (res) => {
        const { data, status } = res.data;
        console.log(data);
        if (status) {
          // console.log(data);
          // console.log(data.map(item => item.m_vendor_relation.vendor_id));

          setven_iss_trax_id(transaction_id);
          // console.log(ven_iss_trax_id);
          const fetchedVendor = {
            vendor_id: data[0].m_vendor_relation.vendor_id,
            vendor_name: data[0].m_vendor_relation.vendor_name,
          };
          setAllVendor([fetchedVendor]);

          setSelectedVendor({
            value: fetchedVendor.vendor_id,
            label: fetchedVendor.vendor_name,
          });

          // console.log(data.map(item =>item.ven_iss_magazine_issues_relation));
          // data[0].ven_iss_magazine_issues_relation
          setAllMagazinesIssue(
            data.map((item) => item.ven_iss_magazine_issues_relation)
          );
          // console.log(allMagazinesIssue);

          const currentDiscountType =
            data[0].m_vendor_issues_trans_relation.discount_type;
          console.log(currentDiscountType);
          const selectedDiscountOption = discountDataType.find(
            (option) => option.value === currentDiscountType
          );
          setSelectedDiscountType(selectedDiscountOption);

          data.forEach((item) => addTransaction(item));

          // console.log(data.forEach(item =>item.ven_iss_magazine_issues_relation.mi_id));
          // addTransaction(data[0]);

          function addTransaction(data) {
            const transactionItem = {
              magazine: data.ven_iss_fk_mm_id,
              id: data.ven_iss_id,
              magazineIssue: data.ven_iss_magazine_issues_relation.mi_id,
              quantity: data.ven_iss_qty,
              defaultPrice: data.ven_iss_price,
              price: data.ven_iss_qty_price,
              ven_iss_id: data.ven_iss_id,
            };

            // console.log(transactionItem.magazineIssue)
            setTransactions((prevTransactions) => [
              ...prevTransactions,
              transactionItem,
            ]);
          }
          let calculatedDiscountAmount = 0; // Initialize to 0

          console.log(selectedDiscountType);

          if (
            data[0].m_vendor_issues_trans_relation.discount_type === "FIXED"
          ) {
            calculatedDiscountAmount =
              parseFloat(
                data[0].m_vendor_issues_trans_relation.ven_iss_trax_sub_tot
              ) -
              parseFloat(
                data[0].m_vendor_issues_trans_relation.ven_iss_trax_grand_tot
              );
            console.log(calculatedDiscountAmount);
            setDiscountValue(calculatedDiscountAmount);
            console.log(discountValue);
          } else if (
            data[0].m_vendor_issues_trans_relation.discount_type ===
            "PERCENTAGE"
          ) {
            const discountPercentage =
              (parseFloat(
                data[0].m_vendor_issues_trans_relation.ven_iss_trax_tot_disc
              ) /
                parseFloat(
                  data[0].m_vendor_issues_trans_relation.ven_iss_trax_sub_tot
                )) *
              100;
            console.log(discountPercentage);
            setDiscountValue(discountPercentage);
            console.log(discountValue);
          } else {
            calculatedDiscountAmount = parseFloat(discountValue);
            setDiscountValue(calculatedDiscountAmount);
          }

          console.log(calculatedDiscountAmount);

          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <Breadcrumb pages={pages} />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">{transaction_id > 0 ? "Update" : "Add"} Transaction</h1>

        <div className="mt-4 flex">
          <div>
            <Select
              isDisabled={transaction_id > 0 ? true : false}
              name="vendor_name"
              value={selectedVendor}
              options={vendorOptions}
              className="basic-single w-56"
              classNamePrefix="select"
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              }}
              onChange={handleVendorChange}
            />
            {formErrors.vendor && (
              <span className="text-red-600">{formErrors.vendor}</span>
            )}
          </div>

          <button
            onClick={addTransaction}
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144]  px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#BE3144] focus:ring-offset-2 ml-auto"
          >
            Add
          </button>
        </div>

        {isLoading ? (
          <FallingLinesLoader />
        ) : (
          <div className={`mt-4 flex flex-col `}>
            <div className="my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block  min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden  border border-gray-200 sm:rounded-lg">
                  <table className=" table-auto w-full">
                    <thead className="bg-gray-50">
                      <tr className=" ">
                        <th
                          scope="col"
                          className="   group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                        >
                          sr
                        </th>
                        <th
                          scope="col"
                          className="  group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                        >
                          Magazine
                        </th>
                        <th
                          scope="col"
                          className="  group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                        >
                          Magazine Issue
                        </th>
                        <th
                          scope="col"
                          className=" group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                        >
                          Quantity
                        </th>
                        <th
                          scope="col"
                          className="  group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                        >
                          Price
                        </th>
                        <th
                          scope="col"
                          className="group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {transactions.map((transaction) => (
                        <tr key={transaction.id}>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                            {transaction.id}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            <Select
                              isDisabled={transaction?.ven_iss_id > 0 ? true : false}
                              name="magazines"
                              value={
                                magazineOptions.find(
                                  (options) =>
                                    options.value === transaction.magazine
                                ) || null
                              }
                              // placeholder={
                              //   <span>
                              //     Select Magazine
                              //     <span className="text-red-600">*</span>
                              //   </span>
                              // }
                              // defaultValue={selectedMagazine}
                              // onChange={(e) => handleMagazine(e, "BUNDLE", index)}
                              // components={animatedComponents}
                              // value={item.selectedMagazine}
                              onChange={(option) =>
                                handlemagazineChange(transaction.id, option)
                              }
                              options={magazineOptions}
                              classNamePrefix="select"
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                              }}
                            />
                            {formErrors[
                              `transaction_${transaction.id}_magazine`
                            ] && (
                                <span className="text-red-600">
                                  {
                                    formErrors[
                                    `transaction_${transaction.id}_magazine`
                                    ]
                                  }
                                </span>
                              )}
                          </td>
                          <td className="px-6  py-4 whitespace-nowrap text-sm text-gray-500">
                            <Select
                              isDisabled={transaction?.ven_iss_id > 0 ? true : false}
                              name="magazineIssue"
                              value={
                                magazineIssueOptions.find(
                                  (option) =>
                                    option.value === transaction.magazineIssue
                                ) || null
                              }
                              onChange={(option) =>
                                handlemagazineIssueChange(
                                  transaction.id,
                                  option
                                )
                              }
                              options={getFilteredMagazineIssueOptions(
                                transaction.id
                              )}
                              classNamePrefix="select"
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                              }}
                            />
                            {formErrors[
                              `transaction_${transaction.id}_magazineIssue`
                            ] && (
                                <span className="text-red-600">
                                  {
                                    formErrors[
                                    `transaction_${transaction.id}_magazineIssue`
                                    ]
                                  }
                                </span>
                              )}
                          </td>
                          <td className="px-6 py-4 relative whitespace-nowrap text-sm text-gray-500">
                            <Select
                              isDisabled={transaction?.ven_iss_id > 0 ? true : false}
                              name="quantity"
                              value={{
                                label: transaction.quantity.toString(),
                                value: transaction.quantity,
                              }}
                              onChange={(option) =>
                                handleQuantityChange(transaction.id, option)
                              }
                              options={quantityOptions}
                              className="basic-single"
                              classNamePrefix="select"
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                              }}
                            />
                            {formErrors[
                              `transaction_${transaction.id}_quantity`
                            ] && (
                                <span className="text-red-600">
                                  {
                                    formErrors[
                                    `transaction_${transaction.id}_quantity`
                                    ]
                                  }
                                </span>
                              )}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {transaction.price} Rs
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {transaction.ven_iss_id <= 0 ? (<button
                              onClick={() => removeTransaction(transaction.id)}
                              className="text-red-500 hover:text-red-700"
                            >
                              Remove
                            </button>) : (<></>)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="mt-2 flex items-center justify-between gap-x-6">
        <div>
          <label className="sm:text-sm text-black whitespace-nowrap">
            Discount Type
          </label>
          <div>
            <Select
              isDisabled={transaction_id > 0 ? true : false}
              name="discount"
              placeholder={
                <span>
                  Select Discount Type
                  <span className="text-red-600">*</span>
                </span>
              }
              options={discountDataType}
              classNamePrefix="select"
              className="basic-multi-select block w-full max-w-lg rounded-md border-[1px] shadow-sm focus:border-red-500 sm:max-w-xs sm:text-sm"
              onChange={(selectedOption) =>
                handleDiscountTypeChange(selectedOption)
              }
              value={selectedDiscountType}
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            />
            {formErrors.discount && (
              <span className="text-red-600">{formErrors.discount}</span>
            )}
          </div>
        </div>
        {selectedDiscountType && (
          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700">
              {selectedDiscountType.value === "FIXED"
                ? "Discount Amount"
                : "Discount Percentage"}
            </label>
            <input
              disabled={transaction_id > 0 ? true : false}
              required
              type="number"
              value={discountValue}
              onChange={(e) => setDiscountValue(e.target.value)}
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              placeholder={
                selectedDiscountType.value === "FIXED"
                  ? "Enter amount"
                  : "Enter percentage"
              }
            />
            {formErrors.discountValue && (
              <span className="text-red-600">{formErrors.discountValue}</span>
            )}
          </div>
        )}

        <table>
          <tfoot>
            <tr>
              <th
                scope="row"
                colSpan={3}
                className="hidden pt-4 text-right font-semibold text-gray-900 sm:table-cell"
              >
                Total Cost
              </th>
              <td className="pb-0 pl-8 pr-0 pt-4 text-right font-semibold tabular-nums text-gray-900">
                {totalPrice} Rs
              </td>
            </tr>

            <tr>
              <th
                scope="row"
                colSpan={3}
                className="pt-4 text-right font-semibold text-gray-900"
              >
                Discount Cost
              </th>
              <td className="pb-0 pl-8 pr-0 pt-4 text-right font-semibold tabular-nums text-gray-900">
                {discountedAmount
                  ? discountedAmount
                  : calculatedDiscountAmount.toFixed(2)}{" "}
                Rs
              </td>
            </tr>
            <tr>
              <th
                scope="row"
                colSpan={3}
                className="pt-4 text-right font-semibold text-gray-900"
              >
                Grand Total
              </th>
              <td className="pb-0 pl-8 pr-0 pt-4 text-right font-semibold tabular-nums text-gray-900">
                {grandTotal.toFixed(2)} Rs
              </td>
            </tr>
          </tfoot>
        </table>
        <div className="mt-4">
          <button
            disabled={disableSubmitButton}
            onClick={handleSubmit} // Trigger createPayload when this button is clicked
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144]  px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#BE3144] focus:ring-offset-2 ml-auto"

          >
            {transaction_id ? (disableSubmitButton ? <FaSpinner color="gold" /> : "Update") : (disableSubmitButton ? <FaSpinner color="gold" /> : "Submit")}

            {/* Submit Transaction */}
          </button>
        </div>
        {/* <Table
                    columns={magazine_issue_columns({ onDeleteOpen, handleDrawer, imageBaseURL, onStatusUpdate, allowedActions })}
                    data={allMagazinesIssues}
                    is_toggle={false}
                /> */}
      </div>
    </div>
  );
};

export default AddVendorTransaction;
