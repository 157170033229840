import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import MasterServices from "../../../ApiServices/MasterServices";
import ConfirmationModal from '../../../components/ConfirmationModal'
import Breadcrumb from '../../../components/Breadcrumb'
import { FallingLinesLoader } from '../../../components/spinners/Spinner'
import Table from '../../../components/tables/table'
import { transaction_columns } from '../../../components/tables/tableheader'
import { toast } from "react-toast";
import { getAllowedActions, checkPermissionsAndRedirect } from "../../../helper/commonHelper";

const TransactionList = () => {
  const [transactionData, setTransactionData] = useState([])
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const pages = [{ title: "Transaction", href: "/transaction", module_id: 10 }];
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    trans_id: null,
  });

  const [allowedActions, setAllowedActions] = useState([]);
  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("user_permissions"));
    if (permissions && window.location.pathname) {
      const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect(window.location.pathname, permissions, navigate);
      const actions = getAllowedActions(permissions, window.location.pathname);
      setAllowedActions(actions);
    }
  }, []);


  useEffect(() => {
    getAllTransactionData(3);
  }, []);
  const getAllTransactionData = async (payload) => {
    setLoading(true)
    const response = await MasterServices.getAllTransactions(payload);
    console.log("response.data=>", response.data);
    if (response.data.status === true) {
      if (response?.data?.data?.length > 0) {
        setTransactionData(response?.data?.data);
      } else {
        setTransactionData([]);
      }
      setLoading(false)
    }
  };
  const onDeleteOpen = (trans_id) => {
    setConfirmationModal({ trans_id, status: true });
  }

  const onDeleteItem = (trans_id) => {
    console.log("trans_id", trans_id)
    MasterServices.deleteOrder({ trans_id })
      .then((response) => {
        const { data, status } = response;
        if (data.status) {
          setLoading(true)
          toast.success(data.message)
          setConfirmationModal({ trans_id: null, status: false });
          getAllTransactionData(3);
          navigate("/sku");
        } else {
          toast.error(data.message)
        }
        setLoading(false)
      })
      .catch((error) => {
        // const msg = error.response.data.message;
        toast.error("Sorry Fatal Error!")
        setLoading(false)
      });
  }

  const onStatusUpdate = (trans_id, trans_is_active) => {
    let payload = {
      table_name: "m_transaction",
      table_field: "trans_id",
      table_field_val: trans_id,
      table_status_field: "trans_is_active",
      table_status_val: trans_is_active ? false : true,
      table_text: "Transaction",
      deleted_by_key: "trans_updated_by",
      delete_active_txt: trans_is_active ? " Deactivated" : " Activated"
    }
    MasterServices.removeMaster(payload).then((res) => {
      const { data, status, message } = res.data
      if (status) {
        getAllTransactionData(3);
        toast.success(message);
      } else {
        toast.error(message)
      }
    })
  }
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <ConfirmationModal
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => onDeleteItem(confirmationModal.trans_id)}
      />
      <Breadcrumb pages={pages} />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">Transaction List</h1>
        {/* <p className="mt-2 text-sm text-gray-700">A list of all the Transaction.</p> */}
      </div>
      <div className="mt-4 mb-4 flex">
        {/* <Link
                    to={"/add-transaction"}
                    type="button"
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144]  px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#BE3144] focus:ring-offset-2 ml-auto"
                >
                    Add Transaction
                </Link> */}
      </div>
      {loading ? (<FallingLinesLoader />) : (
        <Table
          columns={transaction_columns({ onDeleteOpen, onStatusUpdate, allowedActions })}
          data={transactionData}
          is_toggle={false}
        />)}
    </div>
  )
}

export default TransactionList