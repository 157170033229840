import React, { useEffect, useState, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import Table from "../../../components/tables/table";
import { Delivery_columns } from "../../../components/tables/tableheader";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import Api from "../../../ApiServices/Api";
import SlideOverlay from "../../../components/SlideOverlay";

function Delivery() {
    const pages = [{ title: "Delivery Master", href: "/delivery", module_id: 25 }];
    const [PincodeList, setPincodeList] = useState([]);
    const [deliveryList, setDeliveryList] = useState([]);
    const [delivery_id, setDeliveryid] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [modalOpenFlage, setmodalOpenFlage] = useState(false);
    const [confirmationModal, setConfirmationModal] = useState({
        status: false,
        delivery_id: null,
    });
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Outlook | Delivery Master";
        getAllpincode();
        getAllDelivery();
    }, []);
    const getAllpincode = async () => {
        try {
            setIsLoading(true);
            const { data } = await Api().get(`/api/dashboard/masters/pincode`);
            console.log("All Citys Data", data);
            if (data.status) {
                setPincodeList(data.data);
            }
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    };



    const getAllDelivery = async () => {
        try {
            setIsLoading(true);
            const { data } = await Api().get(`/api/dashboard/masters/delivery`);
            console.log("All delivery Data", data);
            if (data.status) {
                setDeliveryList(data.data);
            }
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    };



    const initialValues = {
        name: "",
        phone_number: "",
        email: false,
        short_code: "",
        location: "",
        pin_code_id: "",
    };

    const [formDelivery, setFormDelivery] = useState(initialValues);


    const handleDrawer = (type, id, obj) => {
        console.log("Drawer", type, id, obj);
        if (type === "edit" && id) {
            setDeliveryid(id);
            const initialValues = {
                name: obj.name,
                phone_number: obj.phone_number,
                email: obj.email,
                short_code: obj.short_code,
                location: obj.location,
                pin_code_id: obj.pin_code_id,
            };
            setFormDelivery(initialValues);
        } else {
            setDeliveryid("");
            setFormDelivery(initialValues);
        }
        if (modalOpenFlage === false) {
            setmodalOpenFlage(true);
        }
    };
    const onDeleteOpen = (id) => {
        setConfirmationModal({ delivery_id: id, status: true });

    };

    const onDeleteDelivery = async (id) => {
        try {
            setIsLoading(true);
            const res = await Api().delete(`/api/dashboard/masters/delivery/${id}`);
            if (res.status) {
                getAllDelivery();
            }
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    };
    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setValues } =
        useFormik({
            enableReinitialize: true,
            initialValues: formDelivery,
            // validationSchema: citySchema,
            onSubmit: async (values, action) => {
                try {
                    setIsLoading(true);
                    let body = {
                        name: values.name,
                        phone_number: values.phone_number,
                        email: values.email,
                        short_code: values.short_code,
                        location: values.location,
                        pin_code_id: Number(values.pin_code_id),

                    };

                    if (delivery_id) {
                        const res = await Api().put(
                            `/api/dashboard/masters/delivery/${delivery_id}`,
                            body
                        );
                        if (res.status) {
                            getAllDelivery();
                        }
                    } else {
                        const res = await Api().post(`/api/dashboard/masters/delivery`, body);
                        if (res.status) {
                            getAllDelivery();
                        }
                    }

                    setIsLoading(false);
                } catch (error) {
                    console.log(error);
                    setIsLoading(false);
                }
                action.resetForm();
                if (modalOpenFlage === true) {
                    getAllDelivery();
                    setmodalOpenFlage(false);
                }
            },
        });

    function renderOverLay() {
        return (
            <div>
                <SlideOverlay open={modalOpenFlage} setOpen={setmodalOpenFlage}>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit(e);
                        }}
                        className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                    >
                        <div className="h-0 flex-1 overflow-y-auto">
                            <div className="bg-gradient-to-b from-[#872341] to-[#BE3144] py-6 px-4 sm:px-6">
                                <div className="flex items-center justify-between">
                                    <label className="text-lg font-medium text-white">
                                        {delivery_id ? "Update" : "Add"} Delivery
                                    </label>
                                    <div className="ml-3 flex h-7 items-center">
                                        <button
                                            type="button"
                                            className="rounded-md bg-red-700 text-red-200 hover:text-white"
                                            onClick={() => setmodalOpenFlage(false)}
                                        >
                                            <span className="sr-only">Close panel</span>
                                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-1 flex-col justify-between">
                                <div className="p-4 sm:p-6">
                                    <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                        <div>
                                            <label
                                                htmlFor="name"
                                                className="block text-sm font-medium text-gray-900"
                                            >
                                                Name
                                            </label>
                                        </div>
                                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                                            <input
                                                value={values.name}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter  Name"
                                                name="name"
                                                autoComplete="off"
                                                className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                                            />

                                        </div>
                                    </div>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                        <div>
                                            <label
                                                htmlFor="phone_number"
                                                className="block text-sm font-medium text-gray-900"
                                            >
                                                Phone Number
                                            </label>
                                        </div>
                                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                                            <input
                                                value={values.phone_number}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                                placeholder="Enter phone number"
                                                name="phone_number"
                                                values={values.phone_number}
                                                autoComplete="off"
                                                className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                                            />

                                        </div>
                                    </div>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                        <div>
                                            <label
                                                htmlFor="email"
                                                className="block text-sm font-medium text-gray-900"
                                            >
                                                Email
                                            </label>
                                        </div>
                                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                                            <input
                                                value={values.email}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="email"
                                                placeholder="Enter email"
                                                name="email"
                                                values={values.email}
                                                autoComplete="off"
                                                className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                                            />

                                        </div>
                                    </div>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                        <div>
                                            <label
                                                htmlFor="short_code"
                                                className="block text-sm font-medium text-gray-900"
                                            >
                                                Short code
                                            </label>
                                        </div>
                                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                                            <input
                                                value={values.short_code}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="short_code"
                                                placeholder="Enter Code"
                                                name="short_code"
                                                values={values.short_code}
                                                autoComplete="off"
                                                className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                                            />

                                        </div>
                                    </div>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                        <div>
                                            <label
                                                htmlFor="short_code"
                                                className="block text-sm font-medium text-gray-900"
                                            >
                                                Location
                                            </label>
                                        </div>
                                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                                            <input
                                                value={values.location}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="location"
                                                placeholder="Enter location"
                                                name="location"
                                                values={values.location}
                                                autoComplete="off"
                                                className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                                            />

                                        </div>
                                    </div>


                                    <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                        <div>
                                            <label
                                                htmlFor="fk_state_id"
                                                className="block text-sm font-medium text-gray-900"
                                            >
                                                Pincode
                                            </label>
                                        </div>
                                        <select
                                            className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:max-w-xs sm:text-sm"
                                            name="pin_code_id"
                                            id="pin_code_id"
                                            value={values.pin_code_id}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        >
                                            <option value={null}> Select pincode </option>
                                            {PincodeList?.map((s, i) => (
                                                <option
                                                    defaultValue={delivery_id ? values.pin_code_id : ""}
                                                    key={s.pincode}
                                                    value={s.id}
                                                >
                                                    {s.pincode}
                                                </option>
                                            ))}
                                        </select>


                                    </div>


                                </div>
                            </div>
                        </div>
                        <div className="flex flex-shrink-0 justify-end px-4 py-4">
                            <button
                                type="button"
                                className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                                onClick={() => {
                                    setmodalOpenFlage(false);
                                }}
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                            >
                                {delivery_id ? "Update" : "Add"}
                            </button>
                        </div>
                    </form>
                </SlideOverlay>
            </div>
        );
    }
    return (
        <div className="px-4 sm:px-6 lg:px-8">
            {renderOverLay()}
            <ConfirmationModal
                open={confirmationModal.status}
                setOpen={setConfirmationModal}
                onDelete={() => onDeleteDelivery(confirmationModal.delivery_id)}
            />
            <Breadcrumb pages={pages} />
            <div className="">
                <h1 className="text-xl font-semibold text-gray-900">Delivery</h1>
                <p className="mt-2 text-sm text-gray-700">A list of all the Delivery.</p>
            </div>
            <div className="mt-4 flex">
                <Link
                    onClick={() => handleDrawer("add", "", {})}
                    type="button"
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 ml-auto"
                >
                    Add Delivery
                </Link>
            </div>
            {isLoading ? (
                <FallingLinesLoader />
            ) : (
                <Table
                    columns={Delivery_columns({ onDeleteOpen, handleDrawer })}
                    data={deliveryList?.length > 0 && !isLoading ? deliveryList : []}
                    is_toggle={false}
                />
            )}

        </div>
    );
}
export default Delivery;
