import React, { useEffect, useState } from 'react'
import { FallingLinesLoader } from '../../../components/spinners/Spinner'
import Table from '../../../components/tables/table'
import Breadcrumb from '../../../components/Breadcrumb'
import { Link, useNavigate } from 'react-router-dom'
import { getAllowedActions, checkPermissionsAndRedirect } from "../../../helper/commonHelper";
import ReportServices from '../../../ApiServices/ReportServices'
import { IssuesReport_columns } from '../../../components/tables/tableheader'
import moment from 'moment';
import { convertToAnotherTimezone } from '../../../helper/commonHelper';
import { utils, writeFile } from 'xlsx';
import { toast } from 'react-toast';

const IssuesReport = () => {
  const pages = [{ title: "Issues Report", href: `/issues-report`, module_id: 26 }];
  const [isLoading, setIsLoading] = useState(false)
  const [issuesReportList, setIssuesReportList] = useState([])
  const navigate = useNavigate();
  const [allowedActions, setAllowedActions] = useState([]);
  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("user_permissions"));
    if (permissions && window.location.pathname) {
      const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect(window.location.pathname, permissions, navigate);
      const actions = getAllowedActions(permissions, window.location.pathname);
      setAllowedActions(actions);
    }
  }, []);

  useEffect(() => {
    getIssuesReport()
  }, [])

  const getIssuesReport = () => {
    setIsLoading(true)
    let payload = {
      status_code: 3,
    }
    ReportServices.getAllIssuesReport(payload).then((res) => {
      const { data, status } = res;
      if (status) {
        setIssuesReportList(data.data)
      }
      setIsLoading(false)
    }).catch((error) => {
      console.log(error)

    });
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <Breadcrumb pages={pages} />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">Issues Report</h1>
      </div>

      <div className="flex justify-end">
        <div className="mt-4 flex">
          {allowedActions.filter((data) => data.permission_id == 78)?.length > 0 ? (<Link
            to={`/add-customer-address/`}
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2"
          >
            Download Excel
          </Link>) : null}
        </div>
      </div>

      {isLoading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={IssuesReport_columns({ allowedActions })}
          data={issuesReportList ? issuesReportList : []}
          is_toggle={false}
        />
      )}
    </div>
  )
}

export default IssuesReport