import { read, utils } from 'xlsx';

/**
 * Function to handle file reading and data extraction dynamically
 * @param {File} file - The Excel file to read
 * @param {Object} entityMap - The mapping of Excel fields to entity fields
 * @param {Object} options - Optional configurations for additional transformation
 * @returns {Promise} - Resolves to the transformed data
 */
export const handleDynamicFileImport = (file, entityMap, options = {}) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      try {
        const workbook = read(event.target.result, { type: 'array' });
        const sheetName = options.sheetName || workbook.SheetNames[0]; // Use first sheet by default
        const sheet = workbook.Sheets[sheetName];
        const rows = utils.sheet_to_json(sheet);

        if (rows.length === 0) {
          return reject(new Error("Excel sheet is empty"));
        }

        const transformedData = transformDynamicData(rows, entityMap, options);
        resolve(transformedData);
      } catch (error) {
        reject(error);
      }
    };

    reader.onerror = (error) => reject(error);
    reader.readAsArrayBuffer(file);
  });
};

/**
 * Transform and map data dynamically based on the provided entity map
 * @param {Array} rows - Rows from Excel sheet
 * @param {Object} entityMap - Mapping between Excel fields and entity fields
 * @param {Object} options - Additional transformation options
 * @returns {Array} - Array of transformed objects
 */
const transformDynamicData = (rows, entityMap, options = {}) => {

  return rows.map((row) => {
    const transformedRow = {};
  
    // Dynamic mapping based on entityMap
    for (const [key, value] of Object.entries(entityMap)) {
      transformedRow[key] = row[value];
    }

    // Add created/updated timestamps if necessary
    transformedRow.created_at = new Date();
    transformedRow.updated_at = options.includeUpdatedAt ? new Date() : null;

    return transformedRow;
  });
};
