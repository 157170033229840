import React, { useEffect, useState } from 'react'
import Breadcrumb from '../../../components/Breadcrumb'
import Select from 'react-select'
import makeAnimated from "react-select/animated";
import MasterServices from '../../../ApiServices/MasterServices';
import { PlusCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { handleLogin } from '../../../redux/auth/actions';
import { useNavigate, useParams } from 'react-router-dom';
import { FaSpinner } from "react-icons/fa";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import { toast } from "react-toast";
import { Switch } from "@headlessui/react";

const tabs = [
  { name: 'Single', href: '#SINGLE', current: true },
  { name: 'Bundle', href: '#BUNDLE', current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const AddSku = () => {
  const pages = [{ title: "Add SKU", href: "/add-sku", module_id: 7 }];
  // Add state for form validation errors
  const [validationErrors, setValidationErrors] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [digitalFormErrors, setDigitalFormErrors] = useState({});
  const [pdFormErrors, setPDFormErrors] = useState({});
  const [activeTab, setActiveTab] = useState(tabs);
  const [magazine, setMagazine] = useState([])
  const [magazineAll, setMagazineAll] = useState([])
  const [allMagazinesIssues, setAllMagazinesIssues] = useState([]);
  const [selectedMagazine, setSelectedMagazine] = useState(null)
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [selectedSubscription, setSelectedSubscription] = useState(null)
  const [selectedMagazineIssue, setSelectedMagazineIssue] = useState([])
  const [getTabsIndex, setActiveTabIndex] = useState(0)
  const [countryPrice, setCountryPrice] = useState("")
  const [selectedProduct, setSelectedProduct] = useState([])
  const [allProduct, setAllProduct] = useState([])
  const animatedComponents = makeAnimated();
  const [allCountryList, setAllCountryList] = useState([])
  const [alldigiCountryList, setAllDigiCountryList] = useState([])
  const [allpdCountryList, setAllpdCountryList] = useState([])
  const [countryAll, setCountryAll] = useState([])
  const [countryDigiAll, setCountryDigiAll] = useState([])
  const [countryPDAll, setCountryPDAll] = useState([])
  const [singleSku, setSingleSku] = useState({ type: "SINGLE" })
  const [backendSkuType, setBackendSkuType] = useState(false)
  const [backendSingleSku, setBackendSingleSku] = useState({})
  const [getAllFilterCountry, setGetAllFilterCountry] = useState([])
  const [getAllFilterDigitalCountry, setGetAllFilterDigitalCountry] = useState([])
  const [getAllFilterPDCountry, setGetAllFilterPDCountry] = useState([])
  const [getAllFilterMagzgine, setGetAllFilterMagzgine] = useState([])
  const [imageBaseURL, setImageBaseURL] = useState('https://outlook-subscription-test.s3.ap-south-1.amazonaws.com/uploads/skus/')
  const [loading, setLoading] = useState(false);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const navigate = useNavigate()
  const { sku_id: sku_id } = useParams();
  const [skuCode, setSkuCode] = useState(null);
  const [skuImg, setSkuImg] = useState(null);
 
  const [formData, setFormData] = useState([
    {
      id: 1,
      country: {
        "label": "Rest of the World",
        "value": 4
      },
      cp_pricing_type:0,
      selectedCountry: {
        "country_id": 4,
        "country_code": "OT",
        "country_name": "Rest of the World",
        "currency": null,
        cp_pricing_type:0,
        "country_isd_code3": null,
        "country_isd_code": 0,
        "country_is_active": true,
        "country_delete": false,
        "country_is_edit": false,
        "country_created_at": "2024-01-03T09:44:51.573Z",
        "country_updated_at": "2024-01-03T09:44:51.573Z",
        "country_created_by": null,
        "country_updated_by": null,
        "label": "Rest of the World",
        "value": 4
      },
      cp_id: '',
      country_id: '',
      price: '',
      display_price: "",
      countryPrice: '', 
      countryDisplayPrice: '',
      "label": "Rest of the World",
      "value": 4
    },
    {
      id: 2,
      cp_id: '',
      country: {
        "label": "INDIA",
        "value": 1
      },
      cp_pricing_type:0,
      selectedCountry: {
        "country_id": 1,
        "country_code": "IN",
        "country_name": "INDIA",
        "currency": "INR",
        cp_pricing_type:0,
        "country_isd_code3": "IND",
        "country_isd_code": 91,
        "country_is_active": true,
        "country_delete": false,
        "country_is_edit": false,
        "country_created_at": "2024-01-03T09:19:04.074Z",
        "country_updated_at": "2024-01-03T09:19:04.074Z",
        "country_created_by": null,
        "country_updated_by": null,
        "label": "INDIA",
        "value": 1
      },
      country_id: '',
      price: '',
      display_price: "",
      countryPrice: '', 
      countryDisplayPrice: '',
      "label": "INDIA",
      "value": 1
    },
  ]);

  const [digitalFormData, setDigitalFormData] = useState([
    {
      id: 1,
      country: {
        "label": "Rest of the World",
        "value": 4
      },
      cp_pricing_type:1,
      selectedCountry: {
        "country_id": 4,
        "country_code": "OT",
        "country_name": "Rest of the World",
        "currency": null,
        cp_pricing_type:1,
        "country_isd_code3": null,
        "country_isd_code": 0,
        "country_is_active": true,
        "country_delete": false,
        "country_is_edit": false,
        "country_created_at": "2024-01-03T09:44:51.573Z",
        "country_updated_at": "2024-01-03T09:44:51.573Z",
        "country_created_by": null,
        "country_updated_by": null,
        "label": "Rest of the World",
        "value": 4
      },
      cp_id: '',
      country_id: '',
      price: '',
      display_price: "", 
      countryPrice: '', 
      countryDisplayPrice: '',
      "label": "Rest of the World",
      "value": 4
    },
    {
      id: 2,
      cp_id: '',
      country: {
        "label": "INDIA",
        "value": 1
      },
      cp_pricing_type:1,
      selectedCountry: {
        "country_id": 1,
        "country_code": "IN",
        "country_name": "INDIA",
        "currency": "INR",
        cp_pricing_type:1,
        "country_isd_code3": "IND",
        "country_isd_code": 91,
        "country_is_active": true,
        "country_delete": false,
        "country_is_edit": false,
        "country_created_at": "2024-01-03T09:19:04.074Z",
        "country_updated_at": "2024-01-03T09:19:04.074Z",
        "country_created_by": null,
        "country_updated_by": null,
        "label": "INDIA",
        "value": 1
      },
      country_id: '',
      price: '',
      display_price: "", 
      countryPrice: '', 
      countryDisplayPrice: '',
      "label": "INDIA",
      "value": 1
    },
  ]);

  const [pdFormData, setPDFormData] = useState([
    {
      id: 1,
      country: {
        "label": "Rest of the World",
        "value": 4
      },
      cp_pricing_type:2,
      selectedCountry: {
        "country_id": 4,
        "country_code": "OT",
        "country_name": "Rest of the World",
        "currency": null,
        cp_pricing_type:2,
        "country_isd_code3": null,
        "country_isd_code": 0,
        "country_is_active": true,
        "country_delete": false,
        "country_is_edit": false,
        "country_created_at": "2024-01-03T09:44:51.573Z",
        "country_updated_at": "2024-01-03T09:44:51.573Z",
        "country_created_by": null,
        "country_updated_by": null,
        "label": "Rest of the World",
        "value": 4
      },
      cp_id: '',
      country_id: '',
      price: '',
      display_price: "", 
      countryPrice: '', 
      countryDisplayPrice: '',
      "label": "Rest of the World",
      "value": 4
    },
    {
      id: 2,
      cp_id: '',
      country: {
        "label": "INDIA",
        "value": 1
      },
      cp_pricing_type:2,
      selectedCountry: {
        "country_id": 1,
        "country_code": "IN",
        "country_name": "INDIA",
        "currency": "INR",
        cp_pricing_type:2,
        "country_isd_code3": "IND",
        "country_isd_code": 91,
        "country_is_active": true,
        "country_delete": false,
        "country_is_edit": false,
        "country_created_at": "2024-01-03T09:19:04.074Z",
        "country_updated_at": "2024-01-03T09:19:04.074Z",
        "country_created_by": null,
        "country_updated_by": null,
        "label": "INDIA",
        "value": 1
      },
      country_id: '',
      price: '',
      display_price: "", 
      countryPrice: '', 
      countryDisplayPrice: '',
      "label": "INDIA",
      "value": 1
    },
  ]);


  const [formDataBundle, setFormDataBundle] = useState([
    {
      id: 1,
      magazine: '',
      magazine_issue: [],
      subscription: '',
      getAllMagazineIssue: [],
    },
  ]);

  const onFileChange = (e) => {
    const file = e.target.files[0];
    const fileName = file.name;

    const reader = new FileReader();
    reader.onload = (e) => {
      setSkuImg(e.target.result);
    };
    reader.readAsDataURL(file);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([getMagazines(), getAllCountryList()]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    // Only trigger handleTabClick when countryAll changes
    if (countryAll?.length > 0) {
      handleTabClick(0);
      let getAllCountry = [];

      formData?.forEach((item1, ind1) => {
        handleCountry(item1.selectedCountry, singleSku.type, ind1, item1.selectedCountry);
        getAllCountry.push({ label: item1?.country?.label, value: item1?.country?.value });
      });
      if (countryAll.length > 0) {
        let getAllFilterCountryItem = getAllFilterDataEdit(countryAll, getAllCountry);
        setAllCountryList(getAllFilterCountryItem);
      }

    }
  }, [countryAll]);

  useEffect(() => {
    // Only trigger handleTabClick when countryDigiAll changes
    if (countryDigiAll?.length > 0) {
      handleTabClick(0);
      let getAllCountry = [];

      digitalFormData?.forEach((item1, ind1) => {
        handleDigitalCountry(item1.selectedCountry, singleSku.type, ind1, item1.selectedCountry);
        getAllCountry.push({ label: item1?.country?.label, value: item1?.country?.value });
      });
      if (countryDigiAll.length > 0) {
        let getAllFilterCountryItem = getAllFilterDataEdit(countryDigiAll, getAllCountry);
        setAllDigiCountryList(getAllFilterCountryItem);
      }

    }
  }, [countryDigiAll]);

  useEffect(() => {
    // Only trigger handleTabClick when countryPDAll changes
    if (countryPDAll?.length > 0) {
      handleTabClick(0);
      let getAllCountry = [];

      pdFormData?.forEach((item1, ind1) => {
        handlePDCountry(item1.selectedCountry, singleSku.type, ind1, item1.selectedCountry);
        getAllCountry.push({ label: item1?.country?.label, value: item1?.country?.value });
      });
      if (countryPDAll.length > 0) {
        let getAllFilterCountryItem = getAllFilterDataEdit(countryPDAll, getAllCountry);
        setAllpdCountryList(getAllFilterCountryItem);
      }

    }
  }, [countryPDAll]);

  useEffect(() => {
    getAllProducts()
    if (sku_id) {
      getAllMagazineIssuesData(1)
      setFormData([]);
      setFormDataBundle([]);
      getSingleSkuData(sku_id)
    }
  }, [])

  const handleTabClick = (tabNumber) => {
    if (tabNumber == 1) {
      singleSku.type = "BUNDLE";
    } else {
      singleSku.type = "SINGLE";
    }
    setSingleSku(singleSku)
    if (backendSkuType !== singleSku.type) {

      if (sku_id) {
        setFormData([
          {
            id: 1,
            country: {},
            selectedCountry: {},
            country_id: '',
            cp_id: '',
            price: '',
            display_price: ""
            , countryPrice: '', countryDisplayPrice: ''
          },
        ]);
      }
      setFormDataBundle([
        {
          id: 1,
          magazine: [],
          magazine_issue: [],
          subscription: [],
          getAllMagazineIssue: [],
        },
      ]);
      setSelectedProduct([]);
      setSelectedMagazine([])
    } else {
      setItem(backendSingleSku);
    }
    tabs.map((obj, key) => {
      if (key == tabNumber) {
        tabs[tabNumber].current = true
        setActiveTabIndex(tabNumber)
      } else {
        tabs[key].current = false
      }
    })
    setActiveTab(...tabs)
  };

  const addFormBundleFields = () => {
    setFormDataBundle([...formDataBundle, {
      id: formDataBundle.length + 1,
      magazine: '',
      magazine_issue: formDataBundle.length == 1 ? formDataBundle[0].selectedMagazineIssue ? formDataBundle[0].selectedMagazineIssue : [] : [],
      subscription: formDataBundle.length > 0 ? formDataBundle[0].selectedSubscription ? formDataBundle[0].selectedSubscription : [] : [],

      getAllMagazineIssue: [],
    }]);
  }

  const addFormFields = () => {
    setFormData([...formData, {
      id: formData.length + 1,
      country: {},
      selectedCountry: {},
      cp_pricing_type:0,
      country_id: '', 
      price: '', 
      countryPrice: '', 
      countryDisplayPrice: '',
      cp_id: '',
    }]);
  };
  const addDigitalFormFields = () => {
    setDigitalFormData([...digitalFormData, {
      id: digitalFormData.length + 1,
      country: {},
      selectedCountry: {},
      cp_pricing_type:1,
      country_id: '', 
      price: '', 
      countryPrice: '', 
      countryDisplayPrice: '',
      cp_id: '',
    }]);
  };
  const addPDFormFields = () => {
    setPDFormData([...pdFormData, {
      id: pdFormData.length + 1,
      country: {},
      selectedCountry: {},
      cp_pricing_type:2,
      country_id: '', 
      price: '', 
      countryPrice: '', 
      countryDisplayPrice: '',
      cp_id: '',
    }]);
  };

  const removeFormBundleFields = (id) => {
    setFormDataBundle(prevData => prevData.filter(item => item.id !== id));
  }

  // Assuming you are working within a functional component

  useEffect(() => {
    // This code will run after the state has been updated
    let getAllMagzgine = [];
    formDataBundle.forEach((item) => {
      if (item.selectedMagazine !== undefined) {
        getAllMagzgine.push(item.selectedMagazine);
      }
    });

    let getAllFilterItem = getAllFilterData(magazineAll, getAllMagzgine);
    setMagazine(getAllFilterItem);
  }, [formDataBundle]); // The effect depends on formDataBundle

  useEffect(() => {
    // This code will run after the state has been updated
    let getAllCountry = [];
    formData.forEach((item) => {
      if (item.selectedCountry !== undefined) {
        getAllCountry.push(item.selectedCountry);
      }
    });

    let getAllFilterItem = getAllFilterData(countryAll, getAllCountry);
  }, [formData]); // The effect depends on formData

  useEffect(() => {
    // This code will run after the state has been updated
    let getAllCountry = [];
    digitalFormData.forEach((item) => {
      if (item.selectedCountry !== undefined) {
        getAllCountry.push(item.selectedCountry);
      }
    });

    let getAllFilterItem = getAllFilterData(countryDigiAll, getAllCountry);
  }, [digitalFormData]); // The effect depends on formData

  useEffect(() => {
    // This code will run after the state has been updated
    let getAllCountry = [];
    pdFormData.forEach((item) => {
      if (item.selectedCountry !== undefined) {
        getAllCountry.push(item.selectedCountry);
      }
    });

    let getAllFilterItem = getAllFilterData(countryPDAll, getAllCountry);
  }, [pdFormData]); // The effect depends on formData

  const removeFormFields = (items) => {
    setFormData(formData.filter((item) => item.id !== items.id));
  };
  const removeDigitalFormFields = (items) => {
    setDigitalFormData(digitalFormData.filter((item) => item.id !== items.id));
  };
  const removePDFormFields = (items) => {
    setPDFormData(pdFormData.filter((item) => item.id !== items.id));
  };

  const getAllProducts = () => {
    MasterServices.getAllProduct(1).then((res) => {
      const { data, status } = res.data;
      if (status) {
        const newArray = data?.data?.map((ele) => ({
          ...ele,
          value: ele.product_id,
          label: ele.product_name
        }))
        setAllProduct(newArray)
      }
    }).catch((error) => {
      console.log(error)
    });
  }

  useEffect(() => {

    // This effect will be triggered whenever magazineAll or countryAll changes
    if (countryAll.length > 0 && getAllFilterCountry.length > 0) {
      // Now both magazineAll and countryAll are not empty
      let getAllFilterCountryItem = getAllFilterDataEdit(countryAll, getAllFilterCountry);
  
      if (getAllFilterCountryItem.length > 0) {
        setAllCountryList(getAllFilterCountryItem);
      }

      // ... rest of your code
    }
  }, [countryAll, getAllFilterCountry]);

  useEffect(() => {

    // This effect will be triggered whenever magazineAll or countryDigiAll changes
    if (countryDigiAll.length > 0 && getAllFilterDigitalCountry.length > 0) {
      // Now both magazineAll and countryDigiAll are not empty
      let getAllFilterCountryItem = getAllFilterDataEdit(countryDigiAll, getAllFilterDigitalCountry);
    
      if (getAllFilterCountryItem.length > 0) {
        setAllDigiCountryList(getAllFilterCountryItem);
      }

      // ... rest of your code
    }
  }, [countryDigiAll, getAllFilterDigitalCountry]);

  useEffect(() => {

    // This effect will be triggered whenever magazineAll or countryPDAll changes
    if (countryPDAll.length > 0 && getAllFilterPDCountry.length > 0) {
      // Now both magazineAll and countryPDAll are not empty
      let getAllFilterCountryItem = getAllFilterDataEdit(countryPDAll, getAllFilterPDCountry);
     
      if (getAllFilterCountryItem.length > 0) {
        setAllpdCountryList(getAllFilterCountryItem);
      }

      // ... rest of your code
    }
  }, [countryPDAll, getAllFilterPDCountry]);


  useEffect(() => {

    // This effect will be triggered whenever magazineAll or countryAll changes
    if (magazineAll.length > 0 && getAllFilterMagzgine.length > 0) {

      let getAllFilterItem = getAllFilterDataEdit(magazineAll, getAllFilterMagzgine);
      if (getAllFilterItem.length > 0) {
        setMagazine(getAllFilterItem);
      }
      // ... rest of your code
    }
  }, [magazineAll, getAllFilterMagzgine]);

  const setItem = async (data) => {

    let MagazineArr = [];
    let getAllMagzgine = [];
    let getAllCountry = [];
    let getAllDigitalCountry = [];
    let getAllPDCountry = [];

    data?.cp_data?.forEach((item1, ind1) => {
      getAllCountry.push({ label: item1?.country?.label, value: item1?.country?.value });
    });

    data?.digital_cp_data?.forEach((item1, ind1) => {
      getAllDigitalCountry.push({ label: item1?.country?.label, value: item1?.country?.value });
    });

    data?.pd_cp_data?.forEach((item1, ind1) => {
      getAllPDCountry.push({ label: item1?.country?.label, value: item1?.country?.value });
    });
    let getAllFilterCountryItem = getAllFilterData(countryAll, getAllCountry);
    
    setAllCountryList(getAllFilterCountryItem);

    let getAllFilterDigitalCountryItem = getAllFilterData(countryDigiAll, getAllDigitalCountry);

    setAllDigiCountryList(getAllFilterDigitalCountryItem);

    let getAllFilterPDCountryItem = getAllFilterData(countryPDAll, getAllPDCountry);
    
    setAllpdCountryList(getAllFilterPDCountryItem);

    for (const item of data?.magazine_data || []) {
      let getAllMagazineIssue = [];
      if (item.magazine) {
        getAllMagzgine.push(item.magazine)
        let issuesData = [];
        try {
          issuesData = await getMagazineIssuesDataReturn(item?.magazine?.value);
        } catch (error) {
          // console.error("Error fetching magazine issues data:", error.message);
        }
        getAllMagazineIssue = issuesData;
      }

      MagazineArr.push({
        id: MagazineArr.length + 1,
        selectedMagazine: item.magazine,
        getAllMagazineIssue: getAllMagazineIssue,
        selectedMagazineIssue: item.magazine_issues,
        selectedSubscription: item.subscription,
        magazine: item.magazine,
        magazine_issue: item.magazine_issues,
        selectedSubscription: item.subscription,
        skumagm_id: item?.skumagm_id,
      });
    }

    setFormDataBundle((prevFormDataBundle) => [...MagazineArr]);


    setFormData((prevFormData) => {
      const newData = data?.cp_data?.map((item1, ind1) => ({
        id: ind1 + 1,
        selectedCountry: item1.country,
        country: item1.country,
        country_id: item1.country.country_id,
        country_name: item1.country.country_name,
        countryPrice: item1.country_price,
        countryDisplayPrice: item1.country_display_price,
        cp_id: item1.cp_id,
        cp_pricing_type: item1.cp_pricing_type,
      }));

      return [...newData];
    });

    setDigitalFormData((prevFormData) => {
      const newData = data?.digital_cp_data?.map((item1, ind1) => ({
        id: ind1 + 1,
        selectedCountry: item1.country,
        country: item1.country,
        country_id: item1.country.country_id,
        country_name: item1.country.country_name,
        countryPrice: item1.country_price,
        countryDisplayPrice: item1.country_display_price,
        cp_id: item1.cp_id,
        cp_pricing_type: item1.cp_pricing_type,
      }));

      return [...newData];
    });

    setPDFormData((prevFormData) => {
      const newData = data?.pd_cp_data?.map((item1, ind1) => ({
        id: ind1 + 1,
        selectedCountry: item1.country,
        country: item1.country,
        country_id: item1.country.country_id,
        country_name: item1.country.country_name,
        countryPrice: item1.country_price,
        countryDisplayPrice: item1.country_display_price,
        cp_id: item1.cp_id,
        cp_pricing_type: item1.cp_pricing_type,
      }));

      return [...newData];
    });

    setSelectedProduct(data?.product)
    setSelectedMagazine(data?.magazine_data?.magazine)
    setGetAllFilterCountry(getAllCountry);
    setGetAllFilterDigitalCountry(getAllDigitalCountry);
    setGetAllFilterPDCountry(getAllPDCountry);
    setGetAllFilterMagzgine(getAllMagzgine);
    setLoading(false)
  }

  const getSingleSkuData = async (sku_id) => {
    setLoading(true)
    setFormData([]);
    setFormDataBundle([]);
    MasterServices.getSingleSku(sku_id).then(async (res) => {
      const { data, status } = res.data;
      if (status) {
        let updatedCountryList = [];
        setEnabled(data?.sku_show_ejs);
        setSkuCode(data?.sku_code);
        setSkuImg(data?.sku_img ? data?.sku_img : imageBaseURL + data?.sku_img);
        handleTabClick(data?.type == "SINGLE" ? 0 : 1)
        setSingleSku(data)
        setBackendSingleSku(data);
        setBackendSkuType(data?.type);
        setItem(data);

      }
    }).catch((error) => {
      console.log(error)
    })
  }


  const handleproducts = (e) => {
    setSelectedProduct(e)
  }

  const handleMagazine = (e, type, ind) => {
    let getAllMagzgine = [];
    formDataBundle.map((item, index) => {
      if (index === ind) {
        item.selectedMagazine = e;
        item.selectedMagazineIssue = []
        item.selectedSubscription = []
      }
      if (item.selectedMagazine != undefined) {
        getAllMagzgine.push(item.selectedMagazine);
      }
    })

    let getAllFilterItem = getAllFilterData(magazineAll, getAllMagzgine);
    setMagazine(getAllFilterItem);

    setSelectedMagazine([e])
    getMagazineIssuesData(e?.mm_id ? e?.mm_id : e?.value, ind);
  }

  const getAllFilterData = (original, removeItem) => {
    const newArray = original.filter(item => !removeItem.includes(item));
    return newArray;
  }

  const getAllFilterDataEdit = (original, removeItems) => {
    const valuesToRemove = removeItems.map(item => item.value);
    const newArray = original.filter(item => !valuesToRemove.includes(item.value));
    return newArray;
  };


  const getAllMagazineIssuesData = async (payload) => {
    const response = await MasterServices.getAllMagazineIssuesData(payload);
    if (response?.data?.data?.data?.length > 0) {
      const newArray = response?.data?.data?.data?.map((ele) => ({
        ...ele,
        value: ele.mi_id,
        label: ele.mi_name
      }))
      setAllMagazinesIssues(newArray)
    }
  };
  const getMagazineIssuesData = (mm_id, ind) => {
    MasterServices.getAllMagazineIssues(mm_id).then((res) => {
      const { data, status } = res.data;
      if (status) {
        const newArray = data?.data?.map((ele) => ({
          ...ele,
          value: ele.mi_id,
          label: ele.mi_name
        }))
        setAllMagazinesIssues(newArray)
        formDataBundle.map((itm, indf) => {
          if (indf > 0) {
            itm.selectedSubscription = formDataBundle[0].selectedSubscription;
            itm.selectedMagazineIssue = indf == 0 ? formDataBundle[0].selectedMagazineIssue : [];
          }
          if (indf == ind) {
            itm.getAllMagazineIssue = newArray;

          }
        })
        setFormDataBundle(formDataBundle);
      }
    }).catch((error) => {
      console.log(error)
    });
  }

  const getMagazineIssuesDataReturn = async (mm_id, ind) => {
    try {
      const res = await MasterServices.getAllMagazineIssues(mm_id);
      const { data, status } = res.data;

      if (status) {
        const newArray = data?.data?.map((ele) => ({
          ...ele,
          value: ele.mi_id,
          label: ele.mi_name
        }));
        return newArray;
      } else {
        // Handle the case where status is falsy (e.g., an error occurred)
        // console.error("Error fetching magazine issues data:", data?.message);
        return null; // or throw an error if appropriate
      }
    } catch (error) {
      // console.error("Error fetching magazine issues data:", error.message);
      throw error; // Re-throw the error to be handled by the calling code
    }
  };

  const getMagazines = () => {
    MasterServices.getAllMagazine(1).then((res) => {
      const { data, status } = res.data
      if (status) {
        const newArray = data?.data?.map((ele) => ({
          // ...ele,
          label: ele.mm_name,
          value: ele.mm_id,
        }))
        setMagazine(newArray);
        setMagazineAll(newArray);
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  const handleCountry = (e, type, index, selectedCountry) => {
    let getAllCountry = [];
    formData.map((item, ind) => {
      if (ind === index) {
        item.selectedCountry = e;
      }
      if (item.selectedCountry != undefined) {
        getAllCountry.push(item.selectedCountry);
      }
    })
    let getAllFilterItem = getAllFilterData(countryAll, getAllCountry);
    setAllCountryList(getAllFilterItem);
    setFormData([...formData])

  }

  const handlePricing = (e, type, index) => {
    formData.map((item, ind) => {
      if (ind === index) {
        item.countryPrice = e;
      }
    })
    setFormData([...formData])
  }

  const handleDisplayPricing = (e, type, index) => {
    formData.map((item, ind) => {
      if (ind === index) {
        item.countryDisplayPrice = e;
      }
    })
    setFormData([...formData])
  }

  const handleDigitalCountry = (e, type, index, selectedCountry) => {
    let getAllCountry = [];
    digitalFormData.map((item, ind) => {
      if (ind === index) {
        item.selectedCountry = e;
      }
      if (item.selectedCountry != undefined) {
        getAllCountry.push(item.selectedCountry);
      }
    })
    let getAllFilterItem = getAllFilterData(countryDigiAll, getAllCountry);
    setAllDigiCountryList(getAllFilterItem);
    setDigitalFormData([...digitalFormData])

  }

  const handleDigitalPricing = (e, type, index) => {
    digitalFormData.map((item, ind) => {
      if (ind === index) {
        item.countryPrice = e;
      }
    })
    setDigitalFormData([...digitalFormData])
  }

  const handleDigitalDisplayPricing = (e, type, index) => {
    digitalFormData.map((item, ind) => {
      if (ind === index) {
        item.countryDisplayPrice = e;
      }
    })
    setDigitalFormData([...digitalFormData])
  }

  const handlePDCountry = (e, type, index, selectedCountry) => {
    let getAllCountry = [];
    pdFormData.map((item, ind) => {
      if (ind === index) {
        item.selectedCountry = e;
      }
      if (item.selectedCountry != undefined) {
        getAllCountry.push(item.selectedCountry);
      }
    })
    let getAllFilterItem = getAllFilterData(countryPDAll, getAllCountry);
    setAllpdCountryList(getAllFilterItem);
    setPDFormData([...pdFormData])

  }

  const handlePDPricing = (e, type, index) => {
    pdFormData.map((item, ind) => {
      if (ind === index) {
        item.countryPrice = e;
      }
    })
    setPDFormData([...pdFormData])
  }

  const handlePDDisplayPricing = (e, type, index) => {
    pdFormData.map((item, ind) => {
      if (ind === index) {
        item.countryDisplayPrice = e;
      }
    })
    setPDFormData([...pdFormData])
  }

  const handleMagazineIssue = (e, type, index) => {
    formDataBundle.map((item, ind) => {
      if (ind === index) {
        item.selectedMagazineIssue = e;
        item.selectedSubscription = []
      }
    })
    setSelectedMagazineIssue(e)

  }
  const handleSubscription = (e, type, index) => {
    formDataBundle.map((item, ind) => {
      if (ind === index) {
        item.selectedSubscription = e;
        item.selectedMagazineIssue = []
      }
    })
    setFormDataBundle(prevData => {
      const firstItem = prevData[0];

      return prevData.map(item => ({
        ...item,
        selectedSubscription: firstItem.selectedSubscription,
        selectedMagazineIssue: firstItem.selectedMagazineIssue,
      }));
    });
    setSelectedSubscription(e)
  }

  const getAllCountryList = () => {
    MasterServices.getAllCountry(1).then((res) => {
      const { data, status } = res;
      if (status) {
        setAllCountryList(data.data);
        setCountryAll(data.data);

        setAllDigiCountryList(data.data);
        setCountryDigiAll(data.data);

        setAllpdCountryList(data.data);
        setCountryPDAll(data.data);
      }
    }).catch((error) => {
      console.log(error)
    });
  }
  const subscription = [
    {
      label: "One Month",
      value: "1 Month",
    },
    {
      label: "Three Month",
      value: "3 Month",
    },
    {
      label: "Six Month",
      value: "6 Month",
    },
    {
      label: "One Year",
      value: "1 Year",
    },
    {
      label: "Two Year",
      value: "2 Year",
    },
    {
      value: "Three Year",
      label: "Three Year",
    },
  ]
  const [formMagazineError, setFormMagazineError] = useState('');
  const [formCountryError, setFormCountryError] = useState('');
  const [formProductError, setFormProductError] = useState('');
  // Function to validate the form before submission
  const validateForm = () => {
    const errors = {};
    const digital_errors = {};
    const pd_errors = {};
    // Validate SKU Name
    const trimmedSkuCode = skuCode ? skuCode.trim() : ''; // Ensure skuCode is not null or undefined
    if (!trimmedSkuCode) {
      errors.skuCode = 'SKU Name is required';
    }

    if (singleSku.type !== 'SINGLE') {

      const trimmedSkuImg = skuImg ? skuImg.trim() : ''; // Ensure skuCode is not null or undefined
      if (!trimmedSkuImg) {
        errors.sku_img = 'SKU Img is required';
      }
    }

    // Validate Magazine for each item in formDataBundle
    formDataBundle.forEach((item, index) => {
      if (!item.selectedMagazine) {
        errors[`selectedMagazine${index}`] = `Magazine is required for Bundle ${index + 1}`;
      }
      // Add additional validations for magazine-related fields in each item
    });
    // Validate Country for each item in formDataBundle
    formData.forEach((item, index) => {
      if (!item?.selectedCountry?.value) {
        errors[`selectedCountry${index}`] = `Country is required for Bundle ${index + 1}`;
      }
      if (!item?.countryPrice) {
        errors[`price${index}`] = `Country Price is required for Bundle ${index + 1}`;
      }
      if (!item?.countryDisplayPrice) {
        errors[`display_price${index}`] = `Country Display Price is required for Bundle ${index + 1}`;
      }
      // Add additional validations for country-related fields in each item
    });

    digitalFormData.forEach((item, index) => {
      if (!item?.selectedCountry?.value) {
        digital_errors[`selectedCountry${index}`] = `Country is required for Bundle ${index + 1}`;
      }
      if (!item?.countryPrice) {
        digital_errors[`price${index}`] = `Country Price is required for Bundle ${index + 1}`;
      }
      if (!item?.countryDisplayPrice) {
        digital_errors[`display_price${index}`] = `Country Display Price is required for Bundle ${index + 1}`;
      }
      // Add additional validations for country-related fields in each item
    });

    pdFormData.forEach((item, index) => {
      if (!item?.selectedCountry?.value) {
        pd_errors[`selectedCountry${index}`] = `Country is required for Bundle ${index + 1}`;
      }
      if (!item?.countryPrice) {
        pd_errors[`price${index}`] = `Country Price is required for Bundle ${index + 1}`;
      }
      if (!item?.countryDisplayPrice) {
        pd_errors[`display_price${index}`] = `Country Display Price is required for Bundle ${index + 1}`;
      }
      // Add additional validations for country-related fields in each item
    });

    // Set errors in state
    setFormErrors(errors);
    setDigitalFormErrors(digital_errors);
    setPDFormErrors(pd_errors);
    console.log("errors=>",)

    // Return true if there are no errors, indicating the form is valid
    return ((Object.keys(errors).length === 0)&&(Object.keys(digital_errors).length === 0)&&(Object.keys(pd_errors).length === 0));
  };



  const handleSave = () => {
    // Validate the form
    const isValid = validateForm();

    // If the form is valid, proceed with saving
    if (isValid) {
      // ... (your save logic)

      let magazine_data = [];
      let product = selectedProduct.length > 0 ? selectedProduct : [];
      if (formData.length <= 0 || formDataBundle.length <= 0) {
        return false;
      }
      if (formDataBundle.length > 0) {
        formDataBundle.map((md, mi) => {
          const mdObj = {
            skumagm_id: md?.skumagm_id,
            magazine: md?.selectedMagazine,
            magazine_issues: md?.selectedMagazineIssue,
            subscription: md?.selectedSubscription
          }
          magazine_data.push(mdObj);
        });
      }
      if (magazine_data[0].magazine_issues.length <= 0) {
        if (!magazine_data[0]?.subscription?.value) {
          toast.error("Please Choose Either Subscription or Issues");
          return false;
        }
      }
      let cp_data = [];
      let digital_cp_data = [];
      let pd_cp_data = [];
      // console.log("formData=>", formData, formDataBundle);
      // return false;
      if (formData.length > 0) {
        formData.map((cp, index) => {
          const cpObj = {
            country: cp?.country?.value ? cp?.country : cp?.selectedCountry?.value ? cp?.selectedCountry : null,
            selectedCountry: cp?.country,
            country_price: cp?.countryPrice ? cp?.countryPrice : 0,
            country_id: cp?.selectedCountry?.value ? cp?.selectedCountry?.value : cp?.country?.value ? cp?.country?.value : 0,
            cp_pricing_type:cp?.cp_pricing_type,
            country_display_price: cp?.countryDisplayPrice ? cp?.countryDisplayPrice : 0,
          }
          cp_data.push(cpObj);
        })
      }

      if (digitalFormData.length > 0) {
        digitalFormData.map((cp, index) => {
          const digi_cpObj = {
            country: cp?.country?.value ? cp?.country : cp?.selectedCountry?.value ? cp?.selectedCountry : null,
            selectedCountry: cp?.country,
            country_price: cp?.countryPrice ? cp?.countryPrice : 0,
            country_id: cp?.selectedCountry?.value ? cp?.selectedCountry?.value : cp?.country?.value ? cp?.country?.value : 0,
            cp_pricing_type:cp?.cp_pricing_type,
            country_display_price: cp?.countryDisplayPrice ? cp?.countryDisplayPrice : 0,
          }
          digital_cp_data.push(digi_cpObj);
        })
      }

      if (pdFormData.length > 0) {
        pdFormData.map((cp, index) => {
          const pdcpObj = {
            country: cp?.country?.value ? cp?.country : cp?.selectedCountry?.value ? cp?.selectedCountry : null,
            selectedCountry: cp?.country,
            country_price: cp?.countryPrice ? cp?.countryPrice : 0,
            country_id: cp?.selectedCountry?.value ? cp?.selectedCountry?.value : cp?.country?.value ? cp?.country?.value : 0,
            cp_pricing_type:cp?.cp_pricing_type,
            country_display_price: cp?.countryDisplayPrice ? cp?.countryDisplayPrice : 0,
          }
          pd_cp_data.push(pdcpObj);
        })
      }
      const FinalData = {
        sku_id: sku_id ? sku_id : 0,
        sku_code: skuCode,
        sku_img: skuImg,
        type: singleSku.type,
        magazine_data: singleSku.type !== 'SINGLE' ? magazine_data : [magazine_data[0]],
        cp_data: cp_data,
        digital_cp_data: digital_cp_data,
        pd_cp_data: pd_cp_data,
        product: product,
        sku_show_ejs:enabled,
      }
      if (FinalData.sku_code != undefined || FinalData.sku_code != null || FinalData.sku_code != "") {
        setTimeout(() => {
          setDisableSubmitButton(false)
        }, 1000)
        setDisableSubmitButton(true)
        MasterServices.saveSkus(FinalData).then((res) => {
          const { data, status } = res;

          if (status) {
            setDisableSubmitButton(false)
            navigate("/sku")
          }
          setDisableSubmitButton(false)
        }).catch((err) => {
          setDisableSubmitButton(false)
          console.log(err)
        })
      }
    }
  }
  return (
    <>
      {!loading ? (
        <div className='min-h-screen'>
          <Breadcrumb pages={pages} />
          <div className="">
            <h1 className="text-xl font-semibold text-gray-900">{sku_id ? 'Update' : 'Add'} SKU </h1>
          </div>
          <div>
            {sku_id <= 0 || !sku_id ? (<div className="sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
              <select
                id="tabs"
                name="tabs"
                className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              >
                {tabs.map((tab) => (
                  <option key={tab.name}>{tab.name}</option>
                ))}
              </select>
            </div>) : (null)}
            {sku_id <= 0 || !sku_id ? (<div className="hidden sm:block">
              <div className="border-b border-gray-200">
                <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                  {tabs.map((tab, index) => (
                    <button
                      key={index}
                      onClick={() => handleTabClick(index)}
                      className={classNames(
                        tab.current
                          ? 'border-red-500 text-red-600'
                          : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                        'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium'
                      )}
                      aria-current={tab.current ? 'page' : undefined}
                    >
                      {tab.name}
                    </button>
                  ))}
                </nav>
              </div>
            </div>) : (null)}
            <div>
              <div className='my-5'>
                {(formDataBundle.length <= 0 || formData.length <= 0) && (
                  <div className="text-red-600">Error: Please fill in SKU information for the {formDataBundle.length <= 0 ? 'Magazine' : formData.length <= 0 ? 'Country Pricing' : ''} bundle.</div>
                )}
                <div className='grid grid-cols-9 gap-3 mb-3'>
                  <div className='col-span-9 sm:col-span-3 md:col-span-3 lg:col-span-3 xl:col-span-3 flex w-full items-center'>
                      <span className="flex">
                       <span className="w-1/2 font-bold">SKU</span>
                       <span className="w-1/2 font-bold">Name</span>
                       <span className="text-red-600 ml-1">*</span>
                      </span>
                      <div className='w-full ml-3'>
                      <input
                        disabled={sku_id}
                        type="text"
                        placeholder="Enter SKU Name"
                        name="skuCode"
                        value={skuCode}
                        onChange={(e) => setSkuCode(e.target.value)}
                        autoComplete="off"
                        className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 md:max-w-xs md:text-sm"
                      />
                      {formErrors.skuCode && (
                        <span className="text-red-600">{formErrors.skuCode}</span>
                      )}
                      </div>
                  </div>
                  
                  <div className='col-span-9 sm:col-span-3 md:col-span-3 lg:col-span-3 xl:col-span-3 flex w-full items-center'>
                      <span className="flex">
                        <span className={`${singleSku.type === "BUNDLE" ? 'w-1/2' : ''} font-bold`}>SKU </span>
                        <span className={`${singleSku.type === "BUNDLE" ? 'w-1/2' : ''} font-bold`}>&nbsp;IMG </span>
                        {singleSku.type === "BUNDLE" && (<span className="text-red-600">*</span>)}
                      </span>

                      <div className='w-full ml-3'>
                      <input
                        onChange={(e) => {
                          onFileChange(e);
                        }}
                        type="file"
                        name="sku_img"
                        autoComplete="off"
                        className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 md:max-w-xs md:text-sm"
                      />
                      <div className="col-span-full">
                        {skuImg ? (
                          <img
                            className="shadow-sm mt-4 w-40"
                            src={skuImg}
                            alt="preview-image"
                          />
                        ) : null}
                      </div>
                      {formErrors.sku_img && (
                        <span className="text-red-600">{formErrors.sku_img}</span>
                      )}
                      </div>
                  </div>

                  <div className='col-span-9 sm:col-span-3 md:col-span-3 lg:col-span-3 xl:col-span-3 flex w-full items-center'>
                      <span className="flex">
                       <span className="w-2/3">Show</span>
                       <span className="w-1/3">EJS</span>                       
                      </span>
                      <div className='w-full ml-3'>
                      <Switch
                        checked={enabled}
                        onChange={setEnabled}
                        className={classNames(
                          enabled ? "bg-[#872341]" : "bg-gray-200",
                          "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2"
                        )}
                      >
                        <span className="sr-only">Use setting</span>
                        <span
                          aria-hidden="true"
                          className={classNames(
                            enabled
                              ? "translate-x-5"
                              : "translate-x-0",
                            "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                          )}
                        />
                      </Switch>
                      &nbsp;&nbsp;&nbsp;&nbsp;{enabled?'  Yes':'  No'}
                      </div>
                  </div>
                </div>

                <div className='flex'>
                  <div className='w-[90%]'>
                    {formDataBundle.map((item, index) => (
                      <div key={item.id} className='grid grid-cols-9 gap-3 mb-3'>
                        <div className='col-span-9 sm:col-span-5 md:col-span-4 lg:col-span-3 xl:col-span-3'>
                        
                          <Select
                            isDisabled={item?.skumagm_id}
                            name={`magazine${index}`}
                            placeholder={
                              <span>
                                Select Magazine
                                <span className="text-red-600">*</span>
                              </span>
                            }
                            onChange={(e) => handleMagazine(e, "BUNDLE", index)}
                            components={animatedComponents}
                            value={item.selectedMagazine}
                            options={magazine}
                            classNamePrefix="select"
                            className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                          />
                          {formErrors[`selectedMagazine${index}`] && (
                            <span className="text-red-600">{formErrors[`selectedMagazine${index}`]}</span>
                          )}
                        </div>
                        <div className='col-span-9 sm:col-span-4 md:col-span-4 lg:col-span-3 xl:col-span-3'>
                          <Select

                            name="magazine_issue"
                            placeholder={
                              <span>
                                Select Magazine Issue
                              </span>
                            }
                            onChange={(e) => handleMagazineIssue(e, "BUNDLE", index)}
                            components={animatedComponents}
                            value={item.selectedMagazineIssue}
                            isMulti
                            isDisabled={item?.skumagm_id ? true : sku_id ? false : item.selectedMagazineIssue ? false : true}
                            options={item.getAllMagazineIssue}
                            classNamePrefix="select"
                            className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                          />
                        </div>
                        <div className='col-span-9 sm:col-span-5 md:col-span-4 lg:col-span-3 xl:col-span-3'>
                          <Select
                            name="subscription"
                            placeholder={
                              <span>
                                Select Subscription
                              </span>
                            }
                            value={item.selectedSubscription}
                            onChange={(e) => handleSubscription(e, "BUNDLE", index)}
                            components={animatedComponents}
                            isDisabled={index > 0 ? true : item?.skumagm_id ? true : sku_id ? false : item.selectedMagazineIssue ? false : true}
                            options={subscription}
                            classNamePrefix="select"
                            className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                          />
                        </div>
                        {singleSku.type == "BUNDLE" ? (<div>
                          <button
                            disabled={(index == 0 || item?.skumagm_id) ? true : false}
                            type="button"
                            onClick={() => removeFormBundleFields(item.id)}
                            className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2 ml-auto"
                          >
                            <XMarkIcon className='w-5 h-5' />
                          </button>
                        </div>) : (null)}
                      </div>
                    ))}
                  </div>
                  <div className='w-[10%]'>
                    {singleSku.type === "BUNDLE" && (
                      <div className='md:ml-3'>
                        <button
                          disabled={sku_id}
                          type="button"
                          onClick={addFormBundleFields}
                          className="inline-flex gap-2 rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2"
                        >
                          Add
                          <PlusCircleIcon className='h-5 w-5 cursor-pointer' />
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                {/* Physical Pricing Start */}
                <div>
                  <div className='flex items-center gap-3 my-5'>
                    <h2 className="text-base font-semibold leading-7 text-gray-900">Physical Country Pricing</h2>
                    <button
                      type="button"
                      onClick={addFormFields}
                      className="inline-flex gap-2 rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2"
                    >
                      Add
                      <PlusCircleIcon className='h-5 w-5 cursor-pointer' />
                    </button>
                  </div>
                  {formData.map((item, index) => (
                    <div key={item.id} className='grid grid-cols-4 gap-3 mb-3'>
                      <div className='col-span-2 sm:col-span-2 md:col-span-2 lg:col-span-1 xl:col-span-1 '>
                        <Select
                          name={`country${index}`}
                          isDisabled={(index == 0 || index == 1 || item?.cp_id) ? true : false}
                          placeholder={
                            <span>
                              Select country
                              <span className="text-red-600">*</span>
                            </span>
                          }
                          onChange={(e) => handleCountry(e, "BUNDLE", index, item?.selectedCountry)}
                          value={item.selectedCountry}
                          components={animatedComponents}
                          options={allCountryList}
                          classNamePrefix="select"
                          className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                        />
                        {formErrors[`selectedCountry${index}`] && (
                          <span className="text-red-600">{formErrors[`selectedCountry${index}`]}</span>
                        )}
                      </div>
                      <div className='col-span-2 sm:col-span-2 md:col-span-2 lg:col-span-1 xl:col-span-1'>
                        <input
                          type="number"
                          placeholder="Enter Regular Price"
                          name="price"
                          min="1"
                          value={item.countryPrice}
                          onChange={(e) => handlePricing(e.target.value, "BUNDLE", index)}
                          autoComplete="off"
                          className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                        />
                        {formErrors[`price${index}`] && (
                          <span className="text-red-600">{formErrors[`price${index}`]}</span>
                        )}
                      </div>
                      <div className='col-span-2 sm:col-span-2 md:col-span-2 lg:col-span-1 xl:col-span-1'>
                        <input
                          type="number"
                          placeholder="Enter Sale price"
                          name="display_price"
                          min="1"
                          value={item.countryDisplayPrice}
                          onChange={(e) => handleDisplayPricing(e.target.value, "BUNDLE", index)}
                          autoComplete="off"
                          className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                        />
                        {formErrors[`display_price${index}`] && (
                          <span className="text-red-600">{formErrors[`display_price${index}`]}</span>
                        )}
                      </div>
                      <div className='col-span-2 sm:col-span-2 md:col-span-2 lg:col-span-1 xl:col-span-1'>
                        <button
                          disabled={(index == 0 || index == 1 || item?.cp_id) ? true : false}
                          type="button"
                          onClick={() => removeFormFields(item)}
                          className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2 ml-auto"
                        >
                          <XMarkIcon className='w-5 h-5' />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
                {/* Physical Pricing End */}

                 {/* Digital Pricing Start */}
                 <div>
                  <div className='flex items-center gap-3 my-5'>
                    <h2 className="text-base font-semibold leading-7 text-gray-900">Digital Country Pricing</h2>
                    <button
                      type="button"
                      onClick={addDigitalFormFields}
                      className="inline-flex gap-2 rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2"
                    >
                      Add
                      <PlusCircleIcon className='h-5 w-5 cursor-pointer' />
                    </button>
                  </div>
                  {digitalFormData.map((item, index) => (
                    <div key={item.id} className='grid grid-cols-4 gap-3 mb-3'>
                      <div className='col-span-2 sm:col-span-2 md:col-span-2 lg:col-span-1 xl:col-span-1 '>
                        <Select
                          name={`country${index}`}
                          isDisabled={(index == 0 || index == 1 || item?.cp_id) ? true : false}
                          placeholder={
                            <span>
                              Select country
                              <span className="text-red-600">*</span>
                            </span>
                          }
                          onChange={(e) => handleDigitalCountry(e, "BUNDLE", index, item?.selectedCountry)}
                          value={item.selectedCountry}
                          components={animatedComponents}
                          options={alldigiCountryList}
                          classNamePrefix="select"
                          className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                        />
                        {digitalFormErrors[`selectedCountry${index}`] && (
                          <span className="text-red-600">{digitalFormErrors[`selectedCountry${index}`]}</span>
                        )}
                      </div>
                      <div className='col-span-2 sm:col-span-2 md:col-span-2 lg:col-span-1 xl:col-span-1'>
                        <input
                          type="number"
                          placeholder="Enter Regular Price"
                          name="price"
                          min="1"
                          value={item.countryPrice}
                          onChange={(e) => handleDigitalPricing(e.target.value, "BUNDLE", index)}
                          autoComplete="off"
                          className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                        />
                        {digitalFormErrors[`price${index}`] && (
                          <span className="text-red-600">{digitalFormErrors[`price${index}`]}</span>
                        )}
                      </div>
                      <div className='col-span-2 sm:col-span-2 md:col-span-2 lg:col-span-1 xl:col-span-1'>
                        <input
                          type="number"
                          placeholder="Enter Sale price"
                          name="display_price"
                          min="1"
                          value={item.countryDisplayPrice}
                          onChange={(e) => handleDigitalDisplayPricing(e.target.value, "BUNDLE", index)}
                          autoComplete="off"
                          className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                        />
                        {digitalFormErrors[`display_price${index}`] && (
                          <span className="text-red-600">{digitalFormErrors[`display_price${index}`]}</span>
                        )}
                      </div>
                      <div className='col-span-2 sm:col-span-2 md:col-span-2 lg:col-span-1 xl:col-span-1'>
                        <button
                          disabled={(index == 0 || index == 1 || item?.cp_id) ? true : false}
                          type="button"
                          onClick={() => removeDigitalFormFields(item)}
                          className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2 ml-auto"
                        >
                          <XMarkIcon className='w-5 h-5' />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
                {/* Digital Pricing End */}

                  {/* Physical+Digital Pricing Start */}
                  <div>
                  <div className='flex items-center gap-3 my-5'>
                    <h2 className="text-base font-semibold leading-7 text-gray-900">Physical+Digital Country Pricing</h2>
                    <button
                      type="button"
                      onClick={addPDFormFields}
                      className="inline-flex gap-2 rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2"
                    >
                      Add
                      <PlusCircleIcon className='h-5 w-5 cursor-pointer' />
                    </button>
                  </div>
                  {pdFormData.map((item, index) => (
                    <div key={item.id} className='grid grid-cols-4 gap-3 mb-3'>
                      <div className='col-span-2 sm:col-span-2 md:col-span-2 lg:col-span-1 xl:col-span-1 '>
                        <Select
                          name={`country${index}`}
                          isDisabled={(index == 0 || index == 1 || item?.cp_id) ? true : false}
                          placeholder={
                            <span>
                              Select country
                              <span className="text-red-600">*</span>
                            </span>
                          }
                          onChange={(e) => handlePDCountry(e, "BUNDLE", index, item?.selectedCountry)}
                          value={item.selectedCountry}
                          components={animatedComponents}
                          options={allpdCountryList}
                          classNamePrefix="select"
                          className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                        />
                        {pdFormErrors[`selectedCountry${index}`] && (
                          <span className="text-red-600">{pdFormErrors[`selectedCountry${index}`]}</span>
                        )}
                      </div>
                      <div className='col-span-2 sm:col-span-2 md:col-span-2 lg:col-span-1 xl:col-span-1'>
                        <input
                          type="number"
                          placeholder="Enter Regular Price"
                          name="price"
                          min="1"
                          value={item.countryPrice}
                          onChange={(e) => handlePDPricing(e.target.value, "BUNDLE", index)}
                          autoComplete="off"
                          className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                        />
                        {pdFormErrors[`price${index}`] && (
                          <span className="text-red-600">{pdFormErrors[`price${index}`]}</span>
                        )}
                      </div>
                      <div className='col-span-2 sm:col-span-2 md:col-span-2 lg:col-span-1 xl:col-span-1'>
                        <input
                          type="number"
                          placeholder="Enter Sale price"
                          name="display_price"
                          min="1"
                          value={item.countryDisplayPrice}
                          onChange={(e) => handlePDDisplayPricing(e.target.value, "BUNDLE", index)}
                          autoComplete="off"
                          className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                        />
                        {pdFormErrors[`display_price${index}`] && (
                          <span className="text-red-600">{pdFormErrors[`display_price${index}`]}</span>
                        )}
                      </div>
                      <div className='col-span-2 sm:col-span-2 md:col-span-2 lg:col-span-1 xl:col-span-1'>
                        <button
                          disabled={(index == 0 || index == 1 || item?.cp_id) ? true : false}
                          type="button"
                          onClick={() => removePDFormFields(item)}
                          className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2 ml-auto"
                        >
                          <XMarkIcon className='w-5 h-5' />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
                {/* Physical+Digital Pricing End */}
                <div>
                  <div className='flex items-center gap-3 my-5'>
                    <h2 className="text-base font-semibold leading-7 text-gray-900">Product</h2>
                  </div>
                  <div className='grid grid-cols-3 gap-3 mb-3'>
                    <div className='col-span-3 sm:col-span-3 md:col-span-3 lg:col-span-2 xl:col-span-1'>
                      <span>
                        Select Product
                      </span>
                      <Select
                        name="country"
                        placeholder={
                          <span>
                            Select Product
                          </span>
                        }
                        value={selectedProduct}
                        onChange={(e) => handleproducts(e)}
                        components={animatedComponents}
                        options={allProduct}
                        isMulti
                        classNamePrefix="select"
                        className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                      />
                      {/* {formErrors.selectedProduct && (
                    <span className="text-red-600">{formErrors.selectedProduct}</span>
                  )} */}
                    </div>
                  </div>
                </div>
                <div className="mt-10 flex items-center justify-end gap-x-6">
                  
                  <button
                    onClick={() => handleSave()}
                    type="submit"
                    className="bbb inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2"
                    disabled={disableSubmitButton}
                  >
                    {sku_id ? (disableSubmitButton ? <FaSpinner color="gold" /> : "Update") : (disableSubmitButton ? <FaSpinner color="gold" /> : "Add")}
                  </button>
                </div>
              </div>
            </div>

          </div>
        </div>) : (

        <FallingLinesLoader />
      )}</>
  )
}

export default AddSku