import React, { useEffect, useState } from 'react';
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  useAsyncDebounce,
  usePagination,
} from "react-table";
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Button, PageButton } from "./paginationButtons";
import { SortIcon, SortUpIcon, SortDownIcon } from "../../assets/icons/sorting";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from "@heroicons/react/20/solid";
// import { Link } from "react-router-dom";
import { getAllowedActions } from "../../helper/commonHelper";
import { FaAngleDown, FaAngleUp, FaJedi } from 'react-icons/fa';
import { toast } from "react-toast";
import makeAnimated from "react-select/animated";
import Select from 'react-select';
import MasterServices from "../../ApiServices/MasterServices";
import { FaSpinner } from "react-icons/fa";
import moment from 'moment';

// Define a default UI for filtering

// This is a custom filter UI for selecting
// a unique option from a list
export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id, render },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  return (
    <label className="flex gap-x-2 items-baseline">
      <span className="text-gray-700">{render("Header")}: </span>
      <select
        className="rounded-md border-gray-300 shadow-sm focus:border-cyan-300 focus:ring focus:ring-cyan-200 focus:ring-opacity-50"
        name={id}
        id={id}
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    </label>
  );
}

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  // console.log(preGlobalFilteredRows, "onFilterChange >>>>>")
  const [value, setValue] = React.useState(globalFilter);
  const onFilterChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);
  // console.log(value, 'value')
  return (
    <label className="flex gap-x-2 items-baseline">
      {/* <span className="text-gray-700">Search: </span> */}
      <input
        type="text"
        className="rounded-md border-gray-300 shadow-sm focus:border-cyan-300 focus:ring focus:ring-cyan-200 focus:ring-opacity-50"
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onFilterChange(e.target.value);
        }}
        placeholder={`${count} records...`}
      />
    </label>
  );
}

function Table({
  columns,
  data,
  is_toggle,
  table_id,
  printRef,
  zoom,
  printOption,
  printAddressDataHandler,
  setShowPrintAddress,
  bigFilterArea
}) {
  const {
    state,
    pageOptions,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    preGlobalFilteredRows,
    setGlobalFilter,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      is_toggle,

      // initialState: { pageIndex: 1 },
    },
    useFilters, // useFilters!
    useGlobalFilter,
    useSortBy,
    usePagination // new
  );

  useEffect(() => {
    setPageSize(50);
  }, []);
  const [openRow, setOpenRow] = useState({}); // State to track open rows
  const toggleRow = (rowIndex) => {
    setOpenRow(prevOpenRow => ({
      ...prevOpenRow,
      [rowIndex]: !prevOpenRow[rowIndex]
    }));
  };

  const navigate = useNavigate()
  const months = [];
  const currentYear = new Date().getFullYear();
  for (let i = 1; i <= 12; i++) {
    const monthValue = i < 10 ? `0${i}` : `${i}`;
    const monthLabel = new Date(currentYear, i - 1, 1).toLocaleString('en-US', { month: 'short' });
    months.push({ label: monthLabel, value: monthValue });
  }

  const [selectedMonth, setSelectedMonth] = useState(Array(data.length).fill(null))
  const [allData, setAllData] = useState(data);

  const [selectedDate, setSelectedDate] = useState([]);
  const [selectedDateDrop, setSelectedDateDrop] = useState([]);
  const [sendData, setSendData] = useState([]);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [disableSubmitButtonInt, setDisableSubmitButtonInt] = useState(false);

  const getNextMonth = (currentMonth) => {
    if (!currentMonth) {
      return null; // handle case where currentMonth is undefined
    }
    const currentIndex = months.findIndex(month => month.value === currentMonth.value);
    const nextIndex = (currentIndex + 1) % months.length;
    return months[nextIndex];
  };

  const handleMonth = (e, rowIndex, DateArrDropCount, item_ind) => {
    console.log("rowIndex", rowIndex)
    const newSelectedMonths = [...selectedMonth];
    newSelectedMonths[rowIndex] = e;
    setSelectedMonth(newSelectedMonths);

    setAllData((prevData) => {
      return prevData.map((row, i) => {
        if (row.dateDayType == 'Date') {
          let nextMonthIndex;
          for (let j = 0; j < row.DateArrDropCount.length; j++) {
            if (item_ind === i) {
              if (!row.DateArrDropCount[rowIndex]) {
                row.DateArrDropCount[rowIndex] = {};
              }

              // Ensure newSelectedMonths property exists in each DateArrDropCount array
              if (!row.DateArrDropCount[j]) {
                row.DateArrDropCount[j] = {};
              }

              // Set the newSelectedMonths for the next row
              row.DateArrDropCount[j].newSelectedMonths = {
                label: "",
                value: "",
              };

              // Check if the first row has a selected month
              if (newSelectedMonths[0]?.value !== undefined) {
                // Set the newSelectedMonths based on the selected month in the first row
                // const currentMonthIndex = parseInt(newSelectedMonths[0]?.value) - 1;
                // let nextMonthIndex;
                // // Ensure that the next month index doesn't go beyond the array bounds
                // if (row?.subs_master_magazines_relation?.mm_interval_type == "Date") {
                //   if (j == 0) {
                //     nextMonthIndex = (currentMonthIndex + j) % months.length;
                //   } else {
                //     let nextMonth = parseInt(row.DateArrDropCount[j].value);

                //     nextMonthIndex = (currentMonthIndex + j) % months.length;
                //     nextMonthIndex = parseInt(nextMonthIndex) + parseInt(nextMonth) - 1;
                //     if (nextMonthIndex == 12) {
                //       nextMonthIndex = 0;
                //     }
                //   }
                // } else {
                //   nextMonthIndex = (currentMonthIndex + j) % months.length;
                // }

                // row.DateArrDropCount[j].value=3
                console.log(row.DateArrDropCount[j]);
                const currentMonthIndex = parseInt(newSelectedMonths[0]?.value) - 1;
                let nextMonth = parseInt(row.DateArrDropCount[j].value);
                if (row?.subs_master_magazines_relation?.mm_interval_type == "Date") {
                  if (j == 0) {
                    nextMonthIndex = (currentMonthIndex + j) % months.length;
                  } else {
                    nextMonthIndex = nextMonthIndex && !isNaN(nextMonthIndex) ? parseInt(nextMonthIndex) : 0;
                    nextMonthIndex = parseInt(nextMonthIndex) + parseInt(row.DateArrDropCount[j].value);

                    if (parseInt(nextMonthIndex) >= 12) {
                      // alert(nextMonthIndex + "jS" + j,"PS"+row.DateArrDropCount[j].value)
                      nextMonthIndex = 0;
                      if (nextMonth == 3) {

                        nextMonthIndex = 3 - 2;
                      } else if (nextMonth == 2) {
                        nextMonthIndex = 2 - 1;
                      } else if (nextMonth == 6) {
                        // nextMonthIndex = 6-1;
                      } else {
                        nextMonthIndex = 0;
                      }
                      // alert(nextMonthIndex + "FInal" + j)
                    }
                  }
                } else {
                  nextMonthIndex = (currentMonthIndex + j) % months.length;
                }
                // alert(nextMonthIndex+"PS"+j)
                row.DateArrDropCount[j].newSelectedMonths = months[parseInt(nextMonthIndex)];
              }
            }
          }
        } else {
          let yearApplyFlag = false;
          if (item_ind === i) {
            const selectedMonthValue = parseInt(newSelectedMonths[0]?.value);
            row.DateArrDropCount.forEach((dateObj, j) => {
              const currentYear = new Date().getFullYear();
              dateObj.newSelectedMonths = e;
              if (j !== 0) {
                // Check if the second row's date is in the same month or next month
                const isSameMonthOrNext = row.DateArrDropCount[j - 1].value <= row.DateArrDropCount[j]?.value;
                const currindex = months.findIndex(month => month.value === row.DateArrDropCount[j - 1]?.newSelectedMonths?.value);
                if (isSameMonthOrNext) {

                  dateObj.newSelectedMonths = {
                    ...row.DateArrDropCount[j - 1]?.newSelectedMonths
                  };

                } else {
                  // const currindex = months.findIndex(month => month.value === row.DateArrDropCount[j - 1]?.newSelectedMonths?.value);
                  console.log("currindex=", currindex)
                  if (currindex == 11) {
                    dateObj.newSelectedMonths = {
                      label: months[0].label,
                      value: months[0].value,
                    };
                  } else {
                    dateObj.newSelectedMonths = {
                      label: months[parseInt(currindex) + 1].label,
                      value: months[parseInt(currindex) + 1].value,
                    };
                  }
                }
                // Check if the selected date is the last day of the month
                // const lastDayOfMonth = new Date(currentYear, dateObj.newSelectedMonths.value, 0).getDate();
                // console.log("dateObj.newSelectedMonths.value=>", dateObj.newSelectedMonths.value, isSameMonthOrNext, currindex, dateObj.value);
                // if (!isSameMonthOrNext && dateObj.newSelectedMonths.value === 12 && currindex == 11) {

                //   console.log("lastDayOfMonth=>", lastDayOfMonth, dateObj.value);
                // }

                const prevDate = row.DateArrDropCount[j - 1].selectedDate;
                const prevMonth = parseInt(prevDate.split('-')[1]);
                const prevYear = parseInt(prevDate.split('-')[1]);


                if (prevMonth > dateObj.newSelectedMonths.value && !isSameMonthOrNext) {
                  const nextYear = currentYear + 1;
                  dateObj.newSelectedMonths.value = months[0].value;
                  dateObj.selectedDate = `${nextYear}-${dateObj.newSelectedMonths.value}-${dateObj.value}`;
                  yearApplyFlag = true;
                } else {
                  dateObj.selectedDate = `${yearApplyFlag ? (currentYear + 1) : currentYear}-${dateObj.newSelectedMonths.value}-${dateObj.value}`;
                }
              } else {
                dateObj.selectedDate = `${yearApplyFlag ? (currentYear + 1) : currentYear}-${dateObj.newSelectedMonths.value}-${dateObj.value}`;
              }

              // let Day = dateObj.value < 10 ? `0${dateObj.value}` : `${dateObj.value}`;
              // dateObj.selectedDate = currentYear + '-' + dateObj.newSelectedMonths?.value + '-' + Day;
            });
          }
        }

        if (item_ind === i) {
          sendDataFunc(row, row?.DateArrDropCount);
        }
        console.log("row=>", row);
        return row;
      });
    });
  };
  const incrementMonth = (monthValue) => {
    let nextMonth = parseInt(monthValue, 10) + 1;
    return nextMonth < 10 ? `0${nextMonth}` : `${nextMonth}`;
  }

  const getDateArr = (sortedPayload, selectedMonth, currentYear, DateArr) => {
    DateArr = [];
    sortedPayload?.map((item, ind) => {
      let currentMonthValue = selectedMonth?.value;
      if (ind !== 0) {
        // Increment logic based on your requirement
        currentMonthValue = incrementMonth(selectedMonth?.value);
      }
      let itemDate = currentYear + '-' + currentMonthValue + '-' + item.value;
      let myDate = new Date(itemDate);
      DateArr.push({
        date: myDate, subinm_fk_midm_id: item?.midm_id, subinm_type: item?.midm_type, subinm_label: item?.label
        , subinm_value: item?.value
        , subinm_label_value_json: item?.midm_label_value_json, selectedMonth: selectedMonth
      })
    })
    return DateArr;
  }

  // Function to generate DateArr based on duration and selectedMonth
  const generateMonthArr = (sortedPayload, duration, selectedMonth, currentYear) => {
    const DateArr = [];
    const startMonth = parseInt(selectedMonth.value, 10); // Assuming selectedMonth is an object like { label: "Feb", value: "02" }
    let subinm_fk_midm_id = sortedPayload.length > 0 ? sortedPayload[0]?.midm_id : 0;
    let subinm_type = sortedPayload.length > 0 ? sortedPayload[0]?.midm_type : 0;
    let subinm_label = sortedPayload.length > 0 ? sortedPayload[0]?.label : 0;
    let subinm_value = sortedPayload.length > 0 ? sortedPayload[0]?.value : 0;
    let subinm_label_value_json = sortedPayload.length > 0 ? sortedPayload[0]?.midm_label_value_json : 0;
    let myDate;
    for (let i = 0; i < duration; i++) {
      const monthValue = (startMonth + i) % 12 || 12; // Wrap around to January if needed
      const monthLabel = new Date(currentYear, monthValue - 1, 1).toLocaleString('en-US', { month: 'short' });

      DateArr.push({
        label: monthLabel, value: monthValue < 10 ? `0${monthValue}` : `${monthValue}`, date: myDate, subinm_fk_midm_id, subinm_type, subinm_label
        , subinm_value
        , subinm_label_value_json, selectedMonth: selectedMonth
      });
    }

    return DateArr;
  }

  const handleUpdateSubsInterval = async (index) => {

    if (sendData?.length > 0) {
      const hasInvalidData = sendData.some((item) => {
        if (!item.selectedDate || !item.newSelectedMonths_value) {

          if (!item.selectedDate) {
            toast.error('Date Field is Required!')
          }
          if (!item.newSelectedMonths_value) {
            toast.error('Month Field is Required')
          }
          return true;
        }
        if (item.subinm_dateDayType === "Day" && !item.value) {
          if (!item.newSelectedMonths_value) {
            toast.error('Day Field is Required')
          }
          return true;
        }
        return false;
      });

      if (hasInvalidData) {
        // If any condition is true, return false
        // alert("Error")
        // toast.error('')
        return false;
      } else {
        // alert("TRue")
        let Payload = { sendData }
        console.log("Payload=>", Payload);
        // return false;

        // setLoading(true)
        setTimeout(() => {
          setDisableSubmitButton(false)
        }, 1000)
        setDisableSubmitButton(true)
        let res = await MasterServices.saveSubscriptionInterval(Payload)
        if (res.status == 200) {

          setSendData([])
          console.log("res.data returnData=>", res?.data?.data)
          setDisableSubmitButton(false)
          setAllData((prevData) => {
            return prevData.map((row, i) => {
              if (i == index) {
                row.subscription_interval_map = res?.data?.data;
              }
              return row;
            });
          });

          toast.success(res?.data?.message)
          navigate("/subscription");
        } else {
          setDisableSubmitButton(false)
          toast.error(res?.data?.message)
        }
      }
    } else {
      toast.error('Sorry Wrong Data!')
    }
  }

  const handleSubsInterval = async (d_item, index, d_index, DateArrDropCount, subinm_id) => {
    setAllData((prevData) => {
      return prevData.map((row, i) => {
        if (i == index) {
          row.DateArrDropCount.forEach((dateObj, j) => {
            if (dateObj.subinm_id == subinm_id) {
              dateObj.disableSubmitButtonInt = true;
            }
          })
        }
        return row;
      });
    });
    let Payload = d_item;

    setTimeout(() => {
      setDisableSubmitButtonInt(false)
    }, 1000)
    setDisableSubmitButtonInt(true)
    let res = await MasterServices.updateSubscriptionInterval(Payload)
    if (res.status == 200) {
      setAllData((prevData) => {
        return prevData.map((row, i) => {
          if (i == index) {
            row.DateArrDropCount.forEach((dateObj, j) => {
              if (dateObj.subinm_id == subinm_id) {
                dateObj.disableSubmitButtonInt = false;
              }
            })
          }
          return row;
        });
      });
      setDisableSubmitButtonInt(false)
      toast.success(res?.data?.message)
      navigate("/subscription");
    } else {
      setDisableSubmitButtonInt(false)
      toast.error(res?.data?.message)
    }

  }

  const getDayMonth = (allData, e, selectedDate, d_index, DateArrDropCount, midm_type, index) => {
    let newSelectedDate;
    let MonthsArr = [];
    if (midm_type === 'Day') {
      newSelectedDate = [...selectedDate];
      selectedDate[d_index] = e;
      setSelectedDateDrop(selectedDate);
    } else {
      newSelectedDate = [...selectedDate];
      selectedDate[d_index] = e;
      setSelectedDate(selectedDate);
    }

    // Get the current year
    const currentYear = new Date().getFullYear();

    setAllData((prevData) => {
      return prevData.map((row, i) => {
        const newRow = { ...row };
        let yearApplyFlag = false;
        if (index === i) {
          for (let j = 0; j < row.DateArrDropCount.length; j++) {
            let month;
            let day;
            let Year;

            if (midm_type === 'Day') {
              month = newRow.DateArrDropCount[d_index]?.newSelectedMonths?.value;
              day = e?.value;
              //Moth LOgic Again Start
              // Set the newSelectedMonths based on the selected month in the first row
              const currentMonthIndex = parseInt(newRow.DateArrDropCount[d_index]?.newSelectedMonths?.value) - 1;
              // Ensure that the next month index doesn't go beyond the array bounds
              const nextMonthIndex = (currentMonthIndex + j) % months.length;
              MonthsArr.push(months[nextMonthIndex]);
              //Moth LOgic Again ENd

              // Sorting logic after updating DateArrDropCount Start
              // Find the index of the selected day value in the array
              const selectedIndex = newRow.DateArrDropCount.findIndex(item => item.value === e.value);

              // Rearrange the array starting from the selected index
              newRow.DateArrDropCount = [
                ...newRow.DateArrDropCount.slice(selectedIndex),
                ...newRow.DateArrDropCount.slice(0, selectedIndex),
              ];

              MonthsArr?.map((item_o, ind_o) => {
                newRow?.DateArrDropCount?.map((item_t, ind_t) => {
                  if (ind_t == ind_o) {
                    item_t.newSelectedMonths = item_o;
                    item_t.month = item_o;
                  }
                })
              })
              // Sorting logic after updating DateArrDropCount End
            } else {
              month = newRow.DateArrDropCount[j]?.newSelectedMonths?.value;

              day = e.split('-')[2];
              Year = e.split('-')[0];
            }

            Year = yearApplyFlag ? (parseInt(Year) + 1) : Year;
            if (j === d_index) {
              if (!newRow.DateArrDropCount[d_index]?.selectedDate) {
                newRow.DateArrDropCount[d_index] = {
                  ...newRow.DateArrDropCount[d_index],
                  month: getNextMonth(newRow.DateArrDropCount[j]?.newSelectedMonths),
                  selectedDate: midm_type === 'Day' ? currentYear + '-' + month + '-' + day : Year + '-' + month + '-' + day,
                };
              } else {
                newRow.DateArrDropCount[d_index] = {
                  ...newRow.DateArrDropCount[d_index],
                  month: getNextMonth(newRow.DateArrDropCount[j]?.newSelectedMonths),
                  selectedDate: midm_type === 'Day' ? currentYear + '-' + month + '-' + day : Year + '-' + month + '-' + day,
                };
              }
            } else {
              if (!newRow.DateArrDropCount[j]?.selectedDate) {
                newRow.DateArrDropCount[j] = {
                  ...newRow.DateArrDropCount[j],
                  month: getNextMonth(newRow.DateArrDropCount[j - 1]?.newSelectedMonths),
                  selectedDate: midm_type === 'Day' ? currentYear + '-' + month + '-' + day : Year + '-' + month + '-' + day,
                };
              } else {
                newRow.DateArrDropCount[j] = {
                  ...newRow.DateArrDropCount[j],
                  month: getNextMonth(newRow.DateArrDropCount[j - 1]?.newSelectedMonths),
                  selectedDate: midm_type === 'Day' ? currentYear + '-' + month + '-' + day : Year + '-' + month + '-' + day,
                };
              }
            }
            if ((newRow.DateArrDropCount[j].newSelectedMonths?.value == 12 || newRow.DateArrDropCount[j].newSelectedMonths?.value == 11) && newRow.DateArrDropCount[j].value >= 2) {
              yearApplyFlag = true;
            }
          }

          // Automatically select days greater than the selected day in other dropdowns
          for (let k = 1; k < newRow.DateArrDropCount.length; k++) {
            newRow.DateArrDropCount[k].selectedDateDrop = {
              label: newRow.DateArrDropCount[k].label,
              value: newRow.DateArrDropCount[k].value,
            };
          }
        }

        if (index === i) {
          sendDataFunc(newRow, newRow?.DateArrDropCount);
        }
        console.log("newRow=>", newRow);
        return newRow;
      });
    });
  };

  const getTrackNo = (e, d_index, index, type) => {
    setAllData((prevData) => {
      return prevData.map((row, i) => {
        let newRow = { ...row };

        if (index === i) {
          if (type == 'track_no') {
            newRow.DateArrDropCount[d_index].track_no = e ? e : newRow.DateArrDropCount[d_index].track_no;
          } else if (type == 'shipped_date') {
            newRow.DateArrDropCount[d_index].shipped_date = e ? e : newRow.DateArrDropCount[d_index].shipped_date;
          }
          sendDataFunc(newRow, newRow?.DateArrDropCount);
        }
        console.log("newRow=>", newRow)
        return newRow;
      });
    });
  };

  const sendDataFunc = (newRow, DateArrDropCount) => {
    let sendDataArr = [];
    if (DateArrDropCount?.length > 0) {
      DateArrDropCount?.map((item_o, ind_o) => {
        let sendDataObj = {
          subinm_id: item_o?.subinm_id ? item_o?.subinm_id : null,
          subinm_fk_midm_id: item_o?.midm_id ? item_o?.midm_id : null,
          subinm_fk_subs_id: newRow?.subs_id ? newRow?.subs_id : null,
          subinm_fk_mm_id: newRow?.subs_fk_mm_id ? newRow?.subs_fk_mm_id : null,
          subinm_fk_sku_id: newRow?.subs_fk_sku_id ? newRow?.subs_fk_sku_id : null,
          subinm_fk_ord_id: newRow?.subs_fk_ord_id ? newRow?.subs_fk_ord_id : null,
          subinm_fk_ord_skum_id: newRow?.subs_fk_ord_skum_id ? newRow?.subs_fk_ord_skum_id : null,
          subinm_fk_customer_id: newRow?.subs_fk_customer_id ? newRow?.subs_fk_customer_id : null,

          label: item_o?.label ? item_o?.label : null,
          value: item_o?.value ? item_o?.value : null,
          subinm_label_value_json: {
            label: item_o?.label ? item_o?.label : null,
            value: item_o?.value ? item_o?.value : null,
          },
          subinm_dateDayType: newRow?.dateDayType ? newRow?.dateDayType : null,
          midm_type: item_o?.midm_type ? item_o?.midm_type : null,
          midm_label: item_o?.midm_label_value_json?.label ? item_o?.midm_label_value_json?.label : null,
          midm_value: item_o?.midm_label_value_json?.value ? item_o?.midm_label_value_json?.value : null,
          midm_label_value_json: item_o?.midm_label_value_json ? item_o?.midm_label_value_json : { label: "", value: "" },

          newSelectedMonths_label: item_o?.newSelectedMonths?.label ? item_o?.newSelectedMonths?.label : null,
          newSelectedMonths_value: item_o?.newSelectedMonths?.value ? item_o?.newSelectedMonths?.value : null,
          newSelectedMonths: item_o?.newSelectedMonths ? item_o?.newSelectedMonths : { label: "", value: "" },
          duration: item_o?.duration ? item_o?.duration : null,
          shipped_date: item_o?.shipped_date ? item_o?.shipped_date : null,
          selectedDate: item_o?.selectedDate ? item_o?.selectedDate : null,
          track_no: item_o?.track_no ? item_o?.track_no : null

        };
        sendDataArr.push(sendDataObj);
        setSendData(sendDataArr);
      });
    }
  }

  // console.log("sendData=>", sendData);

  // // Render the UI for your table
  const animatedComponents = makeAnimated();
  const [selectOption, setSelectOption] = useState([]);
  const [selectOptions, setSelectOptions] = useState([]);
  const [refreshSelect, setRefreshSelect] = useState(0)

  const handleChange = (selectedOptions) => {
    setSelectOptions(selectedOptions);
  };

  const clearSelectOption = () => {
    setSelectOptions([]);
    setRefreshSelect(Math.random());
  }

  const [options, setOptions] = useState([]);
  useEffect(() => {
    populateOptions();
  }, [data]);

  useEffect(() => {
    if (printOption) {
      printDataHandler();
    }
  }, [selectOptions,]);

  function filterDataByRanges(data, ranges) {
    let filteredData = [];
    ranges.forEach(range => {
      filteredData = filteredData.concat(data.slice(range.min - 1, range.max));
    });
    return filteredData;
  }


  const printDataHandler = () => {
    const ranges = selectOptions.map(item => {
      const [minStr, maxStr] = item.value.split('/');
      const min = parseInt(minStr);
      const max = parseInt(maxStr);
      return { min, max };
    });
    const filteredData = filterDataByRanges(data, ranges);
    printAddressDataHandler(filteredData)
  };

  const populateOptions = () => {
    const count = data.length;
    const numOptions = Math.ceil(count / 12);

    if (numOptions === 1) {
      setOptions([{
        value: `1/${count}`,
        label: `1 to ${count}`
      }]);
    } else {
      const newOptions = [];
      for (let i = 0; i < numOptions; i++) {
        const start = i * 12 + 1;
        const end = Math.min((i + 1) * 12, count);
        newOptions.push({
          label: `${start} to ${end}`,
          value: `${start}/${end}`
        });
      }
      setOptions(newOptions);
    }
  };

  return (
    <>
      {/* SEARCH UI */}
      <div className="sm:flex sm:gap-x-3 mt-5 justify-between">
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />

        {headerGroups.map((headerGroup) =>
          headerGroup.headers.map((column) =>
            column.Filter ? (
              <div className="mt-2 sm:mt-0" key={column.id}>
                {column.render("Filter")}
              </div>
            ) : null
          )
        )}

        {/* Big Filter Area */}
        {bigFilterArea && bigFilterArea}
        {printOption && data && data.length > 1 && (
          <div className="sm:flex sm:gap-x-3 justify-end w-full ">
            <div className="p-2">
              <label htmlFor="" className="text-md font-medium">
                Select Batch To Print Address
              </label>
            </div>
            <Select
              id='printAdressSelect'
              key={refreshSelect}
              name={`print-range`}
              placeholder={
                <span>
                  Select Range
                  <span className="text-red-600">*</span>
                </span>
              }
              onChange={(e) => handleChange(e)}
              components={animatedComponents}
              // isDisabled={selectOptions.length >= 3}
              options={options}
              isMulti
              classNamePrefix="select"
              className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
            />
            {selectOption && selectOption !== "" ? (
              <>
                <button
                  type="button"
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2 md:ml-2 mt-4 md:mt-0"
                  onClick={() => {
                    printDataHandler();
                    setShowPrintAddress(true);
                  }}
                >
                  Print Bulk
                </button>
                <button
                  type="button"
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2 ml-2 mt-4 md:mt-0"
                  onClick={clearSelectOption}
                >
                  Clear
                </button>
              </>
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
      {/* TABLE UI */}
      <div
        id={table_id}
        ref={printRef}
        className={`mt-4 flex flex-col ${zoom ? zoom : ""}`}
      >
        <div className="my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border border-gray-200 sm:rounded-lg">
              <table
                {...getTableProps()}
                className="min-w-full divide-y divide-gray-200"
              >
                <thead className="bg-gray-50">
                  {headerGroups.map((headerGroup, index) => (
                    <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                      {/* <th>Sr No.</th> */}
                      {is_toggle ? (<th
                        scope="col"
                        className="group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"

                      ></th>) : (null)} {/* Additional header for toggle column */}

                      {headerGroup.headers.map((column, index) => (
                        // Add the sorting props to control sorting. For this example
                        // we can add them into the header props
                        <th key={index}
                          scope="col"
                          className="group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          {...column.getHeaderProps({
                            style: { width: column.width },
                          })}
                        >
                          <div className="flex items-center justify-between">
                            {column.render("Header")}
                            {/* Add a sort direction indicator */}
                            <span>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <SortDownIcon className="w-4 h-4 text-gray-400" />
                                ) : (
                                  <SortUpIcon className="w-4 h-4 text-gray-400" />
                                )
                              ) : (
                                <SortIcon className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100" />
                              )}
                            </span>
                          </div>
                        </th>
                      ))}

                    </tr>
                  ))}
                </thead>
                <tbody
                  {...getTableBodyProps()}
                  className="bg-white divide-y divide-gray-200"
                >
                  {page?.length > 0 ? (
                    page.map((row, i) => {
                      // new

                      prepareRow(row);
                      return (
                        <>
                          <tr key={i} {...row.getRowProps()}>
                            {/* <td
                              className={`px-6 py-4 whitespace-nowrap text-left `}
                              role="cell">
                              {parseInt(i)+1}
                            </td> */}
                            {(is_toggle) ? (<td
                              className={`px-6 py-4 whitespace-nowrap text-left `}
                              role="cell">
                              {(allData[i]?.DateArrDropCount?.length > 0) ? (<button onClick={() => toggleRow(i)}>
                                {openRow[i] ? <FaAngleUp /> : <FaAngleDown />}
                              </button>) : (<></>)}
                            </td>) : (<></>)}
                            {row.cells.map((cell, index) => {
                              return (
                                <td
                                  key={index}
                                  {...cell.getCellProps()}
                                  className={`px-6 py-4 whitespace-nowrap text-left `}
                                  role="cell"
                                >
                                  {cell.column.Cell.name === "defaultRenderer" ? (
                                    <div className="text-sm text-gray-500">
                                      {cell.render("Cell")}
                                    </div>
                                  ) : (
                                    cell.render("Cell")
                                  )}
                                </td>

                              );
                            })}
                          </tr>

                          {openRow[i] && (
                            <tr>
                              <td colSpan={row.cells.length + 1}>
                                <div>
                                  {(allData[i]?.DateArrDropCount?.length > 0) ? (<table className="w-[100%] ">
                                    <thead className="">
                                      <tr className=' rounded-md'>
                                        <th className="group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Issues</th>
                                        {allData[i].dateDayType == 'Day' ? (<th className="group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Day</th>) : null}
                                        <th className="group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Month</th>
                                        <th className="group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Tracking No</th>
                                        <th className="group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                                        <th className="group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">shipped date</th>
                                        <th className="group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                                      </tr>
                                    </thead>
                                    <tbody
                                      className="bg-white ">
                                      {

                                        (allData[i]?.DateArrDropCount?.length > 0) ? (allData[i]?.DateArrDropCount?.map((d_item, d_index) => (
                                          <>
                                            <tr key={d_index}>
                                              <td className={`px-6 py-4 whitespace-nowrap text-left cursor-pointer text-sm text-gray-500`} style={{ width: '1%' }}>{parseInt(d_index + 1)}</td>
                                              {allData[i].dateDayType == 'Day' ? (<td className={`px-6 py-4 whitespace-nowrap text-left cursor-pointer text-sm text-gray-500`} style={{ width: '10%' }}> {allData[i]?.DateArrDropCount && allData[i]?.DateArrDropCount?.length > 0 ? (
                                                <Select
                                                  className="flex-1 w-full max-w-lg rounded-md border-[1px] shadow-sm focus:border-[1px] sm:max-w-xs sm:text-sm "
                                                  placeholder="Select Interval"
                                                  name="start_date_interval"
                                                  id={`start_date_interval_${d_index}`}  // Unique id for each row
                                                  onChange={(e) => {
                                                    setSelectedDateDrop(e);
                                                    getDayMonth(allData[i], e, selectedDateDrop, d_index, allData[i]?.DateArrDropCount, d_item?.midm_type, i);
                                                    // handleUpdateSubsInterval(allData[i], e.target.value, selectedMonth)
                                                  }}
                                                  isDisabled={d_item?.newSelectedMonths?.value ? true : d_index == 0 ? false : true}
                                                  // isDisabled={!d_item?.newSelectedMonths?.value ? true : d_index == 0 ? false : true}
                                                  options={allData[i]?.DateArrDropCount}
                                                  value={d_item?.selectedDateDrop}
                                                />

                                              ) : (null)}</td>) : null}
                                              <td className={`px-6 py-4 whitespace-nowrap text-left cursor-pointer text-sm text-gray-500`} style={{ width: '5%' }}>
                                                {/* {allData[i]?.subscription_interval_map?.length} */}
                                                {months && months?.length > 0 ? (
                                                  <Select
                                                    className="rounded-md border-[1px] shadow-sm focus:border-[1px] w-32"
                                                    placeholder="Select Month"
                                                    name="month_name"
                                                    id={`month_name_${d_index}`}  // Unique id for each row
                                                    onChange={(e) => handleMonth(e, d_index, allData[i]?.DateArrDropCount, i)}
                                                    options={months}
                                                    isDisabled={(allData[i]?.subscription_interval_map?.length <= 0 && d_index == 0) ? false : true}
                                                    value={d_item?.newSelectedMonths}
                                                  />
                                                ) : (null)}</td>
                                              <td className={`px-6 py-4 whitespace-nowrap text-left cursor-pointer text-sm text-gray-500`} style={{ width: '5%' }}><input
                                                type="text"
                                                name="track_no"
                                                id={`track_no_${d_index}`}

                                                disabled={!d_item?.newSelectedMonths?.value ? true : false}

                                                value={allData[i]?.DateArrDropCount[d_index]?.track_no}
                                                onChange={(e) => { console.log('onChange triggered track_no:', e.target.value); getTrackNo(e.target.value, d_index, i, 'track_no') }}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ed1b24] sm:text-sm sm:leading-6"
                                                placeholder="Tracking No"
                                              /></td>
                                              <td className={`px-6 py-4 whitespace-nowrap text-left cursor-pointer text-sm text-gray-500`} style={{ width: '5%' }}>
                                                {/* {d_item?.midm_type} */}
                                                {allData[i]?.DateArrDropCount && allData[i]?.DateArrDropCount?.length > 0 && (d_item?.midm_type == 'Date' || d_item?.midm_type == 'Day') ? (
                                                  <input
                                                    type="date"
                                                    name="start_date"
                                                    id={`start_date_${d_index}`}
                                                    disabled={allData[i].dateDayType == 'Day' ? true : !d_item?.newSelectedMonths?.value ? true : d_index > 0 ? true : false}
                                                    min={new Date().toISOString().split('T')[0]} // Set the minimum date to the current date
                                                    value={allData[i]?.DateArrDropCount[d_index]?.selectedDate ? moment(allData[i]?.DateArrDropCount[d_index]?.selectedDate).format("YYYY-MM-DD") : null}
                                                    onChange={(e) => { console.log('onChange triggered:', e.target.value); setSelectedDate(e.target.value); getDayMonth(allData[i], e.target.value, selectedDate, d_index, allData[i]?.DateArrDropCount, d_item?.midm_type, i) }}
                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ed1b24] sm:text-sm sm:leading-6"
                                                    placeholder="Start Date"
                                                  />) : (null)}

                                              </td>
                                              <td className={`px-6 py-4 whitespace-nowrap text-left cursor-pointer text-sm text-gray-500`} style={{ width: '5%' }}>{allData[i]?.DateArrDropCount && allData[i]?.DateArrDropCount?.length > 0 ? (
                                                <input
                                                  type="date"
                                                  name="shipped_date"
                                                  id={`shipped_date_${d_index}`}
                                                  disabled={!d_item?.newSelectedMonths?.value ? true : false}
                                                  min={new Date().toISOString().split('T')[0]} // Set the minimum date to the current date
                                                  value={allData[i]?.DateArrDropCount[d_index]?.shipped_date ? moment(allData[i]?.DateArrDropCount[d_index]?.shipped_date).format("YYYY-MM-DD") : null}
                                                  onChange={(e) => { console.log('onChange triggered Shipped Date:', e.target.value); getTrackNo(e.target.value, d_index, i, 'shipped_date') }}
                                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ed1b24] sm:text-sm sm:leading-6"
                                                  placeholder="Start Date"
                                                />) : (null)}</td>
                                              <td className={`px-6 py-4 whitespace-nowrap text-left cursor-pointer text-sm text-gray-500`} style={{ width: '1%' }}>
                                                {d_item?.subinm_id && d_item?.subinm_id > 0 ? (<center>
                                                  <button
                                                    disabled={d_item?.disableSubmitButtonInt}
                                                    onClick={() => handleSubsInterval(d_item, i, d_index, allData[i]?.DateArrDropCount, d_item?.subinm_id)}
                                                    type="submit"
                                                    className="rounded-md bg-red-600 px-3 py-2 mb-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                                  >
                                                    {(d_item?.disableSubmitButtonInt ? <FaSpinner color="gold" /> : "Update")}
                                                  </button>
                                                </center>) : (<></>)}
                                              </td>

                                            </tr >

                                          </>
                                        ))) : null}
                                      {allData[i]?.subscription_interval_map?.length <= 0 ? (<tr>
                                        <td></td>
                                        <td colSpan={4}><center>
                                          <button
                                            disabled={disableSubmitButton}
                                            onClick={() => handleUpdateSubsInterval(i)}
                                            type="submit"
                                            className="rounded-md bg-red-600 px-3 py-2 mb-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                          >
                                            {(disableSubmitButton ? <FaSpinner color="gold" /> : "Save")}
                                          </button>
                                        </center></td>
                                        <td></td>

                                      </tr>) : null}
                                    </tbody>

                                  </table>) : (<></>)}
                                </div>
                              </td>
                            </tr >
                          )}
                        </>
                      );
                    })
                  ) : (
                    // <div className="p-5 text-center whitespace-nowrap w-full text-gray-500">
                    <>{headerGroups.map((headerGroup, index) => (
                      <tr key={index}>
                        <td className='p-5 text-center whitespace-nowrap w-full text-gray-500' colSpan={headerGroup.headers.length}>No Data Found</td>
                      </tr>
                    ))}</>

                    // </div>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div >


      <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0 mt-5">
        <div className="-mt-px flex w-0 flex-1">
          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 cursor-pointer hover:text-gray-700"
          >
            <ArrowLongLeftIcon
              className="mr-3 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            Previous
          </button>
          <div className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 pl-2 font-medium">
            {preGlobalFilteredRows.length < 50 ? (
              <span>
                Data{" "}
                <strong>
                  {preGlobalFilteredRows.length} of{" "}
                  {preGlobalFilteredRows.length}
                </strong>{" "}
              </span>
            ) : (
              <span>
                Data{" "}
                <strong>
                  {state.pageSize} of {preGlobalFilteredRows.length}
                </strong>{" "}
              </span>
            )}
          </div>
        </div>
        <div className="hidden md:-mt-px md:flex">
          {pageOptions?.map((page, key) =>
            page < Math.ceil(pageOptions.length / 2) + 1 &&
              page + 1 < state.pageIndex + 3 &&
              page + 2 > state.pageIndex ? (
              // || ((page + 1) < 4 && state.pageIndex < 3)
              <button
                key={key}
                onClick={(e) => {
                  gotoPage(page);
                }}
                // className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                className={`${page == state.pageIndex
                  ? "inline-flex items-center border-t-2 border-cyan-500 px-4 pt-4 text-sm font-medium text-cyan-600"
                  : "inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                  }`}
              >
                {page + 1}
              </button>
            ) : null
          )}

          {/* Done code */}
          {pageOptions?.map((page, key) =>
            state.pageIndex > Math.ceil(pageOptions.length / 2) + 1 &&
              (page == 0 || page == 1 || page == 2) ? (
              <button
                key={key}
                onClick={(e) => {
                  gotoPage(page);
                }}
                className={`${page == state.pageIndex
                  ? "inline-flex items-center border-t-2 border-cyan-500 px-4 pt-4 text-sm font-medium text-cyan-600"
                  : "inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                  }`}
              >
                {page + 1}
              </button>
            ) : null
          )}

          {pageOptions.length > 3 ? (
            <div>
              <span className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500">
                ...
              </span>

              {pageOptions?.map((page, key) =>
                state.pageIndex <= Math.ceil(pageOptions.length / 2) - 1 &&
                  (page == pageOptions.length - 3 ||
                    page == pageOptions.length - 2 ||
                    page == pageOptions.length - 1) ? (
                  <button
                    key={key}
                    onClick={(e) => {
                      gotoPage(page);
                    }}
                    className={`${page == state.pageIndex
                      ? "inline-flex items-center border-t-2 border-cyan-500 px-4 pt-4 text-sm font-medium text-cyan-600"
                      : "inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                      }`}
                  >
                    {page + 1}
                  </button>
                ) : null
              )}

              {pageOptions?.map((page, key) =>
                page > Math.ceil(pageOptions.length / 2) &&
                  page + 1 < state.pageIndex + 3 &&
                  page + 2 > state.pageIndex ? (
                  // || ((page + 1) < 4 && state.pageIndex < 3)
                  <button
                    key={key}
                    onClick={(e) => {
                      gotoPage(page);
                    }}
                    // className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                    className={`${page == state.pageIndex
                      ? "inline-flex items-center border-t-2 border-cyan-500 px-4 pt-4 text-sm font-medium text-cyan-600"
                      : "inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                      }`}
                  >
                    {page + 1}
                  </button>
                ) : null
              )}
            </div>
          ) : null}

          {/* {pageOptions?.map((page, key) => (
            (page + 1) > pageOptions.length - 3 ? (
              <button
                key={key}
                onClick={(e) => {
                  gotoPage(page);
                }}

                className={`${page == state.pageIndex ? "inline-flex items-center border-t-2 border-cyan-500 px-4 pt-4 text-sm font-medium text-cyan-600" : "inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"}`}
              >
                {page + 1}
              </button>
            ) : null
          ))} */}
        </div>
        <div className="-mt-px flex w-0 flex-1 justify-end">
          <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:text-gray-700 cursor-pointer"
          >
            Next
            <ArrowLongRightIcon
              className="ml-3 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </button>
        </div>
      </nav>
    </>
  );
}

export default Table;
