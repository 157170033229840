import React, { useEffect, useState } from 'react'
import { FallingLinesLoader } from '../../../components/spinners/Spinner'
import Table from '../../../components/tables/table'
import Breadcrumb from '../../../components/Breadcrumb'
import { Link, useNavigate } from 'react-router-dom'
import { getAllowedActions, checkPermissionsAndRedirect } from "../../../helper/commonHelper";
import ReportServices from '../../../ApiServices/ReportServices'
import { RegionReport_columns } from '../../../components/tables/tableheader'
import moment from 'moment';
import { convertToAnotherTimezone } from '../../../helper/commonHelper';
import { utils, writeFile } from 'xlsx';
import { toast } from 'react-toast';

const data = [
  { OrderType: 'P', RegionCode: 0, CopyDel: 'AM', CopyCour: '', Count: 18, Copies: 18 },
  { OrderType: 'P', RegionCode: 1, CopyDel: 'COU', CopyCour: 'DTC', Count: 41, Copies: 41 },
  { OrderType: 'P', RegionCode: 1, CopyDel: 'CPS', CopyCour: '', Count: 192, Copies: 192 },
  { OrderType: 'P', RegionCode: 1, CopyDel: 'HWK', CopyCour: 'AK', Count: 171, Copies: 171 },
  { OrderType: 'P', RegionCode: 1, CopyDel: 'HWK', CopyCour: 'AKS', Count: 282, Copies: 282 },
  { OrderType: 'P', RegionCode: 1, CopyDel: 'HWK', CopyCour: 'ASH', Count: 43, Copies: 48 },
  { OrderType: 'P', RegionCode: 1, CopyDel: 'HWK', CopyCour: 'SKS', Count: 59, Copies: 59 },
  { OrderType: 'P', RegionCode: 1, CopyDel: 'HWK', CopyCour: 'SMH', Count: 86, Copies: 122 },
  { OrderType: 'P', RegionCode: 2, CopyDel: 'COU', CopyCour: 'DTC', Count: 217, Copies: 217 },
  { OrderType: 'P', RegionCode: 2, CopyDel: 'COU', CopyCour: 'FCS', Count: 68, Copies: 68 },
  { OrderType: 'P', RegionCode: 2, CopyDel: 'COU', CopyCour: 'MSC', Count: 93, Copies: 96 },
  { OrderType: 'P', RegionCode: 2, CopyDel: 'COU', CopyCour: 'SLR', Count: 9, Copies: 9 },
  { OrderType: 'P', RegionCode: 2, CopyDel: 'CPS', CopyCour: '', Count: 1165, Copies: 1165 },
  { OrderType: 'P', RegionCode: 2, CopyDel: 'HWK', CopyCour: 'DPS', Count: 48, Copies: 48 },
  { OrderType: 'P', RegionCode: 2, CopyDel: 'LUK', CopyCour: '', Count: 69, Copies: 69 },
  { OrderType: 'P', RegionCode: 3, CopyDel: 'COU', CopyCour: 'RC', Count: 57, Copies: 57 },
  { OrderType: 'P', RegionCode: 3, CopyDel: 'COU', CopyCour: 'STR', Count: 106, Copies: 106 },
  { OrderType: 'P', RegionCode: 3, CopyDel: 'COU', CopyCour: 'VC', Count: 220, Copies: 220 },
  { OrderType: 'P', RegionCode: 3, CopyDel: 'CPS', CopyCour: '', Count: 26, Copies: 26 },
  { OrderType: 'P', RegionCode: 4, CopyDel: 'COU', CopyCour: 'NAG', Count: 46, Copies: 46 },
  { OrderType: 'P', RegionCode: 4, CopyDel: 'COU', CopyCour: 'RC', Count: 54, Copies: 54 },
  { OrderType: 'P', RegionCode: 4, CopyDel: 'COU', CopyCour: 'STR', Count: 49, Copies: 49 },
  { OrderType: 'P', RegionCode: 4, CopyDel: 'CPS', CopyCour: '', Count: 432, Copies: 432 },
  { OrderType: 'P', RegionCode: 5, CopyDel: 'COU', CopyCour: 'MAD', Count: 107, Copies: 107 }
];

const RegionReport = () => {
  const pages = [{ title: "Region Report", href: `/region-report`, module_id: 26 }];
  const [isLoading, setIsLoading] = useState(false)
  const [regionReportList, setRegionReportList] = useState(data)
  const navigate = useNavigate();
  const [allowedActions, setAllowedActions] = useState([]);
  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("user_permissions"));
    if (permissions && window.location.pathname) {
      const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect(window.location.pathname, permissions, navigate);
      const actions = getAllowedActions(permissions, window.location.pathname);
      setAllowedActions(actions);
    }
  }, []);

  useEffect(() => {
    // getRegionReport()
  }, [])

  const getRegionReport = () => {
    setIsLoading(true)
    let payload = {
      status_code: 3,
    }
    ReportServices.getAllRegionReport(payload).then((res) => {
      const { data, status } = res;
      if (status) {
        setRegionReportList(data.data)
      }
      setIsLoading(false)
    }).catch((error) => {
      console.log(error)

    });
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <Breadcrumb pages={pages} />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">Region Report</h1>
      </div>

      <div className="flex justify-end">
        <div className="mt-4 flex">
          {allowedActions.filter((data) => data.permission_id == 78)?.length > 0 ? (<Link
            to={`/add-customer-address/`}
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2"
          >
            Download Excel
          </Link>) : null}
        </div>
      </div>

      {isLoading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={RegionReport_columns({ allowedActions })}
          data={regionReportList ? regionReportList : []}
          is_toggle={false}
        />
      )}
    </div>
  )
}

export default RegionReport