import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Table from "../../../components/tables/table";
import Breadcrumb from "../../../components/Breadcrumb";
import MasterServices from "../../../ApiServices/MasterServices";
import { sku_columns } from "../../../components/tables/tableheader";
import { useEffect } from "react";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import { toast } from "react-toast";
import { getAllowedActions, checkPermissionsAndRedirect } from "../../../helper/commonHelper";

const Sku = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const pages = [{ title: "SKU", href: "/sku", module_id: 6 }];
  const [skuData, setSkuData] = useState([]);
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    sku_id: null,
  });
  const [imageBaseURL, setImageBaseURL] = useState(null)
  const [imageMMBaseURL, setImageMMBaseURL] = useState(null)

  const [allowedActions, setAllowedActions] = useState([]);
  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("user_permissions"));
    if (permissions && window.location.pathname) {
      const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect(window.location.pathname, permissions, navigate);
      const actions = getAllowedActions(permissions, window.location.pathname);
      setAllowedActions(actions);
    }
  }, []);


  useEffect(() => {
    getAllSkuData();
  }, []);

  const getAllSkuData = async () => {
    setLoading(true)
    const response = await MasterServices.getSkuData(3);
    console.log("response.data=>", response?.data)
    if (response.data.status == true) {
      console.log("response.data=>", response?.data.data.data)
      if (response?.data?.data?.data?.length > 0) {

        setImageBaseURL(response?.data?.data?.image_base_url);
        setImageMMBaseURL(response?.data?.data?.magazine_image_base_url)
        setSkuData(response?.data?.data?.data);
      } else {
        setSkuData([]);
      }
      setLoading(false)
    }
  };

  const onDeleteOpen = (sku_id) => {
    setConfirmationModal({ sku_id, status: true });
  }

  const onDeleteSku = (sku_id) => {
    // let payload = {
    //   table_name: "skus",
    //   table_field: "sku_id",
    //   table_field_val: sku_id,
    //   table_status_field: "sku_deleted",
    //   table_status_val: true,
    //   table_text: "SKU",
    //   deleted_by_key: "sku_updated_by"
    // }
    // MasterServices.removeMaster(payload).then((res) => {
    //   const { data, status, message } = res.data
    //   if (status) {
    //     toast.success(message);
    //     getAllSkuData();
    //   } else {
    //     toast.error(message)
    //   }
    // })
    // setConfirmationModal({ sku_id: null, status: false });

    MasterServices.deleteSkus({ sku_id })
      .then((response) => {
        const { data, status } = response;
        if (data.status) {
          setLoading(true)
          toast.success(data.message)
          setConfirmationModal({ sku_id: null, status: false });
          getAllSkuData()
          navigate("/sku");
        } else {
          toast.error(data.message)
        }
        setLoading(false)
      })
      .catch((error) => {
        // const msg = error.response.data.message;
        toast.error("Sorry Fatal Error!")
        setLoading(false)
      });
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <ConfirmationModal
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => onDeleteSku(confirmationModal.sku_id)}
      />
      <Breadcrumb pages={pages} />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">SKU List</h1>
        {/* <p className="mt-2 text-sm text-gray-700">A list of all the SKU.</p> */}
      </div>
      <div className="mt-4 mb-4 flex">
        {allowedActions.filter((data) => data.permission_id == 22)?.length > 0 ? (<Link
          to={"/add-sku"}
          type="button"
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144]  px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#BE3144] focus:ring-offset-2 ml-auto"
        >
          Add SKU
        </Link>) : null}
      </div>
      {loading ? (<FallingLinesLoader />) : (
        <Table
          columns={sku_columns({ onDeleteOpen, imageBaseURL, imageMMBaseURL, allowedActions })}
          data={skuData}
          is_toggle={false}
        />)}
    </div>
  );
};

export default Sku;
