import React, { useEffect, useState } from 'react'
import Breadcrumb from '../../../components/Breadcrumb'
import { PlusCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import Select from 'react-select'
import makeAnimated from "react-select/animated";
import MasterServices from '../../../ApiServices/MasterServices';

const AddTransaction = () => {
    const pages = [{ title: "Add Transaction", href: "/add-transaction", module_id: 8 }];
    const animatedComponents = makeAnimated();
    const [skuData, setSkuData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [allproduct, setAllProduct] = useState([])
    const [selectedProduct, setSelectedProduct] = useState([])
    const [selectedSku, setSelectedSku] = useState([])
    const [totalPrice, setTotalPrice] = useState(0);
    const [formSkuData, setFormSkuData] = useState([
        {
            id: 1,
            sku: {},
            price: ""
        },
    ]);
    useEffect(() => {
        getAllSkuData();
        getAllProducts()
    }, []);

    const getAllProducts = async () => {
        setLoading(true)
        const response = await MasterServices.getAllProduct(3);
        if (response.data.status == true) {
            if (response.data.data.data.length > 0) {
                const newArray = response?.data?.data?.data?.map((ele) => ({
                    ...ele,
                    value: ele.product_id,
                    label: ele.product_name
                }))
                setAllProduct(newArray);
                // setImageBaseURL(response.data.data.image_base_url);
            }
            setLoading(false)
        }
    }

    const getAllSkuData = async () => {
        setLoading(true)
        const response = await MasterServices.getSkuData(3);
        if (response.data.status == true) {
            if (response.data.data.length > 0) {
                const newArray = response?.data?.data?.map((ele) => ({
                    ...ele,
                    value: ele.sku_id,
                    label: `SKU ${ele.sku_id}`
                }))
                setSkuData(newArray);
            }
            setLoading(false)
        }
    };

    const addFormFields = () => {
        setFormSkuData([...formSkuData, {
            id: formSkuData.length + 1,
            sku: {},
            price: '',
        }]);
    };

    const removeFormFields = (items) => {
        // const newState =
        //   [...allCountryList,
        //   items.selectedCountry];
        // setAllCountryList(newState);
        // setFormSkuData(formSkuData.filter((item) => item.id !== items.id));
        const updatedFormSkuData = formSkuData.filter((formItem) => formItem.id !== items.id);
        setFormSkuData(updatedFormSkuData);

        // Recalculate total price
        const newTotalPrice = updatedFormSkuData.reduce((acc, formItem) => acc + (formItem.price || 0), 0);
        setTotalPrice(newTotalPrice);
    };

    const handleSku = (e, index) => {
        console.log("handleSku", e)
        formSkuData.map((item, ind) => {
            if (ind === index) {
                item.selectedSku = e;
            }
        })
        setSelectedSku(e)
        setFormSkuData([...formSkuData])
    }


    const handleTransactionCreate = () => {
        console.log("nitesh", formSkuData, selectedProduct)
    }

    const handlePriceChange = (e, index) => {
        const newPrice = parseFloat(e.target.value) || 0;

        // Update the price in formSkuData
        const updatedFormSkuData = [...formSkuData];
        updatedFormSkuData[index] = {
            ...updatedFormSkuData[index],
            price: newPrice,
        };
        setFormSkuData(updatedFormSkuData);

        // Update total price
        const newTotalPrice = updatedFormSkuData.reduce((acc, item) => acc + (item.price || 0), 0);
        setTotalPrice(newTotalPrice);
    };

    return (
        <div>
            <Breadcrumb pages={pages} />
            <div className="">
                <h1 className="text-xl font-semibold text-gray-900">Add Transaction</h1>
            </div>
            <div>
                <div className='flex justify-end gap-3 my-5'>
                    {/* <h2 className="text-base font-semibold leading-7 text-gray-900">Country Pricing</h2> */}
                    <button
                        type="button"
                        onClick={addFormFields}
                        className="inline-flex gap-2 rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2"
                    >
                        Add
                        <PlusCircleIcon className='h-5 w-5 cursor-pointer' />
                    </button>
                </div>
                {formSkuData.map((item, index) => (
                    <div key={item.id} className='grid grid-cols-3 gap-3 mb-3'>
                        <div>
                            <Select
                                name="Sku"
                                placeholder={
                                    <span>
                                        Select Sku
                                        <span className="text-red-600">*</span>
                                    </span>
                                }
                                // , item?.selectedCountry
                                onChange={(e) => handleSku(e, index)}
                                value={item.selectedSku}
                                components={animatedComponents}
                                options={skuData}
                                classNamePrefix="select"
                                className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                            />
                        </div>
                        <div>
                            <input
                                type="number"
                                placeholder="Enter price"
                                name="price"
                                min="1"
                                value={item.price || ''}
                                onChange={(e) => handlePriceChange(e, index)}
                                autoComplete="off"
                                className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                            />
                        </div>
                        <div>
                            <button
                                type="button"
                                onClick={() => removeFormFields(item)}
                                className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2 ml-auto"
                            >
                                <XMarkIcon className='w-5 h-5' />
                            </button>
                        </div>
                    </div>
                ))}
            </div>
            <div>
                <div className='flex items-center gap-3 my-5'>
                    <h2 className="text-base font-semibold leading-7 text-gray-900">Product</h2>
                </div>
                <div className='grid grid-cols-3 gap-3 mb-3'>
                    <div>
                        <Select
                            name="product"
                            placeholder={
                                <span>
                                    Select Product
                                    <span className="text-red-600">*</span>
                                </span>
                            }
                            // defaultValue={selectedMagazine}
                            onChange={setSelectedProduct}
                            components={animatedComponents}
                            options={allproduct}
                            classNamePrefix="select"
                            className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                        />
                    </div>
                </div>
            </div>
            <div className="mt-10 flex items-center justify-end gap-x-6">
                <table>
                    <tfoot>
                        <tr>
                            <th
                                scope="row"
                                colSpan={3}
                                className="hidden pt-4 text-right font-semibold text-gray-900 sm:table-cell"
                            >
                                Total Amount
                            </th>
                            <td className="pb-0 pl-8 pr-0 pt-4 text-right font-semibold tabular-nums text-gray-900">
                                Rs {totalPrice}
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <div className="mt-10 flex items-center justify-end gap-x-6">
                {/* <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
                    Cancel
                </button> */}
                <button
                    onClick={() => handleTransactionCreate()}
                    type="submit"
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2"
                >
                    Create Transaction
                </button>
            </div>
        </div>
    )
}

export default AddTransaction