import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import MasterServices from "../../../ApiServices/MasterServices";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from '../../../components/Breadcrumb'
import { toast } from "react-toast";
import { Link } from "react-router-dom";
import Table from "../../../components/tables/table";
import { voucher_columns } from "../../../components/tables/tableheader";
import { getAllowedActions, checkPermissionsAndRedirect } from "../../../helper/commonHelper";

const Voucher = () => {
  const [loading, setLoading] = useState(false);
  // const [voucherData, setVoucherData] = useState([])
  const navigate = useNavigate();
  const initialValues = [
    {
      name: "abc",
      code: 123,
      description: "lorem abc",
      percentage: 20,
      value: "NA",
      minimum_transaction: 20,
      max_discount: 10,
      per_user_limit: 2,
      max_redeems: 5,
      sku_applicable: "Na",
      start_date: "04/01/2024",
      end_date: "05/01/2024",
    },
  ];
  const [voucherData, setVoucherData] = useState(initialValues);
  const pages = [{ title: "voucher", href: "/voucher" }];
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    voucher_id: null,
  });

  const [allowedActions, setAllowedActions] = useState([]);
  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("user_permissions"));
    if (permissions && window.location.pathname) {
      const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect(window.location.pathname, permissions, navigate);
      const actions = getAllowedActions(permissions, window.location.pathname);
      setAllowedActions(actions);
    }
  }, []);

  useEffect(() => {
    getVoucherData(3);
  }, []);
  const getVoucherData = async (payload) => {
    setLoading(true)
    const response = await MasterServices.getVoucherList(payload);
    console.log("response.data=>", response.data);
    if (response.data.status === true) {
      if (response?.data?.data?.length > 0) {
        setVoucherData(response?.data?.data);
      } else {
        setVoucherData([]);
      }
      setLoading(false)
    }
  };

  const onDeleteOpen = (voucher_id) => {
    setConfirmationModal({ voucher_id, status: true });
  };
  const onDeleteVoucher = (voucher_id) => {
    console.log("voucher_id", voucher_id)
    MasterServices.deleteVoucher({ voucher_id })
      .then((response) => {
        const { data, status } = response;
        if (data.status) {
          setLoading(true)
          toast.success(data.message)
          setConfirmationModal({ voucher_id: null, status: false });
          getVoucherData(3);
          navigate("/voucher");
        } else {
          toast.error(data.message)
        }
        setLoading(false)
      })
      .catch((error) => {
        toast.error("Sorry Fatal Error!")
        setLoading(false)
      });
  }

  const onStatusUpdate = (voucher_id, voucher_is_active) => {
    let payload = {
      table_name: "vouchers",
      table_field: "voucher_id",
      table_field_val: voucher_id,
      table_status_field: "voucher_is_active",
      table_status_val: voucher_is_active ? false : true,
      table_text: "Voucher",
      deleted_by_key: "voucher_updated_by",
      delete_active_txt: voucher_is_active ? " Deactivated" : " Activated"
    }
    MasterServices.removeMaster(payload).then((res) => {
      const { data, status, message } = res.data
      if (status) {
        getVoucherData(3);
        toast.success(message);
      } else {
        toast.error(message)
      }
    })
  }
  return (
    <div className='className="px-4 sm:px-6 lg:px-8"'>
      {/* <Breadcrumb pages={pages} /> */}
      <ConfirmationModal
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => onDeleteVoucher(confirmationModal.voucher_id)}
      />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">Voucher list</h1>
        <p className="mt-2 text-sm text-gray-700">A list of all the Voucher.</p>
      </div>
      <div className="mt-4 mb-4 flex">
        {allowedActions.filter((data) => data.permission_id == 44)?.length > 0 ? (<Link
          to={"/add-voucher"}
          type="button"
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144]  px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#BE3144] focus:ring-offset-2 ml-auto"
        >
          Add Voucher
        </Link>) : null}
      </div>
      {loading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={voucher_columns({ onDeleteOpen, onStatusUpdate, allowedActions })}
          data={voucherData}
          is_toggle={false}
        />
      )}
      {/* <Table columns={voucher_columns({ onDeleteOpen, onStatusUpdate })} data={voucherData} /> */}
    </div>
  );
};

export default Voucher;
