/* eslint-disable import/no-anonymous-default-export */
import Api from "./Api";

export default {

  async getAllSubscriptionReport(payload) {
    const response = await Api().get(`api/dashboard/report/?search=${payload}`);
    return response;
  },

  async getAllIssuesReport(payload) {
    const response = await Api().get(`api/dashboard/report/issues-report/?search=${payload?.status_code}`);
    return response;
  },
  async getAllDashboardStat(payload) {
    const response = await Api().get(`api/dashboard/dashboard/?search=${payload}`);
    return response;
  },

};
