
import { Outlet, Navigate } from 'react-router-dom';
import DashboardLayout from "../layout/dashboardLayout";

const ProtectedRoute = () => {

  const user = localStorage.getItem("user");
  return user ?
  <DashboardLayout>
      <Outlet /> 
  </DashboardLayout> :
  <Navigate to="/login" />;

};

export default ProtectedRoute;
