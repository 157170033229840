import React, { useEffect, useState, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
// import { RegionSchema } from "../../../schemas";
import Table from "../../../components/tables/table";
import { Region_columns } from "../../../components/tables/tableheader";
import { Dialog, Transition } from "@headlessui/react";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import Api from "../../../ApiServices/Api";
import { toast } from "react-toast";

function Region() {
  const pages = [{ title: "Region List", href: "/Region", module_id: 24 }];
  const [RegionList, setRegionList] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [modalOpenFlage, setmodalOpenFlage] = useState(false);
  const [Region_id, setRegionid] = useState("");
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    Region_id: null,
  });
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Outlook | Regions";
    getAllRegions();
    getAllZone();
  }, []);

  const getAllRegions = async () => {
    try {
      setIsLoading(true);
      const { data } = await Api().get(`/api/dashboard/masters/Region`);
      if (data.status) {
        setRegionList(data?.data);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const getAllZone = async () => {
    try {
      setIsLoading(true);
      const { data } = await Api().get(`/api/dashboard/masters/zone`);
      if (data.status) {
        setZoneList(data?.data);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const initialValues = {
    name: "",
    fk_zone_id: "",
    tel_number: "",
    fax_number: "",
    toll_free_number: "",
  };

  const [formRegion, setFormRegion] = useState(initialValues);

  const handleDrawer = async (type, id, obj) => {
    if (type === "edit" && id) {
      setRegionid(id);
      setFormRegion({
        name: obj.name,
        fk_zone_id: obj.fk_zone_id,
        tel_number: obj.tel_number,
        fax_number: obj.fax_number,
        toll_free_number: obj.toll_free_number,
      });
    } else {
      setRegionid("");
      setFormRegion(initialValues);
    }
    if (modalOpenFlage === false) {
      setmodalOpenFlage(true);
    }
  };
  const onDeleteOpen = (Region_id) => {
    setConfirmationModal({ Region_id, status: true });
  };

  const onDeleteRegion = async (Region_id) => {
    try {
      const res = await Api().delete(
        `/api/dashboard/masters/region/${Region_id}`
      );
      if (res.status) {
        getAllRegions();
      }
      setConfirmationModal({ Region_id: null, status: false });
    } catch (error) {
      console.log(error);
      setConfirmationModal({ Region_id: null, status: false });
    }
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formRegion,
      //   validationSchema: RegionSchema,
      onSubmit: async (values, action) => {
        try {
          setIsLoading(true);
          let body = {
            name: values.name,
            fk_zone_id: Number(values.fk_zone_id),
            tel_number: values.tel_number,
            fax_number: values.fax_number,
            toll_free_number: values.toll_free_number,
          };

          if (Region_id) {
            const res = await Api().put(
              `/api/dashboard/masters/region/${Region_id}`,
              body
            );
            if (res.status) {
              getAllRegions();
            }
          } else {
            const res = await Api().post(`/api/dashboard/masters/region`, body);
            if (res.status) {
              getAllRegions();
            }
          }

          setIsLoading(false);
        } catch (error) {
          console.log(error);
          setIsLoading(false);
        }
        action.resetForm();
        if (modalOpenFlage === true) {
          getAllRegions();
          setmodalOpenFlage(false);
        }
      },
    });
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <ConfirmationModal
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => onDeleteRegion(confirmationModal.Region_id)}
      />
      <Breadcrumb pages={pages} />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">Region</h1>
        <p className="mt-2 text-sm text-gray-700">A list of all the Regions.</p>
      </div>
      <div className="mt-4 flex">
        <Link
          onClick={() => handleDrawer("add", "", {})}
          type="button"
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 ml-auto"
        >
          Add Region
        </Link>
      </div>

      {isLoading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={Region_columns({ onDeleteOpen, handleDrawer })}
          data={RegionList?.length > 0 && !isLoading ? RegionList : []}
          is_toggle={false}
        />
      )}

      {isLoading ? null : (
        <div>
          <Transition.Root show={modalOpenFlage} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-10"
              onClose={setmodalOpenFlage}
            >
              <div className="fixed inset-0" />
              <div className="fixed inset-0 overflow-hidden">
                <div className="absolute inset-0 overflow-hidden">
                  <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                    <Transition.Child
                      as={Fragment}
                      enter="transform transition ease-in-out duration-500 sm:duration-700"
                      enterFrom="translate-x-full"
                      enterTo="translate-x-0"
                      leave="transform transition ease-in-out duration-500 sm:duration-700"
                      leaveFrom="translate-x-0"
                      leaveTo="translate-x-full"
                    >
                      <Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
                        <form
                          onSubmit={handleSubmit}
                          className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                        >
                          <div className="h-0 flex-1 overflow-y-auto">
                            <div className="bg-gradient-to-b from-[#872341] to-[#BE3144] py-6 px-4 sm:px-6">
                              <div className="flex items-center justify-between">
                                <Dialog.Title className="text-lg font-medium text-white">
                                  {Region_id ? "Update" : "Add"} Region
                                </Dialog.Title>
                                <div className="ml-3 flex h-7 items-center">
                                  <button
                                    type="button"
                                    className="rounded-md  text-red-200 hover:text-white"
                                    onClick={() => setmodalOpenFlage(false)}
                                  >
                                    <span className="sr-only">Close panel</span>
                                    <XMarkIcon
                                      className="h-6 w-6"
                                      aria-hidden="true"
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-1 flex-col justify-between">
                              <div className="p-4 sm:p-6">
                                <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                  <div>
                                    <label
                                      htmlFor="Region_name_en"
                                      className="block text-sm font-medium text-gray-900"
                                    >
                                      Region Name
                                    </label>
                                  </div>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <input
                                      value={values.name}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      type="text"
                                      placeholder="Enter Region Name"
                                      name="name"
                                      autoComplete="off"
                                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="mt-1 sm:col-span-2 sm:mt-0 mx-6 mb-2">
                              <div>
                                <label
                                  htmlFor="fk_state_id"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  Zone
                                </label>
                              </div>
                              <select
                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:max-w-xs sm:text-sm"
                                name="fk_zone_id"
                                id="fk_zone_id"
                                value={values.fk_zone_id}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              >
                                <option value={null}> Select Zone </option>
                                {zoneList?.map((s, i) => (
                                  <option key={s.id} value={s.id}>
                                    {s.zone_name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="flex flex-1 flex-col justify-between">
                              <div className="p-4 sm:p-6">
                                <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                  <div>
                                    <label
                                      htmlFor="Region_name_en"
                                      className="block text-sm font-medium text-gray-900"
                                    >
                                      Telephone Number
                                    </label>
                                  </div>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <input
                                      value={values.tel_number}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      type="text"
                                      placeholder="Enter Telephone Number"
                                      name="tel_number"
                                      autoComplete="off"
                                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-1 flex-col justify-between">
                              <div className="p-4 sm:p-6">
                                <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                  <div>
                                    <label
                                      htmlFor="Region_name_en"
                                      className="block text-sm font-medium text-gray-900"
                                    >
                                      Fax Number
                                    </label>
                                  </div>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <input
                                      value={values.fax_number}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      type="text"
                                      placeholder="Enter Fax Number"
                                      name="fax_number"
                                      autoComplete="off"
                                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-1 flex-col justify-between">
                              <div className="p-4 sm:p-6">
                                <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                  <div>
                                    <label
                                      htmlFor="Region_name_en"
                                      className="block text-sm font-medium text-gray-900"
                                    >
                                      Toll Free Number
                                    </label>
                                  </div>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <input
                                      value={values.toll_free_number}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      type="text"
                                      placeholder="Enter toll free number"
                                      name="toll_free_number"
                                      autoComplete="off"
                                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-red-500 sm:max-w-xs sm:text-sm"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="flex flex-shrink-0 justify-end px-4 py-4">
                            <button
                              type="button"
                              className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                              onClick={() => {
                                setmodalOpenFlage(false);
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                            >
                              {Region_id ? "Update" : "Add"}
                            </button>
                          </div>
                        </form>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        </div>
      )}
    </div>
  );
}
export default Region;
