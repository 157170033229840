import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProtectedRoute from "./protectedRoute";
import Login from "../container/pages/login/Login";
import UsersList from "../container/pages/users/Users";
import CustomerList from "../container/pages/customer/Customer";
import ProductList from "../container/pages/product/Product";
import MagazineList from "../container/pages/magazine/Magazine";
import SkuList from "../container/pages/sku/Sku";
import RoleList from "../container/pages/role/RoleListNew";
import AddUser from "../container/pages/users/AddUser";
import CountryList from "../container/pages/country/CountryList";
import Profile from "../container/pages/profile/Profile";
import GenderList from "../container/pages/gender/Gender";
import StateList from "../container/pages/state/State";
import CityList from "../container/pages/city/CityNew";
import EditPermissions from "../container/pages/role/EditPermissions";

import Error from "../container/error/Error";
import Home from "../container/pages/home/Home";
import MagazineIssue from "../container/pages/magazine/MagazineIssue";
import AddSku from "../container/pages/sku/AddSku";
import TransactionList from "../container/pages/transaction/TransactionList";
import AddTransaction from "../container/pages/transaction/AddTransaction";
import OrderList from "../container/pages/order/OrderList";
import AddOrder from "../container/pages/order/AddOrder";
import Subscription from "../container/pages/subscription/Subscription";
import PrintSubsIssues from "../container/pages/printIssues/SubscriptionIssuse";
import SubsIntervalDispatch from "../container/pages/subscription/SubsIntervalDispatch";
import SubscriptionAccordian from "../container/pages/subscription/SubscriptionAccordian";
import Voucher from "../container/pages/voucher/Voucher";
import CampignVoucher from "../container/pages/voucher/campignVoucher";
import AddVoucher from "../container/pages/voucher/AddVoucher";
import AddCustomer from "../container/pages/customer/AddCustomer";
import CustomerAddress from "../container/pages/customer/CustomerAddress";
import AddCustomerAddress from "../container/pages/customer/AddCustomerAddress";
import Masters from "../container/pages/masters/Masters";
import Vendor from "../container/pages/vendorTransaction/Vendor";
import VendorTransaction from "../container/pages/vendorTransaction/VendorTransaction";

import SubscriptionReport from "../container/pages/reports/SubscriptionReport";
import IssuesReport from "../container/pages/reports/IssuesReport";
import CommissionReport from "../container/pages/commission/commissionList";
import IncomeReport from "../container/pages/reports/IncomeReport";
import RegionReport from "../container/pages/reports/RegionReport";

import AddVendorTransaction from "../container/pages/vendorTransaction/AddVendorTransaction";
import Region from "../container/pages/regionMaster";
import Batch from "../container/pages/Batch";
import Zone from "../container/pages/zone";
import CityType from "../container/pages/CityType";
import CityGroup from "../container/pages/cityGroup";
import Pincode from "../container/pages/pincode/Pincode";
import Delivery from "../container/pages/delivery";
import Courier from "../container/pages/Courier";
import Company from "../container/pages/Company/Company";
import ProductDispatch from "../container/pages/product/ProductDispatch";
import PrintOrderReport from "../container/pages/reports/PrintOrderReport";
import BarcodeDispatch from "../container/pages/BarcodeDispatch/BarcodeDispatch";

const RoutesConfig = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />{" "}
        <Route path="/signUp" element={<Login />} />{" "}
        <Route path="*" element={<Error />} />{" "}
        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<Home />} />{" "}
          <Route path="/user" element={<UsersList />} />{" "}
          <Route path="/profile" element={<Profile />} />{" "}
          <Route path="/user-list" element={<UsersList />} />{" "}
          <Route path="/customer" element={<CustomerList />} />{" "}
          <Route path="/product" element={<ProductList />} />{" "}
          <Route path="/magazine" element={<MagazineList />} />{" "}
          <Route path="/magazine-issue/" element={<MagazineIssue />} />{" "}
          <Route path="/magazine-issue/:mid" element={<MagazineIssue />} />{" "}
          <Route path="/sku" element={<SkuList />} />{" "}
          <Route path="/voucher" element={<Voucher />} />{" "}
          <Route path="/add-customer/">
            <Route index element={<AddCustomer />} />{" "}
            <Route path=":customer_id" element={<AddCustomer />} />{" "}
          </Route>{" "}
          <Route path="/customer-address/">
            <Route index element={<CustomerAddress />} />{" "}
            <Route path=":customer_id" element={<CustomerAddress />} />{" "}
          </Route>{" "}
          <Route path="/add-customer-address/">
            <Route index element={<AddCustomerAddress />} />{" "}
            <Route
              path=":customer_id/:ca_id"
              element={<AddCustomerAddress />}
            />{" "}
          </Route>{" "}
          <Route path="/add-voucher/">
            <Route index element={<AddVoucher />} />{" "}
            <Route path=":voucher_id" element={<AddVoucher />} />{" "}
          </Route>{" "}
          <Route path="/campign-voucher/">
            <Route index element={<CampignVoucher />} />{" "}
            <Route path=":voucher_id" element={<CampignVoucher />} />{" "}
          </Route>{" "}
          <Route path="/add-sku/">
            <Route index element={<AddSku />} />{" "}
            <Route path=":sku_id" element={<AddSku />} />{" "}
          </Route>{" "}
          <Route path="/order" element={<OrderList />} />{" "}
          <Route path="/add-order/">
            <Route index element={<AddOrder />} />{" "}
            <Route path=":ord_id" element={<AddOrder />} />{" "}
          </Route>{" "}
          <Route path="/transaction" element={<TransactionList />} />{" "}
          <Route path="/add-transaction/">
            <Route index element={<AddTransaction />} />{" "}
            <Route path=":trans_id" element={<AddTransaction />} />{" "}
          </Route>{" "}
          <Route path="/role" element={<RoleList />} />{" "}
          <Route path="/role">
            <Route index element={<RoleList />} />{" "}
            <Route path=":role_id" element={<EditPermissions />} />{" "}
          </Route>{" "}
          <Route path="/add-user/">
            <Route index element={<AddUser />} />{" "}
            <Route path=":user_id" element={<AddUser />} />{" "}
          </Route>{" "}
          <Route path="/gender" element={<GenderList />} />{" "}
          <Route path="/country" element={<CountryList />} />{" "}
          <Route path="/state" element={<StateList />} />{" "}
          <Route path="/city" element={<CityList />} />{" "}
          <Route path="/region" element={<Region />} />{" "}
          <Route path="/batch" element={<Batch />} />{" "}
          <Route path="/zone" element={<Zone />} />{" "}
          <Route path="/company" element={<Company />} />{" "}
          <Route path="/product-dispatch" element={<ProductDispatch />} />
          <Route path="/city-type" element={<CityType />} />{" "}
          <Route path="/pincode" element={<Pincode />} />{" "}
          <Route path="/delivery" element={<Delivery />} />{" "}
          <Route path="/courier" element={<Courier />} />{" "}
          <Route path="/city-group" element={<CityGroup />} />{" "}
          <Route path="/print-issue" element={<PrintSubsIssues />} />{" "}
          <Route path="/subscription/">
            <Route index element={<Subscription />} />{" "}
            <Route path=":customer_id" element={<Subscription />} />{" "}
          </Route>{" "}
          <Route
            path="/subscription-dispatch"
            element={<SubsIntervalDispatch />}
          />{" "}
          <Route path="/SubscriptionAcc/">
            <Route index element={<SubscriptionAccordian />} />{" "}
            <Route path=":customer_id" element={<SubscriptionAccordian />} />{" "}
          </Route>{" "}
          <Route path="/vendor-masters" element={<Vendor />} />{" "}
          <Route path="/vendor-transaction" element={<VendorTransaction />} />{" "}
          <Route path="/add-vendor-transaction/">
            <Route index element={<AddVendorTransaction />} />{" "}
            <Route
              path=":vendor_transaction_id"
              element={<AddVendorTransaction />}
            />{" "}
          </Route>{" "}
          <Route path="/subscription-report" element={<SubscriptionReport />} />{" "}
          <Route path="/issues-report" element={<IssuesReport />} />{" "}
          {/* <Route path="/subscription/">
                                                            						<Route index element={<SubscriptionAccordian />} />
                                                            						<Route path=":customer_id" element={<SubscriptionAccordian />} />
                                                            					</Route> */}{" "}
          <Route path="/commission" element={<CommissionReport />} />{" "}
          <Route path="/region-report" element={<IncomeReport />} />{" "}
          <Route path="/income-report" element={<RegionReport />} />{" "}
          <Route path="/print-order-report" element={<PrintOrderReport />} />
          <Route path="/barcode-dispatch" element={<BarcodeDispatch />} />
        </Route>{" "}
      </Routes>{" "}
    </Router>
  );
};

export default RoutesConfig;
