import { ChevronDownIcon, ChevronUpIcon, PlusCircleIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import MasterServices from "../../../ApiServices/MasterServices";
import ConfirmationModal from '../../../components/ConfirmationModal'
import Breadcrumb from '../../../components/Breadcrumb'
import { FallingLinesLoader } from '../../../components/spinners/Spinner';
import Table from '../../../components/tables/table';
import { subscription_columns } from '../../../components/tables/tableheader';
import { toast } from "react-toast";
import Select from 'react-select';

const Subscription = () => {
    const [loading, setLoading] = useState(false);
    const [subscriptionData, setSubscriptionData] = useState([])
    const [openIndex, setOpenIndex] = useState(null);
    const navigate = useNavigate();
    const { customer_id: customer_id } = useParams();
    const pages = [{ title: "Subscription", href: "/subscription/" + customer_id, module_id: 10 }];
    const [confirmationModal, setConfirmationModal] = useState({
        status: false,
        subs_id: null,
    });
    const toggleAccordion = (index) => {
        setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    };
    const months = [];
    const currentYear = new Date().getFullYear();
    for (let i = 1; i <= 12; i++) {
        const monthValue = i < 10 ? `0${i}` : `${i}`;
        const monthLabel = new Date(currentYear, i - 1, 1).toLocaleString('en-US', { month: 'short' });
        months.push({ label: monthLabel, value: monthValue });
    }

    const [selectedMonth, setSelectedMonth] = useState([])
    const handleMonth = (e) => {
        setSelectedMonth(e)
    }
    useEffect(() => {
        getAllSubscriptionData(3);
    }, []);
    const getAllSubscriptionData = async () => {
        setLoading(true)
        let payload = {
            status_code: 3,
            customer_id: customer_id,
        }
        const response = await MasterServices.getAllSubscriptionList(payload);
        console.log("response.data=>", response.data);
        if (response.data.status === true) {
            if (response?.data?.data?.length > 0) {
                setSubscriptionData(response?.data?.data);
            } else {
                setSubscriptionData([]);
            }
            setLoading(false)
        }
    };
    const onDeleteOpen = (subs_id) => {
        setConfirmationModal({ subs_id, status: true });
    }

    const onDeleteItem = (subs_id) => {
        console.log("subs_id", subs_id)
        MasterServices.deleteSubscription({ subs_id })
            .then((response) => {
                const { data, status } = response;
                if (data.status) {
                    setLoading(true)
                    toast.success(data.message)
                    setConfirmationModal({ subs_id: null, status: false });
                    getAllSubscriptionData(3);
                    navigate("/subscription/" + customer_id);
                } else {
                    toast.error(data.message)
                }
                setLoading(false)
            })
            .catch((error) => {
                toast.error("Sorry Fatal Error!")
                setLoading(false)
            });
    }

    const onStatusUpdate = (subs_id, subs_is_deleted) => {
        let payload = {
            table_name: "subscription",
            table_field: "subs_id",
            table_field_val: subs_id,
            table_status_field: "subs_is_deleted",
            table_status_val: subs_is_deleted ? false : true,
            table_text: "Subscription",
            deleted_by_key: "subs_updated_by",
            delete_active_txt: subs_is_deleted ? " Deactivated" : " Activated"
        }
        MasterServices.removeMaster(payload).then((res) => {
            const { data, status, message } = res.data
            if (status) {
                getAllSubscriptionData(3);
                toast.success(message);
            } else {
                toast.error(message)
            }
        })
    }

    // You can define a function to increment the month value
    const incrementMonth = (monthValue) => {
        let nextMonth = parseInt(monthValue, 10) + 1;
        return nextMonth < 10 ? `0${nextMonth}` : `${nextMonth}`;
    }

    const getDateArr = (sortedPayload, selectedMonth, currentYear, DateArr) => {
        DateArr = [];
        sortedPayload?.map((item, ind) => {
            let currentMonthValue = selectedMonth?.value;
            if (ind !== 0) {
                // Increment logic based on your requirement
                currentMonthValue = incrementMonth(selectedMonth?.value);
            }
            let itemDate = currentYear + '-' + currentMonthValue + '-' + item.value;
            let myDate = new Date(itemDate);
            DateArr.push({
                date: myDate, subinm_fk_midm_id: item?.midm_id, subinm_type: item?.midm_type, subinm_label: item?.label
                , subinm_value: item?.value
                , subinm_label_value_json: item?.midm_label_value_json, selectedMonth: selectedMonth
            })
        })
        return DateArr;
    }

    // Function to generate DateArr based on duration and selectedMonth
    const generateMonthArr = (sortedPayload, duration, selectedMonth, currentYear) => {
        const DateArr = [];
        const startMonth = parseInt(selectedMonth.value, 10); // Assuming selectedMonth is an object like { label: "Feb", value: "02" }
        let subinm_fk_midm_id = sortedPayload.length > 0 ? sortedPayload[0]?.midm_id : 0;
        let subinm_type = sortedPayload.length > 0 ? sortedPayload[0]?.midm_type : 0;
        let subinm_label = sortedPayload.length > 0 ? sortedPayload[0]?.label : 0;
        let subinm_value = sortedPayload.length > 0 ? sortedPayload[0]?.value : 0;
        let subinm_label_value_json = sortedPayload.length > 0 ? sortedPayload[0]?.midm_label_value_json : 0;
        let myDate;
        for (let i = 0; i < duration; i++) {
            const monthValue = (startMonth + i) % 12 || 12; // Wrap around to January if needed
            const monthLabel = new Date(currentYear, monthValue - 1, 1).toLocaleString('en-US', { month: 'short' });

            DateArr.push({
                label: monthLabel, value: monthValue < 10 ? `0${monthValue}` : `${monthValue}`, date: myDate, subinm_fk_midm_id, subinm_type, subinm_label
                , subinm_value
                , subinm_label_value_json, selectedMonth: selectedMonth
            });
        }

        return DateArr;
    }

    const handleUpdateLSubs = async (payload, interval, selectedMonth) => {
        // console.log("payload=>",payload);
        // console.log("interval=>",interval);
        let intervalDate = JSON.parse(interval);
        // alert(intervalDate.label)
        let DateArr = [];

        const sortedPayload = [...payload?.DateArrDrop].sort((a, b) => {
            if (a.label === intervalDate.label) return -1; // Place 'Day 14' at the beginning
            if (b.label === intervalDate.label) return 1;
            return a.value - b.value; // Sort the rest based on the 'value'
        });
        const currentYear = new Date().getFullYear();
        let subinm_type = sortedPayload.length > 0 ? sortedPayload[0]?.midm_type : 0;
        console.log("sortedPayload=>", sortedPayload)
        if (subinm_type == "Day") {
            DateArr = getDateArr(sortedPayload, selectedMonth, currentYear, DateArr)
        } else {
            const duration = parseInt(sortedPayload[0].value, 10);
            DateArr = generateMonthArr(sortedPayload, duration, selectedMonth, currentYear);
        }

        let Payload = {
            // subinm_label:payload?.,
            // subinm_value:payload?.,
            // subinm_label_value_json:payload?.,
            // subinm_type:payload?.,
            // subinm_fk_midm_id:payload?.,



            subinm_type: sortedPayload.length > 0 ? sortedPayload[0]?.midm_type : 0,
            // subinm_fk_midm_id:sortedPayload.length>0?sortedPayload[0]?.midm_id:0,

            subinm_fk_subs_id: payload?.subs_id,
            subinm_fk_mm_id: payload?.subs_fk_mm_id,
            subinm_fk_sku_id: payload?.subs_fk_sku_id,
            subinm_fk_ord_id: payload?.subs_fk_ord_id,
            subinm_fk_ord_skum_id: payload?.subs_fk_ord_skum_id,
            subinm_fk_customer_id: payload?.subs_fk_customer_id,
            dateInterval: sortedPayload,
            DateArr: DateArr,
        }
        console.log("Payload=>", Payload);
        return false;

        setLoading(true)
        let res = await MasterServices.updateSubsInterval(Payload)
        if (res.status == 200) {
            toast.success(res.data.message)
            getAllSubscriptionData(3);
            setLoading(false)
        } else {
            toast.error(res.data.message)
            setLoading(false)
        }
    }
    return (
        <>
            <div className="px-4 py-5 mb-2 bg-white rounded-md shadow-lg sm:px-6 ring-1 ring-white ring-opacity-5 focus:outline-none">
                <div className="sales-booking-head">
                    <ConfirmationModal
                        open={confirmationModal.status}
                        setOpen={setConfirmationModal}
                        onDelete={() => onDeleteItem(confirmationModal.subs_id)}
                    />
                    <Breadcrumb pages={pages} />
                    <div className="">
                        <h1 className="text-xl font-semibold text-gray-900">Subscription List</h1>
                    </div>
                </div>
            </div>


            <div className="mt-4 flex flex-col">
                <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                    <tr className='border rounded-md'>
                                        <th scope="col" className="group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Subscription ID</th>
                                        <th scope="col" className="group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Order Id</th>
                                        <th scope="col" className="group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Sys Order No</th>
                                        <th scope="col" className="group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Sku Name</th>
                                        <th scope="col" className="group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Magazine Name</th>
                                        <th scope="col" className="group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Interval</th>
                                        <th scope="col" className="group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Customer Name</th>
                                        <th scope="col" className="group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Customer Email</th>
                                        <th scope="col" className="group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Customer Phone</th>
                                        {/* <th scope="col" className="group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Subs</th>
                                        <th scope="col" className="group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                                        <th scope="col" className="group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th> */}
                                    </tr>
                                </thead>
                                <tbody
                                    className="bg-white divide-y divide-gray-200">
                                    {(subscriptionData?.length > 0) ? (subscriptionData?.map((item, index) => (
                                        <>
                                            <tr key={index} onClick={() => { toggleAccordion(index); }}>
                                                <td className={` px-6 py-4 whitespace-nowrap text-left cursor-pointer text-sm text-gray-500`}>
                                                    <div class="flex">
                                                        <span class="flex-1">{item.subs_id ? item.subs_id : ''}</span>
                                                        <span class="flex-1 ml-1"><ChevronDownIcon className='w-6 h-6' /></span>
                                                    </div>

                                                </td>
                                                <td className={`px-6 py-4 whitespace-nowrap text-left cursor-pointer text-sm text-gray-500`}>
                                                    <span className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-red-600 bg-red-100">
                                                        {item.subs_fk_ord_id}
                                                    </span>
                                                </td>
                                                <td className={`px-6 py-4 whitespace-nowrap text-left cursor-pointer text-sm text-gray-500`}><span className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-red-600 bg-red-100">{item.subs_m_order_relation?.ord_sys_order_id}</span></td>
                                                <td className={`px-6 py-4 whitespace-nowrap text-left cursor-pointer text-sm text-gray-500`}>{item?.subs_skus_relation?.sku_code}</td>
                                                <td className={`px-6 py-4 whitespace-nowrap text-left cursor-pointer text-sm text-gray-500`}>{item?.subs_master_magazines_relation?.mm_name}</td>
                                                <td className={`px-6 py-4 whitespace-nowrap text-left cursor-pointer text-sm text-gray-500`}>{item?.magazineIntervalsString}</td>
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{item?.subs_customer_relation?.customer_username}</td>
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{item?.subs_customer_relation?.customer_email}</td>
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{item?.subs_customer_relation?.customer_number}</td>

                                            </tr>
                                            {openIndex === index && <tr className="mt-2">
                                                <td colSpan="12" className='py-4 pl-4 pr-3'>
                                                    <div>
                                                        <table className="min-w-full divide-y divide-gray-200">
                                                            <thead className="bg-gray-50">
                                                                <tr className='border rounded-md'>
                                                                    <th scope="col" className="group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">SN</th>
                                                                    <th scope="col" className="group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Month</th>
                                                                    <th scope="col" className="group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Interval</th>
                                                                    <th scope="col" className="group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody
                                                                className="bg-white divide-y divide-gray-200">
                                                                {(item?.DateArrDropCount?.length > 0) ? (item?.DateArrDropCount?.map((d_item, d_index) => (
                                                                    <>
                                                                        <tr key={d_index}>
                                                                            <td  className={`px-6 py-4 whitespace-nowrap text-left cursor-pointer text-sm text-gray-500`}>{parseInt(d_index+1)}</td>
                                                                            <td className={`px-6 py-4 whitespace-nowrap text-left cursor-pointer text-sm text-gray-500`}> {months && months?.length > 0 ? (
                                                                                <Select
                                                                                    className="flex-1 w-full max-w-lg rounded-md border-[1px] shadow-sm focus:border-[1px] sm:max-w-xs sm:text-sm "
                                                                                    placeholder="Select Month"
                                                                                    name="month_name"
                                                                                    // id="month_name"
                                                                                    id={`month_name_${d_index}`}  // Unique id for each row
                                                                                    onChange={(e) => handleMonth(e)}
                                                                                    options={months}
                                                                                    value={selectedMonth}
                                                                                />
                                                                            ) : (null)}</td>
                                                                            <td className={`px-6 py-4 whitespace-nowrap text-left cursor-pointer text-sm text-gray-500`}>
                                                                                {item?.DateArrDropCount && item?.DateArrDropCount?.length > 0 ? (
                                                                                    <select
                                                                                        className="flex-1 ml-1 block max-w-lg border-gray-300 rounded-md shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                                                                        // name="midm_id"
                                                                                        // id="midm_id"
                                                                                        id={`midm_id_${d_index}`}  // Unique id for each row
                                                                                        onChange={(e) => { handleUpdateLSubs(item, e.target.value, selectedMonth) }}
                                                                                        disabled={selectedMonth.length <= 0}
                                                                                    >
                                                                                        <option value={0}> Select Interval </option>
                                                                                        {item?.DateArrDropCount.map((interval, i) => (
                                                                                            <option

                                                                                                key={i}
                                                                                                value={JSON.stringify(interval)}
                                                                                            >
                                                                                                {interval.label}
                                                                                            </option>
                                                                                        ))}
                                                                                    </select>
                                                                                ) : (null)}
                                                                            </td>
                                                                           
                                                                        </tr>
                                                                        {/* {openIndex === index && <tr className="mt-2">
                                                                            <td colSpan="6" className='py-4 pl-4 pr-3'>
                                                                                <div>
                                                                                    <label htmlFor={"remark" + index} className="block text-sm font-medium leading-6 text-gray-900">
                                                                                        Remark
                                                                                    </label>

                                                                                </div>

                                                                            </td>
                                                                        </tr>} */}
                                                                    </>
                                                                ))) : null}
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                </td>
                                            </tr>}
                                        </>
                                    ))) : null}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}
export default Subscription; 