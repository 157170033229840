import React, { useEffect, useState, Fragment } from "react";
import Breadcrumb from "../../../components/Breadcrumb";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { useFormik } from "formik";
import { magazineIssueSchema } from "../../../schemas";
import { FileUploader } from "react-drag-drop-files";
import { singleUploadFiles } from "../../../helper/multiUploadHelper";
import { magazine_issue_columns } from "../../../components/tables/tableheader";
import Table from "../../../components/tables/table";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import { Switch } from '@headlessui/react'
import MasterServices from "../../../ApiServices/MasterServices";
import { toast } from "react-toast";
import moment from "moment";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { getAllowedActions, checkPermissionsAndRedirect } from "../../../helper/commonHelper";
import { FaSpinner } from "react-icons/fa";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const magazinIssueList = [
    {
        name: "name1",
        magazine_issue_date: "12-02-2023",
        price: "200",
        magazine: "magazine1",
        magazine_issue_is_active: true,
    },
    {
        name: "name2",
        magazine_issue_date: "13-03-2023",
        price: "250",
        magazine: "magazine3",
        magazine_issue_is_active: false,
    },
    {
        name: "name3",
        magazine_issue_date: "14-12-2023",
        price: "230",
        magazine: "magazine4",
        magazine_issue_is_active: true,
    },
    {
        name: "name4",
        magazine_issue_date: "12-04-2023",
        price: "240",
        magazine: "magazine5",
        magazine_issue_is_active: true,
    },

]

const MagazineIssue = () => {
    const pages = [{ title: "Magazine", href: "/magazine" }]
    const [modalOpenFlage, setmodalOpenFlage] = useState(false);
    const [loading, setLoading] = useState(false);
    const [allMagazinesIssues, setAllMagazinesIssues] = useState([]);
    const [enabled, setEnabled] = useState(true)
    const [currentMagazineID, setCurrentMagazineID] = useState(0)
    const [magazineIssueID, setMagazineIssueID] = useState("")
    const [poster, setPoster] = useState(null);
    const [tempPoster, setTempPoster] = useState(null);
    const [base64poster, setBase64poster] = useState(null);
    const [imageBaseURL, setImageBaseURL] = useState(null)
    const [confirmationModal, setConfirmationModal] = useState({
        status: false,
        mi_id: null,
    });
    const [disableSubmitButton, setDisableSubmitButton] = useState(false);
    const navigate = useNavigate()
    const fileTypes = ["JPEG", "PNG", "GIF"];
    const [allowedActions, setAllowedActions] = useState([]);
    useEffect(() => {
        const permissions = JSON.parse(localStorage.getItem("user_permissions"));
        if (permissions && window.location.pathname) {
            const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect('/magazine-issue', permissions, navigate);
            const actions = getAllowedActions(permissions, '/magazine-issue');
            setAllowedActions(actions);
        }
    }, []);

    const { mid: mid } = useParams();

    useEffect(() => {
        document.title = "Outlook | Magazine Issue";
        getAllMagazineIssues(mid);
        setCurrentMagazineID(mid)
    }, []);

    const initialValues = {
        issue_name: "",
        issue_month: "",
        issue_date: "",
        issue_price: "",
        issue_cover: "",
        issue_magazine: 0
    }

    const [formUser, setFormUser] = useState(initialValues);
    const handleDrawer = (type, id, obj) => {
        console.log("obj", type, obj)
        if ((type === "edit", id)) {
            setMagazineIssueID(id)
            setEnabled(obj?.mi_status)
            setTempPoster(imageBaseURL ? imageBaseURL + obj?.mi_cover : null)
            const initialValues = {
                issue_name: obj.mi_name,
                issue_month: moment(obj?.mi_issue_month).format("YYYY-MM"),
                issue_date: moment(obj?.mi_release_date).format("YYYY-MM-DD"),
                issue_price: obj.mi_default_price,
                issue_cover: obj.mi_cover,
                issue_magazine: obj.mi_id,
                // issue_status: obj.mi_status
            };
            console.log("initialValues", initialValues)
            setFormUser(initialValues)
        } else {
            console.log("first")
            setMagazineIssueID('')
            setFormUser(initialValues)
        }
        if (modalOpenFlage === false) {
            setmodalOpenFlage(true);
        }
    };

    const getAllMagazineIssues = (mid) => {
        setLoading(true)
        MasterServices.getAllMagazineIssues(mid).then((res) => {
            const { data, status } = res.data;
            if (status) {
                if (data.data.length > 0) {
                    setAllMagazinesIssues(data.data)
                } else {
                    setAllMagazinesIssues([])
                }
                setImageBaseURL(data?.image_base_url);
                setLoading(false)
            } else {
                setLoading(false)
            }
        }).catch((error) => {
            console.log(error)
        });
    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
            enableReinitialize: true,
            initialValues: formUser,
            validationSchema: magazineIssueSchema,
            onSubmit: async (values, action) => {
                const payload = {
                    mi_id: magazineIssueID ? magazineIssueID : 0,
                    mi_status: enabled,
                    mi_fk_master_magazine_id: currentMagazineID,
                    mi_default_price: values.issue_price,
                    mi_release_date: values.issue_date,
                    mi_issue_month: values.issue_month,
                    mi_name: values.issue_name,
                    mi_cover: base64poster
                }
                console.log("valupayloades", payload)
                if (payload.mi_name != undefined || payload.mi_name != null || payload.mi_name != "") {
                    setTimeout(() => {
                        setDisableSubmitButton(false)
                    }, 1000)
                    setDisableSubmitButton(true)
                    MasterServices.saveMagazineIssue(payload).then((res) => {
                        console.log(res)
                        const { data, status, message } = res.data;
                        if (status) {
                            action.resetForm();
                            if (modalOpenFlage === true) {
                                toast.success(message)
                                setmodalOpenFlage(false);
                                setTempPoster(null)
                                getAllMagazineIssues(mid);
                                setDisableSubmitButton(false)
                            }
                        } else {
                            toast.error(message)
                            setDisableSubmitButton(false)
                        }
                    }).catch((error) => {
                        console.log(error)
                        setDisableSubmitButton(false)
                    });
                }
            },
        });

    const onFileChangeMagazineIssue = (e) => {
        const file = e.target.files[0];
        const fileName = file.name;

        const reader = new FileReader();
        reader.onload = (e) => {
            setPoster(file);
            setTempPoster(e.target.result);
            setBase64poster(e.target.result);
        };
        reader.readAsDataURL(file);
    };

    const onDeleteOpen = (mi_id) => {
        setConfirmationModal({ mi_id, status: true });
    }
    const onDeleteItem = (mi_id) => {
        // let payload = {
        //     table_name: "magazine_issues",
        //     table_field: "mi_id",
        //     table_field_val: mi_id,
        //     table_status_field: "mi_deleted",
        //     table_status_val: true,
        //     table_text: "Magazine",
        //     deleted_by_key: "mi_updated_by"
        // }
        // MasterServices.removeMaster(payload).then((res) => {
        //     const { data, status, message } = res.data
        //     if (status) {
        //         getAllMagazineIssues()
        //         toast.success(message);
        //     } else {
        //         toast.error(message)
        //     }
        // })
        // setConfirmationModal({ mi_id: null, status: false });
        MasterServices.deleteMagazineIssues({ mi_id })
            .then((response) => {
                const { data, status } = response;
                if (data.status) {
                    setLoading(true)
                    toast.success(data.message)
                    setConfirmationModal({ mi_id: null, status: false });
                    getAllMagazineIssues(mid);
                    navigate("/magazine-issue/"+mid);
                } else {
                    toast.error(data.message)
                }
                setLoading(false)
            })
            .catch((error) => {
                // const msg = error.response.data.message;
                // toast.error(msg)
                toast.error("Sorry Fatal Error!")
                setLoading(false)
            });
    }

    const onStatusUpdate = (mi_id, mi_status) => {
        let payload = {
            table_name: "magazine_issues",
            table_field: "mi_id",
            table_field_val: mi_id,
            table_status_field: "mi_status",
            table_status_val: mi_status ? false : true,
            table_text: "Magazine Issue",
            deleted_by_key: "mi_updated_by",
            delete_active_txt: mi_status ? " Deactivated" : " Activated"
        }
        MasterServices.removeMaster(payload).then((res) => {
            const { data, status, message } = res.data
            if (status) {
                getAllMagazineIssues(mid);
                toast.success(message);
            } else {
                toast.error(message)
            }
        })
    }


    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <ConfirmationModal
                open={confirmationModal.status}
                setOpen={setConfirmationModal}
                onDelete={() => onDeleteItem(confirmationModal.mi_id)}
            />
            <Breadcrumb pages={pages} />
            <div className="">
                <h1 className="text-xl font-semibold text-gray-900">Magazine Issue List</h1>
                <p className="mt-2 text-sm text-gray-700">A list of all the Magazine Issue.</p>
            </div>
            <div className="mt-4 flex">
                {allowedActions.filter((data) => data.permission_id == 13)?.length > 0 ? (<Link
                    onClick={() => handleDrawer("add", "", {})}
                    type="button"
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2 ml-auto"
                >
                    Add Magazine Issue
                </Link>) : null}
            </div>

            {loading ? (
                <FallingLinesLoader />
            ) : (
                <Table
                    columns={magazine_issue_columns({ onDeleteOpen, handleDrawer, imageBaseURL, onStatusUpdate, allowedActions })}
                    data={allMagazinesIssues}
                    is_toggle={false}
                />
            )}

            <div>
                <Transition.Root show={modalOpenFlage} as={Fragment}>
                    <Dialog
                        as="div"
                        className="relative z-10"
                        onClose={setmodalOpenFlage}
                    >
                        <div className="fixed inset-0" />
                        <div className="fixed inset-0 overflow-hidden">
                            <div className="absolute inset-0 overflow-hidden">
                                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="transform transition ease-in-out duration-500 sm:duration-700"
                                        enterFrom="translate-x-full"
                                        enterTo="translate-x-0"
                                        leave="transform transition ease-in-out duration-500 sm:duration-700"
                                        leaveFrom="translate-x-0"
                                        leaveTo="translate-x-full"
                                    >
                                        <Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
                                            <form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    console.log(e);
                                                    handleSubmit(e);
                                                }}
                                                className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                                            >
                                                <div className="h-0 flex-1 overflow-y-auto">
                                                    <div className="bg-gradient-to-b from-[#872341] to-[#BE3144] py-6 px-4 sm:px-6">
                                                        <div className="flex items-center justify-between">
                                                            <Dialog.Title className="text-lg font-medium text-white">
                                                                Magazine Issue
                                                            </Dialog.Title>
                                                            <div className="ml-3 flex h-7 items-center">
                                                                <button
                                                                    type="button"
                                                                    className="rounded-md bg-gradient-to-b from-[#872341] to-[#BE3144] text-white hover:text-white"
                                                                    onClick={() => setmodalOpenFlage(false)}
                                                                >
                                                                    <span className="sr-only">Close panel</span>
                                                                    <XMarkIcon
                                                                        className="h-6 w-6"
                                                                        aria-hidden="true"
                                                                    />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-1 flex-col justify-between">
                                                        <div className="p-4 sm:p-6">
                                                            <div className="my-2 sm:col-span-2 sm:mt-0">
                                                                <div>
                                                                    <label
                                                                        htmlFor="issue_name"
                                                                        className="block text-sm font-medium text-gray-900 mb-1"
                                                                    >
                                                                        Name<span className="text-red-600">*</span>
                                                                    </label>
                                                                </div>
                                                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                                                    <input
                                                                        value={values.issue_name}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        type="text"
                                                                        placeholder="Name"
                                                                        name="issue_name"
                                                                        autoComplete="off"
                                                                        className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-[#BE3144] sm:text-sm"
                                                                    />
                                                                    {errors.issue_name &&
                                                                        touched.issue_name ? (
                                                                        <p className="text-red-600 text-sm">
                                                                            {errors.issue_name}
                                                                        </p>
                                                                    ) : null}
                                                                </div>
                                                            </div>

                                                            <div className="my-2 sm:col-span-2 sm:mt-0">
                                                                <div>
                                                                    <label
                                                                        htmlFor="issue_month"
                                                                        className="block text-sm font-medium text-gray-900 mb-1"
                                                                    >
                                                                        Issue Month
                                                                    </label>
                                                                </div>
                                                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                                                    <input
                                                                        value={values.issue_month}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        type="month"
                                                                        placeholder="Month"
                                                                        name="issue_month"
                                                                        autoComplete="off"
                                                                        className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-[#BE3144] sm:text-sm"
                                                                    />
                                                                    {errors.issue_month &&
                                                                        touched.issue_month ? (
                                                                        <p className="text-red-600 text-sm">
                                                                            {errors.issue_month}
                                                                        </p>
                                                                    ) : null}
                                                                </div>
                                                            </div>

                                                            <div className="my-2 sm:col-span-2 sm:mt-0">
                                                                <div>
                                                                    <label
                                                                        htmlFor="issue_date"
                                                                        className="block text-sm font-medium text-gray-900 mb-1"
                                                                    >
                                                                        Issue Date
                                                                    </label>
                                                                </div>
                                                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                                                    <input
                                                                        value={values.issue_date}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        type="date"
                                                                        min={new Date().toISOString().split('T')[0]}
                                                                        placeholder="Date"
                                                                        name="issue_date"
                                                                        autoComplete="off"
                                                                        className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-[#BE3144] sm:text-sm"
                                                                    />
                                                                    {errors.issue_date &&
                                                                        touched.issue_date ? (
                                                                        <p className="text-red-600 text-sm">
                                                                            {errors.issue_date}
                                                                        </p>
                                                                    ) : null}
                                                                </div>
                                                            </div>

                                                            <div className="my-2 sm:col-span-2 sm:mt-0">
                                                                <div>
                                                                    <label
                                                                        htmlFor="issue_price"
                                                                        className="block text-sm font-medium text-gray-900 mb-1"
                                                                    >
                                                                        Price
                                                                    </label>
                                                                </div>
                                                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                                                    <input
                                                                        value={values.issue_price}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        type="number"
                                                                        placeholder="Price"
                                                                        name="issue_price"
                                                                        autoComplete="off"
                                                                        className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-[#BE3144] sm:text-sm"
                                                                    />
                                                                    {errors.issue_price &&
                                                                        touched.issue_price ? (
                                                                        <p className="text-red-600 text-sm">
                                                                            {errors.issue_price}
                                                                        </p>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                            <div className="my-2 sm:col-span-2 sm:mt-0">
                                                                <div>
                                                                    <label
                                                                        htmlFor="issue_cover"
                                                                        className="block text-sm font-medium text-gray-900 mb-1"
                                                                    >
                                                                        Issue Cover
                                                                    </label>
                                                                </div>
                                                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                                                    <input
                                                                        onChange={(e) => {
                                                                            handleChange(e);
                                                                            onFileChangeMagazineIssue(e);
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                        type="file"
                                                                        placeholder="Issue Cover"
                                                                        name="issue_cover"
                                                                        autoComplete="off"
                                                                        className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-[#BE3144] sm:text-sm"
                                                                    />
                                                                    {errors.issue_cover &&
                                                                        touched.issue_cover ? (
                                                                        <p className="text-red-600 text-sm">
                                                                            {errors.issue_cover}
                                                                        </p>
                                                                    ) : null}
                                                                </div>
                                                                <div className="col-span-full">
                                                                    {tempPoster ? (
                                                                        <img
                                                                            className="shadow-sm mt-4 w-40"
                                                                            src={tempPoster}
                                                                            alt="preview-banner"
                                                                        />
                                                                    ) : null}
                                                                </div>
                                                            </div>

                                                            <div className="my-2 sm:col-span-2 sm:mt-0">
                                                                <div>
                                                                    <label
                                                                        htmlFor="magazine_is_active"
                                                                        className="block text-sm font-medium text-gray-900 mb-1"
                                                                    >
                                                                        Status
                                                                    </label>
                                                                </div>
                                                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                                                    <Switch
                                                                        checked={enabled}
                                                                        onChange={setEnabled}
                                                                        className={classNames(
                                                                            enabled ? 'bg-[#872341]' : 'bg-gray-200',
                                                                            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2'
                                                                        )}
                                                                    >
                                                                        <span className="sr-only">Use setting</span>
                                                                        <span
                                                                            aria-hidden="true"
                                                                            className={classNames(
                                                                                enabled ? 'translate-x-5' : 'translate-x-0',
                                                                                'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                                                            )}
                                                                        />
                                                                    </Switch>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex flex-shrink-0 justify-end px-4 py-4">
                                                    <button
                                                        type="button"
                                                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                                                        onClick={() => {
                                                            setmodalOpenFlage(false);
                                                        }}
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button
                                                        type="submit"
                                                        className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gradient-to-b focus:outline-none focus:ring-2 focus:ring-[#BE3144] focus:ring-offset-2"
                                                        disabled={disableSubmitButton}
                                                    >
                                                        {magazineIssueID ? (disableSubmitButton ? <FaSpinner color="gold" /> : "Update") : (disableSubmitButton ? <FaSpinner color="gold" /> : "Add")}
                                                        {/* {role_id ? "Update" : "Add"} Add */}
                                                    </button>
                                                </div>
                                            </form>
                                        </Dialog.Panel>
                                    </Transition.Child>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </Transition.Root>
            </div>
        </div>
    )
}


export default MagazineIssue