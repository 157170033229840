import { ProgressBar } from "react-loader-spinner";

const FallingLinesLoader = () => {
  return (
    <div className="flex justify-center items-center">
      <ProgressBar color="red" width="400" visible={true} />
    </div>
  );
};

export { FallingLinesLoader };
