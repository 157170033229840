import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import MasterServices from "../../../ApiServices/MasterServices";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from '../../../components/Breadcrumb'
import { toast } from "react-toast";
import { Link } from "react-router-dom";
import Table from "../../../components/tables/table";
import { voucher_compagine_columns } from "../../../components/tables/tableheader";

const CampignVoucher = () => {
  const [loading, setLoading] = useState(false);
  const { voucher_id: voucher_id } = useParams();
  // const [voucherData, setVoucherData] = useState([])
  const navigate = useNavigate();

  const [voucherData, setVoucherData] = useState([]);
  const pages = [{ title: "voucher", href: "/campign-voucher/"+voucher_id }];
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    voucher_id: null,
  });
  useEffect(() => {
    getVoucherData(3);
  }, []);
  const getVoucherData = async () => {
    setLoading(true)
    let payload = {
      voucher_id:voucher_id,
      status_code:3,
    }
    const response = await MasterServices.getAllCampignVoucher(payload);
    console.log("response.data=>", response.data);
    if (response.data.status === true) {
      if (response?.data?.data?.length > 0) {
        setVoucherData(response?.data?.data);
      } else {
        setVoucherData([]);
      }
      setLoading(false)
    }
  };

  const onDeleteOpen = (voucher_id) => {
    setConfirmationModal({ voucher_id, status: true });
  };
  const onDeleteVoucher = (voucher_id) => {
    console.log("voucher_id", voucher_id)
    MasterServices.deleteVoucher({ voucher_id })
      .then((response) => {
        const { data, status } = response;
        if (data.status) {
          setLoading(true)
          toast.success(data.message)
          setConfirmationModal({ voucher_id: null, status: false });
          getVoucherData(3);
          navigate("/voucher");
        } else {
          toast.error(data.message)
        }
        setLoading(false)
      })
      .catch((error) => {
        toast.error("Sorry Fatal Error!")
        setLoading(false)
      });
  }

  const onStatusUpdate = (voucher_id, voucher_is_active) => {
    let payload = {
      table_name: "vouchers",
      table_field: "voucher_id",
      table_field_val: voucher_id,
      table_status_field: "voucher_is_active",
      table_status_val: voucher_is_active ? false : true,
      table_text: "Voucher",
      deleted_by_key: "voucher_updated_by",
      delete_active_txt:voucher_is_active ? " Deactivated" : " Activated"
    }
    MasterServices.removeMaster(payload).then((res) => {
      const { data, status, message } = res.data
      if (status) {
        getVoucherData(3);
        toast.success(message);
      } else {
        toast.error(message)
      }
    })
  }
  return (
    <div className='className="px-4 sm:px-6 lg:px-8"'>
      {/* <Breadcrumb pages={pages} /> */}
      <ConfirmationModal
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => onDeleteVoucher(confirmationModal.voucher_id)}
      />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">Campagine Voucher list</h1>
      </div>
      <div className="mt-4 mb-4 flex">
        {/* <Link
          to={"/add-voucher"}
          type="button"
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144]  px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#BE3144] focus:ring-offset-2 ml-auto"
        >
          Add Voucher
        </Link> */}
      </div>
      {loading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={voucher_compagine_columns({ onDeleteOpen, onStatusUpdate })}
          data={voucherData}
          is_toggle={false}
        />
      )}
      {/* <Table columns={voucher_columns({ onDeleteOpen, onStatusUpdate })} data={voucherData} /> */}
    </div>
  );
};

export default CampignVoucher;
