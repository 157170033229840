import { Link } from "react-router-dom";
import { capitalise } from "../../utilities/utilities";
import { Switch } from "@headlessui/react";
import "./style.css"; // import CSS file
import moment from "moment";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { CurrencyRupeeIcon, PlusIcon } from "@heroicons/react/20/solid";
import { PencilIcon } from "@heroicons/react/24/outline";
import Select from "react-select";
import React, { useEffect, useState } from "react";
import { BsCurrencyRupee } from "react-icons/bs";
import { calculateAge } from "../../helper/commonHelper";
import { FaDownload, FaSpinner } from "react-icons/fa";
import BarcodeComponent from '../../container/pages/BarcodeDispatch/Barcode';
function userStatusToggle(...classes) {
  return classes.filter(Boolean).join(" ");
}
function magazineStatusToggle(...classes) {
  return classes.filter(Boolean).join(" ");
}
function magazineIssueStatusToggle(...classes) {
  return classes.filter(Boolean).join(" ");
}
function productStatusToggle(...classes) {
  return classes.filter(Boolean).join(" ");
}
export const user_columns = ({
  onDeleteOpen,
  onDeleteOpenSection,
  handleDrawer,
  allowedActions,
}) => [
    {
      Header: "Sr No.",
      accessor: (d, i) => i + 1,
      Cell: ({ cell }) => (
        <div className="p-2 text-sm text-gray-500">{cell.value}</div>
      ), // Adding a class to the cell
    },
    {
      Header: "Name",
      accessor: (d) =>
        capitalise(d.user_first_name) + " " + capitalise(d.user_last_name),
    },
    {
      Header: "Email",
      accessor: "user_email",
    },
    {
      Header: "Mobile",
      accessor: "user_mobile",
    },
    {
      Header: "Role",
      accessor: (d) => capitalise(d.m_role_relation?.role_name),
    },
    {
      Header: "Status",
      accessor: (d) => (d.user_is_active == true ? "Active" : "Inactive"),
      Cell: (cell) => {
        const cellId = cell.row.original.user_id;
        const enabled = cell.row.original.user_is_active;
        const isBtn = cell.row.original.isBtn;
        if (
          allowedActions?.filter((data) => data.permission_id === 3)?.length >
          0 &&
          isBtn
        ) {
          return (
            <Switch
              checked={enabled}
              onChange={() => onDeleteOpen(cellId, enabled)}
              className={userStatusToggle(
                enabled ? "bg-[#872341]" : "bg-gray-200",
                "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              )}
            >
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className={userStatusToggle(
                  enabled ? "translate-x-5" : "translate-x-0",
                  "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                )}
              />
            </Switch>
          );
        } else {
          // Add a default case or return null if nothing should be rendered
          return null;
        }
        // return (
        //   {(allowedActions.filter((data) => data.permission_id == 3)?.length > 0 && isBtn) ? ( <Switch
        //     checked={enabled}
        //     onChange={() => {
        //       onDeleteOpen(cellId, cell.row.original.user_is_active);
        //     }}
        //     className={userStatusToggle(
        //       enabled ? "bg-[#872341]" : "bg-gray-200",
        //       "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
        //     )}
        //   >
        //     <span className="sr-only">Use setting</span>
        //     <span
        //       aria-hidden="true"
        //       className={userStatusToggle(
        //         enabled ? "translate-x-5" : "translate-x-0",
        //         "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
        //       )}
        //     />
        //   </Switch>)}
        // );
      },
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell.row.original.user_id;
        const isBtn = cell.row.original.isBtn;
        const user_is_deleted = cell.row.original.user_is_deleted;
        return (
          <>
            {localStorage.getItem("user_id") == cellId ? (
              <Link
                onClick={() => {
                  localStorage.setItem("commission_user_id", cellId);
                }}
                to={`../commission`}
                className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-red-600 bg-red-100"
              >
                <button
                  className="text-red-600 "
                  size="default"
                  variant="outlined"
                >
                  Commission<span className="sr-only">, </span>
                </button>
              </Link>
            ) : null}
            {(allowedActions.filter((data) => data.permission_id == 3)?.length >
              0 &&
              isBtn) ||
              localStorage.getItem("user_id") == cellId ? (
              <Link
                onClick={() => handleDrawer("edit", cellId, cell.row.original)}
                className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-red-600 bg-red-100"
              >
                <button
                  className="text-red-600 "
                  size="default"
                  variant="outlined"
                >
                  Edit<span className="sr-only">, </span>
                </button>
              </Link>
            ) : null}
            {allowedActions.filter((data) => data.permission_id == 3)?.length >
              0 && isBtn ? (
              <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
                <button
                  onClick={() => {
                    onDeleteOpenSection(cellId, user_is_deleted);
                  }}
                  size="default"
                  variant="outlined"
                >
                  Delete
                </button>
              </span>
            ) : null}
          </>
        );
      },
    },
  ];
export const role_columns = ({
  onDeleteOpen,
  handleDrawer,
  handleSaveResult,
  handleUpdateResult,
  saveMaxDiscountValue,
  allowedActions,
}) => [
    {
      Header: "Sr No.",
      accessor: (d, i) => i + 1,
      Cell: ({ cell }) => (
        <div className="p-2 text-sm text-gray-500">{cell.value}</div>
      ), // Adding a class to the cell
    },
    {
      Header: "Role Id",
      accessor: "role_id",
    },
    {
      Header: "Role Name",
      accessor: (d) => capitalise(d.role_name),
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell.row.original.role_id;
        const isBtn = cell.row.original.isBtn;
        return (
          <>
            {/* {isBtn && ( */}
            {(allowedActions.filter((data) => data.permission_id == 6)?.length >
              0 &&
              isBtn) ||
              localStorage.getItem("role_id") == 1 ? (
              <Link
                onClick={() => handleDrawer("edit", cellId, cell.row.original)}
                className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-red-600 bg-red-100"
              >
                <button
                  className="text-red-600 "
                  size="default"
                  variant="outlined"
                >
                  Edit
                </button>
              </Link>
            ) : null}
            {allowedActions.filter((data) => data.permission_id == 7)?.length >
              0 && isBtn ? (
              // {isBtn && (
              <Link
                to={`/role/${cellId}`}
                onClick={() => handleDrawer("edit", cellId, cell.row.original)}
                className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-red-600 bg-red-100"
              >
                <button
                  className="text-red-600 "
                  size="default"
                  variant="outlined"
                >
                  Edit Permissions
                </button>
              </Link>
            ) : null}
            {/* {isBtn && ( */}
            {allowedActions.filter((data) => data.permission_id == 8)?.length >
              0 && isBtn ? (
              <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
                <button
                  onClick={() => {
                    onDeleteOpen(cellId);
                  }}
                  size="default"
                  variant="outlined"
                >
                  Delete
                </button>
              </span>
            ) : null}
          </>
        );
      },
    },
  ];
export const country_columns = ({ onDeleteOpen, handleDrawer }) => [
  {
    Header: "Sr No.",
    accessor: (d, i) => i + 1,
    Cell: ({ cell }) => (
      <div className="p-2 text-sm text-gray-500">{cell.value}</div>
    ), // Adding a class to the cell
  },
  {
    Header: "Country",
    accessor: (d) => capitalise(d.country_name),
  },
  {
    Header: "Country Code",
    accessor: "country_code",
  },
  {
    Header: "Country ISD Code",
    accessor: "country_isd_code",
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const cellId = cell.row.original.country_id;
      const country_is_edit = cell.row.original.country_is_edit;
      return (
        <>
          {country_is_edit && (
            <Link
              onClick={() => handleDrawer("edit", cellId, cell.row.original)}
              className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-red-600 bg-red-100"
            >
              <button
                className="text-red-600 "
                size="default"
                variant="outlined"
              >
                Edit<span className="sr-only">, </span>
              </button>
            </Link>
          )}
          {country_is_edit && (
            <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
              <button
                onClick={() => {
                  onDeleteOpen(cellId);
                }}
                size="default"
                variant="outlined"
              >
                Delete
                <span className="sr-only">, </span>
              </button>
            </span>
          )}
        </>
      );
    },
  },
];
export const gender_columns = ({ onDeleteOpen, handleDrawer }) => [
  {
    Header: "Sr No.",
    accessor: (d, i) => i + 1,
    Cell: ({ cell }) => (
      <div className="p-2 text-sm text-gray-500">{cell.value}</div>
    ), // Adding a class to the cell
  },
  {
    Header: "Gender English",
    accessor: (d) => capitalise(d.gender_name),
  },
  {
    Header: "Gender Arabic",
    accessor: (d) => capitalise(d.gender_name_ar),
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const cellId = cell.row.original.gender_id;
      return (
        <>
          <Link
            onClick={() => handleDrawer("edit", cellId, cell.row.original)}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-red-600 bg-red-100"
          >
            <button className="text-red-600 " size="default" variant="outlined">
              Edit<span className="sr-only">, </span>
            </button>
          </Link>
          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cellId);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];
export const state_columns = ({ onDeleteOpen, handleDrawer }) => [
  {
    Header: "Sr No.",
    accessor: (d, i) => i + 1,
    Cell: ({ cell }) => (
      <div className="p-2 text-sm text-gray-500">{cell.value}</div>
    ), // Adding a class to the cell
  },
  {
    Header: "State Name",
    accessor: (d) => capitalise(d.state_name),
  },
  {
    Header: "region Name",
    accessor: (d) => capitalise(d?.RegionMaster?.name),
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const cellId = cell.row.original.state_id;
      return (
        <>
          <Link
            onClick={() => handleDrawer("edit", cellId, cell.row.original)}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-red-600 bg-red-100"
          >
            <button className="text-red-600 " size="default" variant="outlined">
              Edit<span className="sr-only">, </span>
            </button>
          </Link>
          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cellId);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];
export const Region_columns = ({ onDeleteOpen, handleDrawer }) => [
  {
    Header: "Sr No.",
    accessor: (d, i) => i + 1,
    Cell: ({ cell }) => (
      <div className="p-2 text-sm text-gray-500">{cell.value}</div>
    ), // Adding a class to the cell
  },
  {
    Header: "Region Name",
    accessor: (d) => capitalise(d.name),
  },
  {
    Header: "zone",
    accessor: (d) => d?.ZoneMaster?.zone_name,
  },
  {
    Header: "fax number",
    accessor: (d) => d.fax_number,
  },
  {
    Header: "tel number",
    accessor: (d) => d.tel_number,
  },
  {
    Header: "toll free number",
    accessor: (d) => d.toll_free_number,
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const cellId = cell.row.original.id;
      return (
        <>
          <Link
            onClick={() => handleDrawer("edit", cellId, cell.row.original)}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-red-600 bg-red-100"
          >
            <button className="text-red-600 " size="default" variant="outlined">
              Edit<span className="sr-only">, </span>
            </button>
          </Link>
          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cellId);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];
export const Batch_columns = ({ onDeleteOpen, handleDrawer }) => [
  {
    Header: "Sr No.",
    accessor: (d, i) => i + 1,
    Cell: ({ cell }) => (
      <div className="p-2 text-sm text-gray-500">{cell.value}</div>
    ), // Adding a class to the cell
  },
  {
    Header: "Batch",
    accessor: (d) => capitalise(d.batch_name),
  },
  {
    Header: "Code",
    accessor: (d) => d.batch_code,
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const cellId = cell.row.original.id;
      return (
        <>
          <Link
            onClick={() => handleDrawer("edit", cellId, cell.row.original)}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-red-600 bg-red-100"
          >
            <button className="text-red-600 " size="default" variant="outlined">
              Edit<span className="sr-only">, </span>
            </button>
          </Link>
          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cellId);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];
export const Zone_columns = ({ onDeleteOpen, handleDrawer }) => [
  {
    Header: "Sr No.",
    accessor: (d, i) => i + 1,
    Cell: ({ cell }) => (
      <div className="p-2 text-sm text-gray-500">{cell.value}</div>
    ), // Adding a class to the cell
  },
  {
    Header: "Zone",
    accessor: (d) => capitalise(d.zone_name),
  },
  {
    Header: "Code",
    accessor: (d) => d.zone_code,
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const cellId = cell.row.original.id;
      return (
        <>
          <Link
            onClick={() => handleDrawer("edit", cellId, cell.row.original)}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-red-600 bg-red-100"
          >
            <button className="text-red-600 " size="default" variant="outlined">
              Edit<span className="sr-only">, </span>
            </button>
          </Link>
          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cellId);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const Company_columns = ({ onDeleteOpen, handleDrawer, imageBaseURL, }) => [
  {
    Header: "Sr No.",
    accessor: (d, i) => i + 1,
    Cell: ({ cell }) => (
      <div className="p-2 text-sm text-gray-500">{cell.value}</div>
    ), // Adding a class to the cell
  },
  {
    Header: "Name",
    accessor: (d) => capitalise(d.company_name),
  },
  {
    Header: "Logo",
    accessor: "logo",
    Cell: (cell) => {
      const logoSrc = cell.row.original.company_logo;
      return (
        <>
          <img
            src={imageBaseURL + logoSrc}
            alt={cell.row.original.company_name}
            className="w-[50px] h-auto"
          />
        </>
      );
    },
  },
  {
    Header: "Banner",
    accessor: "banner",
    Cell: (cell) => {
      const bannerSrc = cell.row.original.company_banner;
      return (
        <>
          <img
            src={imageBaseURL + bannerSrc}
            alt={cell.row.original.company_name}
            className="w-[50px] h-auto"
          />
        </>
      );
    },
  },
  {
    Header: "Sign",
    accessor: "sign",
    Cell: (cell) => {
      const signSrc = cell.row.original.company_sign;
      return (
        <>
          <img
            src={imageBaseURL + signSrc}
            alt={cell.row.original.company_name}
            className="w-[50px] h-auto"
          />
        </>
      );
    },
  },
  {
    Header: "Email",
    accessor: (d) => d.company_email,
  },
  {
    Header: "legal entity type",
    accessor: (d) => d.company_legal_entity_type,
  },
  {
    Header: "registration date",
    accessor: (d) => moment(d.company_registration_date).format("YYYY-MM-DD"),
  },
  {
    Header: "Website",
    accessor: (d) => d.company_website,
  },
  {
    Header: "Country",
    accessor: (d) => d.company_country,
  },
  {
    Header: "State",
    accessor: (d) => d.company_state,
  },
  {
    Header: "City",
    accessor: (d) => d.company_city,
  },
  {
    Header: "Pincode",
    accessor: (d) => d.company_pincode,
  },
  {
    Header: "Registered office address",
    accessor: (d) => d.company_registered_office_add,
  },
  {
    Header: "Corp office address",
    accessor: (d) => d.company_corporate_office_add,
  },
  {
    Header: "cin",
    accessor: (d) => d.company_cin,
  },
  {
    Header: "GSTIN",
    accessor: (d) => d.company_gstno,
  },
  {
    Header: "tax registration number",
    accessor: (d) => d.company_tax_registration_no,
  },
  {
    Header: "PAN card number",
    accessor: (d) => d.company_panno,
  },
  {
    Header: "Telephone number",
    accessor: (d) => d.company_telephone_no,
  },
  {
    Header: "vat number",
    accessor: (d) => d.company_vat_no,
  },
  {
    Header: "swift code",
    accessor: (d) => d.company_swift_code,
  },
  {
    Header: "Bank name",
    accessor: (d) => d.company_bank_name,
  },
  {
    Header: "branch name",
    accessor: (d) => d.company_branch_name,
  },
  {
    Header: "Bank A/c number",
    accessor: (d) => d.company_bank_account_no,
  },
  {
    Header: "Bank A/c name",
    accessor: (d) => d.company_bank_account_name,
  },
  {
    Header: "Bank ifsc code",
    accessor: (d) => d.company_ifsc_code,
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const cellId = cell.row.original.company_id;
      return (
        <>
          <Link
            onClick={() => handleDrawer("edit", cellId, cell.row.original)}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-red-600 bg-red-100"
          >
            <button className="text-red-600 " size="default" variant="outlined">
              Edit
            </button>
          </Link>
          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cellId);
              }}
              size="default"
              variant="outlined"
            >
              Delete
            </button>
          </span>
        </>
      );
    },
  },
];

export const Pincode_columns = ({ onDeleteOpen, handleDrawer }) => [
  {
    Header: "Sr No.",
    accessor: (d, i) => i + 1,
    Cell: ({ cell }) => (
      <div className="p-2 text-sm text-gray-500">{cell.value}</div>
    ), // Adding a class to the cell
  },
  {
    Header: "pin Code",
    accessor: (d) => d.pincode,
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const cellId = cell.row.original.id;
      return (
        <>
          <Link
            onClick={() => handleDrawer("edit", cellId, cell.row.original)}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-red-600 bg-red-100"
          >
            <button className="text-red-600 " size="default" variant="outlined">
              Edit<span className="sr-only">, </span>
            </button>
          </Link>
          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cellId);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];
export const CityType_columns = ({ onDeleteOpen, handleDrawer }) => [
  {
    Header: "Sr No.",
    accessor: (d, i) => i + 1,
    Cell: ({ cell }) => (
      <div className="p-2 text-sm text-gray-500">{cell.value}</div>
    ), // Adding a class to the cell
  },
  {
    Header: "TYPE",
    accessor: (d) => capitalise(d.city_type_name),
  },
  {
    Header: "Code",
    accessor: (d) => d.city_type_code,
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const cellId = cell.row.original.id;
      return (
        <>
          <Link
            onClick={() => handleDrawer("edit", cellId, cell.row.original)}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-red-600 bg-red-100"
          >
            <button className="text-red-600 " size="default" variant="outlined">
              Edit<span className="sr-only">, </span>
            </button>
          </Link>
          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cellId);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];
export const Delivery_columns = ({ onDeleteOpen, handleDrawer }) => [
  {
    Header: "Sr No.",
    accessor: (d, i) => i + 1,
    Cell: ({ cell }) => (
      <div className="p-2 text-sm text-gray-500">{cell.value}</div>
    ), // Adding a class to the cell
  },
  {
    Header: "name",
    accessor: (d) => capitalise(d.name),
  },
  {
    Header: "phone_number",
    accessor: (d) => d.phone_number,
  },
  {
    Header: "email",
    accessor: (d) => d.email,
  },
  {
    Header: "short_code",
    accessor: (d) => d.short_code,
  },
  {
    Header: "location",
    accessor: (d) => d.location,
  },
  {
    Header: "pin_code",
    accessor: (d) => d.pin_code.pincode,
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const cellId = cell.row.original.id;
      return (
        <>
          <Link
            onClick={() => handleDrawer("edit", cellId, cell.row.original)}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-red-600 bg-red-100"
          >
            <button className="text-red-600 " size="default" variant="outlined">
              Edit<span className="sr-only">, </span>
            </button>
          </Link>
          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cellId);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];
export const CityGroup_columns = ({ imageBaseURL, onDeleteOpen, handleDrawer }) => [
  {
    Header: "Sr No.",
    accessor: (d, i) => i + 1,
    Cell: ({ cell }) => (
      <div className="p-2 text-sm text-gray-500">{cell.value}</div>
    ), // Adding a class to the cell
  },
  {
    Header: "Group",
    accessor: (d) => capitalise(d.city_group_name),
  },
  {
    Header: "Name",
    accessor: "mm_name",
    Cell: (cell) => {
      const mm_id = cell.row.original.mm_id;
      const mm_name = cell.row.original.mm_name;

      return (
        <div className="flex items-center">
          <span>{mm_name}</span>
          <Link
            to={`/magazine-issue/${mm_id}`}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full"
          >
            <span>
              <PencilIcon className="w-4 h-4 ml-2" />
            </span>
          </Link>
        </div>
      );
    },
  },
  {
    Header: "Lead Time",
    accessor: "mm_lead_time",
  },
  {
    Header: "banner",
    accessor: "mm_poster",
    Cell: (cell) => {
      const bannerSrc = cell.row.original.mm_poster;
      return (
        <>
          <img
            src={imageBaseURL + bannerSrc}
            alt={cell.row.original.name}
            className="w-[50px] h-auto"
          />
        </>
      );
    },
  },
  // {
  //   Header: "Group",
  //   accessor: (d) => capitalise(d.city_group_name),
  //   Header: "Name",
  //   accessor: "mm_name",
  //   Cell: (cell) => {
  //     const mm_id = cell.row.original.mm_id;
  //     const mm_name = cell.row.original.mm_name;

  //     return (
  //       <div className="flex items-center">
  //         <span>{mm_name}</span>
  //         <Link
  //           to={`/magazine-issue/${mm_id}`}
  //           className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full"
  //         >
  //           <span>
  //             <PencilIcon className="w-4 h-4 ml-2" />
  //           </span>
  //         </Link>
  //       </div>
  //     );
  //   },
  // },
  // {
  //   Header: "Lead Time",
  //   accessor: "mm_lead_time",
  // },
  // {
  //   Header: "banner",
  //   accessor: "mm_poster",
  //   Cell: (cell) => {
  //     const bannerSrc = cell.row.original.mm_poster;
  //     return (
  //       <>
  //         <img
  //           src={imageBaseURL + bannerSrc}
  //           alt={cell.row.original.name}
  //           className="w-[50px] h-auto"
  //         />
  //       </>
  //     );
  //   },
  // },
  {
    Header: "Code",
    accessor: (d) => d.city_group_code,
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const cellId = cell.row.original.id;
      return (
        <>
          <Link
            onClick={() => handleDrawer("edit", cellId, cell.row.original)}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-red-600 bg-red-100"
          >
            <button className="text-red-600 " size="default" variant="outlined">
              Edit<span className="sr-only">, </span>
            </button>
          </Link>
          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cellId);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];


export const city_columns = ({ onDeleteOpen, handleDrawer }) => [
  {
    Header: "Sr No.",
    accessor: (d, i) => i + 1,
    Cell: ({ cell }) => (
      <div className="p-2 text-sm text-gray-500">{cell.value}</div>
    ), // Adding a class to the cell
  },
  {
    Header: "City Name",
    accessor: (d) => capitalise(d.city_name),
  },
  {
    Header: "State Name",
    accessor: (d) => capitalise(d?.m_state_relation?.state_name),
    // accessor: (d) => capitalise(d.m_state_relation.state_name),
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const cellId = cell.row.original.city_id;
      return (
        <>
          <Link
            onClick={() => handleDrawer("edit", cellId, cell.row.original)}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-red-600 bg-red-100"
          >
            <button className="text-red-600 " size="default" variant="outlined">
              Edit<span className="sr-only">, </span>
            </button>
          </Link>
          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cellId);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];
export const magazine_columns = ({
  issueModalOpen,
  onDeleteOpen,
  handleDrawer,
  imageBaseURL,
  onStatusUpdate,
  allowedActions,
}) => [
    {
      Header: "Sr No.",
      accessor: (d, i) => i + 1,
      Cell: ({ cell }) => (
        <div className="p-2 text-sm text-gray-500">{cell.value}</div>
      ), // Adding a class to the cell
    },
    // {
    //   Header: "Name",
    //   accessor: 'mm_name',
    //   // accessor: (d) => capitalise(d.mm_name),
    //   Cell: (cell) => {
    //     const mm_id = cell.row.original.mm_id;
    //     const mm_name = cell.row.original.mm_name;
    //     return (
    //       <>
    //         <Link
    //           to={`/magazine-issue/${mm_id}`}
    //           className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-red-600 bg-red-100"
    //         >
    //           <span><PencilIcon className="w-4 h-4 ml-2" /></span>
    //         </Link>
    //       </>
    //     );
    //   },
    // },
    {
      Header: "Name",
      accessor: "mm_name",
      Cell: (cell) => {
        const mm_id = cell.row.original.mm_id;
        const mm_name = cell.row.original.mm_name;
        return (
          <div className="flex items-center">
            <span>{mm_name}</span>
            <Link
              to={`/magazine-issue/${mm_id}`}
              className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full"
            >
              <span>
                <PencilIcon className="w-4 h-4 ml-2" />
              </span>
            </Link>
          </div>
        );
      },
    },
    {
      Header: "Code",
      accessor: "mm_code",

    },
    {
      Header: "banner",
      accessor: "mm_poster",
      Cell: (cell) => {
        const bannerSrc = cell.row.original.mm_poster;
        return (
          <>
            <img
              src={imageBaseURL + bannerSrc}
              alt={cell.row.original.name}
              className="w-[50px] h-auto"
            />
          </>
        );
      },
    },
    {
      Header: "Status",
      accessor: (d) => (d.mm_status == true ? "Active" : "Inactive"),
      Cell: (cell) => {
        const cellId = cell.row.original.mm_id;
        const enabled = cell.row.original.mm_status;
        // const [enabled, setEnabled] = useState()
        return (
          <>
            {allowedActions.filter((data) => data.permission_id == 11)?.length >
              0 ? (
              <Switch
                checked={enabled}
                onChange={() => {
                  onStatusUpdate(cellId, cell.row.original.mm_status);
                }}
                className={magazineStatusToggle(
                  enabled ? "bg-[#872341]" : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                )}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={magazineStatusToggle(
                    enabled ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            ) : null}
          </>
        );
      },
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell.row.original.mm_id;
        return (
          <>
            <span className="inline-flex px-2 ml-3 text-xs leading-5 text-blue-600 bg-blue-100 rounded-full">
              <button
                onClick={() => {
                  issueModalOpen(cellId, cell.row.original);
                }}
                size="default"
                variant="outlined"
                className="inline-flex items-center gap-2"
              >
                Add Issue <PlusIcon className="h-4 w-4" />
              </button>
            </span>
            {allowedActions.filter((data) => data.permission_id == 10)?.length >
              0 ? (
              <Link
                onClick={() => handleDrawer("edit", cellId, cell.row.original)}
                className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-red-600 bg-red-100"
              >
                <button
                  className="text-green-900 "
                  size="default"
                  variant="outlined"
                >
                  Edit
                </button>
              </Link>
            ) : null}
            {allowedActions.filter((data) => data.permission_id == 12)?.length >
              0 ? (
              <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
                <button
                  onClick={() => {
                    onDeleteOpen(cellId);
                  }}
                  size="default"
                  variant="outlined"
                >
                  Delete
                </button>
              </span>
            ) : null}
          </>
        );
      },
    },
  ];

// export const magazine_issue_columns = ({
//   onDeleteOpen,
//   handleDrawer,
//   imageBaseURL,
//   onStatusUpdate,
//   allowedActions,
// }) => [
//     {
//       Header: "Sr No.",
//       accessor: (d, i) => i + 1,
//       Cell: ({ cell }) => (
//         <div className="p-2 text-sm text-gray-500">{cell.value}</div>
//       ), // Adding a class to the cell
//     },
//     {
//       Header: "Name",

//       accessor: "product_name",
//       //accessor: (d) => capitalise(d.mm_name),
//     },
//     // {
//     //   Header: "Type",
//     //   accessor: "product_type",
//     // },
//     {
//       Header: "Price",
//       accessor: "product_price",
//     },
//     // {
//     //   Header: "Discount",
//     //   accessor: "product_discount",
//     // },
//     // {
//     //   Header: "No_of_issue",
//     //   accessor: "product_no_of_issue",
//     // },

//     {
//       Header: "Image",
//       accessor: "product_image",
//       Cell: (cell) => {
//         const bannerSrc = cell.row.original.product_image;
//         return (
//           <>
//             <img
//               src={imageBaseURL + bannerSrc}
//               alt={cell.row.product_name}
//               className="w-[50px] h-auto"
//             />
//           </>
//         );
//       },
//     },
//     {
//       Header: "Status",
//       accessor: (d) => (d.product_status == true ? "Active" : "Inactive"),
//       Cell: (cell) => {
//         const cellId = cell.row.original.product_id;
//         const enabled = cell.row.original.product_status;
//         // const [enabled, setEnabled] = useState()

//         return (
//           <>
//             {allowedActions.filter((data) => data.permission_id == 19)?.length >
//               0 ? (
//               <Switch
//                 checked={enabled}
//                 onChange={() => {
//                   onStatusUpdate(cellId, cell.row.original.product_status);
//                 }}
//                 className={productStatusToggle(
//                   enabled ? "bg-[#872341]" : "bg-gray-200",
//                   "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
//                 )}
//               >
//                 <span className="sr-only">Use setting</span>
//                 <span
//                   aria-hidden="true"
//                   className={productStatusToggle(
//                     enabled ? "translate-x-5" : "translate-x-0",
//                     "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
//                   )}
//                 />
//               </Switch>
//             ) : null}
//           </>
//         );
//       },
//     },
//     {
//       Header: "Action",
//       accessor: "action",
//       Cell: (cell) => {
//         const cellId = cell.row.original.product_id;
//         return (
//           <>
//             {allowedActions.filter((data) => data.permission_id == 18)?.length >
//               0 ? (
//               <Link
//                 onClick={() => handleDrawer("edit", cellId, cell.row.original)}
//                 className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-red-600 bg-red-100"
//               >
//                 <button
//                   className="text-green-900 "
//                   size="default"
//                   variant="outlined"
//                 >
//                   Edit
//                 </button>
//               </Link>
//             ) : null}

//             {allowedActions.filter((data) => data.permission_id == 20)?.length >
//               0 ? (
//               <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
//                 <button
//                   onClick={() => {
//                     onDeleteOpen(cellId);
//                   }}
//                   size="default"
//                   variant="outlined"
//                 >
//                   Delete
//                 </button>
//               </span>
//             ) : null}
//           </>
//         );
//       },
//     },
//     {
//       Header: "Action",
//       accessor: "action",
//       Cell: (cell) => {
//         const cellId = cell.row.original.mi_id;
//         return (
//           <>
//             {allowedActions.filter((data) => data.permission_id == 14)?.length >
//               0 ? (
//               <span className="inline-flex px-2 ml-3 text-xs leading-5 text-green-600 bg-green-100 rounded-full">
//                 <button
//                   onClick={() => handleDrawer("edit", cellId, cell.row.original)}
//                   className="text-green-900 "
//                   size="default"
//                   variant="outlined"
//                 >
//                   Edit
//                 </button>
//               </span>
//             ) : null}
//             })
//             }
//             ];

//             {allowedActions.filter((data) => data.permission_id == 16)?.length >
//               0 ? (
//               <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
//                 <button
//                   onClick={() => {
//                     onDeleteOpen(cellId);
//                   }}
//                   size="default"
//                   variant="outlined"
//                 >
//                   Delete
//                 </button>
//               </span>
//             ) : null}
//           </>
//         );
//       },
//     },
//   ];
export const magazine_issue_columns = ({
  onDeleteOpen,
  handleDrawer,
  imageBaseURL,
  onStatusUpdate,
  allowedActions,
}) => [
    {
      Header: "Sr No.",
      accessor: (d, i) => i + 1,
      Cell: ({ cell }) => (
        <div className="p-2 text-sm text-gray-500">{cell.value}</div>
      ), // Adding a class to the cell
    },
    {
      Header: "Name",
      accessor: (d) => capitalise(d.mi_name),
    },
    {
      Header: "Magazine Cover",
      accessor: "mi_cover",
      Cell: (cell) => {
        const cellPoster = cell.row.original.mi_cover;
        // console.log("cellPoster", cellPoster, imageBaseURL, imageBaseURL+cellPoster)
        return (
          <>
            <img
              src={imageBaseURL + cellPoster}
              alt={""}
              className="w-[50px] h-auto"
            />
          </>
        );
      },
    },
    {
      Header: "Issue Date",
      accessor: (d) => moment(d.mi_release_date).format("DD-MM-YYYY"),
    },
    {
      Header: "Price",
      accessor: "mi_default_price",
    },
    {
      Header: "Status",
      accessor: (d) => (d.mi_status == true ? "Active" : "Inactive"),
      Cell: (cell) => {
        const cellId = cell.row.original.mi_id;
        const enabled = cell.row.original.mi_status;
        // const [enabled, setEnabled] = useState()
        return (
          <>
            {allowedActions.filter((data) => data.permission_id == 15)?.length >
              0 ? (
              <Switch
                checked={enabled}
                onChange={() => {
                  onStatusUpdate(cellId, cell.row.original.mi_status);
                }}
                className={magazineIssueStatusToggle(
                  enabled ? "bg-[#872341]" : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                )}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={magazineIssueStatusToggle(
                    enabled ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            ) : null}
          </>
        );
      },
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell.row.original.mi_id;
        return (
          <>
            {allowedActions.filter((data) => data.permission_id == 14)?.length >
              0 ? (
              <span className="inline-flex px-2 ml-3 text-xs leading-5 text-green-600 bg-green-100 rounded-full">
                <button
                  onClick={() => handleDrawer("edit", cellId, cell.row.original)}
                  className="text-green-900 "
                  size="default"
                  variant="outlined"
                >
                  Edit
                </button>
              </span>
            ) : null}
            {allowedActions.filter((data) => data.permission_id == 16)?.length >
              0 ? (
              <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
                <button
                  onClick={() => {
                    onDeleteOpen(cellId);
                  }}
                  size="default"
                  variant="outlined"
                >
                  Delete
                </button>
              </span>
            ) : null}
          </>
        );
      },
    },
  ];
export const sku_columns = ({
  onDeleteOpen,
  imageBaseURL,
  imageMMBaseURL,
  allowedActions,
}) => [
    {
      Header: "Sr No.",
      accessor: (d, i) => i + 1,
      Cell: ({ cell }) => (
        <div className="p-2 text-sm text-gray-500">{cell.value}</div>
      ), // Adding a class to the cell
    },
    {
      Header: "SKU Id",
      accessor: "sku_id",
    },
    {
      Header: "SKU Name",
      accessor: "sku_code",
    },
    {
      Header: "SKU TYPE",
      accessor: "sku_type",
    },
    {
      Header: "Image",
      accessor: "sku_img",
      Cell: (cell) => {
        let sku_type = cell.row.original.sku_type;
        let bannerSrc = cell.row.original.sku_img;
        let bannerposter =
          cell.row.original.skus_magazine_map.length > 0
            ? cell.row.original.skus_magazine_map[0]
              ?.skumagm_master_magazines_relation?.mm_poster
            : null;
        const image =
          cell.row.original.sku_type == "SINGLE"
            ? cell.row.original.sku_img
              ? imageBaseURL + bannerSrc
              : imageMMBaseURL + bannerposter
            : imageBaseURL + bannerSrc;
        // const bannerSrc = cell.row.original.sku_img;
        return (
          <>
            <img
              src={image}
              // src={imageBaseURL + bannerSrc}
              alt={cell.row.sku_code}
              className="w-[50px] h-auto"
            />
          </>
        );
      },
    },
    {
      Header: "SKU CREATED DATE",
      accessor: (d) => moment(d.sku_created).format("DD-MM-YYYY"),
    },
    // {
    //   Header: "SKU MAGAZINE NAMES",
    //   accessor: "SKU MAGAZINE NAMES",
    //   Cell: (cell) => {
    //     const cellData = cell.row.original.skus_magazine_map;
    //     return (
    //       <>
    //         {cellData?.map((item, index) => (
    //           <span key={index}
    //             className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-red-600 bg-red-100"
    //           >{item?.skumagm_master_magazines_relation?.mm_name}</span>
    //         ))}
    //       </>
    //     )
    //   }
    // },
    {
      Header: "SKU MAGAZINE NAMES",
      accessor: "SKU MAGAZINE NAMES",
      Cell: (cell) => {
        const cellData = cell.row.original.skus_magazine_map;
        return (
          <>
            {cellData?.map((item, index) => (
              <span
                key={index}
                className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-red-600 bg-red-100"
              >
                {/* {item?.skumagm_magazine_issues_relation?.mi_name}</span> */}
                {item?.skumagm_master_magazines_relation?.mm_name}
              </span>
            ))}
          </>
        );
      },
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell.row.original.sku_id;
        return (
          <>
            {allowedActions.filter((data) => data.permission_id == 23)?.length >
              0 ? (
              <Link
                // onClick={() => handleDrawer("edit", cellId, cell.row.original)}
                to={`../add-sku/${cellId}`}
                className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-red-600 bg-red-100"
              >
                <button
                  className="text-red-600 "
                  size="default"
                  variant="outlined"
                >
                  Edit
                </button>
              </Link>
            ) : null}
            {allowedActions.filter((data) => data.permission_id == 24)?.length >
              0 ? (
              <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
                <button
                  onClick={() => {
                    onDeleteOpen(cellId);
                  }}
                  size="default"
                  variant="outlined"
                >
                  Delete
                </button>
              </span>
            ) : null}
          </>
        );
      },
    },
  ];
export const product_columns = ({
  issueModalOpen,
  onDeleteOpen,
  handleDrawer,
  imageBaseURL,
  onStatusUpdate,
  allowedActions,
  generateInvoiceReport,
}) => [
    {
      Header: "Sr No.",
      accessor: (d, i) => i + 1,
      Cell: ({ cell }) => (
        <div className="p-2 text-sm text-gray-500">{cell.value}</div>
      ), // Adding a class to the cell
    },
    {
      Header: "Name",
      accessor: "product_name",
      //accessor: (d) => capitalise(d.mm_name),
    },
    // {
    //   Header: "Type",
    //   accessor: "product_type",
    // },
    {
      Header: "Price",
      accessor: "product_price",
    },
    // {
    //   Header: "Discount",
    //   accessor: "product_discount",
    // },
    // {
    //   Header: "No_of_issue",
    //   accessor: "product_no_of_issue",
    // },
    {
      Header: "Image",
      accessor: "product_image",
      Cell: (cell) => {
        const bannerSrc = cell.row.original.product_image;
        return (
          <>
            <img
              src={imageBaseURL + bannerSrc}
              alt={cell.row.product_name}
              className="w-[50px] h-auto"
            />
          </>
        );
      },
    },
    {
      Header: "Status",
      accessor: (d) => (d.product_status == true ? "Active" : "Inactive"),
      Cell: (cell) => {
        const cellId = cell.row.original.product_id;
        const enabled = cell.row.original.product_status;
        // const [enabled, setEnabled] = useState()
        return (
          <>
            {allowedActions.filter((data) => data.permission_id == 19)?.length >
              0 ? (
              <Switch
                checked={enabled}
                onChange={() => {
                  onStatusUpdate(cellId, cell.row.original.product_status);
                }}
                className={productStatusToggle(
                  enabled ? "bg-[#872341]" : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                )}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={productStatusToggle(
                    enabled ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            ) : null}
          </>
        );
      },
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell.row.original.product_id;
        return (
          <>
            {allowedActions.filter((data) => data.permission_id == 18)?.length >
              0 ? (
              <Link
                onClick={() => handleDrawer("edit", cellId, cell.row.original)}
                className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-red-600 bg-red-100"
              >
                <button
                  className="text-green-900 "
                  size="default"
                  variant="outlined"
                >
                  Edit
                </button>
              </Link>
            ) : null}
            {allowedActions.filter((data) => data.permission_id == 20)?.length >
              0 ? (
              <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
                <button
                  onClick={() => {
                    onDeleteOpen(cellId);
                  }}
                  size="default"
                  variant="outlined"
                >
                  Delete
                </button>
              </span>
            ) : null}
          </>
        );
      },
    },
  ];
export const order_columns = ({
  onDeleteOpen,
  onStatusUpdate,
  allowedActions,
  generateInvoiceReport,
  handleRefund,
  handleRefundDetails,
}) => [
    {
      Header: "Sr No.",
      accessor: (d, i) => i + 1,
      Cell: ({ cell }) => (
        <div className="p-2 text-sm text-gray-500">{cell.value}</div>
      ), // Adding a class to the cell
    },
    {
      Header: "Order Id",
      accessor: "ord_id",
    },
    // {
    //   Header: "Trans Id",
    //   accessor: "ord_id",
    // },
    // {
    //   Header: "Order No",
    //   accessor: (d) => capitalise(d?.ord_pmtg_order_id),
    // },
    // {
    //   Header: "Sys Order No",
    //   accessor: (d) => capitalise(d?.ord_sys_order_id),
    // },
    {
      Header: "SKUS",
      accessor: (d) => capitalise(d?.skus_names),
    },
    // {
    //   Header: "Referral Code",
    //   accessor: (d) => capitalise(d?.ord_user_referral_code),
    // },
    {
      Header: "Customer Name",
      accessor: (d) =>
        capitalise(
          d?.ord_customer_name
            ? d?.ord_customer_name
            : d?.ord_customer_relation?.customer_username
        ),
    },
    {
      Header: "Order Date",
      accessor: (d) => moment(d?.ord_created_at).format("DD-MM-YYYY"), //capitalise(d.order_date),
    },
    {
      Header: "Order Type",
      accessor: (d) => capitalise(d?.online_status), //capitalise(d.order_date),
    },
    {
      Header: "Payment Status",
      accessor: (d) => capitalise(d?.trans_status), //capitalise(d.order_date),
    },

    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const subscriptionCount = cell?.row?.original?.subscription?.length;
        const cellId = cell?.row?.original?.ord_id;
        const skumagm_subscription = cell?.row?.original?.skumagm_subscription;
        const ord_payment_status = cell?.row?.original?.ord_payment_status;
        const customer_id =
          cell?.row?.original?.ord_customer_relation?.customer_id;
        const data = cell?.row?.original;
        return (
          <div className="flex flex-wrap min-w-[266px] max-w-[267px]">
            {ord_payment_status == "Paid" &&
              !data?.ord_invoice_url &&
              !data?.ord_invoice_name ? (
              <div className="m-1">
                <span className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144]  px-1 py-1 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#BE3144] focus:ring-offset-2 ml-auto">
                  <button
                    type="button"
                    onClick={() => {
                      generateInvoiceReport(data);
                    }}
                    size="default"
                    variant="outlined"
                  >
                    Generate Invoice
                  </button>
                </span>
              </div>
            ) : (
              <></>
            )}
            {(ord_payment_status == "Paid" ||
              ord_payment_status == "Partly Paid" ||
              ord_payment_status == "Extra Paid") &&
              data?.ord_invoice_url &&
              data?.ord_invoice_name ? (
              <div class="tooltip m-1">
                <a
                  className="text-xs underline"
                  target="__blank"
                  href={data?.ord_invoice_url}
                >
                  <FaDownload className="mt-[7px]" />
                </a>
                <span class="tooltiptext3">Download Invoice</span>
              </div>
            ) : (
              <></>
            )}
            {/* {allowedActions.filter((data) => data.permission_id == 48)?.length > 0 ? ( */}
            {subscriptionCount > 0 &&
              (ord_payment_status == "Paid" ||
                ord_payment_status == "Partly Paid" ||
                ord_payment_status == "Extra Paid") &&
              skumagm_subscription ? (
              <div className="m-1">
                <Link
                  // onClick={() => handleDrawer("edit", cellId, cell?.row?.original)}
                  to={`../subscription/${customer_id}`}
                  className="inline-flex px-2 text-xs leading-5 rounded-full text-red-600 bg-red-100"
                >
                  <button
                    className="text-red-600 "
                    size="default"
                    variant="outlined"
                  >
                    Subscription
                  </button>
                </Link>
              </div>
            ) : (
              <></>
            )}
            {/* ) : null} */}
            {allowedActions.filter((data) => data.permission_id == 27)?.length >
              0 ? (
              <div className="m-1">
                <Link
                  // onClick={() => handleDrawer("edit", cellId, cell?.row?.original)}
                  to={`../add-order/${cellId}`}
                  className="inline-flex px-2 text-xs leading-5 rounded-full text-red-600 bg-red-100"
                >
                  <button
                    className="text-red-600 "
                    size="default"
                    variant="outlined"
                  >
                    View
                  </button>
                </Link>
              </div>
            ) : null}
            {allowedActions.filter((data) => data.permission_id == 29)?.length >
              0 ? (
              <div className="m-1">
                <span className="inline-flex px-2 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
                  <button
                    onClick={() => {
                      onDeleteOpen(cellId);
                    }}
                    size="default"
                    variant="outlined"
                  >
                    Delete
                    {/* <span className="sr-only">, </span> */}
                  </button>
                </span>
              </div>
            ) : null
            }

            {
              (ord_payment_status == "Paid" ||
                ord_payment_status == "Partly Paid" ||
                ord_payment_status == "Extra Paid") &&
                data?.ord_invoice_url &&
                data?.ord_invoice_name && !data?.ord_is_refunded ? (
                <div className="m-1">
                  <span className="inline-flex px-2 text-xs leading-5 rounded-full text-red-600 bg-red-100 cursor-pointer" onClick={() => {
                    handleRefund(data);
                  }}>
                    {data?.result?.totalCounts?.totalFinalizeCount == 0 ? 'Full ' : 'Half '} Refund
                  </span>
                </div>
              ) : (
                <></>
              )
            }

            {
              (ord_payment_status == "Paid" ||
                ord_payment_status == "Partly Paid" ||
                ord_payment_status == "Extra Paid") &&
                data?.ord_invoice_url &&
                data?.ord_invoice_name && data?.ord_is_refunded ? (
                <div className="m-1">
                  <span className="inline-flex px-2 text-xs leading-5 rounded-full text-red-600 bg-red-100 cursor-pointer" onClick={() => {
                    handleRefundDetails(data);
                  }}>
                    Refund Details
                  </span>
                </div>
              ) : (
                <></>
              )
            }
          </div >
        );
      },
    },
  ];

export const refundDetails_colunms = ({

}) => [
    {
      Header: "Sr No.",
      accessor: (d, i) => i + 1,
      Cell: ({ cell }) => (
        <div className="p-2 text-sm text-gray-500">{cell.value}</div>
      ), // Adding a class to the cell
    },
    {
      Header: "Refund id",
      accessor: "refund_id",
      //accessor: (d) => capitalise(d.mm_name),
    },
    {
      Header: "first name",
      accessor: "customer_first_name",
      //accessor: (d) => capitalise(d.mm_name),
    },
    {
      Header: "last name",
      accessor: "customer_last_name",
      //accessor: (d) => capitalise(d.mm_name),
    },
    {
      Header: "email",
      accessor: "cust_email",
      //accessor: (d) => capitalise(d.mm_name),
    },
    {
      Header: "mobile",
      accessor: "customer_mobile",
      //accessor: (d) => capitalise(d.mm_name),
    },
    {
      Header: "pincode",
      accessor: "customer_pincode",
      //accessor: (d) => capitalise(d.mm_name),
    },
    {
      Header: "invoice number",
      accessor: "refund_invoice_number",
      //accessor: (d) => capitalise(d.mm_name),
    },
    {
      Header: "invoice url",
      accessor: "refund_invoice_url",
      //accessor: (d) => capitalise(d.mm_name),
    },
    {
      Header: "refund id",
      accessor: "refund_id_str",
      //accessor: (d) => capitalise(d.mm_name),
    },
    {
      Header: "refund entity",
      accessor: "refund_entity",
      //accessor: (d) => capitalise(d.mm_name),
    },
    {
      Header: "refund amount",
      accessor: "refund_amount",
      //accessor: (d) => capitalise(d.mm_name),
    },
    {
      Header: "refund currency",
      accessor: "refund_currency",
      //accessor: (d) => capitalise(d.mm_name),
    },
  ]

export const transaction_columns = ({
  onDeleteOpen,
  onStatusUpdate,
  allowedActions,
}) => [
    {
      Header: "Sr No.",
      accessor: (d, i) => i + 1,
      Cell: ({ cell }) => (
        <div className="p-2 text-sm text-gray-500">{cell.value}</div>
      ), // Adding a class to the cell
    },
    {
      Header: "Trans Id",
      accessor: "trans_id",
    },
    {
      Header: "Order Id",
      accessor: "trans_fk_ord_id",
    },
    {
      Header: "Sys Order No",
      accessor: (d) => capitalise(d?.trans_m_order_relation?.ord_sys_order_id),
    },
    {
      Header: "Razorepay Order No",
      accessor: (d) => capitalise(d?.trans_m_order_relation?.ord_pmtg_order_id),
    },
    {
      Header: "Razorepay Trans No",
      accessor: (d) => capitalise(d?.trans_upi_transaction_id),
    },
    {
      Header: "Razorepay Payment No",
      accessor: (d) => capitalise(d?.trans_m_order_relation?.razorpay_payment_id),
    },
    {
      Header: "Amount",
      accessor: (d) => capitalise(d?.trans_m_order_relation?.ord_total_main_amt),
    },
    {
      Header: "Method",
      accessor: (d) => capitalise(d?.trans_method),
    },
    {
      Header: "Payment Status",
      accessor: (d) => capitalise(d?.trans_status),
    },
    {
      Header: "User Referral Code",
      accessor: (d) =>
        capitalise(d?.trans_m_order_relation?.ord_user_referral_code),
    },
    {
      Header: "Customer Name",
      accessor: (d) => capitalise(d?.trans_m_order_relation?.ord_customer_name),
    },
    {
      Header: "Trans Date",
      accessor: (d) => moment(d?.trans_created_at).format("DD-MM-YYYY"), //capitalise(d.order_date),
    },
    // {
    //   Header: "Status",
    //   accessor: (d) => (d.trans_is_active === true ? "Active" : "Inactive"),
    //   Cell: (cell) => {
    //     const cellId = cell.row.original.trans_id;
    //     const enabled = cell.row.original.trans_is_active;
    //     // const [enabled, setEnabled] = useState()
    //     return (
    //       <>
    //         {allowedActions.filter((data) => data.permission_id == 41)?.length >
    //         0 ? (
    //           <Switch
    //             checked={enabled}
    //             onChange={() => {
    //               onStatusUpdate(cellId, cell.row.original.trans_is_active);
    //             }}
    //             className={productStatusToggle(
    //               enabled ? "bg-[#872341]" : "bg-gray-200",
    //               "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
    //             )}
    //           >
    //             <span className="sr-only">Use setting</span>
    //             <span
    //               aria-hidden="true"
    //               className={productStatusToggle(
    //                 enabled ? "translate-x-5" : "translate-x-0",
    //                 "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
    //               )}
    //             />
    //           </Switch>
    //         ) : null}
    //       </>
    //     );
    //   },
    // },
    // {
    //   Header: "Action",
    //   accessor: "action",
    //   Cell: (cell) => {
    //     const cellId = cell?.row?.original?.trans_id;
    //     return (
    //       <>
    //         {allowedActions.filter((data) => data.permission_id == 42)?.length >
    //         0 ? (
    //           <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
    //             <button
    //               onClick={() => {
    //                 onDeleteOpen(cellId);
    //               }}
    //               size="default"
    //               variant="outlined"
    //             >
    //               Delete
    //             </button>
    //           </span>
    //         ) : null}
    //       </>
    //     );
    //   },
    // },
  ];
export const subscription_columns = ({
  onDeleteOpen,
  onStatusUpdate,
  handleUpdateLSubs,
}) => [
    {
      Header: "Sr No.",
      accessor: (d, i) => i + 1,
      Cell: ({ cell }) => (
        <div className="p-2 text-sm text-gray-500">{cell.value}</div>
      ), // Adding a class to the cell
    },
    {
      Header: "Total Issues",
      accessor: "subs_tot_issues_mm",
      accessor: (d) => (d?.subs_tot_issues_mm == 0 ? 1 : d?.subs_tot_issues_mm),
    },
    {
      Header: "Sub ID",
      accessor: "subs_id",
    },
    // {
    //   Header: "SKU`S Name",
    //   accessor: (d) => capitalise(d?.subs_skus_relation?.sku_code),
    // },
    {
      Header: "Magazine Name",
      accessor: (d) => capitalise(d?.subs_master_magazines_relation?.mm_name),
    },
    {
      Header: "Start Date",
      accessor: (d) =>
        d?.subs_start_date ? moment(d?.subs_start_date).format("DD-MM-YYYY") : "",
    },
    {
      Header: "End Date",
      accessor: (d) =>
        d?.subs_end_date ? moment(d?.subs_end_date).format("DD-MM-YYYY") : "",
    },
    {
      Header: "Order Id",
      accessor: "subs_fk_ord_id",
    },
    {
      Header: "Duration",
      accessor: (d) =>
        capitalise(
          d?.subs_skus_relation?.skus_magazine_map.length > 0
            ? d?.subs_skus_relation?.skus_magazine_map[0]?.skumagm_subscription
              ?.value
            : ""
        ),
    },
    {
      Header: "Customer Name",
      accessor: (d) =>
        capitalise(
          d?.subs_m_order_relation?.ord_customer_name
            ? d?.subs_m_order_relation?.ord_customer_name
            : d?.subs_customer_relation?.customer_username
        ),
    },
    {
      Header: "Customer Email",
      accessor: (d) => capitalise(d?.subs_customer_relation?.customer_email),
    },
    {
      Header: "Customer Phone",
      accessor: (d) => capitalise(d?.subs_customer_relation?.customer_mobile),
    },
    {
      Header: "Status",
      accessor: (d) => (d.subs_is_active === true ? "Active" : "Inactive"),
    },
  ];
export const subscription_dispatch_columns = ({
  onDeleteOpen,
  onStatusUpdate,
  handleSaveTrackNoDispatch,
  setShowPrintAddress,
  prinData,
}) => [
    {
      Header: "Sr No.",
      accessor: (d, i) => i + 1,
      Cell: ({ cell }) => (
        <div className="p-2 text-sm text-gray-500">{cell.value}</div>
      ), // Adding a class to the cell
    },
    {
      Header: "Sub ID",
      accessor: (d) => d?.subscription_relation?.subs_id,
    },
    // {
    //   Header: "SKU`S Name",
    //   accessor: (d) => capitalise(d?.subinm_skus_relation?.sku_code),
    // },
    {
      Header: "Magazine Name",
      accessor: (d) => capitalise(d?.subinm_master_magazines_relation?.mm_name),
    },
    {
      Header: "No Copies",
      accessor: "no_copies",
    },
    {
      Header: "Issues Date",
      accessor: (d) =>
        d?.selectedDate
          ? moment(d?.selectedDate).format("DD-MM-YYYY")
          : d?.selectedDate,
    },
    // {
    //   Header: "Start Date",
    //   accessor: (d) => d?.subscription_relation?.subs_start_date ? moment(d?.subscription_relation?.subs_start_date).format("DD-MM-YYYY") : '',
    // },
    // {
    //   Header: "End Date",
    //   accessor: (d) => d?.subscription_relation?.subs_end_date ? moment(d?.subscription_relation?.subs_end_date).format("DD-MM-YYYY") : '',
    // },
    {
      Header: "Order Id",
      accessor: (d) => d?.subscription_relation?.subs_fk_ord_id,
    },
    // {
    //   Header: "Duration",
    //   accessor: (d) => (d?.duration),
    // },
    {
      Header: "Customer Name",
      accessor: (d) =>
        capitalise(
          d?.subinm_m_order_relation?.ord_customer_name
            ? d?.subinm_m_order_relation?.ord_customer_name
            : d?.subinm_customer_relation?.customer_username
        ),
    },
    {
      Header: "Customer Email",
      accessor: (d) => capitalise(d?.subinm_customer_relation?.customer_email),
    },
    {
      Header: "Customer Phone",
      accessor: (d) => capitalise(d?.subinm_customer_relation?.customer_mobile),
    },
    {
      Header: "Address",
      accessor: (d) =>
        capitalise(d?.subinm_m_order_relation?.ord_customer_customer_address),
    },
    {
      Header: "Tracking Code",
      // accessor: (d) => capitalise(d?.track_no),
      accessor: "track_no",
      Cell: (cell) => {
        let result_value = cell.row.original.track_no;
        let subinm_id = cell.row.original.subinm_id;
        let subinm_fk_subs_id = cell.row.original.subinm_fk_subs_id;
        let subinm_fk_sku_id = cell.row.original.subinm_fk_sku_id;
        let subinm_fk_ord_id = cell.row.original.subinm_fk_ord_id;
        let originalCell = cell.row.original;
        const [editing, setEditing] = useState(false);
        const [newTrackNoValue, setNewTrackNoValue] = useState(result_value);
        const toggleEditing = () => {
          setEditing(!editing);
        };
        const handleInputChange = (event) => {
          setNewTrackNoValue(event.target.value);
        };
        const handleSaveTrackNo = () => {
          handleSaveTrackNoDispatch(
            originalCell,
            newTrackNoValue,
            subinm_id,
            "TrackNo"
          ); //Uncomment After Call This Function
          setNewTrackNoValue(newTrackNoValue); // Replace savedValue with the actual saved value
          cell.row.original.track_no = newTrackNoValue;
          setEditing(false);
        };
        return (
          <p className="text-sm text-gray-500">
            {editing ? (
              <div>
                <input
                  type="text"
                  value={newTrackNoValue}
                  onChange={handleInputChange}
                  className="w-50 rounded-md"
                />
                <span className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-red-600 bg-red-100">
                  <button
                    size="default"
                    variant="outlined"
                    onClick={() => {
                      handleSaveTrackNo();
                    }}
                  >
                    Save
                  </button>
                </span>
              </div>
            ) : (
              <div>
                <button
                  className="text-center rounded-md break-words w-50 px-1 py-2 text-sm font-medium text-red-600 bg-red-200 hover:bg-red-300 focus:outline-none focus:bg-red-300 transition duration-150 ease-in-out"
                  size="default"
                  variant="outlined"
                  onClick={toggleEditing}
                >
                  {newTrackNoValue ? newTrackNoValue : "Empty"}
                </button>
              </div>
            )}
          </p>
        );
      },
    },
    {
      Header: "Dispatch Date",
      // accessor: (d) => d?.shipped_date ? moment(d?.shipped_date).format("DD-MM-YYYY") : '',
      Cell: (cell) => {
        let result_value = cell.row.original.shipped_date
          ? moment(cell.row.original?.shipped_date).format("DD-MM-YYYY")
          : "";
        let set_value = cell.row.original.shipped_date
          ? moment(cell.row.original?.shipped_date).format("YYYY-MM-DD")
          : "";
        let subinm_id = cell.row.original.subinm_id;
        let subinm_fk_subs_id = cell.row.original.subinm_fk_subs_id;
        let subinm_fk_sku_id = cell.row.original.subinm_fk_sku_id;
        let subinm_fk_ord_id = cell.row.original.subinm_fk_ord_id;
        let originalCell = cell.row.original;
        const [editing, setEditing] = useState(false);
        const [newShippedDateValue, setNewShippedDateValue] = useState(set_value);
        const toggleEditing = () => {
          setEditing(!editing);
        };
        const handleInputChange = (event) => {
          setNewShippedDateValue(event.target.value);
        };
        const handleSaveShippedDate = () => {
          handleSaveTrackNoDispatch(
            originalCell,
            newShippedDateValue,
            subinm_id,
            "ShippedDate"
          ); //Uncomment After Call This Function
          setNewShippedDateValue(newShippedDateValue); // Replace savedValue with the actual saved value
          cell.row.original.shipped_date = newShippedDateValue;
          setEditing(false);
        };
        return (
          <p className="text-sm text-gray-500">
            {editing ? (
              <div>
                <input
                  type="date"
                  value={newShippedDateValue}
                  onChange={handleInputChange}
                  className="w-50 rounded-md"
                />
                <span className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-red-600 bg-red-100">
                  <button
                    size="default"
                    variant="outlined"
                    onClick={() => {
                      handleSaveShippedDate();
                    }}
                  >
                    Save
                  </button>
                </span>
              </div>
            ) : (
              <div>
                <button
                  className="text-center rounded-md break-words w-50 px-1 py-2 text-sm font-medium text-red-600 bg-red-200 hover:bg-red-300 focus:outline-none focus:bg-red-300 transition duration-150 ease-in-out"
                  size="default"
                  variant="outlined"
                  onClick={toggleEditing}
                >
                  {newShippedDateValue ? newShippedDateValue : "Empty"}
                </button>
              </div>
            )}
          </p>
        );
      },
    },
    {
      Header: "Dispatch Status",
      accessor: (d) =>
        d?.subinm_dispatch_is_status == true ? "Dispatched" : "Not Dispatched",
    },
    {
      Header: "Action",
      accessor: (d) => {
        return (
          <span className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144]  px-1 py-1 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#BE3144] focus:ring-offset-2 ml-auto">
            <button
              type="button"
              onClick={() => {
                prinData.printAddressDataHandler(d);
                setShowPrintAddress(true);
              }}
              size="default"
              variant="outlined"
            >
              Print Address
            </button>
          </span>
        );
      },
    },
  ];
export const subscription_columnsBak = ({
  onDeleteOpen,
  onStatusUpdate,
  handleUpdateLSubs,
}) => [
    {
      Header: "Sr No.",
      accessor: (d, i) => i + 1,
      Cell: ({ cell }) => (
        <div className="p-2 text-sm text-gray-500">{cell.value}</div>
      ), // Adding a class to the cell
    },
    {
      Header: "Sub ID",
      accessor: "subs_id",
    },
    {
      Header: "Order Id",
      accessor: "subs_fk_ord_id",
    },
    {
      Header: "Sys Order No",
      accessor: (d) => capitalise(d?.subs_m_order_relation?.ord_sys_order_id),
    },
    {
      Header: "Sku Name",
      accessor: (d) => capitalise(d?.subs_skus_relation?.sku_code),
    },
    {
      Header: "Magazine Name",
      accessor: (d) => capitalise(d?.subs_master_magazines_relation?.mm_name),
    },
    // {
    //   Header: "Interval",
    //   accessor: (d) => capitalise(d?.magazineIntervalsString),
    // },
    {
      Header: "Duration",
      accessor: (d) =>
        capitalise(
          d?.subs_skus_relation?.skus_magazine_map.length > 0
            ? d?.subs_skus_relation?.skus_magazine_map[0]?.skumagm_subscription
              ?.value
            : ""
        ),
      // Cell: (cell) => {
      //   const subs_fk_mm_id = cell.row.original.subs_fk_mm_id;
      //   const subs_fk_sku_id = cell.row.original.subs_fk_sku_id;
      //   const subs_fk_ord_id = cell.row.original.subs_fk_ord_id;
      //   const subs_fk_ord_skum_id = cell.row.original.subs_fk_ord_skum_id;
      //   const subs_fk_customer_id = cell.row.original.subs_fk_customer_id;
      //   let originalCell = cell.row.original;
      //   let duration = '';
      //   if (originalCell?.subs_skus_relation?.skus_magazine_map?.length > 0) {
      //     for (const MDI of originalCell?.subs_skus_relation?.skus_magazine_map) {
      //       if (subs_fk_mm_id == MDI?.skumagm_fk_master_magazine_id && subs_fk_sku_id == MDI?.skumagm_fk_sku_id) {
      //         duration = MDI?.skumagm_subscription?.value;
      //       }
      //     }
      //   }
      //   return (
      //     <span>{duration}</span>
      //   );
      // }
    },
    {
      Header: "Customer Name",
      accessor: (d) => capitalise(d?.subs_customer_relation?.customer_username),
    },
    {
      Header: "Customer Email",
      accessor: (d) => capitalise(d?.subs_customer_relation?.customer_email),
    },
    {
      Header: "Customer Phone",
      accessor: (d) => capitalise(d?.subs_customer_relation?.customer_number),
    },
    // {
    //   Header: "Subs",
    //   accessor: (d) => (d.subs_is_active === true ? "Active" : "Inactive"),
    //   Cell: (cell) => {
    //     const cellId = cell.row.original.subs_id;
    //     const enabled = cell.row.original.subs_is_active;
    //     let originalCell = cell.row.original;
    //     const months = [];
    //     const currentYear = new Date().getFullYear();
    //     for (let i = 1; i <= 12; i++) {
    //       const monthValue = i < 10 ? `0${i}` : `${i}`;
    //       const monthLabel = new Date(currentYear, i - 1, 1).toLocaleString('en-US', { month: 'short' });
    //       months.push({ label: monthLabel, value: monthValue });
    //     }
    //     const [selectedMonth, setSelectedMonth] = useState([])
    //     const handleMonth = (e) => {
    //       setSelectedMonth(e)
    //     }
    //     return (
    //       <div className="w-full flex">
    //         {months && months?.length > 0 ? (
    //           <Select
    //             className="flex-1 w-full max-w-lg rounded-md border-[1px] shadow-sm focus:border-[1px] sm:max-w-xs sm:text-sm "
    //             placeholder="Select Month"
    //             name="month_name"
    //             id="month_name"
    //             onChange={(e) => handleMonth(e)}
    //             options={months}
    //             value={selectedMonth}
    //           />
    //         ) : (null)}
    //         {originalCell?.DateArrDrop && originalCell?.DateArrDrop?.length > 0 ? (
    //           <select
    //             className="flex-1 ml-1 block max-w-lg border-gray-300 rounded-md shadow-sm focus:border-red-500 focus:ring-red-500 sm:max-w-xs sm:text-sm"
    //             name="midm_id"
    //             id="midm_id"
    //             onChange={(e) => { handleUpdateLSubs(originalCell, e.target.value, selectedMonth) }}
    //             disabled={selectedMonth.length <= 0}
    //           >
    //             <option value={0}> Select Interval </option>
    //             {originalCell?.DateArrDrop.map((interval, i) => (
    //               <option
    //                 key={i}
    //                 value={JSON.stringify(interval)}
    //               >
    //                 {interval.label}
    //               </option>
    //             ))}
    //           </select>
    //         ) : (null)}
    //       </div>);
    //   },
    // },
    {
      Header: "Status",
      accessor: (d) => (d.subs_is_active === true ? "Active" : "Inactive"),
      Cell: (cell) => {
        const cellId = cell.row.original.subs_id;
        const enabled = cell.row.original.subs_is_active;
        // const [enabled, setEnabled] = useState()
        return (
          <Switch
            checked={enabled}
            onChange={() => {
              onStatusUpdate(cellId, cell.row.original.subs_is_active);
            }}
            className={productStatusToggle(
              enabled ? "bg-[#872341]" : "bg-gray-200",
              "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
            )}
          >
            <span className="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              className={productStatusToggle(
                enabled ? "translate-x-5" : "translate-x-0",
                "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
              )}
            />
          </Switch>
        );
      },
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell?.row?.original?.subs_id;
        return (
          <>
            <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
              <button
                onClick={() => {
                  onDeleteOpen(cellId);
                }}
                size="default"
                variant="outlined"
              >
                Delete
              </button>
            </span>
          </>
        );
      },
    },
  ];
export const voucher_columns = ({
  onDeleteOpen,
  onStatusUpdate,
  allowedActions,
}) => [
    {
      Header: "Sr No.",
      accessor: (d, i) => i + 1,
      Cell: ({ cell }) => (
        <div className="p-2 text-sm text-gray-500">{cell.value}</div>
      ), // Adding a class to the cell
    },
    {
      Header: "ID",
      accessor: "voucher_id",
      Cell: (cell) => {
        const voucher_id = cell?.row?.original?.voucher_id;
        const voucher_code_type = cell?.row?.original?.voucher_code_type;
        // console.log("cell?.row?.original", cell?.row?.original)
        return (
          <>
            {voucher_code_type === "CAMPAIGN" ? (
              <Link
                // onClick={() => handleDrawer("edit", cellId, cell?.row?.original)}
                to={`../campign-voucher/${voucher_id}`}
                className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-red-600 bg-red-100"
              >
                <button
                  className="text-red-600 "
                  size="default"
                  variant="outlined"
                >
                  {voucher_id}
                </button>
              </Link>
            ) : (
              <span className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-grey-600 bg-grey-100">
                {voucher_id}
              </span>
            )}
          </>
        );
      },
    },
    {
      Header: "Name",
      accessor: "voucher_name",
    },
    {
      Header: "Code",
      accessor: "voucher_code",
    },
    {
      Header: "Description",
      accessor: "voucher_description",
    },
    {
      Header: "Type",
      accessor: "voucher_type",
    },
    {
      Header: "Voucher Value",
      accessor: "voucher_value",
      Cell: (cell) => {
        const voucher_value = cell?.row?.original?.voucher_value;
        const voucher_type = cell?.row?.original?.voucher_type;
        console.log("voucher_type", voucher_type);
        return (
          <>
            {voucher_value ? (
              <div className="flex items-center gap-1 text-sm text-gray-500">
                {voucher_type === "FIXED" ? (
                  <BsCurrencyRupee
                    className="h-5 w-4 text-gray-400"
                    aria-hidden="true"
                  />
                ) : (
                  "%"
                )}
                <span> {voucher_value}</span>
              </div>
            ) : null}
          </>
        );
      },
    },
    {
      Header: "Min Transaction",
      accessor: "minimum_transaction",
    },
    {
      Header: "Max Discount",
      accessor: "max_discount",
    },
    {
      Header: "Per User Limit",
      accessor: "voucher_per_user_limit",
    },
    {
      Header: "Max redeems",
      accessor: "voucher_max_redeems",
    },
    {
      Header: "Voucher Redeemed",
      accessor: (d) => (d.voucher_is_redeemed === true ? "Yes" : "No"),
      Cell: (cell) => {
        const voucher_code_type = cell?.row?.original?.voucher_code_type;
        const voucher_is_redeemed_count =
          cell?.row?.original?.voucher_is_redeemed_count;
        const voucher_is_redeemed = cell?.row?.original?.voucher_is_redeemed;
        const voucher_code_count = cell?.row?.original?.voucher_code_count;
        const cellId = cell?.row?.original?.voucher_id;
        const enabled = cell.row.original.voucher_is_active;
        return (
          <>
            {voucher_code_type == "CAMPAIGN"
              ? voucher_is_redeemed_count + "/" + voucher_code_count
              : voucher_is_redeemed === true
                ? "Yes"
                : "No"}
          </>
        );
      },
    },
    {
      Header: "Sku Applicable on",
      accessor: "voucher_applicable_to",
    },
    {
      Header: "Start Date",
      accessor: (d) => moment(d?.voucher_start_date).format("DD-MM-YYYY"),
      // accessor: "voucher_start_date",
    },
    {
      Header: "End Date",
      accessor: (d) => moment(d?.voucher_end_date).format("DD-MM-YYYY"),
      // accessor: "voucher_end_date",
    },
    {
      Header: "Status",
      accessor: (d) => (d.ord_is_active === true ? "Active" : "Inactive"),
      Cell: (cell) => {
        const cellId = cell?.row?.original?.voucher_id;
        const enabled = cell.row.original.voucher_is_active;
        return (
          <>
            {allowedActions.filter((data) => data.permission_id == 46)?.length >
              0 ? (
              <Switch
                checked={enabled}
                onChange={() => {
                  onStatusUpdate(cellId, cell.row.original.voucher_is_active);
                }}
                className={productStatusToggle(
                  enabled ? "bg-[#872341]" : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                )}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={productStatusToggle(
                    enabled ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            ) : null}
          </>
        );
      },
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell?.row?.original?.voucher_id;
        const enabled = cell.row.original.voucher_is_active;
        return (
          <>
            {allowedActions.filter((data) => data.permission_id == 45)?.length >
              0 ? (
              <Link
                // onClick={() => handleDrawer("edit", cellId, cell?.row?.original)}
                to={`../add-voucher/${cellId}`}
                className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-red-600 bg-red-100"
              >
                <button
                  className="text-red-600 "
                  size="default"
                  variant="outlined"
                >
                  Edit
                </button>
              </Link>
            ) : null}
            {allowedActions.filter((data) => data.permission_id == 47)?.length >
              0 ? (
              <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
                <button
                  onClick={() => {
                    onDeleteOpen(cellId);
                  }}
                  size="default"
                  variant="outlined"
                >
                  Delete
                </button>
              </span>
            ) : null}
          </>
        );
      },
    },
  ];
export const voucher_compagine_columns = ({ onDeleteOpen, onStatusUpdate }) => [
  {
    Header: "Sr No.",
    accessor: (d, i) => i + 1,
    Cell: ({ cell }) => (
      <div className="p-2 text-sm text-gray-500">{cell.value}</div>
    ), // Adding a class to the cell
  },
  {
    Header: "ID",
    accessor: "voucher_id",
  },
  {
    Header: "Name",
    accessor: "voucher_name",
  },
  {
    Header: "Code",
    accessor: "voucher_code",
  },
  {
    Header: "Description",
    accessor: "voucher_description",
  },
  // {
  //   Header: "Percentage",
  //   accessor: "voucher_percent",
  // },
  {
    Header: "Voucher Value",
    accessor: "voucher_value",
  },
  {
    Header: "Minimum Transaction",
    accessor: "minimum_transaction",
  },
  {
    Header: "Max Discount",
    accessor: "max_discount",
  },
  {
    Header: "Per User Limit",
    accessor: "voucher_per_user_limit",
  },
  {
    Header: "Max redeems",
    accessor: "voucher_max_redeems",
  },
  {
    Header: "Voucher Redeemed",
    accessor: (d) => (d.voucher_is_redeemed === true ? "Yes" : "No"),
  },
  {
    Header: "Sku Applicable on",
    accessor: "voucher_applicable_to",
  },
  {
    Header: "Start Date",
    accessor: (d) => moment(d?.voucher_start_date).format("DD-MM-YYYY"),
    // accessor: "voucher_start_date",
  },
  {
    Header: "End Date",
    accessor: (d) => moment(d?.voucher_end_date).format("DD-MM-YYYY"),
    // accessor: "voucher_end_date",
  },
  {
    Header: "Status",
    accessor: (d) => (d.ord_is_active === true ? "Active" : "Inactive"),
    Cell: (cell) => {
      const cellId = cell?.row?.original?.voucher_id;
      const enabled = cell.row.original.voucher_is_active;
      return (
        <Switch
          checked={enabled}
          onChange={() => {
            onStatusUpdate(cellId, cell.row.original.voucher_is_active);
          }}
          className={productStatusToggle(
            enabled ? "bg-[#872341]" : "bg-gray-200",
            "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
          )}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={productStatusToggle(
              enabled ? "translate-x-5" : "translate-x-0",
              "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
            )}
          />
        </Switch>
      );
    },
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const cellId = cell?.row?.original?.voucher_id;
      const enabled = cell.row.original.voucher_is_active;
      return (
        <>
          {/* <Link
            to={`/add-voucher/${cellId}`}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-red-600 bg-red-100"
          >
            <button
              className="text-red-600 "
              size="default"
              variant="outlined"
            >
              Edit
            </button>
          </Link> */}
          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cellId);
              }}
              size="default"
              variant="outlined"
            >
              Delete
            </button>
          </span>
        </>
      );
    },
  },
];
export const customer_columns = ({
  onDeleteOpen,
  onStatusUpdate,
  allowedActions,
}) => [
    {
      Header: "Sr No.",
      accessor: (d, i) => i + 1,
      Cell: ({ cell }) => (
        <div className="p-2 text-sm text-gray-500">{cell.value}</div>
      ), // Adding a class to the cell
    },
    {
      Header: "Customer Id",
      accessor: "customer_id",
      Cell: (cell) => {
        const customer_id = cell?.row?.original?.customer_id;
        console.log("customer_id", customer_id);
        return (
          <>
            <Link
              // onClick={() => handleDrawer("edit", cellId, cell?.row?.original)}
              to={`../customer-address/${customer_id}`}
              className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-red-600 bg-red-100"
            >
              <button className="text-red-600 " size="default" variant="outlined">
                {customer_id}
              </button>
            </Link>
          </>
        );
      },
    },
    {
      Header: "Customer User Name",
      accessor: (d) => capitalise(d?.customer_username),
    },
    {
      Header: "Customer Email",
      accessor: (d) => capitalise(d?.customer_email),
    },
    {
      Header: "Customer Mobile",
      accessor: (d) => capitalise(d?.customer_mobile),
    },
    {
      Header: "Customer Gender",
      accessor: (d) => capitalise(d?.customer_gender),
    },
    {
      Header: "Customer Designation",
      accessor: (d) => capitalise(d?.customer_designation),
    },
    {
      Header: "Customer Organisation",
      accessor: (d) => capitalise(d?.customer_organisation),
    },
    {
      Header: "Customer Is Outlook User",
      accessor: "customer_is_outlook_user",
      // accessor: (d) => capitalise(d?.customer_is_outlook_user), //capitalise(d.order_date),
    },
    // {
    //   Header: "Customer Address",
    //   accessor: (d) => capitalise(d?.customer_address), //capitalise(d.order_date),
    // },
    {
      Header: "Status",
      accessor: (d) => (d.ord_is_active === true ? "Active" : "Inactive"),
      Cell: (cell) => {
        const cellId = cell.row.original.customer_id;
        const enabled = cell.row.original.customer_is_active;
        // const [enabled, setEnabled] = useState()
        return (
          <>
            {allowedActions.filter((data) => data.permission_id == 57)?.length >
              0 ? (
              <Switch
                checked={enabled}
                onChange={() => {
                  onStatusUpdate(cellId, cell.row.original.customer_is_active);
                }}
                className={productStatusToggle(
                  enabled ? "bg-[#872341]" : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                )}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={productStatusToggle(
                    enabled ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            ) : null}
          </>
        );
      },
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell?.row?.original?.customer_id;
        const customer_is_active = cell?.row?.original?.customer_is_active;
        return (
          <>
            {allowedActions.filter((data) => data.permission_id == 55)?.length >
              0 ? (
              <Link
                to={`../add-customer/${cellId}`}
                className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-red-600 bg-red-100"
              >
                <button
                  className="text-red-600 "
                  size="default"
                  variant="outlined"
                >
                  Edit
                </button>
              </Link>
            ) : null}
            {allowedActions.filter((data) => data.permission_id == 56)?.length >
              0 ? (
              <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
                <button
                  onClick={() => {
                    onDeleteOpen(cellId, customer_is_active);
                  }}
                  size="default"
                  variant="outlined"
                >
                  Delete
                  {/* <span className="sr-only">, </span> */}
                </button>
              </span>
            ) : null}
          </>
        );
      },
    },
  ];
export const customer_address_columns = ({
  onDeleteOpen,
  onStatusUpdate,
  allowedActions,
}) => [
    {
      Header: "Sr No.",
      accessor: (d, i) => i + 1,
      Cell: ({ cell }) => (
        <div className="p-2 text-sm text-gray-500">{cell.value}</div>
      ), // Adding a class to the cell
    },
    {
      Header: "Customer Name",
      accessor: (d) => capitalise(d?.customer_relation?.customer_username),
    },
    {
      Header: "Customer Address",
      accessor: (d) => capitalise(d?.ca_address),
    },
    {
      Header: "City",
      accessor: (d) => capitalise(d?.city_name),
    },
    {
      Header: "State",
      accessor: (d) => capitalise(d?.state_name),
    },
    {
      Header: "Landmark",
      accessor: (d) => capitalise(d?.ca_landmark),
    },
    {
      Header: "Pincode",
      accessor: (d) => capitalise(d?.pincode),
    },
    {
      Header: "Status",
      accessor: (d) => (d.ca_is_active === true ? "Active" : "Inactive"),
      Cell: (cell) => {
        const cellId = cell.row.original.ca_id;
        const enabled = cell.row.original.ca_is_active;
        // const [enabled, setEnabled] = useState()
        return (
          <>
            {allowedActions.filter((data) => data.permission_id == 61)?.length >
              0 ? (
              <Switch
                checked={enabled}
                onChange={() => {
                  onStatusUpdate(cellId, cell.row.original.ca_is_active);
                }}
                className={productStatusToggle(
                  enabled ? "bg-[#872341]" : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                )}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={productStatusToggle(
                    enabled ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            ) : null}
          </>
        );
      },
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell?.row?.original?.ca_id;
        const customer_id = cell?.row?.original?.fk_customer_id;
        const ca_is_active = cell?.row?.original?.ca_is_active;
        return (
          <>
            {allowedActions.filter((data) => data.permission_id == 60)?.length >
              0 ? (
              <Link
                to={`/add-customer-address/${customer_id}/${cellId}`}
                className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-red-600 bg-red-100"
              >
                <button
                  className="text-red-600 "
                  size="default"
                  variant="outlined"
                >
                  Edit
                </button>
              </Link>
            ) : null}
            {allowedActions.filter((data) => data.permission_id == 62)?.length >
              0 ? (
              <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
                <button
                  onClick={() => {
                    onDeleteOpen(cellId, ca_is_active);
                  }}
                  size="default"
                  variant="outlined"
                >
                  Delete
                </button>
              </span>
            ) : null}
          </>
        );
      },
    },
  ];
export const vendor_columns = ({
  onDeleteOpen,
  handleDrawer,
  onStatusUpdate,
  allowedActions,
}) => [
    {
      Header: "Sr No.",
      accessor: (d, i) => i + 1,
      Cell: ({ cell }) => (
        <div className="p-2 text-sm text-gray-500">{cell.value}</div>
      ), // Adding a class to the cell
    },
    {
      Header: "Vendor ID",
      accessor: "vendor_id",
    },
    {
      Header: "Vendor Name",
      accessor: "vendor_name",
    },
    {
      Header: "Vendor Email",
      accessor: "vendor_email",
    },
    {
      Header: "Vendor Mobile",
      accessor: "vendor_mobile",
    },
    // {
    //   Header: "Vendor Gender",
    //   accessor: "vendor_gender",
    // },
    {
      Header: "Country",
      accessor: (d) => capitalise(d?.m_country_relation?.country_name),
    },
    {
      Header: "State",
      accessor: (d) => capitalise(d?.m_state_relation?.state_name),
    },
    {
      Header: "City",
      accessor: (d) => capitalise(d?.m_city_relation?.city_name),
    },
    {
      Header: "Address",
      accessor: (d) => capitalise(d?.vendor_address),
    },
    {
      Header: "Pincode",
      accessor: (d) => capitalise(d?.vendor_pincode),
    },
    {
      Header: "Status",
      accessor: (d) => (d.vendor_is_active === true ? "Active" : "Inactive"),
      Cell: (cell) => {
        const cellId = cell.row.original.vendor_id;
        const enabled = cell.row.original.vendor_is_active;
        return (
          <>
            {allowedActions.filter((data) => data.permission_id == 67)?.length >
              0 ? (
              <Switch
                checked={enabled}
                onChange={() => {
                  onStatusUpdate(cellId, cell.row.original.vendor_is_active);
                }}
                className={productStatusToggle(
                  enabled ? "bg-[#872341]" : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                )}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={productStatusToggle(
                    enabled ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            ) : null}
          </>
        );
      },
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell.row.original.vendor_id;
        const enabled = cell.row.original.vendor_is_active;
        return (
          <>
            {allowedActions.filter((data) => data.permission_id == 66)?.length >
              0 ? (
              <Link
                onClick={() => handleDrawer("edit", cellId, cell.row.original)}
                className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-red-600 bg-red-100"
              >
                <button
                  className="text-green-900 "
                  size="default"
                  variant="outlined"
                >
                  Edit
                </button>
              </Link>
            ) : null}
            {allowedActions.filter((data) => data.permission_id == 68)?.length >
              0 ? (
              <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
                <button
                  onClick={() => {
                    onDeleteOpen(cellId);
                  }}
                  size="default"
                  variant="outlined"
                >
                  Delete
                </button>
              </span>
            ) : null}
          </>
        );
      },
    },
  ];
export const vendor_tran_columns = ({
  onDeleteOpen,
  handleDrawer,
  onStatusUpdate,
  allowedActions,
}) => [
    {
      Header: "Sr No.",
      accessor: (d, i) => i + 1,
      Cell: ({ cell }) => (
        <div className="p-2 text-sm text-gray-500">{cell.value}</div>
      ), // Adding a class to the cell
    },
    {
      Header: "Vendor ID",
      accessor: "vendor_id",
    },
    {
      Header: "Vendor Name",
      accessor: "vendor_name",
    },
    // {
    //   Header: "Vendor Email",
    //   accessor: "vendor_email",
    // },
    {
      Header: "Vendor Mobile",
      accessor: "vendor_mobile",
    },
    {
      Header: "Magazine",
      accessor: "magazine",
    },
    {
      Header: "Magazine Issues",
      accessor: "magazineIssues",
    },
    // {
    //   Header: "Vendor Mobile",
    //   accessor: "vendor_mobile",
    // },
    {
      Header: "Sub Tot AMT.",
      accessor: "ven_iss_trax_sub_tot",
    },
    {
      Header: "Discount",
      accessor: "ven_iss_trax_tot_disc",
    },
    {
      Header: "TOT AMT.",
      accessor: "ven_iss_trax_grand_tot",
    },
    // {
    //   Header: "Country",
    //   accessor: (d) => capitalise(d?.m_country_relation?.country_name),
    // },
    // {
    //   Header: "State",
    //   accessor: (d) => capitalise(d?.m_state_relation?.state_name),
    // },
    // {
    //   Header: "City",
    //   accessor: (d) => capitalise(d?.m_city_relation?.city_name),
    // },
    // {
    //   Header: "Address",
    //   accessor: (d) => capitalise(d?.vendor_address),
    // },
    // {
    //   Header: "Pincode",
    //   accessor: (d) => capitalise(d?.vendor_pincode),
    // },
    {
      Header: "Status",
      accessor: (d) =>
        d.ven_iss_trax_is_active === true ? "Active" : "Inactive",
      Cell: (cell) => {
        const cellId = cell.row.original.ven_iss_trax_id;
        const enabled = cell.row.original.ven_iss_trax_is_active;
        const ven_iss_trax_sub_tot = cell.row.original.ven_iss_trax_sub_tot;
        console.log("ven_iss_trax_sub_tot=>", cell.row.original);
        return (
          <>
            {allowedActions.filter((data) => data.permission_id == 72)?.length >
              0 ? (
              <Switch
                checked={enabled}
                onChange={() => {
                  onStatusUpdate(
                    cellId,
                    cell.row.original.ven_iss_trax_is_active
                  );
                }}
                className={productStatusToggle(
                  enabled ? "bg-[#872341]" : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                )}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={productStatusToggle(
                    enabled ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            ) : null}
          </>
        );
      },
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell.row.original.ven_iss_trax_id;
        const enabled = cell.row.original.ven_iss_trax_is_active;
        return (
          <>
            {allowedActions.filter((data) => data.permission_id == 71)?.length >
              0 ? (
              <Link
                to={`/add-vendor-transaction/${cellId}`}
                className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-red-600 bg-red-100"
              >
                <button
                  className="text-green-900 "
                  size="default"
                  variant="outlined"
                >
                  Edit
                </button>
              </Link>
            ) : null}
            {allowedActions.filter((data) => data.permission_id == 73)?.length >
              0 ? (
              <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
                <button
                  onClick={() => {
                    onDeleteOpen(cellId);
                  }}
                  size="default"
                  variant="outlined"
                >
                  Delete
                </button>
              </span>
            ) : null}
          </>
        );
      },
    },
  ];
export const SubscriptionReport_columns = ({ allowedActions }) => [
  {
    Header: "Sr No.",
    accessor: (d, i) => i + 1,
    Cell: ({ cell }) => (
      <div className="p-2 text-sm text-gray-500">{cell.value}</div>
    ), // Adding a class to the cell
  },
  {
    Header: "ID",
    accessor: "subs_id",
  },
  {
    Header: "TRANS_DATE",
    accessor: (d) =>
      d?.getOrder?.m_transaction?.length > 0
        ? moment(d?.getOrder?.m_transaction[0]?.trans_created_at).format(
          "DD-MM-YYYY"
        )
        : "",
    // accessor: (d) => capitalise(d?.getOrder?.m_transaction),
  },
  {
    Header: "PAYMENT/PURCHASE_REF.NO",
    accessor: (d) =>
      d?.getOrder?.m_transaction?.length > 0
        ? capitalise(d?.getOrder?.m_transaction[0]?.trans_upi_transaction_id)
        : "N/A",
  },
  {
    Header: "CURRENCY",
    accessor: (d) => capitalise(d?.Currency),
  },
  {
    Header: "PAID VALUE",
    accessor: (d) => capitalise(d?.getOrder?.ord_total_main_amt),
  },
  {
    Header: "DURATION_MONTH",
    accessor: (d) =>
      capitalise(d?.subs_master_magazines_relation?.mm_month_interval),
  },
  {
    Header: "TYPE",
    accessor: (d) => capitalise(d?.Type),
  },
  {
    Header: "MAGAZINE",
    accessor: (d) => capitalise(d?.subs_master_magazines_relation?.mm_name), //capitalise(d.order_date),
  },
  {
    Header: "NO_OF_SUB",
    accessor: (d) => d?.subscription_interval_map.length, //capitalise(d.order_date),
  },
  {
    Header: "START_DATE",
    accessor: (d) => capitalise(d?.customer_is_outlook_user), //capitalise(d.order_date),
  },
  {
    Header: "EXPIRY_DATE",
    accessor: (d) => capitalise(d?.customer_is_outlook_user), //capitalise(d.order_date),
  },
  {
    Header: "PAYMENT_SOURCE",
    accessor: (d) => capitalise(d?.customer_is_outlook_user), //capitalise(d.order_date),
  },
  {
    Header: "SUB_SOURCE",
    accessor: (d) => capitalise(d?.customer_is_outlook_user), //capitalise(d.order_date),
  },
  {
    Header: "EXECUTIVE/UTM_CODE",
    accessor: (d) =>
      capitalise(d?.getOrder?.ord_ref_m_user_relation?.user_referral_code), //capitalise(d.order_date),
  },
  {
    Header: "DIGITAL_VALUE",
    accessor: (d) => capitalise(d?.customer_is_outlook_user), //capitalise(d.order_date),
  },
  {
    Header: "PRINT_VALUE",
    accessor: (d) => capitalise(d?.customer_is_outlook_user), //capitalise(d.order_date),
  },
  {
    Header: "DISCOUNT %	",
    accessor: (d) => capitalise(d?.customer_is_outlook_user), //capitalise(d.order_date),
  },
  {
    Header: "DISC_VALUE",
    accessor: (d) => capitalise(d?.customer_is_outlook_user), //capitalise(d.order_date),
  },
  {
    Header: "TAXABLE_VALUE",
    accessor: (d) => capitalise(d?.customer_is_outlook_user), //capitalise(d.order_date),
  },
  {
    Header: "NON_TAXABLE_VALUE",
    accessor: (d) => capitalise(d?.customer_is_outlook_user), //capitalise(d.order_date),
  },
  {
    Header: "IGST",
    accessor: (d) => capitalise(d?.customer_is_outlook_user), //capitalise(d.order_date),
  },
  {
    Header: "CGST",
    accessor: (d) => capitalise(d?.customer_is_outlook_user), //capitalise(d.order_date),
  },
  {
    Header: "SGST",
    accessor: (d) => capitalise(d?.customer_is_outlook_user), //capitalise(d.order_date),
  },
  {
    Header: "INVOICE_VALUE",
    accessor: (d) => capitalise(d?.customer_is_outlook_user), //capitalise(d.order_date),
  },
  {
    Header: "BUNDAL_PRICE",
    accessor: (d) => capitalise(d?.customer_is_outlook_user), //capitalise(d.order_date),
  },
  {
    Header: "GSTIN_NO_OF_SUBSCRIBER",
    accessor: (d) => capitalise(d?.customer_is_outlook_user), //capitalise(d.order_date),
  },
  {
    Header: "INVOICE/RECEIPT_NO",
    accessor: (d) => capitalise(d?.customer_is_outlook_user), //capitalise(d.order_date),
  },
  {
    Header: "INVOICE_LINK",
    accessor: (d) => capitalise(d?.customer_is_outlook_user), //capitalise(d.order_date),
  },
  {
    Header: "OFFER_NAME",
    accessor: (d) => capitalise(d?.customer_is_outlook_user), //capitalise(d.order_date),
  },
  {
    Header: "GIFT_NAME",
    accessor: (d) => capitalise(d?.customer_is_outlook_user), //capitalise(d.order_date),
  },
  {
    Header: "GIFT_DISPATCH_DATE",
    accessor: (d) => capitalise(d?.customer_is_outlook_user), //capitalise(d.order_date),
  },
  {
    Header: "DISPACH_REF_NO",
    accessor: (d) => capitalise(d?.customer_is_outlook_user), //capitalise(d.order_date),
  },
  {
    Header: "MRO_(REF_NO)",
    accessor: (d) => capitalise(d?.customer_is_outlook_user), //capitalise(d.order_date),
  },
  {
    Header: "Customer Name",
    accessor: (d) => capitalise(d?.subs_m_order_relation?.ord_customer_name), //capitalise(d.order_date),
  },
  {
    Header: "EMAIL",
    accessor: (d) => capitalise(d?.subs_customer_relation?.customer_email), //capitalise(d.order_date),
  },
  {
    Header: "PHONE_NUMBER",
    accessor: (d) => capitalise(d?.subs_customer_relation?.customer_mobile), //capitalise(d.order_date),
  },
  {
    Header: "City",
    accessor: (d) =>
      capitalise(d?.getOrder?.ord_customer_adress_relation?.city_name), //capitalise(d.order_date),
  },
  {
    Header: "Gender",
    accessor: (d) => capitalise(d?.subs_customer_relation?.customer_gender), //capitalise(d.order_date),
  },
  {
    Header: "State",
    accessor: (d) =>
      capitalise(d?.getOrder?.ord_customer_adress_relation?.state_name), //capitalise(d.order_date),
  },
  {
    Header: "Occupation",
    accessor: (d) => capitalise(d?.subs_customer_relation?.customer_occupation), //capitalise(d.order_date),
  },
  {
    Header: "Designation",
    accessor: (d) =>
      capitalise(d?.subs_customer_relation?.customer_designation), //capitalise(d.order_date),
  },
  {
    Header: "Age",
    accessor: (d) =>
      d?.subs_customer_relation?.customer_dob
        ? calculateAge(
          moment(d?.subs_customer_relation?.customer_dob).format("YYYY-MM-DD")
        )
        : 0, //capitalise(d.order_date),
  },
];
export const IssuesReport_columns = ({ allowedActions }) => [
  {
    Header: "Sr No.",
    accessor: (d, i) => i + 1,
    Cell: ({ cell }) => (
      <div className="p-2 text-sm text-gray-500">{cell.value}</div>
    ), // Adding a class to the cell
  },
  {
    Header: "Customer Name",
    accessor: (d) => capitalise(d?.customer_relation?.customer_username),
  },
  {
    Header: "Customer Address",
    accessor: (d) => capitalise(d?.ca_address),
  },
  {
    Header: "City",
    accessor: (d) => capitalise(d?.city_name),
  },
  {
    Header: "State",
    accessor: (d) => capitalise(d?.state_name),
  },
  {
    Header: "Landmark",
    accessor: (d) => capitalise(d?.ca_landmark),
  },
  {
    Header: "Pincode",
    accessor: (d) => capitalise(d?.pincode),
  },
];
// export const commission_columns = ({
//   onDeleteOpen,
//   onStatusUpdate,
//   allowedActions,
// }) => [
//     {
//       Header: "Sr No.",
//       accessor: (d, i) => i + 1,
//       Cell: ({ cell }) => (
//         <div className="p-2 text-sm text-gray-500">{cell.value}</div>
//       ), // Adding a class to the cell
//     },
//     {
//       Header: "User Name",
//       accessor: (d) =>
//         capitalise(
//           d?.m_user_commission_relation?.user_first_name
//             ? d?.m_user_commission_relation?.user_first_name
//             : ""
//         ),
//     },
//     {
//       Header: "User Email",
//       accessor: (d) =>
//         capitalise(
//           d?.m_user_commission_relation?.user_email
//             ? d?.m_user_commission_relation?.user_email
//             : ""
//         ),
//     },
//     {
//       Header: "User Mobile",
//       accessor: (d) =>
//         capitalise(
//           d?.m_user_commission_relation?.user_mobile
//             ? d?.m_user_commission_relation?.user_mobile
//             : ""
//         ),
//     },
//     {
//       Header: "User Role",
//       accessor: (d) =>
//         capitalise(
//           d?.m_user_commission_relation?.m_role_relation?.role_name
//             ? d?.m_user_commission_relation?.m_role_relation?.role_name
//             : ""
//         ),
//     },
//     {
//       Header: "Order ID",
//       accessor: "fk_order_id",
//     },
//     {
//       Header: "Commision",
//       accessor: "comm_value",
//     },
//     {
//       Header: "Commision %",
//       accessor: "comm_percentage_value",
//     },
//     {
//       Header: "Order AMT",
//       accessor: "comm_ord_main_value",
//     },
//   ];

//     return (
//       <>
//         {allowedActions.filter((data) => data.permission_id == 41)?.length >
//         0 ? (
//           <Switch
//             checked={enabled}
//             onChange={() => {
//               onStatusUpdate(cellId, cell.row.original.trans_is_active);
//             }}
//             className={productStatusToggle(
//               enabled ? "bg-[#872341]" : "bg-gray-200",
//               "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
//             )}
//           >
//             <span className="sr-only">Use setting</span>
//             <span
//               aria-hidden="true"
//               className={productStatusToggle(
//                 enabled ? "translate-x-5" : "translate-x-0",
//                 "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
//               )}
//             />
//           </Switch>
//         ) : null}
//       </>
//     );
//   },
// },
// {
//   Header: "Action",
//   accessor: "action",
//   Cell: (cell) => {
//     const cellId = cell?.row?.original?.trans_id;
//     return (
//       <>
//         {allowedActions.filter((data) => data.permission_id == 42)?.length >
//         0 ? (
//           <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
//             <button
//               onClick={() => {
//                 onDeleteOpen(cellId);
//               }}
//               size="default"
//               variant="outlined"
//             >
//               Delete
//             </button>
//           </span>
//         ) : null}
//       </>
//     );
//   },
// },
// ];
export const commission_columns = ({
  onDeleteOpen,
  onStatusUpdate,
  allowedActions,
}) => [
    {
      Header: "Sr No.",
      accessor: (d, i) => i + 1,
      Cell: ({ cell }) => (
        <div className="p-2 text-sm text-gray-500">{cell.value}</div>
      ), // Adding a class to the cell
    },
    {
      Header: "Name",
      accessor: (d) =>
        capitalise(
          d?.m_user_commission_relation?.user_first_name
            ? d?.m_user_commission_relation?.user_first_name
            : ""
        ),
    },
    {
      Header: "Referal Code",
      accessor: (d) =>
        capitalise(
          d?.m_user_commission_relation?.user_referral_code
            ? d?.m_user_commission_relation?.user_referral_code
            : ""
        ),
    },
    {
      Header: "Role",
      accessor: (d) =>
        capitalise(
          d?.m_user_commission_relation?.m_role_relation?.role_name
            ? d?.m_user_commission_relation?.m_role_relation?.role_name
            : ""
        ),
    },
    {
      Header: "Parent Role",
      accessor: (d) =>
        capitalise(
          d?.m_user_commission_relation?.user_assigned_relation?.m_role_relation?.role_name
            ? d?.m_user_commission_relation?.user_assigned_relation?.m_role_relation?.role_name
            : ""
        ),
    },
    {
      Header: "Parent Referal Code",
      accessor: (d) =>
        capitalise(
          d?.m_user_commission_relation?.user_assigned_relation?.user_referral_code
            ? d?.m_user_commission_relation?.user_assigned_relation?.user_referral_code
            : ""
        ),
    },

    {
      Header: "Parent Name",
      accessor: (d) =>
        capitalise(
          d?.m_user_commission_relation?.user_assigned_relation?.user_first_name
            ? d?.m_user_commission_relation?.user_assigned_relation?.user_first_name
            : ""
        ),
    },
    {
      Header: "Total Orders",
      accessor: "totalOrders",
    },
    {
      Header: "Offline sales",
      accessor: "offlineSales",
    },
    {
      Header: "Online sales",
      accessor: "onlineSales",
    },
    {
      Header: "Total Direct Sales",
      accessor: "totalDirectSales",
    },
    {
      Header: "Total In-direct Sales",
      accessor: "totalIndirectSales",
    },
    {
      Header: "Total Sale",
      accessor: "totalSale",
    },
    {
      Header: "Total Direct Commission",
      accessor: "totalDirectCommission",
    },
  ];
export const printSubsIssues_columns = ({ handleUpdateLSubs }) => [
  {
    Header: "Sr No.",
    accessor: (d, i) => i + 1,
    Cell: ({ cell }) => (
      <div className="p-2 text-sm text-gray-500">{cell.value}</div>
    ), // Adding a class to the cell
  },
  {
    Header: "Order Id",
    accessor: "subinm_fk_ord_id",
  },
  {
    Header: "Sub ID",
    accessor: "subinm_fk_subs_id",
  },
  {
    Header: "SKU`S Name",
    accessor: (d) => capitalise(d?.subinm_skus_relation?.sku_code),
  },
  {
    Header: "Magazine Name",
    accessor: (d) => capitalise(d?.subinm_master_magazines_relation?.mm_name),
  },
  {
    Header: "Magazine Issues Name",
    accessor: (d) =>
      d?.subinm_master_magazines_relation?.magazine_issues?.length > 0
        ? d?.subinm_master_magazines_relation?.magazine_issues[0]?.mi_name
        : "",
  },
  {
    Header: "Issues Date",
    // accessor: (d) => d?.subinm_master_magazines_relation?.mm_interval_type=='Weekend'?d?.subinm_master_magazines_relation?.magazine_issues?.length>0 ? moment(d?.subinm_master_magazines_relation?.magazine_issues[0]?.mi_release_date).format("DD-MM-YYYY"):moment(d?.subinm_master_magazines_relation?.mm_iss_start).format("DD-MM-YYYY"):d?.subinm_master_magazines_relation?.magazine_issues?.length>0 ? moment(d?.subinm_master_magazines_relation?.magazine_issues[0]?.mi_release_date).format("DD-MM-YYYY") : '',
    accessor: (d) =>
      d?.subinm_master_magazines_relation?.mm_interval_type == "Weekend"
        ? moment(d?.subs_date?.subsDate).format("DD-MM-YYYY")
        : d?.subinm_master_magazines_relation?.magazine_issues?.length > 0
          ? moment(
            d?.subinm_master_magazines_relation?.magazine_issues[0]
              ?.mi_release_date
          ).format("DD-MM-YYYY")
          : "",
  },
  {
    Header: "Customer Name",
    accessor: (d) =>
      capitalise(
        d?.subinm_m_order_relation?.ord_customer_name
          ? d?.subinm_m_order_relation?.ord_customer_name
          : d?.subinm_customer_relation?.customer_username
      ),
  },
  {
    Header: "Customer Email",
    accessor: (d) => capitalise(d?.subinm_customer_relation?.customer_email),
  },
  {
    Header: "Customer Phone",
    accessor: (d) => capitalise(d?.subinm_customer_relation?.customer_mobile),
  },
  {
    Header: "Status",
    accessor: (d) => (d.subinm_is_active === true ? "Active" : "Inactive"),
  },
];

export const RegionReport_columns = ({ handleUpdateLSubs }) => [
  {
    Header: "Sr No.",
    accessor: (d, i) => i + 1,
    Cell: ({ cell }) => (
      <div className="p-2 text-sm text-gray-500">{cell.value}</div>
    ),
  },
  {
    Header: "Order Type",
    accessor: "OrderType",
  },
  {
    Header: "Region Code",
    accessor: "RegionCode",
  },
  {
    Header: "Copy_Del",
    accessor: "CopyDel",
  },
  {
    Header: "Copy_Cour",
    accessor: "CopyCour",
  },
  {
    Header: "Count",
    accessor: "Count",
  },
  {
    Header: "Copies",
    accessor: "Copies",
  },

];

export const IncomeReport_columns = ({ handleUpdateLSubs }) => [
  {
    Header: "Sr No.",
    accessor: (d, i) => i + 1,
    Cell: ({ cell }) => (
      <div className="p-2 text-sm text-gray-500">{cell.value}</div>
    ),
  },
  {
    Header: "Order Type",
    accessor: "orderType",
  },
  {
    Header: "Magzine Code",
    accessor: "magCode",
  },
  {
    Header: "Issue Date",
    accessor: "issueDate",
  },
  {
    Header: "Issues",
    accessor: "issues",
  },
  {
    Header: "Amount",
    accessor: "amount",
  },
  {
    Header: "Copies",
    accessor: "copies",
  },

];


export const barcode_columns = ({
  onDeleteOpen,
  allowedActions,
}) => [
    {
      Header: "SN.",
      accessor: (d, i) => i + 1,
      Cell: ({ cell }) => (
        <div className="p-2 text-sm text-gray-500">{cell.value}</div>
      ), // Adding a class to the cell
    },
    {
      Header: "Barcode No",
      accessor: "barcode_no",
    },
    {
      Header: "Barcode",
      accessor: "barcode",
      Cell: (cell) => {
        const cellId = cell.row.original.barcode_id;
        const barcode_no = cell.row.original.barcode_no;
        return (
          <>
            <BarcodeComponent  barcode_no={barcode_no}/>
          </>
        );
      },
    },
    {
      Header: "Barcode Used",
      accessor: (d) => (d.barcode_is_used == 1 ? 'Used' : 'Not Used'),
    },
    {
      Header: "Barcode Used Count",
      accessor: (d) => (d.barcode_used_count),
    },
    {
      Header: "Created By",
      accessor: (d) =>
        capitalise(
          d?.barcode_cu_relation?.user_username
            ? d?.barcode_cu_relation?.user_username
            : ""
        ),
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell.row.original.barcode_id;
        return (
          <>

            {/* {allowedActions.filter((data) => data.permission_id == 8)?.length > 0 ? ( */}
            <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
              <button
                onClick={() => {
                  onDeleteOpen(cellId);
                }}
                size="default"
                variant="outlined"
              >
                Delete
              </button>
            </span>
            {/* ) : null} */}
          </>
        );
      },
    },
  ];