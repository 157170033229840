import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import MasterServices from "../../../ApiServices/MasterServices";
import ConfirmationModal from "../../../components/ConfirmationModal";
import RefundConfirmationModal from "./RefundConfirmationModal";
import Breadcrumb from "../../../components/Breadcrumb";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Table from "../../../components/tables/table";
import { order_columns } from "../../../components/tables/tableheader";
import { toast } from "react-toast";
import {
  getAllowedActions,
  checkPermissionsAndRedirect,
  capitalizeFirstLetter,
  calculateAge,
} from "../../../helper/commonHelper";
import moment from "moment";
import { utils, writeFile, read } from "xlsx";
import OrderRefundDetailsModal from "./OrderRefundDetailsModal";

const OrderList = () => {
  const [orderData, setOrderData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const pages = [{ title: "Order", href: "/order", module_id: 10 }];
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    ord_id: null,
  });
  const [refundConfirmationModal, setRefundConfirmationModal] = useState({
    status: false,
    ord_id: null,
    item: null,
  });

  const [refundDetailsModal, setRefundDetailsModal] = useState({
    status: false,
    data : []
  });

  const [allowedActions, setAllowedActions] = useState([]);
  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("user_permissions"));
    if (permissions && window.location.pathname) {
      const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect(
        window.location.pathname,
        permissions,
        navigate
      );
      const actions = getAllowedActions(permissions, window.location.pathname);
      setAllowedActions(actions);
    }
  }, []);
  // Filter Values Start
  let filterInitialValues = {
    filterFrom: "",
    filterTo: "",
    filterStatus: "",
    filterOrderStatus: "",
    filterOrderType: "",
    filterOrderId: "",
  };
  let [filterOrder, setFilterOrder] = useState(filterInitialValues);
  const [isSearched, setIsSearched] = useState(false);
  const [filterFlag, setFilterFlag] = useState(true);

  const onFilterClear = () => {
    setIsSearched(false);
    setOrderData([]);
    filterOrder.filterFlag = false;
    setFilterOrder(filterInitialValues);
  };

  const onFilter = () => {
    if (checkObjectValues(filterOrder)) {
      setIsSearched(true);
      filterOrder.filterFlag = true;
      console.log("filterOrder=>", filterOrder);
      getAllOrderData(3);
    } else {
      toast.error("Please Select Alteast One Filter!");
    }
  };

  function checkObjectValues(obj) {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        if (typeof obj[key] === "object") {
          if (checkObjectValues(obj[key])) {
            return true;
          }
        } else if (obj[key] !== "") {
          return true;
        }
      }
    }
    return false;
  }
  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilterOrder({ ...filterOrder, [name]: value });
  };
  const handleSelectFilterChange = (selectedOption, name) => {
    console.log("selectedOption=>", selectedOption);
    const { label, value } = selectedOption || {};
    setFilterOrder({ ...filterOrder, [name]: selectedOption });
  };

  useEffect(() => {
    getAllOrderData(3);
  }, []);
  const getAllOrderData = async (payload) => {
    setLoading(true);
    let obj_payload = {
      status_code: payload,
      filterOrder: filterOrder,
    };
    const response = await MasterServices.getOrderList(obj_payload);
    console.log("response.data=>", response.data);
    if (response.data.status === true) {
      if (response?.data?.data?.length > 0) {
        setOrderData(response?.data?.data);
      } else {
        setOrderData([]);
      }
      setLoading(false);
    }
  };
  const onDeleteOpen = (ord_id) => {
    setConfirmationModal({ ord_id, status: true });
  };

  const onDeleteItem = (ord_id) => {
    console.log("ord_id", ord_id);
    MasterServices.deleteOrder({ ord_id })
      .then((response) => {
        const { data, status } = response;
        if (data.status) {
          setLoading(true);
          toast.success(data.message);
          setConfirmationModal({ ord_id: null, status: false });
          getAllOrderData(3);
          navigate("/order");
        } else {
          toast.error(data.message);
        }
        setLoading(false);
      })
      .catch((error) => {
        // const msg = error.response.data.message;
        toast.error("Sorry Fatal Error!");
        setLoading(false);
      });
  };

  const onStatusUpdate = (ord_id, ord_is_active) => {
    let payload = {
      table_name: "m_order",
      table_field: "ord_id",
      table_field_val: ord_id,
      table_status_field: "ord_is_active",
      table_status_val: ord_is_active ? false : true,
      table_text: "Order",
      deleted_by_key: "ord_updated_by",
      delete_active_txt: ord_is_active ? " Deactivated" : " Activated",
    };
    MasterServices.removeMaster(payload).then((res) => {
      const { data, status, message } = res.data;
      if (status) {
        getAllOrderData(3);
        toast.success(message);
      } else {
        toast.error(message);
      }
    });
  };

  const generateInvoiceReport = async (payload) => {
    let res = await MasterServices.generateInvoiceReport(payload);
    if (res.status == 200) {
      toast.success(res.data.message);
      console.log("Invoice res.data=>", res.data.data);
      window.open(res.data.data.file_path, "_blank");
      getAllOrderData(3);
    } else {
      toast.error(res.data.message);
    }
  };

  // const filterRole = (role) => {
  //   const result = roleList.filter(obj => obj?.role_name?.toLowerCase() === role?.toLowerCase());
  //   let resp = null;
  //   if (result.length > 0) {
  //     resp = result[0].role_id
  //   } else {
  //     resp = undefined;
  //   }
  //   console.log("result=>", result)
  //   return resp;
  // }
  const handleImport = ($event) => {
    const files = $event.target.files;
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;
        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          console.log("Order handleImport: ", rows);
          let newData = [];
          if (rows.length > 0) {
            rows.map((obj, index) => {
              console.log("obj.selectedSkusName=>", obj);
              if (
                obj.customer_username != undefined ||
                obj.selectedSkusName != undefined
              ) {
                const newObj = {
                  customer_username:
                    obj.customer_username !== undefined
                      ? obj.customer_username
                        ? obj.customer_username.trim()
                        : obj.customer_username.trim()
                      : "",
                  selectedSkusName:
                    obj.selectedSkusName !== undefined
                      ? obj.selectedSkusName
                        ? obj.selectedSkusName.toString().trim()
                        : obj.selectedSkusName.toString().trim()
                      : "",
                  customer_gender:
                    obj.customer_gender !== undefined
                      ? obj.customer_gender
                        ? obj.customer_gender.trim()
                        : obj.customer_gender.trim()
                      : "",
                  customer_dob:
                    obj.customer_dob !== undefined
                      ? obj.customer_dob
                        ? obj.customer_dob
                        : obj.customer_dob
                      : "",
                  customer_email:
                    obj.customer_email !== undefined
                      ? obj.customer_email
                        ? obj.customer_email.trim()
                        : obj.customer_email.trim()
                      : "",
                  customer_mobile:
                    obj.customer_mobile !== undefined
                      ? obj?.customer_mobile
                        ? parseInt(obj?.customer_mobile + "")
                        : parseInt(obj?.customer_mobile + "")
                      : "",

                  customer_occupation:
                    obj.customer_occupation !== undefined
                      ? obj.customer_occupation
                        ? obj.customer_occupation.toString().trim()
                        : obj.customer_occupation.toString().trim()
                      : "",
                  customer_designation:
                    obj.customer_designation !== undefined
                      ? obj.customer_designation
                        ? obj.customer_designation.toString().trim()
                        : obj.customer_designation.toString().trim()
                      : "",
                  customer_organisation:
                    obj.customer_organisation !== undefined
                      ? obj.customer_organisation
                        ? obj.customer_organisation.toString().trim()
                        : obj.customer_organisation.toString().trim()
                      : "",
                  customer_address:
                    obj.customer_address !== undefined
                      ? obj.customer_address
                        ? obj.customer_address.toString().trim()
                        : obj.customer_address.toString().trim()
                      : "",
                  pincode:
                    obj.pincode !== undefined
                      ? obj.pincode
                        ? obj.pincode.toString().trim()
                        : obj.pincode.toString().trim()
                      : "",
                  city_name:
                    obj.city_name !== undefined
                      ? obj.city_name
                        ? obj.city_name.toString().trim()
                        : obj.city_name.toString().trim()
                      : "",
                  state_name:
                    obj.state_name !== undefined
                      ? obj.state_name
                        ? obj.state_name.toString().trim()
                        : obj.state_name.toString().trim()
                      : "",
                  country_name:
                    obj.country_name !== undefined
                      ? obj.country_name
                        ? obj.country_name.toString().trim()
                        : obj.country_name.toString().trim()
                      : "",
                  currency:
                    obj.currency !== undefined
                      ? obj.currency
                        ? obj.currency.toString().trim()
                        : obj.currency.toString().trim()
                      : "",
                  ord_user_referral_code:
                    obj.ord_user_referral_code !== undefined
                      ? obj.ord_user_referral_code
                        ? obj.ord_user_referral_code.toString().trim()
                        : obj.ord_user_referral_code.toString().trim()
                      : "",
                  payment_type:
                    obj.payment_type !== undefined
                      ? obj.payment_type
                        ? obj.payment_type.toString().trim()
                        : obj.payment_type.toString().trim()
                      : "",
                  trans_card_id:
                    obj.trans_card_id !== undefined
                      ? obj.trans_card_id
                        ? obj.trans_card_id.toString().trim()
                        : obj.trans_card_id.toString().trim()
                      : "",
                  trans_amount:
                    obj.trans_amount !== undefined
                      ? obj.trans_amount
                        ? obj.trans_amount.toString().trim()
                        : obj.trans_amount.toString().trim()
                      : "",
                  trans_bank_date:
                    obj.trans_bank_date !== undefined
                      ? obj.trans_bank_date
                        ? obj.trans_bank_date
                        : obj.trans_bank_date
                      : "",
                  trans_bank:
                    obj.trans_bank !== undefined
                      ? obj.trans_bank
                        ? obj.trans_bank.toString().trim()
                        : obj.trans_bank.toString().trim()
                      : "",
                  trans_branch_name:
                    obj.trans_branch_name !== undefined
                      ? obj.trans_branch_name
                        ? obj.trans_branch_name.toString().trim()
                        : obj.trans_branch_name.toString().trim()
                      : "",
                  selectedSkusName:
                    obj.selectedSkusName !== undefined
                      ? obj.selectedSkusName
                        ? obj.selectedSkusName.toString().trim()
                        : obj.selectedSkusName.toString().trim()
                      : "",
                };

                newData.push(newObj);
              }
            });

            console.log("newArray: ", newData);
            const filteredArray = newData;
            console.log("filteredArray: ", filteredArray);

            if (filteredArray.length > 0) {
              importOrder(filteredArray);
            }
          } else {
            toast.success("Excel is empty");
          }
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const importOrder = (data) => {
    const payload = { excel_data: data };
    MasterServices.orderExcelImport(payload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success("Order Imported Successfully");
          getAllOrderData(3);
          navigate("/order");
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        const msg = "Error while saving";
        toast.error(msg);
      });
  };

  const handleExport = (excel_type) => {
    if (!orderData || orderData.length === 0) {
      toast.error("User list is empty!");
      return;
    }
    if (orderData?.length > 0) {
      let newSheetdata = [];
      orderData.map((obj, index) => {
        const newData = {
          SN: parseInt(index) + 1,
          customer_id: obj?.ord_customer_relation?.customer_id || "-",
          ord_customer_name: obj?.ord_customer_name
            ? capitalizeFirstLetter(obj?.ord_customer_name)
            : "-" || "-",
          customer_email: obj?.ord_customer_relation?.customer_email || "-",
          customer_mobile: obj?.ord_customer_relation?.customer_mobile || "-",
          customer_gender: obj?.ord_customer_relation?.customer_gender || "-",
          customer_dob:
            moment(obj?.ord_customer_relation?.customer_dob).format(
              "DD-MM-YYYY"
            ) || "-",
          customer_age:
            calculateAge(obj?.ord_customer_relation?.customer_dob) || "-",
          customer_occupation: obj?.ord_customer_relation?.customer_occupation
            ? capitalizeFirstLetter(
                obj?.ord_customer_relation?.customer_occupation
              )
            : "-" || "-",
          customer_designation: obj?.ord_customer_relation?.customer_designation
            ? capitalizeFirstLetter(
                obj?.ord_customer_relation?.customer_designation
              )
            : "-" || "-",
          customer_organisation: obj?.ord_customer_relation
            ?.customer_organisation
            ? capitalizeFirstLetter(
                obj?.ord_customer_relation?.customer_organisation
              )
            : "-" || "-",
          ord_customer_customer_address:
            obj?.ord_customer_customer_address || "-",
          ord_customer_city_name: obj?.ord_customer_city_name
            ? capitalizeFirstLetter(obj?.ord_customer_city_name)
            : "-" || "-",
          ord_customer_state_name: obj?.ord_customer_state_name
            ? capitalizeFirstLetter(obj?.ord_customer_state_name)
            : "-" || "-",
          ord_customer_country_name: obj?.ord_customer_country_name
            ? capitalizeFirstLetter(obj?.ord_customer_country_name)
            : "-" || "-",
          ord_customer_pincode: obj?.ord_customer_pincode || "-",
          ord_id: obj?.ord_id || "-",
          ord_sys_order_id: obj?.ord_sys_order_id || "-",

          ord_user_referral_code: obj?.ord_user_referral_code || "-",
          user_username: obj?.ord_ref_m_user_relation?.user_username || "-",
          skus_names: obj?.skus_names || "-",
          // ord_total_price_amt: obj?.ord_total_price_amt || '-',
          // ord_total_discount_amt: obj?.ord_total_discount_amt || '-',
          ord_total_main_amt: obj?.ord_total_main_amt || "-",
          ord_invoice_number: obj?.ord_invoice_number || "-",
          payment_type: obj?.payment_type || "-",
          ord_payment_status: obj?.ord_payment_status || "-",
          trans_method: obj?.trans_method || "-",
          paid_amt: obj?.paid_amt || "-",
          payment_dd_cheque_no: obj?.payment_dd_cheque_no || "-",
          payment_dd_cheque_date:
            moment(obj?.payment_dd_cheque_date).format("DD-MM-YYYY") || "-",

          payment_dd_cheque_bank_name: obj?.payment_dd_cheque_bank_name || "-",
          payment_dd_cheque_branch_name:
            obj?.payment_dd_cheque_branch_name || "-",
          ord_created_at:
            moment(obj?.ord_created_at).format("DD-MM-YYYY") || "-",
          Status: obj?.ord_is_active == true ? "Active" : "In-Active" || "-",
        };
        newSheetdata.push(newData);
      });
      let currentDate = new Date();
      let fileName =
        "Order_Excel_Report_" +
        moment(currentDate).format("DD-MM-YYYY") +
        "." +
        excel_type;

      // 'Price', 'Discount Price',
      const headings = [
        [
          "SN",
          "Customer ID",
          "Customer Name",
          "Customer Email",
          "Customer Mobile",
          "Customer Gender",
          "Customer DOB",
          "Customer Age",
          "Customer Occupation",
          "Customer Designation",
          "Customer Organisation",
          "Customer Address",
          "Customer City",
          "Customer State",
          "Customer Country",
          "Customer Pincode",
          "Order ID",
          "Order No",
          "User Referral Code",
          "Referral User Name",
          "SKUS Name",
          "Total Final Price",
          "Invoice No",
          "Order Type",
          "Payment Status",
          "Payment Method",
          "Paid AMT",
          "Card/Cheque No",
          "Payment Date",
          "Bank Name",
          "Bank Branch Name",
          "Created Date",
          "Status",
        ],
      ];
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);
      utils.sheet_add_aoa(ws, headings);
      utils.sheet_add_json(ws, newSheetdata, {
        origin: "A2",
        skipHeader: true,
      });
      utils.book_append_sheet(wb, ws, "Report");
      writeFile(wb, fileName);
    } else {
      toast.error("Data Not Found!");
    }
  };

  const handleRefund = (item) => {
    setRefundConfirmationModal({
      ord_id: item.ord_id,
      status: true,
      item: item,
    });
  };
  const [loadingRefundData, setLoadingRefundData] = useState(false);  
  const handleRefundDetails = (item) => {
    setLoadingRefundData(true)
    const payload = {
      ord_id: item?.ord_id,
      customer_id: item?.fk_customer_id,
      cust_add_id: item?.fk_cust_add_id,
    };
    console.log(payload);
    MasterServices.getOrderRefundDetails(payload).then((res) => {
      console.log(res);
      setLoadingRefundData(false)
      const { data, status, message } = res.data;
      setRefundDetailsModal({
        status: true,
        data : res.data.data
      });
    });
  };

  const proceedRefund = (item) => {
    // console.log("item handleRefund=>",item);return;
    let payload = {
      ...item,
    };
    MasterServices.refundRazorPay(payload).then((res) => {
      const { data, status, message } = res.data;
      if (status) {
        getAllOrderData(3);
        toast.success(message);
      } else {
        toast.error(message);
      }

      setRefundConfirmationModal({ ord_id: null, status: false, item: {} });
    });
  };
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <RefundConfirmationModal
        open={refundConfirmationModal.status}
        setOpen={setRefundConfirmationModal}
        onDelete={() => proceedRefund(refundConfirmationModal.item)}
      />
      <OrderRefundDetailsModal
        open={refundDetailsModal.status}
        setOpen={setRefundDetailsModal}
        loading={loadingRefundData}
        data={refundDetailsModal.data}
      />
      <ConfirmationModal
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => onDeleteItem(confirmationModal.ord_id)}
      />
      <Breadcrumb pages={pages} />
      {filterFlag && (
        <div className="px-2 py-2 pb-5 sm:px-4 rounded-md bg-white shadow-lg ring-1 ring-white ring-opacity-5 focus:outline-none mb-2">
          <button
            type="button"
            className="text-red-500 absolute right-20 hover:text-red-700 font-bold py-1 px-1 rounded-full border border-red-500 hidden"
            onClick={() => {
              setFilterFlag(false);
              onFilterClear();
            }}
          >
            X
          </button>
          <div className="space-y-6 sm:space-y-5">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 mt-5">
              <div className="sm:col-span-1 lg:col-span-1">
                <label
                  htmlFor="filterFrom"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Start Date
                </label>
                <input
                  type="date"
                  name="filterFrom"
                  id="filterFrom"
                  value={filterOrder.filterFrom}
                  onChange={handleFilterChange}
                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                />
              </div>
              <div className="sm:col-span-1 lg:col-span-1">
                <label
                  htmlFor="filterTo"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  End Date
                </label>
                <input
                  type="date"
                  name="filterTo"
                  id="filterTo"
                  value={filterOrder.filterTo}
                  onChange={handleFilterChange}
                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                />
              </div>

              <div className="sm:col-span-1 lg:col-span-1">
                <label
                  htmlFor="filterOrderId"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Order ID
                </label>
                <input
                  type="number"
                  name="filterOrderId"
                  id="filterOrderId"
                  value={filterOrder.filterOrderId}
                  onChange={handleFilterChange}
                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                />
              </div>
              <div className="sm:col-span-1 lg:col-span-1">
                <label
                  htmlFor="filterStatus"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Status
                </label>
                <select
                  name="filterStatus"
                  value={filterOrder.filterStatus}
                  onChange={handleFilterChange}
                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                >
                  <option value="">Select Status</option>
                  <option value="1">Active</option>
                  <option value="2">Inactive</option>
                </select>
              </div>
              <div className="sm:col-span-1 lg:col-span-1">
                <label
                  htmlFor="filterOrderStatus"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Order Status
                </label>
                <select
                  name="filterOrderStatus"
                  value={filterOrder.filterOrderStatus}
                  onChange={handleFilterChange}
                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                >
                  <option value="">Select Order Status</option>
                  <option value="Paid">Paid</option>
                  <option value="Initiated">Initiated</option>
                </select>
              </div>
              <div className="sm:col-span-1 lg:col-span-1">
                <label
                  htmlFor="filterOrderType"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Order Type
                </label>
                <select
                  name="filterOrderType"
                  value={filterOrder.filterOrderType}
                  onChange={handleFilterChange}
                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                >
                  <option value="">Select Order Type</option>
                  <option value="1">Online</option>
                  <option value="2">Offline</option>
                </select>
              </div>

              <div className="sm:col-span-1 md:col-span-2 lg:col-span-2 mt-6">
                <button
                  type="button"
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2"
                  onClick={onFilter}
                >
                  Search
                </button>
                <button
                  type="button"
                  className="inline-flex items-center justify-center ml-2 rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2"
                  onClick={onFilterClear}
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">Order List</h1>
        {/* <p className="mt-2 text-sm text-gray-700">A list of all the Order.</p> */}
      </div>
      <div className="mt-4 mb-4 flex">
        {allowedActions.filter((data) => data.permission_id == 26)?.length >
        0 ? (
          <Link
            to={"/add-order"}
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144]  px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#BE3144] focus:ring-offset-2 ml-auto"
          >
            Add Order
          </Link>
        ) : null}

        {allowedActions.filter((data) => data.permission_id == 86)?.length >
          0 && orderData?.length > 0 ? (
          <Link
            to={"#"}
            onClick={() => handleExport("xlsx")}
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2 ml-2"
          >
            Download Excel
          </Link>
        ) : null}
        {allowedActions.filter((data) => data.permission_id == 87)?.length >
        0 ? (
          <label
            htmlFor="file-upload"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-3 py-1 text-xs font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2 ml-2 cursor-pointer transition duration-300 ease-in-out"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4 mr-1"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 12a2 2 0 100-4 2 2 0 000 4z"
                clipRule="evenodd"
              />
              <path
                fillRule="evenodd"
                d="M12 10a4 4 0 11-8 0 4 4 0 018 0zm2-2a6 6 0 11-12 0 6 6 0 0112 0z"
                clipRule="evenodd"
              />
              <path d="M10 2a8 8 0 00-8 8c0 4.418 3.582 8 8 8s8-3.582 8-8a8 8 0 00-8-8zM5.293 8.707a1 1 0 011.414 0L10 11.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
            </svg>
            Upload Order
            <input
              id="file-upload"
              type="file"
              className="hidden"
              onChange={handleImport}
            />
          </label>
        ) : null}
      </div>
      {loading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={order_columns({
            onDeleteOpen,
            onStatusUpdate,
            allowedActions,
            generateInvoiceReport,
            handleRefund,
            handleRefundDetails,
          })}
          data={orderData}
          is_toggle={false}
        />
      )}
    </div>
  );
};

export default OrderList;
