import React, { useState, useEffect } from "react";

const PrintAddressTable = (props) => {
  const [data, setData] = useState(
    props.printAddressData
      ? Array.isArray(props.printAddressData)
        ? props.printAddressData
        : [props.printAddressData]
      : []
  );

  useEffect(() => {
    if (props.printAddressData) {
      // Remove duplicates based on 'subinm_id'
      let uniqueData = props.printAddressData
        ? Array.isArray(props.printAddressData)
          ? props.printAddressData
          : [props.printAddressData]
        : [];
      uniqueData = uniqueData.filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.subinm_id === item.subinm_id)
      );
      // Sort the unique data by the first element (subinm_id) in ascending order
      const sortedData = [...uniqueData].sort(
        (a, b) => a.subinm_id - b.subinm_id
      );
      setData(sortedData);
    }
  }, [props.printAddressData]); // Empty dependency array ensures this runs only once on initial render

  console.log(data);
  const renderTable = () => {
    const tableRows = [];
    const tableData = data.slice().reverse();
    for (let i = 0; i < tableData.length; i += 2) {
      const firstElement = tableData[i];
      const secondElement = tableData[i + 1];
      let row;
      if (secondElement) {
        row = (
          <tr key={i}>
            <td colSpan={2}>
              <div class="p-6 bg-white border border-gray-200 rounded-lg shadow  dark:border-gray-700 " style={{ height: "140px", width: "95%", margin: "10px 0px" }}>
                <a href="#">
                  <h5 class="mb-2 text-1xl font-semibold tracking-tight dark:text-black">
                    {firstElement
                      ? `${firstElement.subinm_m_order_relation
                        .ord_customer_name ||
                      firstElement.subinm_customer_relation
                        .customer_first_name ||
                      firstElement.subinm_customer_relation
                        .customer_username
                      }`
                      : ""}
                  </h5>
                </a>
                <p class="mb-3 font-normal text-gray-500 dark:text-back-400 text-xs">
                  {firstElement
                    ? `${firstElement.subinm_m_order_relation
                      .ord_customer_customer_address || "Address not found"
                    }`
                    : ""}
                </p>
              </div>
            </td>
            <td colSpan={2}>
              <div class="p-6 bg-white border border-gray-200 rounded-lg shadow  dark:border-gray-700" style={{ height: "140px", width: "95%", margin: "10px 0px" }}>
                <a href="#">
                  <h5 class="mb-2 text-1xl font-semibold tracking-tight dark:text-black">
                    {firstElement
                      ? `${secondElement.subinm_m_order_relation
                        .ord_customer_name ||
                      secondElement.subinm_customer_relation
                        .customer_first_name ||
                      secondElement.subinm_customer_relation
                        .customer_username
                      }`
                      : ""}
                  </h5>
                </a>
                <p class="mb-3 font-normal text-gray-500 dark:text-back-400 text-xs">
                  {secondElement
                    ? `${secondElement.subinm_m_order_relation
                      .ord_customer_customer_address || "Address not found"
                    }`
                    : ""}
                </p>
              </div>
            </td>
          </tr>
        );
      } else {
        row = (
          <tr key={i}>
            <td colSpan={2}>
              <div class="p-6 bg-white border border-gray-200 rounded-lg shadow  dark:border-gray-700" style={{ height: "100px", width: "95%", margin: "10px 0px" }}>
                <a href="#">
                  <h5 class="mb-2 text-1xl font-semibold tracking-tight dark:text-black">
                    {firstElement
                      ? `${firstElement.subinm_m_order_relation
                        .ord_customer_name ||
                      firstElement.subinm_customer_relation
                        .customer_first_name ||
                      firstElement.subinm_customer_relation
                        .customer_username
                      }`
                      : ""}
                  </h5>
                </a>
                <p class="mb-3 font-normal text-gray-500 dark:text-back-400 text-xs">
                  {firstElement ? (
                    `${firstElement.subinm_m_order_relation.ord_customer_customer_address}${firstElement.subinm_m_order_relation.ord_customer_customer_appartment ? `, ${firstElement.subinm_m_order_relation.ord_customer_customer_appartment}` : ''}` || "Address not found"
                  ) : ""}
                </p>
              </div>
            </td>
          </tr>
        );
      }
      tableRows.push(row);
    }

    return (
      <table className="table-auto w-full">
        <tbody>{tableRows}</tbody>
      </table>
    );
  };

  const handlePrint = () => {
    const printableArea = document.getElementById("printable");
    if (printableArea) {
      const iframe = document.createElement("iframe");
      iframe.style.display = "none";
      document.body.appendChild(iframe);
      const content = printableArea.innerHTML;
      const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
      iframeDoc.write("<html><head><title>Print</title>");
      // Include CSS for printing
      iframeDoc.write(`<style>
      @import url('https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap'); 
  body {  
    line-height:inherit;
    font-family:'Poppins', sans-serif !important;
  }  
body { 
/* CSS Variables that may have been missed get put on body */ 
    --tw-translate-x:  0;  
    --tw-translate-y:  0;  
    --tw-rotate:  0;  
    --tw-skew-x:  0;  
    --tw-skew-y:  0;  
    --tw-scale-x:  1;  
    --tw-scale-y:  1;  
    --tw-ring-offset-shadow:  0 0 #0000;  
    --tw-ring-shadow:  0 0 #0000;  
    --tw-shadow:  0 0 #0000;  
    --tw-translate-x:  0;  
    --tw-translate-y:  0;  
    --tw-rotate:  0;  
    --tw-skew-x:  0;  
    --tw-skew-y:  0;  
    --tw-scale-x:  1;  
    --tw-scale-y:  1;  
    --tw-ring-offset-shadow:  0 0 #0000;  
    --tw-ring-shadow:  0 0 #0000;  
    --tw-shadow:  0 0 #0000;  
    --tw-translate-x:  0;  
    --tw-translate-y:  0;  
    --tw-rotate:  0;  
    --tw-skew-x:  0;  
    --tw-skew-y:  0;  
    --tw-scale-x:  1;  
    --tw-scale-y:  1;  
    --tw-ring-offset-shadow:  0 0 #0000;  
    --tw-ring-shadow:  0 0 #0000;  
    --tw-shadow:  0 0 #0000;  
    --tw-gradient-from:  #872341 var(--tw-gradient-from-position);  
    --tw-gradient-to:  rgb(135 35 65 / 0) var(--tw-gradient-to-position);  
    --tw-gradient-stops:  var(--tw-gradient-from), var(--tw-gradient-to); 
    --tw-gradient-to:  #BE3144 var(--tw-gradient-to-position); 
    --tw-text-opacity:  1;  
    --tw-shadow:  0 1px 2px 0 rgb(0 0 0 / 0.05);  
    --tw-shadow-colored:  0 1px 2px 0 var(--tw-shadow-color);  
    --tw-bg-opacity:  1;  
    --tw-border-opacity:  1;  
    --tw-bg-opacity:  1;  
    --tw-shadow:  0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);  
    --tw-shadow-colored:  0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);  
    --tw-border-opacity:  1;  
    --tw-text-opacity:  1;  
    --tw-text-opacity:  1;  
} 

* { 
    box-sizing: border-box; 
    border-width: 0; 
    border-style: solid; 
    border-color: #e5e7eb;
} 

* { 
    --tw-translate-x: 0; 
    --tw-translate-y: 0; 
    --tw-rotate: 0; 
    --tw-skew-x: 0; 
    --tw-skew-y: 0; 
    --tw-scale-x: 1; 
    --tw-scale-y: 1; 
    --tw-ring-offset-shadow: 0 0 #0000; 
    --tw-ring-shadow: 0 0 #0000; 
    --tw-shadow: 0 0 #0000; 
} 

.rounded-lg { 
    border-radius: 0.5rem;
} 

@media (min-width: 640px){ 
  .sm\:w-full { 
    width: 100%;
  } 

  .sm\:max-w-5xl { 
    max-width: 64rem;
  } 
}     

.flex { 
    display: flex;
} 

.items-center { 
    align-items: center;
} 

.justify-center { 
    justify-content: center;
} 

body { 
    margin: 0; 
    line-height: inherit;
} 

body { 
    font-family: 'Poppins', sans-serif !important;
} 

html { 
    line-height: 1.5; 
    -webkit-text-size-adjust: 100%; 
    tab-size: 4; 
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; 
    font-feature-settings: normal; 
    font-variation-settings: normal;
} 

html { 
    font-family: 'Poppins', sans-serif !important;
} 

.p-6 { 
    padding: 1.5rem;
} 

*,:before,:after { 
    box-sizing: border-box; 
    border-width: 0; 
    border-style: solid; 
    border-color: #e5e7eb;
} 

*,:before,:after { 
    --tw-translate-x: 0; 
    --tw-translate-y: 0; 
    --tw-rotate: 0; 
    --tw-skew-x: 0; 
    --tw-skew-y: 0; 
    --tw-scale-x: 1; 
    --tw-scale-y: 1; 
    --tw-ring-offset-shadow: 0 0 #0000; 
    --tw-ring-shadow: 0 0 #0000; 
    --tw-shadow: 0 0 #0000; 
} 

:backdrop { 
    --tw-translate-x: 0; 
    --tw-translate-y: 0; 
    --tw-rotate: 0; 
    --tw-skew-x: 0; 
    --tw-skew-y: 0; 
    --tw-scale-x: 1; 
    --tw-scale-y: 1; 
    --tw-ring-offset-shadow: 0 0 #0000; 
    --tw-ring-shadow: 0 0 #0000; 
    --tw-shadow: 0 0 #0000; 
} 

.justify-end { 
    justify-content: flex-end;
} 

.flex-shrink-0 { 
    flex-shrink: 0;
} 

button { 
    font-family: inherit; 
    font-feature-settings: inherit; 
    font-variation-settings: inherit; 
    font-size: 100%; 
    font-weight: inherit; 
    line-height: inherit; 
    color: inherit; 
    margin: 0; 
    padding: 0;
} 

button { 
    text-transform: none;
} 

button { 
    cursor: pointer;
} 

button,[type="button"] { 
    -webkit-appearance: button; 
    background-color: transparent; 
    background-image: none;
} 

.mt-2 { 
    margin-top: 0.5rem;
} 

.inline-flex { 
    display: inline-flex;
} 

.rounded-md { 
    border-radius: 0.375rem;
} 

.border { 
    border-width: 1px;
} 

.border-transparent { 
    border-color: transparent;
} 

.bg-gradient-to-b { 
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
} 

.from-\[\#872341\] { 
    --tw-gradient-from: #872341 var(--tw-gradient-from-position); 
    --tw-gradient-to: rgb(135 35 65 / 0) var(--tw-gradient-to-position); 
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
} 

.to-\[\#BE3144\] { 
    --tw-gradient-to: #BE3144 var(--tw-gradient-to-position);
} 

.px-4 { 
    padding-left: 1rem; 
    padding-right: 1rem;
} 

.py-2 { 
    padding-top: 0.5rem; 
    padding-bottom: 0.5rem;
} 

.text-sm { 
    font-size: 0.875rem; 
    line-height: 1.25rem;
} 

.font-medium { 
    font-weight: 500;
} 

.text-white { 
    --tw-text-opacity: 1; 
    color: rgb(255 255 255 / var(--tw-text-opacity));
} 

.shadow-sm { 
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05); 
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color); 
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
} 

.hover\:bg-\[\#872341\]:hover { 
    --tw-bg-opacity: 1; 
    background-color: rgb(135 35 65 / var(--tw-bg-opacity));
} 

table { 
    text-indent: 0; 
    border-color: inherit; 
    border-collapse: collapse;
} 

.w-full { 
    width: 100%;
} 

.table-auto { 
    table-layout: auto;
} 

.sr-only { 
    position: absolute; 
    width: 1px; 
    height: 1px; 
    padding: 0; 
    margin: -1px; 
    overflow: hidden; 
    clip: rect(0, 0, 0, 0); 
    white-space: nowrap; 
    border-width: 0;
} 

svg { 
    display: block; 
    vertical-align: middle;
} 

.h-6 { 
    height: 1.5rem;
} 

.w-6 { 
    width: 1.5rem;
} 

.border-gray-200 { 
    --tw-border-opacity: 1; 
    border-color: rgb(229 231 235 / var(--tw-border-opacity));
} 

.bg-white { 
    --tw-bg-opacity: 1; 
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
} 

.shadow { 
    --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1); 
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color); 
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
} 

@media (prefers-color-scheme: dark){ 
  .dark\:border-gray-700 { 
    --tw-border-opacity: 1; 
    border-color: rgb(55 65 81 / var(--tw-border-opacity));
  } 
}     

a { 
    color: inherit; 
    text-decoration: inherit;
} 

p { 
    margin: 0;
} 

.mb-3 { 
    margin-bottom: 0.75rem;
} 

.font-normal { 
    font-weight: 400;
} 

.text-gray-500 { 
    --tw-text-opacity: 1; 
    color: rgb(107 114 128 / var(--tw-text-opacity));
} 

h5 { 
    font-size: inherit; 
    font-weight: inherit;
} 

h5 { 
    margin: 0;
} 

.mb-2 { 
    margin-bottom: 0.5rem;
} 

.text-2xl { 
    font-size: 1.5rem; 
    line-height: 2rem;
} 

.font-semibold { 
    font-weight: 600;
} 

.tracking-tight { 
    letter-spacing: -0.025em;
} 

@media (prefers-color-scheme: dark){ 
  .dark\:text-black { 
    --tw-text-opacity: 1; 
    color: rgb(0 0 0 / var(--tw-text-opacity));
  } 
}     


/* These were inline style tags. Uses id+class to override almost everything */
#style-cUDtM.style-cUDtM {  
   height: 140px;  
    width: 95%;  
    margin: 10px 0px;  
}  
#style-aKYOg.style-aKYOg {  
   height: 140px;  
    width: 95%;  
    margin: 10px 0px;  
}  
#style-LxogY.style-LxogY {  
   height: 140px;  
    width: 95%;  
    margin: 10px 0px;  
}  
#style-iB527.style-iB527 {  
   height: 140px;  
    width: 95%;  
    margin: 10px 0px;  
}  
#style-gk8yH.style-gk8yH {  
   height: 140px;  
    width: 95%;  
    margin: 10px 0px;  
}  
#style-ZqjIX.style-ZqjIX {  
   height: 140px;  
    width: 95%;  
    margin: 10px 0px;  
}  
#style-m8ive.style-m8ive {  
   height: 140px;  
    width: 95%;  
    margin: 10px 0px;  
}  
#style-y2D63.style-y2D63 {  
   height: 140px;  
    width: 95%;  
    margin: 10px 0px;  
}  
#style-81KsP.style-81KsP {  
   height: 140px;  
    width: 95%;  
    margin: 10px 0px;  
}  
#style-LkglB.style-LkglB {  
   height: 140px;  
    width: 95%;  
    margin: 10px 0px;  
}  
#style-t3Zia.style-t3Zia {  
   height: 140px;  
    width: 95%;  
    margin: 10px 0px;  
}  
#style-4RoK9.style-4RoK9 {  
   height: 140px;  
    width: 95%;  
    margin: 10px 0px;  
}      
</style>`);
      iframeDoc.write("</head><body>");
      iframeDoc.write(content);
      iframeDoc.write("</body></html>");
      iframeDoc.close();
      iframe.contentWindow.focus();
      iframe.contentWindow.print();
      document.body.removeChild(iframe);
    } else {
      console.error("Cannot find printable area.");
    }
  };
  return (
    <div>
      <div id="printable">{data && renderTable()}</div>
      <button
        className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2 mt-2"
        type="button"
        onClick={() => {
          handlePrint();
        }}
        size="default"
        variant="outlined"
      >
        Print
      </button>
    </div>
  );
};

export default PrintAddressTable;
