import React, { useEffect, useState } from 'react'
import { FallingLinesLoader } from '../../../components/spinners/Spinner'
import Table from '../../../components/tables/table'
import Breadcrumb from '../../../components/Breadcrumb'
import ReportServices from '../../../ApiServices/ReportServices'
import { SubscriptionReport_columns } from '../../../components/tables/tableheader'
import { getAllowedActions, checkPermissionsAndRedirect } from "../../../helper/commonHelper";
import { Link, useNavigate } from "react-router-dom";
import moment from 'moment';
import { convertToAnotherTimezone, calculateAge } from '../../../helper/commonHelper';
import { utils, writeFile } from 'xlsx';
import { toast } from 'react-toast';

const SubscriptionReport = () => {
  const pages = [{ title: "Subscription Report List", href: "/subscription-report", module_id: 40 }];
  const [isLoading, setIsLoading] = useState(false)
  const [subscriptionReportList, setSubscriptionReportList] = useState([])

  const navigate = useNavigate();
  const [allowedActions, setAllowedActions] = useState([]);
  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("user_permissions"));
    if (permissions && window.location.pathname) {
      const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect(window.location.pathname, permissions, navigate);
      const actions = getAllowedActions(permissions, window.location.pathname);
      setAllowedActions(actions);
    }
  }, []);

  useEffect(() => {
    getAllSubscriptionReports()
  }, [])

  const getAllSubscriptionReports = () => {
    setIsLoading(true)
    ReportServices.getAllSubscriptionReport(3).then((res) => {
      const { data, status } = res;
      if (status) {
        setSubscriptionReportList(data.data)
      }
      setIsLoading(false)
    }).catch((error) => {
      console.log(error)

    });
  }

  const handleExport = (excel_type) => {

    if (subscriptionReportList?.length > 0) {
      let newSheetdata = [];
      subscriptionReportList.map((obj, index) => {
        const newData = {
          subs_id: obj?.subs_id || '-',
          TRANS_DATE: moment(obj?.getOrder?.m_transaction[0]?.trans_created_at).format("DD-MM-YYYY") || '-',
          PURCHASE_REF: obj?.getOrder?.m_transaction[0]?.trans_upi_transaction_id || '-',
          CURRENCY: obj?.Currency || '-',
          PAID_Value: obj?.getOrder?.ord_total_main_amt || '-',
          DURATION_MONTH: obj?.subs_master_magazines_relation?.mm_month_interval || '-',
          TYPE: obj?.Type || '-',
          MAGAZINE: obj?.subs_master_magazines_relation?.mm_name || '-',
          NO_OF_SUB: obj?.subscription_interval_map.length || '-',
          START_DATE: obj?.Type || '-',
          EXPIRY_DATE: obj?.Type || '-',
          PAYMENT_SOURCE: obj?.Type || '-',
          SUB_SOURCE: obj?.Type || '-',
          UTM_CODE: obj?.getOrder?.ord_ref_m_user_relation?.user_referral_code || '-',
          DIGITAL_VALUE: obj?.Type || '-',
          PRINT_VALUE: obj?.Type || '-',
          DISCOUNT: obj?.Type || '-',
          DISC_VALUE: obj?.Type || '-',
          TAXABLE_VALUE: obj?.Type || '-',
          NON_TAXABLE_VALUE: obj?.Type || '-',
          IGST: obj?.Type || '-',
          CGST: obj?.Type || '-',
          SGST: obj?.Type || '-',
          INVOICE_VALUE: obj?.Type || '-',
          BUNDAL_PRICE: obj?.Type || '-',
          GSTIN_NO_OF_SUBSCRIBER: obj?.Type || '-',
          RECEIPT_NO: obj?.Type || '-',
          INVOICE_LINK: obj?.Type || '-',
          OFFER_NAME: obj?.Type || '-',
          GIFT_NAME: obj?.Type || '-',
          GIFT_DISPATCH_DATE: obj?.Type || '-',
          DISPACH_REF_NO: obj?.Type || '-',
          REF_NO: obj?.Type || '-',
          EMAIL: obj?.subs_customer_relation?.customer_email || '-',
          PHONE_NUMBER: obj?.subs_customer_relation?.customer_mobile || '-',
          City: obj?.getOrder?.ord_customer_adress_relation?.city_name || '-',
          Gender: obj?.subs_customer_relation?.customer_gender || '-',
          State: obj?.getOrder?.ord_customer_adress_relation?.state_name || '-',
          Occupation: obj?.subs_customer_relation?.customer_occupation || '-',
          Designation: obj?.subs_customer_relation?.customer_designation || '-',
          Age: calculateAge(moment(obj?.subs_customer_relation?.customer_dob).format("YYYY-MM-DD")) || '0',
        }
        newSheetdata.push(newData);
      });
      let currentDate = new Date();
      let fileName = 'Subscription_Report_' + moment(currentDate).format("DD-MM-YYYY") + '.' + excel_type;

      const headings = [['ID', 'TRANS_DATE', 'PAYMENT/PURCHASE_REF.NO', 'CURRENCY', 'PAID VALUE', 'DURATION_MONTH', 'TYPE', 'MAGAZINE', 'NO_OF_SUB', 'START_DATE', 'EXPIRY_DATE', 'PAYMENT_SOURCE', 'SUB_SOURCE', 'EXECUTIVE/UTM_CODE', 'DIGITAL_VALUE', 'PRINT_VALUE', 'DISCOUNT %', 'DISC_VALUE', 'TAXABLE_VALUE', 'NON_TAXABLE_VALUE', 'IGST', 'CGST', 'SGST', 'INVOICE_VALUE', 'BUNDAL_PRICE', 'GSTIN_NO_OF_SUBSCRIBER', 'INVOICE/RECEIPT_NO', 'INVOICE_LINK', 'OFFER_NAME', 'GIFT_NAME', 'GIFT_DISPATCH_DATE', 'DISPACH_REF_NO', 'MRO_(REF_NO)', 'EMAIL', 'PHONE_NUMBER', 'City', 'Gender', 'State', 'Occupation', 'Designation', 'Age']];
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);
      utils.sheet_add_aoa(ws, headings);
      utils.sheet_add_json(ws, newSheetdata, { origin: 'A2', skipHeader: true });
      utils.book_append_sheet(wb, ws, 'Report');
      writeFile(wb, fileName);
    } else {
      toast.error("Data Not Found!");
    }
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <Breadcrumb pages={pages} />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">Subscription Report</h1>
      </div>

      <div className="flex justify-end">
        <div className="mt-4 flex">
          {allowedActions.filter((data) => data.permission_id == 76)?.length > 0 && subscriptionReportList?.length > 0 ? (
            <Link
              to={"#"}
              onClick={() => handleExport('xlsx')}
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#872341] to-[#BE3144] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2"
            >
              Download Excel
            </Link>) : null}
        </div>
      </div>

      {isLoading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={SubscriptionReport_columns({ allowedActions })}
          data={subscriptionReportList ? subscriptionReportList : []}
          is_toggle={false}
        />
      )}
    </div>
  )
}

export default SubscriptionReport